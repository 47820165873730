import {
  Retrieval,
  error,
  idleRetrieval,
  success,
  workingRetrival,
} from '@propsfantasy/retrieval';
import produce from 'immer';
import { getType } from 'typesafe-actions';
import { srNflWeek } from '../../models/sportradar';
import { RootActions } from '../rootActions';
import { IRootState } from '../rootReducer';
import {
  requestCurrentWeekSchedule,
  requestFullSchedule,
} from './scheduleActions';

export const BEGDATE_TO_ENDDATE = new Map(); //FULL SCHEDULE WITH START AND END DATES
export const ENTRY_WEEK_CUTOFF = new Map(); //WEEK NUMBER SET TO THE END DATE FOR CREATING CHALLENGE FOR THAT WEEK
export const ENDDATE_WEEK_TO_DATE = new Map(); //WEEK NUMBER SET TO THE LAST DAY THAT CHALLENGE WILL BE LIVE
export const ENDDATE_TO_LOCKDATE = new Map(); //LOCK DATE ASSOCIATED WITH WEEK END DATE
export let CURRENT_LIVE_WEEK_SCHEDULE = new Array(); //CURRENT SCHEDULE FOR LIVE WEEK

export interface IScheduleState {
  fullSchedule: Retrieval<srNflWeek[]>;
  currentWeekSchedule: Retrieval<srNflWeek[]>;
}

const initialState: IScheduleState = {
  fullSchedule: idleRetrieval,
  currentWeekSchedule: idleRetrieval,
};

export const scheduleReducer = produce(
  (state: IScheduleState, action: RootActions) => {
    switch (action.type) {
      case getType(requestFullSchedule.request):
        state.fullSchedule = workingRetrival;
        return;
      case getType(requestFullSchedule.success):
        state.fullSchedule = success(action.payload);

        const schedule = action.payload;
        schedule.map((sched) => {
          BEGDATE_TO_ENDDATE.set(sched.display_start, sched.display_end);
          ENTRY_WEEK_CUTOFF.set(sched.week_sequence, sched.week_lock);
          ENDDATE_TO_LOCKDATE.set(sched.display_end, sched.week_lock);
          ENDDATE_WEEK_TO_DATE.set(sched.week_sequence, sched.display_end);
        });

        return;
      case getType(requestFullSchedule.failure):
        state.fullSchedule = error(action.payload);
        return;
      case getType(requestFullSchedule.cancel):
        state.fullSchedule = idleRetrieval;
        return;
      case getType(requestCurrentWeekSchedule.request):
        state.currentWeekSchedule = workingRetrival;
        return;
      case getType(requestCurrentWeekSchedule.success):
        state.currentWeekSchedule = success(action.payload);

        const entries = action.payload;

        CURRENT_LIVE_WEEK_SCHEDULE.push(entries[0]);
        return;
      case getType(requestCurrentWeekSchedule.failure):
        state.currentWeekSchedule = error(action.payload);
        return;
      case getType(requestCurrentWeekSchedule.cancel):
        state.currentWeekSchedule = idleRetrieval;
        return;
    }
  },
  initialState,
);

const select = (state: IRootState) => state.schedule;

export const getFullSchedule = (state: IRootState) =>
  select(state).fullSchedule;

export const getCurrentWeekSchedule = (state: IRootState) =>
  select(state).currentWeekSchedule;
