import { IError } from '@propsfantasy/retrieval';
import { ActionType, createAsyncAction } from 'typesafe-actions';
import { IdentityValidationRequest, IdentityVerification } from '../../API';
import {
  IOneClickChallengeDefault,
  ITransaction,
  IUserPrivate,
  ProviderType,
  UserFormData,
} from '../../all-models';
import { IStoredUser } from '../../models/user';
import { IUserLeagues, RosterWithOwner } from './userReducer';

export type CreateProviderRequest =
  | { type: ProviderType.Sleeper; username: string }
  | { type: ProviderType.ESPN; username: string; password: string }
  | { type: ProviderType.Yahoo };

export const createProvider = createAsyncAction(
  'ProviderCreateRequest',
  'ProviderCreateSuccess',
  'ProviderCreateError',
)<
  CreateProviderRequest,
  { type: ProviderType },
  { type: ProviderType; err: IError }
>();

export const getCurrentUser = createAsyncAction(
  'UserCurrentGetRequest',
  'UserCurrentGetSuccess',
  'UserCurrentGetError',
  'UserCurrentGetCancel',
)<undefined, IUserPrivate, IError, undefined>();

export const setReferrer = createAsyncAction(
  'SetReferrerRequest',
  'SetReferrerSuccess',
  'SetReferrerError',
)<string, void, IError>();

export const getStoredUser = createAsyncAction(
  'UserStoredGetRequest',
  'UserStoredGetSuccess',
  'UserStoredGetError',
  'UserStoredGetCancel',
)<undefined, IStoredUser, IError, undefined>();

export const submitIdentityVerification = createAsyncAction(
  'submitIdentityVerificationGetRequest',
  'submitIdentityVerificationGetSuccess',
  'submitIdentityVerificationGetError',
  'submitIdentityVerificationGetCancel',
)<
  IdentityValidationRequest,
  Required<IdentityVerification>,
  IError,
  undefined
>();

export const getCurrentUserProviders = createAsyncAction(
  'UserCurrentGetProvidersRequest',
  'UserCurrentGetProvidersSuccess',
  'UserCurrentGetProvidersError',
  'UserCurrentGetProvidersCancel',
)<undefined, { providers: any[] }, IError, undefined>();

export const getCurrentUserLeagues = createAsyncAction(
  'UserCurrentGetLeaguesRequest',
  'UserCurrentGetLeaguesSuccess',
  'UserCurrentGetLeaguesError',
  'UserCurrentGetLeaguesCancel',
)<undefined, { [id: string]: IUserLeagues }, IError, undefined>();

export const getAllRostersForLeague = createAsyncAction(
  'UserCurrentGetAllRosterForLeagueRequest',
  'UserCurrentGetAllRosterForLeagueSuccess',
  'UserCurrentGetAllRosterForLeagueError',
  'UserCurrentGetAllRosterForLeagueCancel',
)<
  /* league ID */ string,
  { leagueId: string; rosters: RosterWithOwner[] },
  { leagueId: string; error: IError },
  /* league ID */ string
>();

export const getAllTransactions = createAsyncAction(
  'UserCurrentGetAllTransactionsRequest',
  'UserCurrentGetAllTransactionsSuccess',
  'UserCurrentGetAllTransactionsError',
  'UserCurrentGetAllTransactionsCancel',
)<undefined, { transactions: ITransaction[] }, { error: IError }, undefined>();

export const login = createAsyncAction(
  'UserLoginRequest',
  'UserLoginSuccess',
  'UserLoginError',
  'UserLoginCancel',
)<{ username: string; password: string }, any, IError, undefined>();

export const logout = createAsyncAction(
  'UserLogoutRequest',
  'UserLogoutSuccess',
  'UserLogoutError',
  'UserLogoutCancel',
)<undefined, any, IError, undefined>();

export const verifyEmail = createAsyncAction(
  'UserVerifyRequest',
  'UserVerifySuccess',
  'UserVerifyError',
  'UserVerifyCancel',
)<{ username: string; confirmationCode: string }, any, IError, undefined>();

export const forgotPassword = createAsyncAction(
  'PasswordForgotRequest',
  'PasswordForgotSuccess',
  'PasswordForgotError',
  'PasswordForgotCancel',
)<{ username: string }, any, IError, undefined>();

export const resetPassword = createAsyncAction(
  'PasswordResetRequest',
  'PasswordResetSuccess',
  'PasswordResetError',
  'PasswordResetCancel',
)<
  { username: string; code: string; password: string },
  any,
  IError,
  undefined
>();

export const updateUser = createAsyncAction(
  'UserUpdateRequest',
  'UserUpdateSuccess',
  'UserUpdateError',
  'UserUpdateCancel',
)<UserFormData, any, IError, undefined>();

export const register = createAsyncAction(
  'UserRegisterRequest',
  'UserRegisterSuccess',
  'UserRegisterError',
  'UserRegisterCancel',
)<UserFormData, any, IError, undefined>();

export const createOneClickChallengeDefaults = createAsyncAction(
  'OneClickChallengeCreateRequest',
  'OneClickChallengeCreateSuccess',
  'OneClickChallengeCreateError',
  'OneClickChallengeCreateCancel',
)<IOneClickChallengeDefault, IOneClickChallengeDefault, IError, undefined>();

export const getOneClickChallengeDefaults = createAsyncAction(
  'GetOneClickChallengeDefaultsRequest',
  'GetOneClickChallengeDefaultsSuccess',
  'GetOneClickChallengeDefaultsError',
  'GetOneClickChallengeDefaultsCancel',
)<undefined, IOneClickChallengeDefault, IError, undefined>();

const actions = {
  getCurrentUser,
  getCurrentUserLeagues,
  getCurrentUserProviders,
  getAllRostersForLeague,
  getAllTransactions,
  updateUser,
  verifyEmail,
  resetPassword,
  forgotPassword,
  login,
  register,
  getStoredUser,
  createProvider,
  submitIdentityVerification,
  logout,
  setReferrer,
  createOneClickChallengeDefaults,
  getOneClickChallengeDefaults,
};

export type UserActions = ActionType<typeof actions>;
