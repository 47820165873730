import { combineEpics } from 'redux-observable';
import { from, of } from 'rxjs';
import {
  catchError,
  exhaustMap,
  filter,
  mergeMap,
  takeUntil,
} from 'rxjs/operators';
import { isActionOf } from 'typesafe-actions';
import { getWallet } from '../money/moneyActions';
import { Epic } from '../rootEpics';
import {
  createChallenge,
  createOpenChallenge,
  enterChallenge,
  getChallenge,
  leaveChallenge,
  refreshChallengePoints,
  requestAllUsers,
  requestAllUsersPlayed,
  requestChallengeCompletedList,
  requestChallengeList,
  requestChallengeScoringBreakdown,
  requestChallenges,
  requestLeagues,
  requestOpenChallenges,
  requestRosters,
  requestUserCompletedChallenges,
  requestUserLiveChallenges,
  updateChallenge,
} from './challengeActions';

const requestChallengesEpic: Epic = (action$) =>
  action$.pipe(
    filter(isActionOf(requestChallenges.request)),
    mergeMap((action) =>
      of(
        requestChallengeList.request({
          continuation: action.payload.continuation,
        }),
      ),
    ),
  );

// const requestCompletedChallengesEpic: Epic = (action$) =>
//   action$.pipe(
//     filter(isActionOf(requestCompletedChallenges.request)),
//     mergeMap((action) =>
//       of(
//         requestChallengeCompletedList.request({
//           continuation: action.payload.continuation,
//         }),
//       ),
//     ),
//   );

const createChallengeEpic: Epic = (action$, _, { challengeApi: api }) =>
  action$.pipe(
    filter(isActionOf(createChallenge.request)),
    exhaustMap(
      async (action) =>
        await api
          .createChallenge(action.payload)
          .then((r) => createChallenge.success(r))
          .catch((err) => createChallenge.failure(err)),
    ),
  );

const updateChallengeEpic: Epic = (action$, _, { challengeApi: api }) =>
  action$.pipe(
    filter(isActionOf(updateChallenge.request)),
    exhaustMap(
      async (action) =>
        await api
          .updateChallenge(action.payload)
          .then((r) => updateChallenge.success(r))
          .catch((err) => updateChallenge.failure(err)),
    ),
  );

const enterChallengeEpic: Epic = (action$, _, { challengeApi: api }) =>
  action$.pipe(
    filter(isActionOf(enterChallenge.request)),
    exhaustMap(
      async (action) =>
        await api
          .enterChallenge(action.payload)
          .then((r) => enterChallenge.success(r))
          .catch((err) => enterChallenge.failure(err)),
    ),
  );

const refreshChallengeListEpic: Epic = (action$, _, { challengeApi: api }) =>
  action$.pipe(
    filter(isActionOf(enterChallenge.success)),
    exhaustMap(() => {
      return of(requestChallengeList.request({ continuation: undefined }));
    }),
  );

const leaveChallengeEpic: Epic = (action$, _, { challengeApi: api }) =>
  action$.pipe(
    filter(isActionOf(leaveChallenge.request)),
    exhaustMap(
      async (action) =>
        await api
          .leaveChallenge(action.payload)
          .then((r) => leaveChallenge.success(r))
          .catch((err) => leaveChallenge.failure(err)),
    ),
  );

const createOpenChallengeEpic: Epic = (action$, _, { challengeApi: api }) =>
  action$.pipe(
    filter(isActionOf(createOpenChallenge.request)),
    exhaustMap(
      async (action) =>
        await api
          .createOpenChallenge(action.payload)
          .then((r) => createOpenChallenge.success(r))
          .catch((err) => createOpenChallenge.failure(err)),
    ),
  );

const refreshChallengeEpic: Epic = (action$, _, { challengeApi: api }) =>
  action$.pipe(
    filter(isActionOf(enterChallenge.success)),
    exhaustMap((action) => {
      return of(getChallenge.request(action.payload.id));
    }),
  );

const refreshWalletEnterEpic: Epic = (action$, _, { challengeApi: api }) =>
  action$.pipe(
    filter(isActionOf(enterChallenge.success)),
    exhaustMap((action) => {
      return of(getWallet.request());
    }),
  );

const refreshWalletLeaveEpic: Epic = (action$, _, { challengeApi: api }) =>
  action$.pipe(
    filter(isActionOf(leaveChallenge.success)),
    exhaustMap((action) => {
      return of(getWallet.request());
    }),
  );

const refreshParticipantEpic: Epic = (action$, _, { challengeApi: api }) =>
  action$.pipe(
    filter(isActionOf(leaveChallenge.success)),
    exhaustMap((action) => {
      return of(getChallenge.request(action.payload.id));
    }),
  );

const refreshChallengePointsEpic: Epic = (action$, _, { challengeApi: api }) =>
  action$.pipe(
    filter(isActionOf(refreshChallengePoints.request)),
    mergeMap(
      async (action) =>
        await api
          .refreshChallengePoints(action.payload)
          .then((r) =>
            refreshChallengePoints.success({
              challengeId: action.payload,
              points: r.points,
              matchedRoster: r.matchedRoster,
            }),
          )
          .catch((err) => refreshChallengePoints.failure(err)),
    ),
  );

const dispatchChallengersEpic: Epic = (action$) =>
  action$.pipe(
    filter(isActionOf(requestChallengeList.success)), // should we grab league data instead?
    mergeMap((action) => {
      const leagueIds = action.payload.challenges.flatMap((challenge) =>
        challenge.challengers.map((challenger: any) => challenger.league),
      );

      const rosterIds = action.payload.challenges.flatMap((challenge) =>
        challenge.challengers.map((challenger: any) => challenger.roster),
      );

      // const challengerIds = action.payload.challenges.flatMap((challenge) =>
      //   challenge.challengers.map((challenger: any) => challenger.id),
      // );
      return of(
        // requestChallengers.request({ challengerIds })
        requestRosters.request({ rosterIds }),
        requestLeagues.request({ leagueIds }),
      );
    }),
  );

const dispatchCompletedChallengersEpic: Epic = (action$) =>
  action$.pipe(
    filter(isActionOf(requestChallengeCompletedList.success)), // should we grab league data instead?
    mergeMap((action) => {
      const leagueIds = action.payload.challenges.flatMap((challenge) =>
        challenge.challengers.map((challenger: any) => challenger.league),
      );

      const rosterIds = action.payload.challenges.flatMap((challenge) =>
        challenge.challengers.map((challenger: any) => challenger.roster),
      );

      // const challengerIds = action.payload.challenges.flatMap((challenge) =>
      //   challenge.challengers.map((challenger: any) => challenger.id),
      // );
      return of(
        // requestChallengers.request({ challengerIds })
        requestRosters.request({ rosterIds }),
        requestLeagues.request({ leagueIds }),
      );
    }),
  );

const requestChallengeListEpic: Epic = (action$, _, { challengeApi: api }) =>
  action$.pipe(
    filter(isActionOf(requestChallengeList.request)),
    mergeMap((action) =>
      from(api.getChallenges(action.payload.continuation)).pipe(
        mergeMap((r) => of(requestChallengeList.success(r))),
        catchError((err) => of(requestChallengeList.failure(err))),
        takeUntil(
          action$.pipe(filter(isActionOf(requestChallengeList.cancel))),
        ),
      ),
    ),
  );

const requestChallengeCompletedListEpic: Epic = (
  action$,
  _,
  { challengeApi: api },
) =>
  action$.pipe(
    filter(isActionOf(requestChallengeCompletedList.request)),
    mergeMap((action) =>
      from(api.getCompletedChallenges(action.payload.continuation)).pipe(
        mergeMap((r) => of(requestChallengeCompletedList.success(r))),
        catchError((err) => of(requestChallengeCompletedList.failure(err))),
        takeUntil(
          action$.pipe(
            filter(isActionOf(requestChallengeCompletedList.cancel)),
          ),
        ),
      ),
    ),
  );

const requestOpenChallengesEpic: Epic = (action$, _, { challengeApi: api }) =>
  action$.pipe(
    filter(isActionOf(requestOpenChallenges.request)),
    mergeMap((action) =>
      from(api.getOpenChallenges(action.payload.continuation)).pipe(
        mergeMap((r) => of(requestOpenChallenges.success(r))),
        catchError((err) => of(requestOpenChallenges.failure(err))),
        takeUntil(
          action$.pipe(filter(isActionOf(requestOpenChallenges.cancel))),
        ),
      ),
    ),
  );

const requestRostersEpic: Epic = (action$, _, { rosterApi: api }) =>
  action$.pipe(
    filter(isActionOf(requestRosters.request)),
    mergeMap((action) =>
      from(api.getRostersBatch(action.payload.rosterIds)).pipe(
        mergeMap((r) => of(requestRosters.success(r))),
        catchError((err) => of(requestRosters.failure(err))),
        takeUntil(action$.pipe(filter(isActionOf(requestRosters.cancel)))),
      ),
    ),
  );

const dispatchChallengeSuccessEpic: Epic = (action$) =>
  action$.pipe(
    filter(isActionOf(requestRosters.success)),
    mergeMap((action) => of(requestChallenges.success({}))),
  );

const getChallengeEpic: Epic = (action$, _, { challengeApi: api }) =>
  action$.pipe(
    filter(isActionOf(getChallenge.request)),
    mergeMap(
      async (action) =>
        await api
          .getChallengeById(action.payload)
          .then((r) => getChallenge.success(r))
          .catch((error) =>
            getChallenge.failure({ id: action.payload, error }),
          ),
    ),
  );

const requestChallengeScoringBreakdownEpic: Epic = (
  action$,
  _,
  { challengeApi: api },
) =>
  action$.pipe(
    filter(isActionOf(requestChallengeScoringBreakdown.request)),
    mergeMap(
      async (action) =>
        await api
          .requestChallengeScoringBreakdown(action.payload)
          .then((r) =>
            requestChallengeScoringBreakdown.success({
              id: action.payload,
              value: r,
            }),
          )
          .catch((error) =>
            requestChallengeScoringBreakdown.failure({
              id: action.payload,
              error,
            }),
          ),
    ),
  );

const requestUserLiveChallengesEpic: Epic = (
  action$,
  _,
  { challengeApi: api },
) =>
  action$.pipe(
    filter(isActionOf(requestUserLiveChallenges.request)),
    mergeMap(
      async (action) =>
        await api
          .getUserChallenges('live')
          .then((r) => requestUserLiveChallenges.success(r))
          .catch((error) => requestUserLiveChallenges.failure(error)),
    ),
  );

const requestUserCompletedChallengesEpic: Epic = (
  action$,
  _,
  { challengeApi: api },
) =>
  action$.pipe(
    filter(isActionOf(requestUserCompletedChallenges.request)),
    mergeMap(
      async (action) =>
        await api
          .getUserChallenges('completed')
          .then((r) => requestUserCompletedChallenges.success(r))
          .catch((error) => requestUserCompletedChallenges.failure(error)),
    ),
  );

const requestAllUsersListEpic: Epic = (action$, _, { challengeApi: api }) =>
  action$.pipe(
    filter(isActionOf(requestAllUsers.request)),
    mergeMap(
      async (action) =>
        await api
          .getUserAttributeData()
          .then((r) => requestAllUsers.success(r))
          .catch((error) => requestAllUsers.failure(error)),
    ),
  );

const requestAllUsersPlayedListEpic: Epic = (
  action$,
  _,
  { challengeApi: api },
) =>
  action$.pipe(
    filter(isActionOf(requestAllUsersPlayed.request)),
    mergeMap(
      async (action) =>
        await api
          .getUsersForTeamsPlayed()
          .then((r) => requestAllUsersPlayed.success(r))
          .catch((error) => requestAllUsersPlayed.failure(error)),
    ),
  );

export const challengeEpics = combineEpics(
  createChallengeEpic,
  dispatchChallengersEpic,
  dispatchChallengeSuccessEpic,
  requestChallengesEpic,
  requestChallengeListEpic,
  requestAllUsersPlayedListEpic,
  requestAllUsersListEpic,
  requestOpenChallengesEpic,
  refreshChallengePointsEpic,
  createOpenChallengeEpic,
  requestRostersEpic,
  updateChallengeEpic,
  getChallengeEpic,
  enterChallengeEpic,
  leaveChallengeEpic,
  refreshChallengeEpic,
  refreshParticipantEpic,
  refreshChallengeListEpic,
  refreshWalletEnterEpic,
  refreshWalletLeaveEpic,
  requestChallengeCompletedListEpic,

  dispatchCompletedChallengersEpic,
  requestChallengeScoringBreakdownEpic,
  requestUserLiveChallengesEpic,
  requestUserCompletedChallengesEpic,
);
