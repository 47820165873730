import { RosterWithOwner } from '../stores/user/userReducer';
import { ILeague } from './league';
import { IRoster } from './roster';

export interface IChallenger {
  id: string;
  challengerID: string;
  challengeID: string;
  leagueID: string;
  rosterID: string;
  name: string;
  backers?: number;
  finalRoster?: IRoster;
  // Points for each player in this challenger's roster. The point value of
  // the challenger is the sum of all player points.
  playerPoints?: IChallengerPlayerPoints[];
  roster?: IRoster;
  league?: ILeague;
}

export interface IOpenChallengeSelected {
  rosterInfo: IRoster;
  opposingUser: string;
}

export interface IChallengerPlayerPoints {
  playerId: string;
  actual: string;
  projected: string;
  isStarter: boolean;
}

export const getProjectedPoints = (
  challenger: IChallenger | RosterWithOwner,
) => {
  if (challenger === undefined || !challenger?.playerPoints) {
    return 0;
  }

  let total = 0;
  for (const point of challenger.playerPoints) {
    if (point.isStarter) {
      total += parseFloat(point.projected);
    }
  }

  return Math.round(total * 100) / 100;
};

export const getActualPoints = (challenger: IChallenger) => {
  if (!challenger || !challenger.playerPoints) {
    return 0;
  }

  let total = 0;
  for (const point of challenger.playerPoints) {
    if (point.isStarter) {
      total += parseFloat(point.actual);
    }
  }

  return Math.round(total * 100) / 100;
};
