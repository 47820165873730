import {
  Avatar,
  Grid,
  IconButton,
  makeStyles,
  Typography,
  useMediaQuery,
  useTheme,
} from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import clsx from 'clsx';
import React, { useCallback, useEffect } from 'react';
import { useHistory } from 'react-router';
import {
  getActualPoints,
  IChallenge,
  IChallenger,
  IUserPrivate,
} from '../../all-models';
import arenaIcon from '../../assets/arena.svg';
import thumbsUpIcon from '../../assets/icon-selected@3x.png';
import rewardsIcon from '../../assets/reward-icon.svg';
import createIcon from '../../assets/ui-icon-challenge-003-copy@3x.png';
import flexBitIcon from '../../assets/ui-icon-rewards@3x.png';
import { getChallengerAvatar } from '../../utils/challenger';
import { formatCurrency } from '../currency/moneyFormatter';
import ProgressBar from './weeklyChallengesProgressBar';

const weeklyChallengesStyles = makeStyles((theme) => ({
  section: {
    backgroundColor: 'white',
    padding: theme.spacing(2.5, 2.5, 1),
    borderRadius: theme.spacing(2),
    marginTop: theme.spacing(2.5),
    minHeight: '357px',
    [theme.breakpoints.down('sm')]: {
      padding: theme.spacing(2.5, 1.5, 1),
    },
  },
  contentRow: {
    width: '100%',
    borderBottom: '1px solid #e4e4e4',
    paddingBottom: theme.spacing(2),
    '&:last-of-type': {
      borderBottom: 'none',
    },
    cursor: 'pointer',
  },
  arrowUp: {
    width: '24px',
    height: '24px',
    marginTop: theme.spacing(2),
    [theme.breakpoints.up('md')]: {
      display: 'none',
    },
  },
  arrowRight: {
    width: '24px',
    height: '24px',
    transform: 'rotate(-90deg)',
    marginLeft: theme.spacing(2),
    marginTop: theme.spacing(2),
    [theme.breakpoints.down('sm')]: {
      display: 'none',
    },
  },
  rightArrow: {
    color: 'black',
    width: '24px',
    height: '24px',
    transform: 'rotate(-90deg)',
    marginLeft: theme.spacing(2),
    lineHeight: 1,
  },
  rightArrowOrange: {
    color: theme.palette.secondary.light,
    width: '24px',
    height: '24px',
    transform: 'rotate(-90deg)',
    marginLeft: theme.spacing(2),
    lineHeight: 1,
  },
  titleRow: {
    width: '100%',
    paddingBottom: theme.spacing(2.75),
  },
  toggleContainer: {
    borderRadius: '3.5px',
    height: '24px',
    [theme.breakpoints.down('sm')]: {
      marginTop: theme.spacing(2),
    },
  },
  currentWeekContainer: {
    backgroundColor: '#05c500',
    borderRadius: '3.5px',

    cursor: 'pointer',
    '& p': {
      color: 'white',
      fontWeight: 'bold',
      fontSize: '14px',
    },
    [theme.breakpoints.down('sm')]: {
      '& p': {
        fontSize: '12px',
      },
      padding: theme.spacing(0.25),
    },
  },
  previousWeekContainer: {
    backgroundColor: '#ededed',
    borderRadius: '3.5px',
    cursor: 'pointer',
    '& p': {
      fontSize: '14px',
      color: '#666000',
    },
    [theme.breakpoints.down('sm')]: {
      fontSize: '12px',
      padding: theme.spacing(0.25),
      '& p': {
        fontSize: '12px',
      },
    },
  },
  boldedFont: {
    fontSize: '16px',
    fontWeight: 'bold',
    marginTop: theme.spacing(2),
    [theme.breakpoints.down('sm')]: {
      fontSize: '14px',
    },
  },
  greyFont: {
    fontSize: '14px',
    color: '#666666',
    [theme.breakpoints.down('sm')]: {
      fontSize: '12px',
    },
  },
  thePotContainer: {
    marginLeft: theme.spacing(6),
    [theme.breakpoints.down('sm')]: {
      marginLeft: 0,
      marginBottom: theme.spacing(2),
    },
  },
  pointsChartContainer: {
    marginTop: theme.spacing(2),
  },
  progressBarContainer: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  pts: {
    fontSize: '14px',
    color: '#666666',
    letterSpacing: '0.58px',
    marginRight: theme.spacing(0.5),
    marginLeft: theme.spacing(0.5),
    [theme.breakpoints.down('sm')]: {
      fontSize: '12px',
      marginRight: theme.spacing(0.25),
      marginLeft: theme.spacing(0.25),
    },
  },
  purplePts: {
    fontSize: '14px',
    fontWeight: 'bold',
    color: theme.palette.primary.main,
    letterSpacing: '0.58px',
    [theme.breakpoints.down('sm')]: {
      fontSize: '12px',
    },
  },
  pinkPts: {
    fontSize: '14px',
    fontWeight: 'bold',
    color: theme.palette.secondary.main,
    letterSpacing: '0.58px',
    [theme.breakpoints.down('sm')]: {
      fontSize: '12px',
    },
  },
  hover: {
    cursor: 'pointer',
  },
  noChallengesCopy: {
    color: '#919191',
    [theme.breakpoints.down('sm')]: {
      textAlign: 'center',
    },
  },
  noChallengesCont: {
    padding: theme.spacing(6, 0, 0),
  },
  createChallengeCopy: {
    color: theme.palette.secondary.light,
    cursor: 'pointer',
    textTransform: 'uppercase',
  },
  createChallengeCont: {
    borderTop: '1px solid #e4e4e4',
    marginTop: theme.spacing(10),
    paddingTop: theme.spacing(2),
  },
  dividerContainer: {
    display: 'none',
    [theme.breakpoints.down('sm')]: {
      display: 'inline',
      textAlign: 'center',
    },
  },
  greyDivider: {
    height: '50px',
    width: '1px',
    background: theme.palette.divider,
  },
  desktopContainer: {
    width: '100%',
    padding: theme.spacing(0, 0, 4),
  },
  desktopContainers: {
    border: '1px solid  #E1E1E1',
    borderRadius: '16px',
    width: '31%',
    height: '244px',
  },
  desktopIcon: {
    width: '87px',
    height: '87px',
  },
  ctaContainer: {
    padding: theme.spacing(4, 0, 0),
  },
  divider: {
    width: '100%',
    height: '1px',
    background: ' #e4e4e4',
  },
}));

const weeklyChallengerProfileStyles = makeStyles((theme) => ({
  profileContainer: {
    marginRight: theme.spacing(1),
  },
  challengerNameIconContainer: {
    position: 'relative',
    width: '48px',
    height: '48px',
    [theme.breakpoints.down('sm')]: {
      width: '32px',
      height: '32px',
    },
  },
  thumbsUpIconContainer: {
    position: 'absolute',
    backgroundColor: '#05c500',
    borderRadius: 50,
    left: theme.spacing(2.5),
    top: theme.spacing(4),
    zIndex: 1,
    [theme.breakpoints.up('md')]: {
      left: theme.spacing(4),
      top: theme.spacing(5.5),
    },
  },
  thumbsUpIcon: {
    width: '20px',
    height: '20px',
    [theme.breakpoints.down('sm')]: {
      width: '16px',
      height: '16px',
    },
  },
  challengerNameIconRed: {
    width: '48px',
    height: '48px',
    marginRight: theme.spacing(1),
    marginTop: theme.spacing(2),
    border: 'solid 0.9px #e94057',
    [theme.breakpoints.down('sm')]: {
      width: '32px',
      height: '32px',
    },
  },
  challengerNameIconPurple: {
    width: '48px',
    height: '48px',
    marginRight: theme.spacing(1),
    marginTop: theme.spacing(2),
    borderRadius: '44.9px',
    border: 'solid 0.9px #892886',
    [theme.breakpoints.down('sm')]: {
      width: '32px',
      height: '32px',
    },
  },
  boldedFont: {
    fontSize: '16px',
    fontWeight: 'bold',
    marginTop: theme.spacing(2),
    [theme.breakpoints.down('sm')]: {
      fontSize: '14px',
    },
  },
  greyFont: {
    fontSize: '14px',
    color: '#666666',
    [theme.breakpoints.down('sm')]: {
      fontSize: '12px',
    },
  },
  opacity: {
    opacity: 0.5,
  },
}));

const WeeklyChallengerProfile: React.FC<{
  challenge: IChallenge;
  challenger: IChallenger;
  type: string;
  isSelectedChallenger: boolean;
  losingChallenger: string | undefined;
}> = ({
  challenge,
  type,
  challenger,
  isSelectedChallenger,
  losingChallenger,
}) => {
  const classes = weeklyChallengerProfileStyles();
  const borderColor =
    type === 'primary'
      ? classes.challengerNameIconRed
      : classes.challengerNameIconPurple;
  const losingOpacity = type === losingChallenger && classes.opacity;

  const backers = challenge?.participants?.items.filter(
    (participant) => participant.challengerId === challenger?.id,
  );

  return (
    <Grid
      container
      wrap="nowrap"
      alignItems="center"
      className={classes.profileContainer}
      xs={12}
    >
      <Grid
        className={classes.challengerNameIconContainer}
        xs={3}
        sm={2}
        md={4}
      >
        {isSelectedChallenger && (
          <Grid item className={classes.thumbsUpIconContainer}>
            <Avatar
              src={thumbsUpIcon}
              className={classes.thumbsUpIcon}
            ></Avatar>
          </Grid>
        )}
        <Grid item>
          <Avatar
            className={clsx([borderColor, losingOpacity])}
            src={
              challenger?.name === undefined
                ? rewardsIcon
                : getChallengerAvatar(challenger)
            }
          ></Avatar>
        </Grid>
      </Grid>
      <Grid container xs={8} direction="column">
        <Grid item xs={12}>
          {challenge.openChallenge && challenger?.name === undefined && (
            <Typography className={classes.boldedFont}>
              Open Challlenge
            </Typography>
          )}
          {challenger?.name && (
            <Typography className={classes.boldedFont}>
              {challenger?.name}
            </Typography>
          )}
        </Grid>
        <Grid item xs={12}>
          <Typography className={classes.greyFont}>
            {backers?.length} Backed
          </Typography>
        </Grid>
      </Grid>
    </Grid>
  );
};

const WeeklyChallengerRow: React.FC<{
  challenge: IChallenge;
  currentUser: IUserPrivate;
}> = ({ challenge, currentUser }) => {
  const classes = weeklyChallengesStyles();
  const theme = useTheme();
  const smViewMediaQuery = useMediaQuery(theme.breakpoints.down('sm'));
  const history = useHistory();
  const [losingChallenger, setLosingChallenger] = React.useState<
    string | undefined
  >(undefined);

  const primaryChallengerPts = getActualPoints(
    challenge?.challengers[0],
  ).toFixed(2);

  const secondaryChallengerPts = getActualPoints(
    challenge?.challengers[1],
  ).toFixed(2);

  const maxPoints = challenge?.challengers?.reduce(
    (acc: number, c: IChallenger) => Math.max(acc, getActualPoints(c)),
    0,
  );

  const getPointProgress = (points: number, maxPoints: number) =>
    (points / Math.max(100, (maxPoints * 3) / 2)) * 100;

  useEffect(() => {
    if (challenge.status === 'completed') {
      parseFloat(primaryChallengerPts) < parseFloat(secondaryChallengerPts)
        ? setLosingChallenger('primary')
        : setLosingChallenger('secondary');
    }
  }, [primaryChallengerPts, secondaryChallengerPts, challenge]);

  const viewChallenge = useCallback(() => {
    history.push(`/challenges/${challenge.id}`);
  }, [history, challenge.id]);

  const isSelected = useCallback(
    (challengerId: string) =>
      challenge.participants.items.some(
        (participant) =>
          participant.userId === currentUser.username &&
          participant.challengerId === challengerId,
      ),
    [challenge, currentUser],
  );

  return (
    <Grid
      container
      item
      direction="row"
      xs={12}
      className={classes.contentRow}
      alignItems="center"
      onClick={viewChallenge}
    >
      <Grid
        container
        item
        xs={6}
        md={6}
        direction={smViewMediaQuery ? 'column' : 'row'}
      >
        <Grid item xs={12} md={6}>
          <WeeklyChallengerProfile
            challenge={challenge}
            challenger={challenge.challengers[1]}
            type={'secondary'} //purple
            isSelectedChallenger={isSelected(challenge?.challengers[1]?.id)}
            losingChallenger={losingChallenger}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <WeeklyChallengerProfile
            challenge={challenge}
            challenger={challenge.challengers[0]}
            type={'primary'}
            isSelectedChallenger={isSelected(challenge?.challengers[0]?.id)}
            losingChallenger={losingChallenger}
          />
        </Grid>
      </Grid>
      <Grid
        item
        container
        xs={1}
        justifyContent="center"
        className={classes.dividerContainer}
      >
        <Grid item className={classes.greyDivider}></Grid>
      </Grid>

      <Grid
        container
        item
        xs={5}
        md={6}
        direction={smViewMediaQuery ? 'column' : 'row'}
        alignItems="center"
      >
        <Grid item xs={12} md={5}>
          <Grid container item xs={12} className={classes.thePotContainer}>
            <Grid
              container
              item
              xs={12}
              justifyContent={smViewMediaQuery ? 'center' : 'flex-start'}
            >
              <Typography className={classes.boldedFont}>
                {formatCurrency(challenge.pot)}
              </Typography>
            </Grid>
            <Grid
              item
              container
              xs={12}
              justifyContent={smViewMediaQuery ? 'center' : 'flex-start'}
            >
              <Typography className={classes.greyFont}>The Pot</Typography>
            </Grid>
          </Grid>
        </Grid>
        <Grid container item xs={12} md={6}>
          <Grid
            container
            item
            xs={12}
            alignItems="center"
            justifyContent="center"
            className={classes.pointsChartContainer}
          >
            <Grid item xs={12}>
              <Grid
                container
                item
                xs={12}
                justifyContent="center"
                className={classes.progressBarContainer}
              >
                <ProgressBar
                  purpleWidth={getPointProgress(
                    secondaryChallengerPts,
                    maxPoints,
                  )}
                  redWidth={getPointProgress(primaryChallengerPts, maxPoints)}
                />
              </Grid>

              <Grid
                container
                item
                xs={12}
                justifyContent="center"
                alignItems="center"
              >
                <Grid
                  container
                  item
                  xs={8}
                  sm={5}
                  md={10}
                  justifyContent="center"
                >
                  <Grid container item xs={3} md={3} justifyContent="flex-end">
                    <Typography className={classes.purplePts}>
                      {secondaryChallengerPts}
                    </Typography>
                  </Grid>
                  <Grid container item xs={5} md={6} justifyContent="center">
                    <Typography className={classes.pts}>| PTS |</Typography>
                  </Grid>
                  <Grid
                    container
                    item
                    xs={3}
                    md={3}
                    justifyContent="flex-start"
                  >
                    <Typography className={classes.pinkPts}>
                      {primaryChallengerPts}
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid container item md={1}>
          <IconButton
            className={classes.arrowRight}
            onClick={viewChallenge}
            aria-label="show challenge"
          >
            <ExpandMoreIcon />
          </IconButton>
        </Grid>
      </Grid>
    </Grid>
  );
};

const WeeklyChallenges: React.FC<{
  filteredChallenges: IChallenge[] | undefined;
  priorWeekChallenges: IChallenge[] | undefined;
  week: number | undefined;
  currentUser: IUserPrivate;
}> = ({ filteredChallenges, priorWeekChallenges, week, currentUser }) => {
  const [challengesDisplayed, setChallengesDisplayed] =
    React.useState<number>(0);
  const classes = weeklyChallengesStyles();
  const theme = useTheme();
  const history = useHistory();

  const smViewMediaQuery = useMediaQuery(theme.breakpoints.down('sm'));

  const backgroundToggleLeft =
    challengesDisplayed === 0
      ? classes.currentWeekContainer
      : classes.previousWeekContainer;

  const backgroundToggleRight =
    challengesDisplayed === 1
      ? classes.currentWeekContainer
      : classes.previousWeekContainer;

  const setChallengesToDisplay = (val: number) => {
    setChallengesDisplayed(val);
  };

  const viewMyChallenges = useCallback(() => {
    history.push('/my-challenges');
  }, [history]);

  const createChallenge = useCallback(() => {
    history.push('/challenge/create');
  }, [history]);

  const enterChallenge = useCallback(() => {
    history.push('/arena');
  }, [history]);

  const earnFlexBit = useCallback(() => {
    history.push('/profile/profile');
  }, [history]);

  const noChallengesBoxes = [
    {
      icon: arenaIcon,
      copy: ' Enter Challenges',
      cta: enterChallenge,
    },
    {
      icon: createIcon,
      copy: 'Create Challenge',
      cta: createChallenge,
    },
    {
      icon: flexBitIcon,
      copy: 'Earn flex bits',
      cta: earnFlexBit,
    },
  ];

  return (
    <Grid container direction="column" className={classes.section}>
      <Grid
        item
        xs={12}
        container
        className={classes.titleRow}
        direction={smViewMediaQuery ? 'column' : 'row'}
      >
        <Grid
          container
          item
          xs={12}
          md={6}
          justifyContent="flex-start"
          alignItems="center"
        >
          <Grid item>
            <Typography
              variant="h5"
              onClick={viewMyChallenges}
              className={classes.hover}
            >
              Weekly Challenges
            </Typography>
          </Grid>
          <Grid item>
            <IconButton
              className={classes.rightArrow}
              onClick={viewMyChallenges}
              aria-label="my challenges"
            >
              <ExpandMoreIcon />
            </IconButton>
          </Grid>
        </Grid>
        <Grid
          container
          item
          xs={12}
          md={6}
          justifyContent={smViewMediaQuery ? 'flex-start' : 'flex-end'}
          className={classes.toggleContainer}
        >
          <Grid
            item
            container
            xs={6}
            md={3}
            justifyContent="center"
            alignContent="center"
            className={backgroundToggleLeft}
            onClick={() => setChallengesToDisplay(0)}
          >
            <Typography>WEEK {week}</Typography>
          </Grid>
          <Grid
            item
            container
            xs={6}
            md={3}
            justifyContent="center"
            alignContent="center"
            className={backgroundToggleRight}
            onClick={() => setChallengesToDisplay(1)}
          >
            <Typography>Last Week</Typography>
          </Grid>
        </Grid>
      </Grid>
      {filteredChallenges && filteredChallenges.length > 0 && (
        <div className={classes.divider}>&nbsp;</div>
      )}

      {!smViewMediaQuery &&
        (!filteredChallenges || filteredChallenges.length === 0) &&
        challengesDisplayed === 0 && (
          <Grid
            container
            direction="row"
            xs={12}
            justifyContent="space-between"
            alignItems="center"
            className={classes.desktopContainer}
          >
            {noChallengesBoxes.map((boxDetail) => (
              <Grid
                item
                container
                direction="column"
                alignItems="center"
                justifyContent="center"
                className={classes.desktopContainers}
              >
                <Grid item>
                  <Avatar
                    src={boxDetail.icon}
                    className={classes.desktopIcon}
                  />
                </Grid>
                <Grid
                  item
                  container
                  direction="row"
                  justifyContent="center"
                  alignItems="center"
                  className={classes.ctaContainer}
                >
                  <Grid item>
                    <Typography
                      variant="subtitle1"
                      onClick={boxDetail.cta}
                      className={classes.createChallengeCopy}
                    >
                      {boxDetail.copy}
                    </Typography>
                  </Grid>
                  <Grid item>
                    <IconButton
                      className={classes.rightArrowOrange}
                      onClick={boxDetail.cta}
                      aria-label={boxDetail.copy}
                    >
                      <ExpandMoreIcon />
                    </IconButton>
                  </Grid>
                </Grid>
              </Grid>
            ))}
          </Grid>
        )}

      {smViewMediaQuery &&
        (!filteredChallenges || filteredChallenges.length === 0) &&
        challengesDisplayed === 0 && (
          <Grid
            item
            container
            xs={12}
            justifyContent="center"
            className={classes.noChallengesCont}
          >
            <Grid item>
              <Typography
                variant="subtitle2"
                className={classes.noChallengesCopy}
              >
                You have not yet entered or created any challenges this week
              </Typography>
            </Grid>
            <Grid
              item
              container
              direction="row"
              justifyContent="center"
              alignItems="center"
              className={classes.createChallengeCont}
            >
              <Grid item>
                <Typography
                  variant="subtitle1"
                  onClick={createChallenge}
                  className={classes.createChallengeCopy}
                >
                  CREATE CHALLENGE
                </Typography>
              </Grid>
              <Grid item>
                <IconButton
                  className={classes.rightArrowOrange}
                  onClick={createChallenge}
                  aria-label="create challenge"
                >
                  <ExpandMoreIcon />
                </IconButton>
              </Grid>
            </Grid>
          </Grid>
        )}

      {challengesDisplayed === 0 &&
        filteredChallenges?.map((challenge) => (
          <WeeklyChallengerRow
            challenge={challenge}
            currentUser={currentUser}
          />
        ))}
      {challengesDisplayed === 1 &&
        priorWeekChallenges?.map((challenge) => (
          <WeeklyChallengerRow
            challenge={challenge}
            currentUser={currentUser}
          />
        ))}
    </Grid>
  );
};

export default WeeklyChallenges;
