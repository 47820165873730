import { makeStyles } from '@material-ui/core';
import React from 'react';
import { PlayerPosition } from '../../models/player';
import { IRosterPlayer } from '../../models/roster';
import {
  FALLBACK_POSITION_COLOR,
  POSITION_COLOR_MAP,
  SUPER_FLEX_COLOR,
} from '../../utils/constants';

export const PlayerCaption: React.FC<{
  player: IRosterPlayer;
  teamName?: boolean;
}> = ({ player, teamName = true }) => (
  <>
    {!Array.isArray(player.startsAs) && player.startsAs
      ? player.startsAs
      : player.positions[0]}
    {teamName ? ` - ${player.team}` : null}
  </>
);

const usePositionBadgeStyles = makeStyles((theme) => ({
  badge: {
    display: 'flex',
    borderRadius: 100,
    overflow: 'hidden',
    alignItems: 'stretch',
    width: 100,
    [theme.breakpoints.down('md')]: {
      width: '52px',
    },
  },
  position: {
    backgroundColor: FALLBACK_POSITION_COLOR,
    flexBasis: 0,
    flexGrow: 1,
    textAlign: 'center',
    fontWeight: 'bold',
    color: '#fff',
    lineHeight: '24px',
    width: 100,
    [theme.breakpoints.down('md')]: {
      width: '52px',
    },
  },
  super: {
    backgroundColor: SUPER_FLEX_COLOR,
    flexBasis: 0,
    flexGrow: 1,
    textAlign: 'center',
    fontWeight: 'bold',
    color: '#fff',
    lineHeight: '24px',
    width: 100,
    [theme.breakpoints.down('md')]: {
      width: '52px',
    },
  },
}));

const IDP_FLEX: PlayerPosition[] = [
  PlayerPosition.DE,
  PlayerPosition.DT,
  PlayerPosition.LB,
  PlayerPosition.CB,
  PlayerPosition.SS,
];

export const PositionBadge: React.FC<{
  positions: readonly PlayerPosition[];
}> = ({ positions }) => {
  const styles = usePositionBadgeStyles();

  if (positions.length > 3 && positions.includes(PlayerPosition.QB)) {
    return (
      <div className={styles.badge}>
        <span className={styles.super}>SUPER</span>
      </div>
    );
  }

  if (
    positions.length > 4 &&
    positions.some((position) => IDP_FLEX.includes(PlayerPosition[position]))
  ) {
    return (
      <div className={styles.badge}>
        <span className={styles.position}>IDP</span>
      </div>
    );
  }

  if (!positions?.length || positions.length > 3) {
    return (
      <div className={styles.badge}>
        <span className={styles.position}>FLEX</span>
      </div>
    );
  }
  return (
    <div className={styles.badge}>
      {positions.map((o, i) => (
        <span
          key={i}
          className={styles.position}
          style={{
            backgroundColor:
              POSITION_COLOR_MAP.get(o) || FALLBACK_POSITION_COLOR,
          }}
        >
          {positions.length > 1 ? o.slice(0, 1) : o}
        </span>
      ))}
    </div>
  );
};
