import CssBaseline from '@material-ui/core/CssBaseline';
import { ThemeProvider } from '@material-ui/core/styles';
import Amplify from 'aws-amplify';
import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { ShortenUrlProvider } from 'react-shorten-url';
import App from './App';
import awsmobile from './aws-exports';
import { PWABannerPopup } from './components/pwaBanner/pwaBannerPopup';
import './index.css';
import serviceWorker from './serviceWorker_custom';
import { store } from './stores/configureStore';
import theme from './theme';

/**
 * Make this last
 */

Amplify.configure(awsmobile);

// TODO we need to add env variables into aws instances.
const bitlyToken = '5fa16cf52075a9e9a15c1960258dfb19da24b4c4';

ReactDOM.render(
  <React.StrictMode>
    <link
      href="https://fonts.googleapis.com/css2?family=Roboto+Condensed:wght@100;300;400;700&display=swap"
      rel="stylesheet"
    />
    <link
      href="https://fonts.googleapis.com/css2?family=Roboto:wght@100&display=swap"
      rel="stylesheet"
    />
    <link
      rel="stylesheet"
      href="https://fonts.googleapis.com/icon?family=Material+Icons"
    />
    <link
      rel="apple-touch-icon"
      href="/images/icons/android-chrome-192x192.png"
    />
    <meta
      name="viewport"
      content="minimum-scale=1, initial-scale=1, width=device-width"
    />
    <meta http-equiv="X-Frame-Options" content="sameorigin" />
    <ShortenUrlProvider config={{ accessToken: bitlyToken }}>
      <Provider store={store}>
        <ThemeProvider theme={theme}>
          <CssBaseline />
          <PWABannerPopup />
          <App />
        </ThemeProvider>
      </Provider>
    </ShortenUrlProvider>
  </React.StrictMode>,
  document.getElementById('root'),
);

// Required for PWAs to be installable
serviceWorker();
