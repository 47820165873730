import {
  Avatar,
  Button,
  Card,
  CircularProgress,
  Dialog,
  DialogContent,
  DialogTitle,
  Divider,
  Grid,
  makeStyles,
  Table,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  useMediaQuery,
  useTheme,
} from '@material-ui/core';
import LinearProgress from '@material-ui/core/LinearProgress';
import { Retrieval, RetrievalState } from '@propsfantasy/retrieval';
import clsx from 'clsx';
import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import {
  getActualPoints,
  getProjectedPoints,
  IRosterPlayer,
} from '../../all-models';
import { Participants } from '../../API';
import bestballOpenIcon from '../../assets/best-ball-live-inactive.svg';
import bestballIcon from '../../assets/best-ball.svg';
import openChallengeIcon from '../../assets/open-challenge-icon.svg';
import rightArrow from '../../assets/pink-right-arrow.png';
import openIcon from '../../assets/ui-icon-h-2-h-inactive.png';
import icon from '../../assets/ui-icon-h-2-h.png';
import { IChallenge, IChallengeScoringBreakdown } from '../../models/challenge';
import { IChallenger } from '../../models/challenger';
import { PlayerPosition } from '../../models/player';
import { requestChallengeScoringBreakdown } from '../../stores/challenge/challengeActions';
import {
  getChallengeLivePoints,
  getChallengePointBreakdown,
  getMatchedRoster,
} from '../../stores/challenge/challengeReducer';
import {
  getChallengerAvatar,
  getChallengerRosterName,
} from '../../utils/challenger';
import { getPlayerImage } from '../../utils/player';
import { PlayerCaption, PositionBadge } from '../base/playerPositions';
import { useLiveChallengePoints } from '../challenge/challengePointRefresher';

const PTS_WIDTH = 135;

const useStyles = makeStyles((theme) => ({
  root: {
    borderRadius: 16,
    marginBottom: theme.spacing(8),
    width: '100%',
    height: '100%',
    boxShadow: ' 2px 4px 4px 2px #00000040',
  },
  swipeRoot: {
    borderRadius: 16,
    marginBottom: theme.spacing(8),
    width: '100%',
    height: '100%',
    boxShadow: ' 2px 4px 4px 2px #00000040',
    position: 'absolute',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    cursor: 'grab',
  },

  avatarPlayerInfoContainer: {
    padding: theme.spacing(3),
    [theme.breakpoints.down('md')]: {
      padding: theme.spacing(2, 0, 1),
    },
  },
  padLeft: {
    [theme.breakpoints.down('md')]: {
      paddingLeft: theme.spacing(1.5),
    },
  },
  padRight: {
    [theme.breakpoints.down('md')]: {
      paddingRight: theme.spacing(1.5),
    },
  },

  avatarContainer: {
    padding: theme.spacing(1, 0),
    [theme.breakpoints.down('md')]: {
      padding: theme.spacing(0),
    },
  },
  playerDetailsCont: {
    padding: theme.spacing(0, 1),
    [theme.breakpoints.down('md')]: {
      padding: theme.spacing(0),
    },
  },
  challengerAvatarCont: {
    [theme.breakpoints.down('md')]: {
      paddingBottom: theme.spacing(1),
    },
  },
  challengerAvatar: {
    width: '64px',
    height: '64px',
    border: '1.2px solid',
    margin: '0 auto',
    [theme.breakpoints.down('md')]: {
      width: '48px',
      height: '48px',
    },
  },

  challengerName: {
    lineHeight: 1,
    padding: theme.spacing(0, 1),
    [theme.breakpoints.down('md')]: {
      padding: theme.spacing(0, 0, 0.5),
      fontWeight: 'bold',
      textOverflow: 'ellipsis',
      width: '110px',
      display: 'block',
      overflow: 'hidden',
      whiteSpace: 'nowrap',
    },
  },
  alignRight: {
    [theme.breakpoints.down('md')]: {
      textAlign: 'right',
    },
  },
  pointsContainer: {
    padding: theme.spacing(0, 1),
    [theme.breakpoints.down('md')]: {
      padding: theme.spacing(0, 1.5),
    },
  },
  pointsFont: {
    fontWeight: 'bold',
  },
  greyDividerVertical: {
    width: '1.5px',
    height: '10px',
    verticalAlign: 'center',
    margin: theme.spacing(0, 0.5),
    backgroundColor: theme.palette.primary.light,
  },
  rosterLiveContainer: {
    width: '100%',
  },
  rosters: {
    padding: theme.spacing(0, 3),
    textOverflow: 'ellipsis',
    [theme.breakpoints.down('md')]: {
      padding: theme.spacing(0.5, 1.5),
    },
  },
  rosterAvatars: {
    width: '32px',
    height: '32px',
    border: '1px solid',
    margin: '0 auto',
  },

  playerName: {
    lineHeight: '1.3',
    marginTop: theme.spacing(1),
    verticalAlign: 'bottom',
    [theme.breakpoints.down('md')]: {
      maxWidth: '80px',
    },
  },
  emptyCell: {
    lineHeight: '1.3',
    marginTop: theme.spacing(2.5),
    verticalAlign: 'bottom',
  },
  playerInfo: {
    lineHeight: '1.3',
    verticalAlign: 'top',
  },
  pointsInfoBottom: {
    lineHeight: '1.0',
    verticalAlign: 'top',
  },
  pointsInfoTop: {
    lineHeight: '1.5',
    marginTop: theme.spacing(1),
    verticalAlign: 'bottom',
  },
  actualPoints: {
    cursor: 'pointer',
    borderBottom: '1px dashed rgba(0, 0, 0, 0.3)',
    outline: '0 !important',
  },
  benchDivider: {
    width: '100%',
    padding: theme.spacing(1, 3),
    backgroundColor: theme.palette.divider,
  },
  benchFont: {
    fontWeight: 'bold',
  },
  primary: {
    borderColor: theme.palette.primary.main,
  },
  secondary: {
    borderColor: theme.palette.secondary.main,
  },
  primaryColor: {
    color: theme.palette.primary.main,
  },
  secondaryColor: {
    color: theme.palette.secondary.main,
  },
  dividerContainer: {
    width: '100%',
  },
  divider: {
    color: theme.palette.divider,
  },
  lineHeight: {
    [theme.breakpoints.down('md')]: {
      lineHeight: 1,
    },
  },
  helmetIcon: {
    width: '44px',
    height: '44px',
    [theme.breakpoints.down('md')]: {
      width: '32px',
      height: '32px',
    },
  },
  helmetContainer: {
    padding: theme.spacing(4, 0),
  },
  swipeBtnContainer: {
    position: 'relative',
    borderTop: '1px solid',
    borderColor: theme.palette.divider,
  },
  swipeBtns: {
    textAlign: 'center',
    padding: theme.spacing(0.8, 0, 1.5),
    cursor: 'pointer',
  },
  btn: {
    color: '#000000',
  },
  typeCopy: {
    color: '#000000',
    padding: theme.spacing(0.5, 0, 0),
    [theme.breakpoints.down('md')]: {
      fontSize: '12px',
    },
  },
  challengerContainer: {
    position: 'relative',
  },
  avatarCopyContainer: {
    padding: theme.spacing(3, 0, 0),
    position: 'absolute',
    left: '50%',
    transform: 'translateX(-50%)',
    [theme.breakpoints.down('md')]: {
      padding: theme.spacing(2, 0, 0),
    },
  },
  linearProgressContainer: {
    width: `calc(100% - (${PTS_WIDTH}px / 2))`,
    padding: theme.spacing(2.2, 0, 4),
  },
  linearProgress: {
    transform: 'rotate(180deg)',
    height: '12px',
    backgroundColor: 'transparent',
    '& .MuiLinearProgress-bar': {
      borderRadius: '50px',
    },
  },
  linearProgressTransform: {
    transform: 'rotate(360deg)',
    height: '12px',
    backgroundColor: 'transparent',
    '& .MuiLinearProgress-bar': {
      borderRadius: '50px',
    },
  },
  linearContainer: {
    position: 'relative',
  },
  centerDetails: {
    position: 'absolute',
    left: '50%',
    top: '93px',
    transform: 'translateX(-50%)',
  },
  centerPill: {
    margin: '5px 0 0',
    width: '135px',
    borderRadius: '8px',
    background: '#F5F5F5',
    padding: theme.spacing(0.6, 0.8),
  },
  darkGrey: {
    color: '#252525',
    fontSize: '14px',
  },
  primaryBacker: {
    fontSize: '12px',
    color: theme.palette.primary.main,
  },
  secBacker: {
    fontSize: '12px',
    color: theme.palette.secondary.main,
  },
  smGrey: {
    color: '#252525',
    fontSize: '12px',
  },
  sideBorder: {
    padding: theme.spacing(0, 0.8),
    borderLeft: '1px solid #D6D6D6',
    borderRight: '1px solid #D6D6D6',
  },
  backersContainer: {
    margin: theme.spacing(0.5, 0, 0.5),
  },
  backers: {
    padding: theme.spacing(0, 2),
  },
  openChallengeContainer: {
    position: 'absolute',
    bottom: '40%',
    left: '30%',
  },
  swipeCopy: {
    lineHeight: '16px',
    color: theme.palette.secondary.main,
    textAlign: 'center',
    marginTop: theme.spacing(2),
  },
  loaderContainerDesktop: {
    position: 'relative',
    left: '49%',
    top: '30%',
  },
  loaderContainerMobile: {
    position: 'relative',
    left: '45%',
    top: '35%',
  },
}));

export const BetChallengeLive: React.FC<{
  challenge: IChallenge;
  isLive: boolean;
  isSwipe: boolean | undefined;
  handleSkip: Function;
  swipeStyles?: boolean;
}> = ({ challenge, isLive, isSwipe, handleSkip, swipeStyles }) => {
  const classes = useStyles();
  const theme = useTheme();

  const matchup = useSelector(getMatchedRoster) || [];
  const live = useSelector(getChallengeLivePoints(challenge.id));
  const isMobile = !useMediaQuery(theme.breakpoints.up('sm'));

  const history = useHistory();

  const handleViewChallenge = useCallback(() => {
    history.push({
      pathname: `/challenges/${challenge.id}`,
    });
  }, [history]);

  const startersA =
    !challenge.openChallenge && matchup?.map((m) => m.players[0]);
  const startersB =
    !challenge.openChallenge && matchup?.map((m) => m.players[1]);

  const startersAIds = startersA?.length
    ? startersA.map((p) => p?.localId)
    : [];
  const startersBIds = startersB?.length
    ? startersB?.map((p) => p?.localId)
    : [];

  const benchAStartingRoster =
    !challenge.openChallenge &&
    challenge.challengers[0].roster.players.filter(
      (p) => !startersAIds?.includes(p.localId),
    );
  const benchAFinalRoster =
    !challenge.openChallenge &&
    challenge.challengers[0].finalRoster?.players?.filter(
      (p) => !startersAIds?.includes(p.localId),
    );

  const benchA = benchAFinalRoster || benchAStartingRoster;

  const benchBStartingRoster =
    !challenge.openChallenge &&
    challenge.challengers[1].roster.players.filter(
      (p) => !startersBIds?.includes(p.localId),
    );
  const benchBFinalRoster =
    !challenge.openChallenge &&
    challenge.challengers[1].finalRoster?.players?.filter(
      (p) => !startersBIds?.includes(p.localId),
    );

  const benchB = benchBFinalRoster || benchBStartingRoster;

  const maxPoints = challenge.challengers.reduce(
    (acc: number, c: IChallenger) =>
      Math.max(acc, getProjectedPoints(c), getActualPoints(c)),
    0,
  );

  const challengerPrimaryParticipants =
    challenge.participants.items?.filter(
      (participant: Participants) =>
        participant?.challengerId === challenge?.challengers[0]?.id,
    ).length || 0;
  const challengerSecondaryParticipants =
    challenge.participants.items?.filter(
      (participant: Participants) =>
        participant?.challengerId === challenge?.challengers[1]?.id,
    ).length || 0;

  useLiveChallengePoints(challenge.id, isSwipe);

  if (live?.state === RetrievalState.Retrieving) {
    return (
      <div
        className={
          window.location.pathname.startsWith('/challenges')
            ? isMobile
              ? classes.loaderContainerMobile
              : classes.loaderContainerDesktop
            : {}
        }
      >
        <CircularProgress />
      </div>
    );
  }

  return (
    <Card
      className={swipeStyles ? classes.swipeRoot : classes.root}
      elevation={1}
    >
      <Grid
        container
        justifyContent="space-between"
        alignItems="flex-start"
        className={classes.challengerContainer}
      >
        <Grid item xs={6} md={5}>
          <ChallengerCard
            challenger={challenge.challengers[0]}
            variant="primary"
            isSwipe={isSwipe}
            maxPoints={maxPoints}
          />
        </Grid>
        {challenge.type === 'Best Ball' && (
          <Grid
            item
            md={2}
            container
            direction="column"
            alignItems="center"
            justifyContent="flex-start"
            className={classes.avatarCopyContainer}
          >
            <Avatar
              src={challenge.openChallenge ? bestballOpenIcon : bestballIcon}
              className={classes.helmetIcon}
            />
            <Typography variant="caption" className={classes.typeCopy}>
              {challenge.type}
            </Typography>
          </Grid>
        )}
        {challenge.type === 'Head 2 Head' && (
          <Grid
            item
            md={2}
            container
            direction="column"
            alignItems="center"
            justifyContent="flex-start"
            className={classes.avatarCopyContainer}
          >
            <Avatar
              src={challenge.openChallenge ? openIcon : icon}
              className={classes.helmetIcon}
            />
            <Typography variant="caption" className={classes.typeCopy}>
              {challenge.type}
            </Typography>
          </Grid>
        )}

        <Grid item xs={6} md={5}>
          <ChallengerCard
            challenger={challenge.challengers[1]}
            variant="secondary"
            isSwipe={isSwipe}
            maxPoints={maxPoints}
          />
        </Grid>
      </Grid>

      {!isSwipe && (
        <>
          {matchup.map(({ players, position }, i) => (
            <RosterRow
              challenge={challenge}
              key={i}
              startsAs={position}
              a={players[0]}
              b={players[1]}
            />
          ))}
          <Grid
            item
            alignItems="center"
            container
            className={classes.benchDivider}
          >
            <Typography className={classes.benchFont} variant="caption">
              Bench
            </Typography>
          </Grid>
          {(benchA.length > benchB.length ? benchA : benchB).map((_, i) => (
            <RosterRow
              challenge={challenge}
              key={i}
              a={benchA[i]}
              b={benchB[i]}
            />
          ))}
        </>
      )}
      {isSwipe && (
        <Grid
          item
          container
          direction="column"
          alignItems="center"
          className={classes.centerDetails}
        >
          <Grid
            item
            container
            direction="row"
            alignItems="center"
            justifyContent="space-between"
            className={classes.centerPill}
            xs={12}
          >
            <Grid item>
              <Typography className={classes.smGrey} variant="caption">
                <b>{getProjectedPoints(challenge.challengers[0]).toFixed(2)}</b>
              </Typography>
            </Grid>
            <Grid item className={classes.sideBorder}>
              <Typography className={classes.darkGrey} variant="caption">
                PROJ
              </Typography>
            </Grid>
            <Grid item>
              <Typography className={classes.smGrey} variant="caption">
                <b>{getProjectedPoints(challenge.challengers[1]).toFixed(2)}</b>
              </Typography>
            </Grid>
          </Grid>
          <Grid
            item
            container
            direction="row"
            justifyContent="center"
            alignItems="center"
            xs={12}
            className={classes.backersContainer}
          >
            <Grid item>
              <Typography className={classes.primaryBacker} variant="caption">
                <b>{challengerPrimaryParticipants}</b>
              </Typography>
            </Grid>
            <Grid item className={classes.backers}>
              <Typography className={classes.smGrey} variant="caption">
                BACKERS
              </Typography>
            </Grid>
            <Grid item>
              <Typography className={classes.secBacker} variant="caption">
                <b>{challengerSecondaryParticipants}</b>
              </Typography>
            </Grid>
          </Grid>
          <Grid item className={classes.dividerContainer}>
            <Divider className={classes.divider} />
          </Grid>
        </Grid>
      )}

      {isSwipe && (
        <>
          {!challenge.openChallenge && matchup.length > 0
            ? matchup
                ?.slice(0, 7)
                .map(({ players, position }, i) => (
                  <RosterRow
                    challenge={challenge}
                    key={i}
                    startsAs={position}
                    a={players[0]}
                    b={players[1]}
                  />
                ))
            : challenge.challengers[0].roster.players
                ?.slice(0, 7)
                .map((player, i) => (
                  <RosterRow
                    challenge={challenge}
                    key={i}
                    startsAs={player.positions}
                    a={player}
                  />
                ))}

          {challenge.openChallenge && (
            <Grid
              item
              container
              direction="column"
              alignItems="center"
              className={classes.openChallengeContainer}
            >
              <Avatar src={rightArrow} />
              <Typography variant="subtitle1" className={classes.swipeCopy}>
                <b>
                  SWIPE TO
                  <br />
                  CHALLENGE
                </b>
              </Typography>
            </Grid>
          )}

          <Grid
            item
            container
            alignItems="center"
            justifyContent="space-between"
            xs={12}
            className={classes.swipeBtnContainer}
          >
            <Grid item xs={6} className={classes.swipeBtns}>
              <Button onClick={handleViewChallenge}>
                <Typography variant="caption" className={classes.btn}>
                  VIEW CHALLENGE
                </Typography>
              </Button>
            </Grid>

            <Grid item xs={6} className={classes.swipeBtns}>
              <Button onClick={() => handleSkip(challenge)}>
                <Typography variant="caption" className={classes.btn}>
                  SKIP
                </Typography>
              </Button>
            </Grid>
          </Grid>
        </>
      )}
    </Card>
  );
};

export const ChallengerCard: React.FC<{
  challenger: IChallenger;
  variant: 'primary' | 'secondary';
  isSwipe: boolean | undefined;
  maxPoints: number;
}> = ({ challenger, variant, isSwipe, maxPoints }) => {
  const classes = useStyles();
  const displayType =
    variant === 'primary' ? classes.primary : classes.secondary;
  const colorDisplayType =
    variant === 'primary' ? classes.primaryColor : classes.secondaryColor;
  const padDisplay = variant === 'primary' ? classes.padLeft : classes.padRight;
  const theme = useTheme();
  const mdViewMediaQuery = useMediaQuery(theme.breakpoints.down('md'));
  const alignmentType =
    variant === 'secondary' ? classes.alignRight : undefined;

  const getPointProgress = (points: number, max: number) => {
    return (points / Math.max(100, (max * 3) / 2)) * 100;
  };

  return (
    <Grid container>
      <Grid
        xs={12}
        alignItems="center"
        direction={variant === 'secondary' ? 'row-reverse' : 'row'}
        item
        container
        className={classes.avatarPlayerInfoContainer}
      >
        <Grid item className={clsx(classes.challengerAvatarCont, padDisplay)}>
          <Avatar
            className={clsx(classes.challengerAvatar, displayType)}
            src={
              challenger ? getChallengerAvatar(challenger) : openChallengeIcon
            }
          />
        </Grid>
        <Grid
          item
          container
          direction="column"
          alignItems={variant === 'primary' ? 'flex-start' : 'flex-end'}
          xs={mdViewMediaQuery ? 12 : 10}
        >
          <Grid item className={padDisplay}>
            <Typography
              variant={mdViewMediaQuery ? 'subtitle2' : 'h5'}
              className={clsx(classes.challengerName, alignmentType)}
            >
              {challenger?.roster
                ? getChallengerRosterName(challenger.roster) || challenger.name
                : 'Challenge Team'}
            </Typography>
          </Grid>
          {isSwipe && (
            <Grid
              item
              className={clsx(
                classes.linearProgressContainer,
                variant === 'secondary' && classes.secondary,
              )}
            >
              <LinearProgress
                className={clsx(
                  classes.linearProgress,
                  variant === 'secondary' && classes.linearProgressTransform,
                )}
                color={variant}
                variant="determinate"
                value={getPointProgress(
                  getProjectedPoints(challenger),
                  maxPoints,
                )}
                style={{ opacity: 1 }}
              />
            </Grid>
          )}
          {!isSwipe && (
            <Grid
              container
              item
              className={classes.pointsContainer}
              direction={mdViewMediaQuery ? 'column' : 'row'}
              alignItems={
                mdViewMediaQuery
                  ? variant === 'primary'
                    ? 'flex-start'
                    : 'flex-end'
                  : 'center'
              }
              justifyContent={variant === 'primary' ? 'flex-start' : 'flex-end'}
            >
              <Grid item>
                <Typography variant="caption" className={classes.lineHeight}>
                  Points:{' '}
                  <span className={clsx(classes.pointsFont, colorDisplayType)}>
                    {getActualPoints(challenger).toFixed(2)}
                  </span>
                </Typography>
              </Grid>
              <Grid item>
                {mdViewMediaQuery ? (
                  ' '
                ) : (
                  <div className={classes.greyDividerVertical}></div>
                )}
              </Grid>
              <Grid item>
                <Typography variant="caption" className={classes.lineHeight}>
                  Proj:{' '}
                  <span className={classes.pointsFont}>
                    {getProjectedPoints(challenger).toFixed(2)}
                  </span>
                </Typography>
              </Grid>
            </Grid>
          )}
        </Grid>
      </Grid>
      {!isSwipe && (
        <Grid item className={classes.dividerContainer}>
          <Divider className={classes.divider} />
        </Grid>
      )}
    </Grid>
  );
};

export const YetToPlay: React.FC<{
  variant: 'primary' | 'secondary';
}> = ({ variant }) => {
  return (
    <Grid
      container
      xs={12}
      direction="column"
      alignItems={variant === 'primary' ? 'flex-start' : 'flex-end'}
    >
      <Grid item xs={12}>
        YET TO PLAY ()
      </Grid>
      <Grid item>
        <Grid item>QB, RB(2), WT(4), DE(4)</Grid>
      </Grid>
    </Grid>
  );
};

const RosterRow: React.FC<{
  challenge: IChallenge;
  startsAs?: readonly PlayerPosition[];
  a?: IRosterPlayer | string;
  b?: IRosterPlayer | string;
}> = ({ challenge, startsAs, a, b }) => {
  const classes = useStyles();

  return (
    <Grid container>
      <Grid item xs={5}>
        {typeof a !== 'string' && a ? (
          <RosterRowCell
            challenge={challenge}
            challenger={challenge.challengers[0]}
            player={a}
          />
        ) : (
          startsAs && <Typography align="center">Empty</Typography>
        )}
      </Grid>
      <Grid item container xs={2} alignItems="center" justifyContent="center">
        {startsAs && <PositionBadge positions={startsAs} />}
      </Grid>
      <Grid item xs={5}>
        {typeof b !== 'string' && b ? (
          <RosterRowCell
            challenge={challenge}
            challenger={challenge.challengers[1]}
            player={b}
          />
        ) : (
          startsAs && (
            <Grid item>
              <Typography
                align="center"
                className={classes.emptyCell}
                variant="subtitle2"
              >
                {!challenge.openChallenge && ' Empty'}
              </Typography>
            </Grid>
          )
        )}
      </Grid>
    </Grid>
  );
};

const PointsBreakdownContent: React.FC<{
  player: IRosterPlayer;
  points: Retrieval<IChallengeScoringBreakdown[]>;
}> = ({ points, player }) => {
  if (points.state !== RetrievalState.Succeeded) {
    return <CircularProgress />;
  }

  const forPlayer = points.value.find((p) => p.localId === player.localId);
  if (!forPlayer) {
    return <p>This player has not made any qualifying plays yet.</p>;
  }

  return (
    <TableContainer>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>Category</TableCell>
            <TableCell>Points</TableCell>
          </TableRow>
        </TableHead>
        {forPlayer.scoring.map((v, i) => (
          <TableRow key={i}>
            <TableCell>{v.name}</TableCell>
            <TableCell>{v.value.toFixed(2)}</TableCell>
          </TableRow>
        ))}
        <TableRow>
          <TableCell>
            <strong>Total</strong>
          </TableCell>
          <TableCell>
            {forPlayer.scoring.reduce((acc, v) => acc + v.value, 0).toFixed(2)}
          </TableCell>
        </TableRow>
      </Table>
    </TableContainer>
  );
};

const PointsBreakdown: React.FC<{
  onClose: () => void;
  challenge: IChallenge;
  player: IRosterPlayer;
}> = ({ challenge, player, onClose }) => {
  const points = useSelector(getChallengePointBreakdown(challenge.id));
  const dispatch = useDispatch();

  useEffect(() => {
    if (points.state === RetrievalState.Idle) {
      dispatch(requestChallengeScoringBreakdown.request(challenge.id));
    }
  }, [points.state, challenge.id, dispatch]);

  return (
    <Dialog open={true} onClose={onClose}>
      <DialogTitle>{player.fullName} Scoring</DialogTitle>
      <DialogContent>
        <PointsBreakdownContent points={points} player={player} />
      </DialogContent>
    </Dialog>
  );
};

const RosterRowCell: React.FC<{
  challenge: IChallenge;
  challenger: IChallenger;
  player: IRosterPlayer;
  secondary?: boolean;
}> = ({ challenge, challenger, player, secondary }) => {
  const classes = useStyles();
  const colorDisplayType = secondary
    ? classes.secondaryColor
    : classes.primaryColor;
  const theme = useTheme();
  const mdViewMediaQuery = useMediaQuery(theme.breakpoints.down('md'));
  const points = challenger?.playerPoints?.find(
    (p) => p.playerId === player.localId,
  );
  const [showBreakdown, setShowBreakdown] = useState(false);

  return (
    <Grid item container xs={12} spacing={0} key={player.localId}>
      <Grid
        item
        direction={secondary ? 'row-reverse' : 'row'}
        container
        xs={12}
        className={classes.rosters}
      >
        {showBreakdown && (
          <PointsBreakdown
            onClose={() => setShowBreakdown(false)}
            challenge={challenge}
            player={player}
          />
        )}
        {!mdViewMediaQuery && (
          <Grid item xs={1} className={classes.avatarContainer}>
            <Avatar
              className={clsx(classes.rosterAvatars, colorDisplayType)}
              src={getPlayerImage(player)}
            />
          </Grid>
        )}

        <Grid
          item
          container
          direction="column"
          className={classes.playerDetailsCont}
          justifyContent={mdViewMediaQuery ? 'center' : undefined}
          alignItems={secondary ? 'flex-end' : 'flex-start'}
          xs={9}
        >
          <Grid item>
            {player.positions.includes(PlayerPosition.DEF) ? (
              <Typography
                noWrap
                className={classes.playerName}
                variant="subtitle2"
              >
                {player.fullName}
              </Typography>
            ) : (
              <Typography
                noWrap
                className={classes.playerName}
                variant="subtitle2"
              >
                {player.fullName
                  .split(' ')
                  .slice(0, 1)
                  .toString()
                  .substring(0, 1)}
                {'. '}
                {player.fullName.split(' ').slice(-1)}
              </Typography>
            )}
          </Grid>
          <Grid item>
            <Typography className={classes.playerInfo} variant="caption">
              <PlayerCaption player={player} />
            </Typography>
          </Grid>
          {/* /* TO DO GET GAME LOCATION AND TIME   */}
        </Grid>
        <Grid
          item
          container
          direction="column"
          alignItems={secondary ? 'flex-start' : 'flex-end'}
          xs={mdViewMediaQuery ? 3 : 2}
        >
          <Grid item>
            <Typography className={classes.pointsInfoTop} variant="subtitle2">
              {points?.actual ? (
                <span
                  role="button"
                  className={classes.actualPoints}
                  onClick={() => setShowBreakdown(true)}
                >
                  {points.actual.toFixed(2)}
                </span>
              ) : (
                '0.00'
              )}
            </Typography>
          </Grid>
          <Grid item>
            <Typography className={classes.pointsInfoBottom} variant="caption">
              {points?.projected.toFixed(2)}
            </Typography>
          </Grid>
        </Grid>
      </Grid>
      <Grid item className={classes.dividerContainer}>
        <Divider className={classes.divider} />
      </Grid>
    </Grid>
  );
};
