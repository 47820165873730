import { SvgIcon } from '@material-ui/core';
import React from 'react';

type SvgProps = React.ComponentProps<typeof SvgIcon>;

export const InstagramIcon: React.FC<SvgProps> = ({ ...props }) => {
  return (
    <SvgIcon {...props} viewBox="0 0 32 32">
      <g fill="none" fill-rule="evenodd">
        <rect fill="#E94057" width="32" height="32" rx="16" />
        <path
          d="M19.945 6.667a5.388 5.388 0 0 1 5.388 5.388v7.89a5.388 5.388 0 0 1-5.388 5.388h-7.89a5.388 5.388 0 0 1-5.388-5.388v-7.89a5.388 5.388 0 0 1 5.388-5.388zm.093 1.765h-8.076a3.576 3.576 0 0 0-3.576 3.577v8.075a3.576 3.576 0 0 0 3.576 3.577h8.076a3.576 3.576 0 0 0 3.576-3.577V12.01a3.576 3.576 0 0 0-3.576-3.577zM16 11.203a4.843 4.843 0 1 1 0 9.687 4.843 4.843 0 0 1 0-9.687zm0 1.704a3.14 3.14 0 1 0 0 6.279 3.14 3.14 0 0 0 0-6.28zm5.005-3.017a1.128 1.128 0 1 1 0 2.257 1.128 1.128 0 0 1 0-2.257z"
          fill="#FFF"
        />
      </g>
    </SvgIcon>
  );
};
