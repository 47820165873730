import { BEGDATE_TO_ENDDATE } from '../stores/schedule/scheduleReducer';

export const getCurrentSeason = () => {
  const currentDate = new Date();
  let currentYear = currentDate.getFullYear();
  const currentMonth = currentDate.getMonth();
  // We need to account for the season ending in Feb and not starting until Aug
  if (currentMonth <= 3) {
    currentYear -= 1;
  }

  //Need to keep this as number for Lambda functions
  return currentYear;
};

export const getFirstEndDateOfSeason = async () => {
  let firstEndDate;
  if (BEGDATE_TO_ENDDATE.size > 0) {
    firstEndDate = BEGDATE_TO_ENDDATE.values().next().value;
  }
  return firstEndDate;
};
