import { CircularProgress, useMediaQuery, useTheme } from '@material-ui/core';
import React, {
  Suspense,
  createContext,
  lazy,
  useEffect,
  useState,
} from 'react';

// this will need to be moved to auth routes
// import { withAuthenticator } from '@aws-amplify/ui-react'
import ReactGA from 'react-ga4';
import { useDispatch, useSelector } from 'react-redux';
import { Route, Router, Switch } from 'react-router-dom';
import './App.scss';
import history from './components/base/history';
import { DashboardPage } from './components/dashboard/dashboardPage';
import {
  getCurrentUser,
  setReferrer as setReferrerRequest,
} from './stores/user/userActions';
import {
  hasAccessToken,
  isLoggedIn,
  requests,
} from './stores/user/userReducer';
import ScrollToTop from './utils/scrollToTop';
import { isTestingEnvironment } from './utils/serverConfig';

import { RetrievalState } from '@propsfantasy/retrieval';
import { SwipeChallenges } from './components/dashboard/swipeChallenges';
import { UserGeolocation } from './components/geolocation/userGeolocation';
import { NotifyItem } from './models/notifications';
import {
  requestCurrentWeekSchedule,
  requestFullSchedule,
} from './stores/schedule/scheduleActions';
import {
  getCurrentWeekSchedule,
  getFullSchedule,
} from './stores/schedule/scheduleReducer';

const DiscoverPage = lazy(() => import('./components/discover/discoverPage'));
const ConfirmEmailPage = lazy(
  () => import('./components/auth/confirmEmailPage'),
);
const ForgotPasswordPage = lazy(
  () => import('./components/auth/forgotPasswordPage'),
);
const TransactionApprovalPage = lazy(
  () => import('./components/admin/transactionApproval'),
);
const LoginGuard = lazy(() => import('./components/auth/loginGuard'));
const OAuthPostbackPage = lazy(
  () => import('./components/auth/oauthPostbackPage'),
);
const ReferrerTracker = lazy(() => import('./components/base/referrer'));
const RegisterPage = lazy(() => import('./components/auth/registerPage'));
const ResetPasswordPage = lazy(
  () => import('./components/auth/resetPasswordPage'),
);
const CreateChallengePage = lazy(
  () => import('./components/challenge/create/createChallengePage'),
);
const ChallengeDetailPage = lazy(
  () => import('./components/challenge/detail/challengeDetailPage'),
);
const ChallengeEnterPage = lazy(
  () => import('./components/challenge/enter/challengeEnterPage'),
);
const ChallengerProfilePage = lazy(
  () => import('./components/challengerProfile/challengerProfilePage'),
);
const MyChallengesPage = lazy(
  () => import('./components/myChallenges/myChallengesPage'),
);
const NotifyToastCountDown = lazy(
  () => import('./components/notifications/notifyToastCountDown'),
);
const ProfilePage = lazy(() => import('./components/profile/profilePage'));
const PaymentCancelledPage = lazy(
  () => import('./components/currency/paymentCancelledPage'),
);
const PaymentErrorPage = lazy(
  () => import('./components/currency/paymentErrorPage'),
);
const PaymentPendingPage = lazy(
  () => import('./components/currency/paymentPendingPage'),
);
const PaymentSuccessPage = lazy(
  () => import('./components/currency/paymentSuccessPage'),
);
const WithdrawCancelledPage = lazy(
  () => import('./components/currency/withdrawCancelledPage'),
);
const WithdrawErrorPage = lazy(
  () => import('./components/currency/withdrawErrorPage'),
);
const WithdrawPendingPage = lazy(
  () => import('./components/currency/withdrawPendingPage'),
);
const WithdrawSuccessPage = lazy(
  () => import('./components/currency/withdrawSuccessPage'),
);
const CreateTournamentChallengePage = lazy(
  () => import('./components/admin/challenge/create/createChallengePage'),
);
const TournamentPage = lazy(
  () => import('./components/tournament/tournamentPage'),
);
const spinner = <CircularProgress />;

export const NotificationContext = createContext<{
  notificationList: NotifyItem[];
  setNotificationList?: Function;
  badgeVisibility: boolean;
  setBadgeVisibility?: Function;
}>({ notificationList: [] as NotifyItem[], badgeVisibility: false });

const checkTokenRefrence = () => {
  const urlParams = new URLSearchParams(window.location.search);
  const tokenQuery = urlParams.get('token');

  if (tokenQuery && tokenQuery !== 'null') {
    const today = new Date();
    const tokenValue = {
      value: tokenQuery,
      expiry: new Date(
        today.getFullYear(),
        today.getMonth(),
        today.getDate() + 7,
      ),
    };

    try {
      localStorage.setItem('tokenReferral', JSON.stringify(tokenValue));
    } catch (error) {
      console.error(error);
    }
  }
};

function App() {
  const dispatch = useDispatch();
  const isUserLoggedIn = useSelector(isLoggedIn);
  const hasUserAccessToken = useSelector(hasAccessToken);
  const isTestingEnv = isTestingEnvironment();
  const requestScheule = useSelector(getFullSchedule);
  const requestWeeklySchedule = useSelector(getCurrentWeekSchedule);
  const [notificationList, setNotificationList] = useState([]);
  const [badgeVisibility, setBadgeVisibility] = useState(false);
  const [referrer, setReferrer] = useState(null);
  const registerUserRequest = useSelector(requests).register;
  const theme = useTheme();

  const smViewMediaQuery = useMediaQuery(theme.breakpoints.down('sm'));

  useEffect(() => {
    if (requestScheule.state !== RetrievalState.Succeeded) {
      dispatch(requestFullSchedule.request());
    }
  }, [dispatch, requestScheule]);

  useEffect(() => {
    if (referrer && registerUserRequest.state === RetrievalState.Succeeded) {
      dispatch(setReferrerRequest.request(referrer));
    }
  }, [dispatch, registerUserRequest, referrer]);

  useEffect(() => {
    if (requestWeeklySchedule.state !== RetrievalState.Succeeded) {
      dispatch(requestCurrentWeekSchedule.request());
    }
  }, [dispatch, requestWeeklySchedule]);

  useEffect(() => {
    const script = document.createElement('script');
    script.src =
      'https://cdn.safecharge.com/safecharge_resources/v1/websdk/safecharge.js';
    script.async = true;
    document.body.appendChild(script);
  }, []);

  useEffect(() => {
    if (isUserLoggedIn || hasUserAccessToken) {
      dispatch(getCurrentUser.request());
    }
  }, [dispatch, isUserLoggedIn, hasUserAccessToken]);

  useEffect(() => {
    history.listen((location) => {
      ReactGA.send({
        hitType: 'pageview',
        page: location.pathname + location.search,
      });
    });
  }, []);

  useEffect(() => {
    checkTokenRefrence();
  }, []);

  ReactGA.initialize(!isTestingEnv ? 'G-GRYCBVQXPJ' : 'G-W4EBJXTEKB');

  if (
    requestScheule.state !== RetrievalState.Succeeded &&
    requestWeeklySchedule.state !== RetrievalState.Succeeded
  ) {
    return <CircularProgress />;
  }

  return (
    <div className="app">
      <Suspense fallback={spinner}>
        <NotificationContext.Provider
          value={{
            notificationList,
            setNotificationList,
            badgeVisibility,
            setBadgeVisibility,
          }}
        >
          {/* <PWABannerPopup /> */}
          <NotifyToastCountDown />
          <Router history={history}>
            <ReferrerTracker setReferrer={setReferrer} />
            <ScrollToTop />
            <Switch>
              <Route exact path="/">
                <LoginGuard>
                  <DashboardPage />
                </LoginGuard>
              </Route>
              <Route exact path="/?login=true">
                <DiscoverPage />
              </Route>
              <Route exact path="/arena">
                <DiscoverPage />
              </Route>
              <Route exact path="/tournament-of-champions">
                <TournamentPage />
              </Route>
              <Route exact path="/oauthpostback">
                <OAuthPostbackPage />
              </Route>
              <Route exact path="/register">
                <RegisterPage />
              </Route>
              <Route exact path="/forgot-password">
                <ForgotPasswordPage />
              </Route>
              <Route path="/reset-password">
                <ResetPasswordPage />
              </Route>
              <Route path="/confirm-user">
                <ConfirmEmailPage />
              </Route>
              <Route path="/challenge/create">
                <LoginGuard>
                  <UserGeolocation />
                  <CreateChallengePage />
                </LoginGuard>
              </Route>
              <Route path="/challenger/:id">
                <ChallengerProfilePage />
              </Route>

              <Route path="/payment/success">
                <PaymentSuccessPage />
              </Route>
              <Route path="/payment/error">
                <PaymentErrorPage />
              </Route>
              <Route path="/payment/cancelled">
                <PaymentCancelledPage />
              </Route>
              <Route path="/payment/pending">
                <PaymentPendingPage />
              </Route>
              <Route path="/withdraw/success">
                <WithdrawSuccessPage />
              </Route>
              <Route path="/withdraw/error">
                <WithdrawErrorPage />
              </Route>
              <Route path="/withdraw/cancelled">
                <WithdrawCancelledPage />
              </Route>
              <Route path="/withdraw/pending">
                <WithdrawPendingPage />
              </Route>
              <Route path="/profile">
                <LoginGuard>
                  <ProfilePage />
                </LoginGuard>
              </Route>
              <Route path="/my-challenges">
                <LoginGuard>
                  <MyChallengesPage />
                </LoginGuard>
              </Route>
              <Route path="/challenges/:challengeId/enter">
                <LoginGuard>
                  <UserGeolocation />
                  <ChallengeEnterPage />
                </LoginGuard>
              </Route>
              <Route path="/challenges/:challengeId">
                <ChallengeDetailPage />
              </Route>
              <Route
                path="/help"
                component={() => {
                  window.location.replace('https://propsfantasy.com/help');
                  return null;
                }}
              />
              <Route path="/admin/challenge/create">
                <LoginGuard>
                  <CreateTournamentChallengePage />
                </LoginGuard>
              </Route>
              <Route path="/admin/transactionApproval">
                <Suspense fallback={spinner}>
                  <TransactionApprovalPage />
                </Suspense>
              </Route>
              {smViewMediaQuery && (
                <Route path="/swipe-to-enter">
                  <LoginGuard>
                    <SwipeChallenges />
                  </LoginGuard>
                </Route>
              )}
            </Switch>
          </Router>
        </NotificationContext.Provider>
      </Suspense>
    </div>
  );
}

export default App;
