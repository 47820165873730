import { IChallenger } from '../models/challenger';
import { IRoster } from '../models/roster';

const baseUrl = 'https://d3h5rjbrdqlbj9.cloudfront.net/public/avatars';

export const getRosterAvatar = (roster?: IRoster) => {
  if (!roster) return undefined;

  return roster?.updatedAvatar
    ? linkUpdatedAvatar(roster?.updatedAvatar)
    : roster?.avatar;
};

export const getChallengerAvatar = (challenger: IChallenger) => {
  if (!challenger) return undefined;

  const idChar = challenger?.rosterId?.charAt(0) || `1`;

  switch (true) {
    case challenger.roster?.updatedAvatar &&
      challenger.roster!.updatedAvatar!.length > 0:
      return linkUpdatedAvatar(challenger.roster?.updatedAvatar);
    case challenger.roster?.avatar &&
      /(svg|png|gif|jpg|sleeper)((?!null).)*$/.test(challenger.roster?.avatar):
      return challenger.roster?.avatar;
    case /1|2/.test(idChar):
      return `${baseUrl}/helmet.svg`;
    case /3|4/.test(idChar):
      return `${baseUrl}/finger.svg`;
    case /5|6|7/.test(idChar):
      return `${baseUrl}/uprights.svg`;
    case /8|9|0/.test(idChar):
      return `${baseUrl}/cleat.svg`;
    case /a|b|c|d|e/.test(idChar):
      return `${baseUrl}/whistle.svg`;
    case /f|g|h|i|j/.test(idChar):
      return `${baseUrl}/field_marker.svg`;
    case /k|l|m|n|o/.test(idChar):
      return `${baseUrl}/pads.svg`;
    case /q|r|s|t|u|v|w|x|y|z/.test(idChar):
      return `${baseUrl}/football.svg`;
  }
};

export const getChallengerRosterName = (roster?: IRoster) => {
  if (!roster) return undefined;

  return roster.updatedTeamName && roster.updatedTeamName!.length > 0
    ? roster.updatedTeamName
    : roster.teamName;
};

const linkUpdatedAvatar = (avatar: string) =>
  /(https?:\/\/(?:www\.|(?!www))|www\.)/.test(avatar)
    ? avatar
    : `https://d3h5rjbrdqlbj9.cloudfront.net/team-profiles/${avatar}`;
