import {
  error,
  idleRetrieval,
  ISuccessfulRetrieval,
  Retrieval,
  RetrievalState,
  shouldAttempt,
  success,
  workingRetrival,
} from '@propsfantasy/retrieval';
import produce from 'immer';
import ReactGA from 'react-ga4';
import { createSelector } from 'reselect';
import { getType } from 'typesafe-actions';
import {
  IChallenge,
  IChallenger,
  IOpenChallenge,
  LeaderBoardUserData,
  TeamsPlayedUserData,
} from '../../all-models';
import {
  IChallengeScoringBreakdown,
  ILeagueChallenge,
  MatchedRosters,
} from '../../models/challenge';
import { IRoster } from '../../models/roster';
import { produceItem } from '../produceIitem';
import { RootActions } from '../rootActions';
import { IRootState } from '../rootReducer';
import {
  createChallenge,
  createOpenChallenge,
  enterChallenge,
  getChallenge,
  leaveChallenge,
  refreshChallengePoints,
  requestAllUsers,
  requestAllUsersPlayed,
  requestChallengeCompletedList,
  requestChallengeList,
  requestChallenges,
  requestChallengeScoringBreakdown,
  requestOpenChallenges,
  requestUserCompletedChallenges,
  requestUserLiveChallenges,
  updateChallenge,
} from './challengeActions';

export interface IChallengeSubState {
  retrieved: Retrieval<IChallenge>;
  scoring: Retrieval<IChallengeScoringBreakdown[]>;
  refreshPoints: Retrieval<void>;
}

export interface IChallengeCompletedSubState {
  retrieved: Retrieval<IChallenge>;
}

export interface IUserChallengesSubState {
  retrieved: Retrieval<IChallenge>;
}

export interface IChallengerSubState {
  retrieved: Retrieval<IChallenger[]>;
}

export interface ILeagueChallengeSubState {
  retrieved: Retrieval<ILeagueChallenge[]>;
}

export interface IRosterSubState {
  retrieved: Retrieval<IRoster[]>;
}

export interface IChallengeState {
  workingChallenges: {
    creating: Retrieval<IChallenge>;
    entering: Retrieval<IChallenge>;
    updating: Retrieval<IChallenge>;
    continuation: Retrieval<string | undefined>;
    leaving: Retrieval<IChallenge>;
  };
  workingOpenChallenges: {
    creating: Retrieval<IOpenChallenge>;
  };
  // TODO: Figure out why this typing is breaking.
  challenges: { [id: string]: IChallengeSubState };
  openChallenges: {
    openChallenges: Retrieval<IOpenChallenge[]>;
    continuation: Retrieval<string | undefined>;
  };
  completedChallenges: { [id: string]: IChallengeCompletedSubState };
  userLiveChallenges: Retrieval<any>;
  userCompletedChallenges: Retrieval<any>;
  retrieved: {
    ids: string[];
    continuation: Retrieval<string | undefined>;
  };
  retrievedCompleted: {
    ids: string[];
    continuation: Retrieval<string | undefined>;
  };
  challengeState: Retrieval<boolean>;
  rosterState: Retrieval<boolean>;
  leagueState: Retrieval<boolean>;
  leaderBoardUsers: Retrieval<LeaderBoardUserData>;
  teamsPlayed: Retrieval<TeamsPlayedUserData>;
  matchedRoster: MatchedRosters | null;
}

const initialState: IChallengeState = {
  workingChallenges: {
    creating: idleRetrieval,
    entering: idleRetrieval,
    updating: idleRetrieval,
    continuation: idleRetrieval,
    leaving: idleRetrieval,
  },
  workingOpenChallenges: {
    creating: idleRetrieval,
  },
  challenges: {},
  openChallenges: {
    openChallenges: idleRetrieval,
    continuation: idleRetrieval,
  },
  completedChallenges: {},
  retrieved: {
    ids: [],
    continuation: idleRetrieval,
  },
  retrievedCompleted: {
    ids: [],
    continuation: idleRetrieval,
  },
  challengeState: idleRetrieval,
  rosterState: idleRetrieval,
  leagueState: idleRetrieval,
  userLiveChallenges: idleRetrieval,
  userCompletedChallenges: idleRetrieval,
  leaderBoardUsers: idleRetrieval,
  teamsPlayed: idleRetrieval,
  matchedRoster: null,
};
const modChallenge = produceItem<IChallengeSubState>({
  retrieved: idleRetrieval,
  refreshPoints: idleRetrieval,
  scoring: idleRetrieval,
});

const modCompletedChallenge = produceItem<IChallengeCompletedSubState>({
  retrieved: idleRetrieval,
});

export const challengeReducer = produce(
  (state: IChallengeState, action: RootActions) => {
    switch (action.type) {
      case getType(createChallenge.request):
        state.workingChallenges.creating = workingRetrival;
        return;
      case getType(createChallenge.success):
        // Trigger analytics events
        sendChallengeCreateEvent(action.payload);
        state.workingChallenges.creating = success(action.payload);
        return;
      case getType(createChallenge.failure):
        state.workingChallenges.creating = error(action.payload);
        return;
      case getType(createChallenge.cancel):
        state.workingChallenges.creating = idleRetrieval;
        return;
      case getType(enterChallenge.request):
        state.workingChallenges.entering = workingRetrival;
        return;
      case getType(enterChallenge.success):
        state.workingChallenges.entering = success(action.payload);
        return;
      case getType(enterChallenge.failure):
        state.workingChallenges.entering = error(action.payload);
        return;
      case getType(enterChallenge.cancel):
        state.workingChallenges.entering = idleRetrieval;
        return;
      case getType(updateChallenge.request):
        state.workingChallenges.updating = workingRetrival;
        return;
      case getType(updateChallenge.success):
        state.workingChallenges.updating = success(action.payload);
        return;
      case getType(updateChallenge.failure):
        state.workingChallenges.updating = error(action.payload);
        return;
      case getType(updateChallenge.cancel):
        state.workingChallenges.updating = idleRetrieval;
        return;
      case getType(leaveChallenge.request):
        state.workingChallenges.leaving = workingRetrival;
        return;
      case getType(leaveChallenge.success):
        state.workingChallenges.leaving = success(action.payload);
        return;
      case getType(leaveChallenge.failure):
        state.workingChallenges.leaving = error(action.payload);
        return;
      case getType(leaveChallenge.cancel):
        state.workingChallenges.leaving = idleRetrieval;
        return;
      case getType(createOpenChallenge.request):
        state.workingOpenChallenges.creating = workingRetrival;
        return;
      case getType(createOpenChallenge.success):
        state.workingOpenChallenges.creating = success(action.payload);
        return;
      case getType(createOpenChallenge.failure):
        state.workingOpenChallenges.creating = error(action.payload);
        return;
      case getType(createOpenChallenge.cancel):
        state.workingOpenChallenges.creating = idleRetrieval;
        return;
      case getType(requestOpenChallenges.request):
        state.openChallenges.continuation = workingRetrival;
        return;
      case getType(requestOpenChallenges.success):
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        for (const challenge of action.payload.openChallenges) {
          state.openChallenges.openChallenges = success(
            action.payload.openChallenges,
          );
        }
        return;
      case getType(requestOpenChallenges.failure):
        state.openChallenges.continuation = error(action.payload);
        return;
      case getType(requestOpenChallenges.cancel):
        state.openChallenges.continuation = idleRetrieval;
        return;
      case getType(requestChallenges.request):
        state.challengeState = workingRetrival;
        return;
      case getType(requestChallenges.success):
        state.challengeState = success(true);
        return;
      case getType(getChallenge.request):
        modChallenge(state.challenges, action.payload, (c) => {
          c.retrieved = workingRetrival;
        });
        return;
      case getType(getChallenge.success):
        modChallenge(state.challenges, action.payload.id, (c) => {
          c.retrieved = success(action.payload);
        });
        return;
      case getType(getChallenge.failure):
        modChallenge(state.challenges, action.payload.id, (c) => {
          c.retrieved = error(action.payload.error);
        });
        return;
      case getType(requestChallengeScoringBreakdown.request):
        modChallenge(state.challenges, action.payload, (c) => {
          c.scoring = workingRetrival;
        });
        return;
      case getType(requestChallengeScoringBreakdown.success):
        modChallenge(state.challenges, action.payload.id, (c) => {
          c.scoring = success(
            action.payload.value,
          ) as ISuccessfulRetrieval<any>;
        });
        return;
      case getType(requestChallengeScoringBreakdown.failure):
        modChallenge(state.challenges, action.payload.id, (c) => {
          c.scoring = error(action.payload.error);
        });
        return;
      case getType(refreshChallengePoints.request):
        return modChallenge(
          state.challenges,
          action.payload,
          (s) => (s.refreshPoints = workingRetrival),
        );
      case getType(refreshChallengePoints.success):
        state.matchedRoster = action.payload.matchedRoster;

        return modChallenge(
          state.challenges,
          action.payload.challengeId,
          (s) => {
            if (s.retrieved.state === RetrievalState.Succeeded) {
              s.retrieved.value.pointsUpdatedAt = new Date().toISOString();
              for (const [id, points] of Object.entries(
                action.payload.points,
              )) {
                const challenger = s.retrieved.value.challengers.find(
                  (c) => c.id === id,
                );
                if (challenger) {
                  challenger.playerPoints = points;
                }
              }
            }

            s.refreshPoints = success(undefined);
          },
        );
      case getType(refreshChallengePoints.failure):
        return modChallenge(
          state.challenges,
          action.payload.challengeId,
          (s) => (s.refreshPoints = error(action.payload.error)),
        );
      case getType(requestChallengeList.request):
        state.retrieved.continuation = workingRetrival;
        return;
      case getType(requestChallengeList.success):
        for (const challenge of action.payload.challenges) {
          modChallenge(
            state.challenges,
            challenge.id,
            (s) => (s.retrieved = success(challenge)),
          );
        }
        state.retrieved.ids = state.retrieved.ids.concat(
          action.payload.challenges.map((c) => c.id),
        );
        state.retrieved.continuation = success(action.payload.continuation);
        return;
      case getType(requestChallengeCompletedList.request):
        state.retrievedCompleted.continuation = workingRetrival;
        return;
      case getType(requestChallengeCompletedList.success):
        state.retrievedCompleted.ids = [];
        for (const challenge of action.payload.challenges) {
          modCompletedChallenge(
            state.completedChallenges,
            challenge.id,
            (s) => (s.retrieved = success(challenge)),
          );
        }
        state.retrievedCompleted.ids = state.retrievedCompleted.ids.concat(
          action.payload.challenges.map((c) => c.id),
        );
        state.retrievedCompleted.continuation = success(
          action.payload.continuation,
        );
        return;
      case getType(requestUserLiveChallenges.request):
        state.userLiveChallenges = workingRetrival;
        return;
      case getType(requestUserLiveChallenges.success):
        state.userLiveChallenges = success(action.payload);
        return;
      case getType(requestUserCompletedChallenges.request):
        state.userCompletedChallenges = workingRetrival;
        return;
      case getType(requestUserCompletedChallenges.success):
        state.userCompletedChallenges = success(action.payload);
        return;
      case getType(requestAllUsers.request):
        state.leaderBoardUsers = workingRetrival;
        return;
      case getType(requestAllUsers.success):
        state.leaderBoardUsers = success(action.payload);
        return;
      case getType(requestAllUsers.failure):
        state.leaderBoardUsers = error(action.payload);
        return;
      case getType(requestAllUsers.cancel):
        state.leaderBoardUsers = idleRetrieval;
        return;
      case getType(requestAllUsersPlayed.request):
        state.teamsPlayed = workingRetrival;
        return;
      case getType(requestAllUsersPlayed.success):
        state.teamsPlayed = success(action.payload);
        return;
      case getType(requestAllUsersPlayed.failure):
        state.teamsPlayed = error(action.payload);
        return;
      case getType(requestAllUsersPlayed.cancel):
        state.teamsPlayed = idleRetrieval;
        return;
    }
  },
  initialState,
);

const select = (state: IRootState) => state.challenges;

export const getChallengesContinuation = (state: IRootState) =>
  select(state).retrieved.continuation;

export const getOpenChallengesContinuation = (state: IRootState) =>
  select(state).openChallenges.continuation;

export const getChallengesCompletedContinuation = (state: IRootState) =>
  select(state).retrievedCompleted.continuation;

export const getChallengesMap = (state: IRootState) => select(state).challenges;

export const getCompletedChallengesMap = (state: IRootState) =>
  select(state).completedChallenges;

export const getCurrentUserLiveChallenges = (state: IRootState) =>
  select(state).userLiveChallenges;

export const getCurrentUserCompletedChallenges = (state: IRootState) =>
  select(state).userCompletedChallenges;

export const getChallengeLivePoints =
  (challengeId: string) => (state: IRootState) =>
    select(state).challenges[challengeId]?.refreshPoints || idleRetrieval;

export const challengePointRefreshInterval = 30_000;

export const getShouldRefreshChallengePoints =
  (challengeId: string) => (state: IRootState) => {
    const challenge = select(state).challenges[challengeId];
    return (
      challenge?.retrieved.state === RetrievalState.Succeeded &&
      (shouldAttempt(challenge.refreshPoints) ||
        Date.now() -
          (new Date(
            challenge.retrieved.value.pointsUpdatedAt || '',
          ).getTime() || 0) >
          challengePointRefreshInterval)
    );
  };

export const getRetrievedChallengeIds = (state: IRootState) =>
  select(state).retrieved.ids;

export const getRetrievedCompletedChallengeIds = (state: IRootState) =>
  select(state).retrievedCompleted.ids;

export const getRetrievedChallenges = createSelector(
  getChallengesMap,
  getRetrievedChallengeIds,
  (map, ids) => {
    const challenges: IChallenge[] = [];
    for (const id of ids) {
      const substate = map[id];
      if (substate?.retrieved.state === RetrievalState.Succeeded) {
        const challengeExist = challenges.some(
          (challenge) => challenge.id === id,
        );
        !challengeExist && challenges.push(substate.retrieved.value);
      }
    }

    return challenges;
  },
);

export const getRetrievedCompletedChallenges = createSelector(
  getCompletedChallengesMap,
  getRetrievedCompletedChallengeIds,
  (map, ids) => {
    const completedChallenges: IChallenge[] = [];
    for (const id of ids) {
      const substate = map[id];
      if (substate?.retrieved.state === RetrievalState.Succeeded) {
        completedChallenges.push(substate.retrieved.value);
      }
    }

    return completedChallenges;
  },
);

export const getRetrievedCompletedAndLiveChallenges = createSelector(
  getRetrievedChallenges,
  getRetrievedCompletedChallenges,
  (active, completed) => {
    return [...active, ...completed];
  },
);

export const getchallengeIds = createSelector(getChallengesMap, Object.keys);

export const workingChallenges = (state: IRootState) =>
  select(state).workingChallenges;

export const getCreatingChallengeState = (state: IRootState) =>
  select(state).workingChallenges.creating;

export const getChallengePointBreakdown =
  (challengeId: string) => (state: IRootState) =>
    getChallengesMap(state)[challengeId]?.scoring || idleRetrieval;

export const getChallengeById = (challengeId: string) => (state: IRootState) =>
  select(state).challenges[challengeId]?.retrieved ?? idleRetrieval;

export const getOpenChallengesList = (state: IRootState) =>
  select(state).openChallenges;

export const workingOpenChallenges = (state: IRootState) =>
  select(state).workingOpenChallenges.creating;

export const updateChallengeState = (state: IRootState) =>
  select(state).workingChallenges.updating;

export const getLeaderboardChallengers = (state: IRootState) =>
  select(state).leaderBoardUsers;

export const getTeamsPlayedUserInfo = (state: IRootState) =>
  select(state).teamsPlayed;

export const getMatchedRoster = (state: IRootState) =>
  select(state).matchedRoster;

// Analytics
const sendChallengeCreateEvent = (payload: IChallenge) => {
  ReactGA.event({
    category: 'Challenge',
    action: 'challenge create',
  });

  ReactGA.event({
    category: 'Challenge',
    action: 'challenge create: entry fee',
    label: 'Entry Fee',
    value: payload.entryFee,
  });

  ReactGA.event({
    category: 'Challenge',
    action: 'challenge create: end date',
    label: 'End Date',
    value: parseInt(payload.endDate),
  });

  ReactGA.event({
    category: 'Challenge',
    action: 'challenge create: privacy',
    label: 'Private',
    value: payload.private ? 1 : 0,
  });
};
