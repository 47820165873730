import {
  Avatar,
  Grid,
  makeStyles,
  Typography,
  useMediaQuery,
  useTheme,
} from '@material-ui/core';
// import HowToBackground from '../../assets/FPO_VIdeo-Image@3x.png'
import React from 'react';
import { IUserPrivate, IWallet } from '../../all-models';
import rewardsIcon from '../../assets/ui-icon-rewards@3x.png';
import tokenIcon from '../../assets/ui-icon-token-001@3x.png';
import walletIcon from '../../assets/wallet@3x.png';
import dollarIcon from '../../assets/winnings-icon.svg';
import { formatCurrency } from '../currency/moneyFormatter';

const balanceSummaryStyles = makeStyles((theme) => ({
  section: {
    backgroundColor: 'white',
    borderRadius: theme.spacing(2),
    padding: theme.spacing(3),

    [theme.breakpoints.down('lg')]: {
      marginBottom: theme.spacing(2),
      padding: theme.spacing(3, 2),
      '& > div:nth-child(even)': {
        paddingLeft: '20px',
        marginLeft: '20px',
        borderLeft: `solid 1px ${theme.palette.divider}`,
      },
    },

    [theme.breakpoints.up('lg')]: {
      '& > div:not(:first-child)': {
        borderLeft: `solid 1px ${theme.palette.divider}`,
        paddingLeft: '10px',
        marginLeft: '10px',
      },
    },
  },
  balanceCopy: {
    [theme.breakpoints.down('sm')]: {
      paddingLeft: theme.spacing(1),
    },
  },

  avatarContainer: {
    position: 'relative',
    width: '32px',
    [theme.breakpoints.down('sm')]: {
      width: '24px',
    },
  },
  avatar: {
    width: '32px',
    height: '32px',
    color: theme.palette.secondary.main,
    [theme.breakpoints.down('sm')]: {
      width: '24px',
      height: '24px',
    },
  },
  iconPinkBg: {
    width: '32px',
    height: '32px',
    borderRadius: '33.7px',
    backgroundColor: theme.palette.secondary.main,
    [theme.breakpoints.down('sm')]: {
      width: '24px',
      height: '24px',
    },
  },
  walletIconAbsolute: {
    position: 'absolute',
    width: '16px',
    height: '16px',
    marginRight: theme.spacing(1.5),
    top: theme.spacing(1),
    left: theme.spacing(1),
  },
  dollarTitleContainer: {
    width: '137px',
  },
  dollars: {
    fontSize: '36px',
    fontWeight: 300,
    marginBottom: theme.spacing(-0.5),
    [theme.breakpoints.down('sm')]: {
      fontSize: '24px',
    },
  },
  balanceTitle: {
    fontSize: '12px',
    color: '#666666',
    [theme.breakpoints.down('sm')]: {
      fontSize: '12px',
    },
  },
  greyDivider: {
    width: '1px',
    height: '82px',
    backgroundColor: theme.palette.divider,

    [theme.breakpoints.down('sm')]: {
      height: '42px',
    },
  },
  greyDividerHide: {
    width: '1px',
    height: '82px',
    backgroundColor: theme.palette.divider,
    marginLeft: theme.spacing(-1),
    [theme.breakpoints.down('sm')]: {
      display: 'none',
    },
  },
  horizontalDivider: {
    height: '1px',
    backgroundColor: '#ededed',
    marginTop: theme.spacing(1.5),
    marginBottom: theme.spacing(1.5),
    [theme.breakpoints.up('md')]: {
      display: 'none',
    },
  },
}));

const BalanceSummary: React.FC<{
  wallet: IWallet;
  userData: IUserPrivate | undefined;
}> = ({ wallet, userData }) => {
  const classes = balanceSummaryStyles();
  const theme = useTheme();
  const smViewMediaQuery = useMediaQuery(theme.breakpoints.down('sm'));

  const balanceData = [
    {
      icon: walletIcon,
      balance: formatCurrency(wallet?.funds),
      copy: 'Balance',
    },
    {
      icon: rewardsIcon,
      balance: wallet?.bits,
      copy: 'Flex Bits',
    },
    {
      icon: dollarIcon,
      balance: formatCurrency(userData?.winnings || 0),
      copy: 'Winnings',
    },
    {
      icon: tokenIcon,
      balance: formatCurrency(wallet?.creditBalance),
      copy: 'Flex Credit',
    },
  ];

  return (
    <Grid
      container
      xs={12}
      alignItems="center"
      justifyContent="flex-start"
      direction="row"
      className={classes.section}
    >
      {balanceData.map((data, index) => (
        <>
          <Grid
            container
            item
            xs={5}
            lg={index === 1 ? 2 : 3}
            alignItems="center"
            justifyContent="flex-start"
          >
            <Grid
              item
              container
              direction="row"
              alignItems="center"
              justifyContent="flex-start"
              xs={12}
            >
              <Grid
                item
                className={classes.avatarContainer}
                xs={3}
                lg={index === 1 && 4}
              >
                <Avatar src={data.icon} className={classes.avatar}></Avatar>
              </Grid>
              <Grid
                item
                container
                direction="column"
                xs={9}
                lg={index === 1 && 8}
                justifyContent="flex-start"
                className={classes.balanceCopy}
              >
                <Grid item>
                  <Typography className={classes.dollars}>
                    {data.balance}
                  </Typography>
                </Grid>
                <Grid item>
                  <Typography className={classes.balanceTitle}>
                    {data.copy}
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </>
      ))}
    </Grid>
  );
};

export default BalanceSummary;
