import API from '@aws-amplify/api';
import { IRosterUpdateBody } from '../../all-models';
import { PropsFantasyApi } from './base';

export class PropsFantasyRosterApi extends PropsFantasyApi {
  public async getCurrentUserRosters(): Promise<{ rosters: any[] }> {
    return await API.get('currentRoster', '/current-roster', {}).then(
      (r: any) => {
        return { rosters: r.data.Items };
      },
    );
  }

  public async getRostersBatch(
    _rosterIds: string[],
  ): Promise<{ rosters: any[] }> {
    const rosterIds = [...new Set(_rosterIds)];
    return await API.post('rosters', '/rosters/batch', {
      body: { rosterIds },
    }).then((r: any) => {
      return { rosters: r.data };
    });
  }

  public async updateRoster(body: IRosterUpdateBody) {
    const axios = (await this.getAxios()) || this.axiosInstance;

    const result: {
      updateRoster: { id: string };
    } = await axios.patch(`/rosters/${body.id}`, {
      updatedTeamName: body.updatedTeamName,
      updatedAvatar: body.updatedAvatar,
    });

    return result.updateRoster;
  }
}
