import {
  Avatar,
  Grid,
  makeStyles,
  Typography,
  useMediaQuery,
  useTheme,
} from '@material-ui/core';
import clsx from 'clsx';
import React, { useCallback, useEffect, useState } from 'react';
import { IUserPrivate, LeaderBoardUserData } from '../../all-models';
import greyChallengeIcon from '../../assets/grey-challenge.png';
import crownIcon from '../../assets/icon-crown@3x.png';
import trophyIcon from '../../assets/Icon_Champ.png';
import pinkCrown from '../../assets/pink-crown.png';
import challengeIcon from '../../assets/ui-icon-challenge-003-copy@3x.png';
import { RosterWithOwner } from '../../stores/user/userReducer';
import theme from '../../theme';

const leaderboardStyles = makeStyles((theme) => ({
  titleRow: {
    paddingBottom: theme.spacing(2.5),
  },
  section: {
    backgroundColor: 'white',
    padding: theme.spacing(2.5),
    borderRadius: theme.spacing(2),
    marginTop: theme.spacing(2.5),
    [theme.breakpoints.down('sm')]: {
      padding: theme.spacing(2, 1.5),
    },
  },
  viewLeaderboardButton: {
    color: '#05c500',
    fontSize: '16px',
    fontWeight: 'bold',
    fontStretch: 'condensed',
    lineHeight: 1.25,
    textAlign: 'center',
    marginTop: theme.spacing(3.5),
    marginBottom: theme.spacing(0.5),
    [theme.breakpoints.down('sm')]: {
      padding: theme.spacing(2.5),
      marginTop: theme.spacing(1),
    },
  },
  notificationRowContainer: {
    [theme.breakpoints.down('sm')]: {
      padding: theme.spacing(2.5),
    },
  },

  blackContainer: {
    background: '#252525',
    borderRadius: '6px',
    padding: theme.spacing(1.3, 3),
    margin: theme.spacing(2.5, 0, 0),
  },
  copyContainer: {
    paddingLeft: theme.spacing(2),
    borderLeft: '1px solid',
    borderLeftColor: 'rgba(255, 255, 255, .15)',
    [theme.breakpoints.down('sm')]: {
      paddingLeft: theme.spacing(1),
    },
  },

  whiteCopy: {
    color: '#ffffff',
    lineHeight: 1.2,
  },
  crownContainer: {
    paddingRight: theme.spacing(2),
  },
  pinkCrownIcon: {
    width: '32px',
    height: '32px',
    [theme.breakpoints.down('sm')]: {
      width: '24px',
      height: '24px',
    },
  },
  tableTitleContainer: {
    backgroundColor: '#e4e4e4',
    borderRadius: '6px',
    height: '30px',
    padding: theme.spacing(0, 2),
    [theme.breakpoints.down('sm')]: {
      padding: theme.spacing(0, 1),
    },
  },

  labelsCopy: {
    fontWeight: 'bold',
    color: '#7C7C7C',
  },
}));

const weekSeasonToggleStyles = makeStyles((theme) => ({
  toggleContainer: {
    borderRadius: '3.5px',
    height: '24px',
    [theme.breakpoints.down('sm')]: {
      marginTop: theme.spacing(1),
    },
  },
  activeContainer: {
    backgroundColor: '#05c500',
    borderRadius: '3.5px',
    height: '24px',
  },
  activeText: {
    color: 'white',
    fontWeight: 'bold',
    fontSize: '14px',
  },
  inactiveContainer: {
    backgroundColor: '#ededed',
    borderRadius: '3.5px',
    height: '24px',
  },
  inactiveText: {
    color: '#666000',
    fontSize: '14px',
  },
}));

const tableContentRowStyles = makeStyles((theme) => ({
  contentRow: {
    borderBottom: '1px solid lightgray',
    padding: theme.spacing(2.5),
    '&:last-of-type': {
      borderBottom: 'none',
    },
    [theme.breakpoints.down('sm')]: {
      padding: theme.spacing(2, 1),
    },
  },
  creamBackground: {
    backgroundColor: '#FEF7EB',
  },
  visibilityNone: {
    visibility: 'hidden',
  },

  challengerNameIcon: {
    width: '48px',
    height: '48px',
    borderRadius: '44.9px',
    border: 'solid 0.9px #c6c6c6',
    marginRight: theme.spacing(1.5),
    [theme.breakpoints.down('sm')]: {
      display: 'none',
    },
  },

  prizeIcon: {
    width: '22px',
    height: '22px',
    marginRight: theme.spacing(0.4),
    '@media (max-width: 360px)': {
      width: '20px',
      height: '20px',
    },
  },
  prizeMoney: {
    fontWeight: 'bold',
    '@media (max-width: 360px)': {
      fontSize: '14px',
    },
  },
  smScreenFont: {
    '@media (max-width: 360px)': {
      fontSize: '14px',
    },
  },
  wltContainer: {
    borderRight: '1px solid #EDEDED',
  },

  wltDash: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    [theme.breakpoints.down('sm')]: {
      marginLeft: theme.spacing(0.5),
      marginRight: theme.spacing(0.5),
    },
    '@media (max-width: 360px)': {
      fontSize: '14px',
    },
  },
  contentGreyDivider: {
    width: '5px',
    height: '32px',
    backgroundColor: '#ededed',

    '&:last-of-type': {
      display: 'none',
    },
    [theme.breakpoints.down('sm')]: {
      display: 'none',
    },
  },
  challengeIcon: {
    width: '24px',
    height: '24px',
    cursor: 'pointer',
    [theme.breakpoints.down('sm')]: {
      width: '20px',
      height: '20px',
    },
  },
  disabled: {
    cursor: 'default',
  },
}));

const WeekSeasonToggle: React.FC = () => {
  const classes = weekSeasonToggleStyles();
  const theme = useTheme();
  const smViewMediaQuery = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <Grid
      xs={12}
      md={6}
      container
      item
      className={classes.toggleContainer}
      justifyContent={smViewMediaQuery ? 'center' : 'flex-end'}
    >
      <Grid
        container
        item
        xs={6}
        md={5}
        className={classes.inactiveContainer}
        justifyContent="center"
        alignItems="center"
      >
        <Typography className={classes.inactiveText}>LAST WEEK</Typography>
      </Grid>
      <Grid
        container
        item
        xs={6}
        md={5}
        className={classes.activeContainer}
        justifyContent="center"
        alignItems="center"
      >
        <Typography className={classes.activeText}>Season</Typography>
      </Grid>
    </Grid>
  );
};

const TableContentRow: React.FC<{
  cognitoData: IUserPrivate[];
  user: IUserPrivate;
  index: number;
  loggedInUserIndex: number;
  createOneClickChallengeChallenge: Function;
  leaderBoardRosters: RosterWithOwner[];
  currentLoggedInUser: IUserPrivate;
}> = ({
  cognitoData,
  user,
  index,
  loggedInUserIndex,
  createOneClickChallengeChallenge,
  leaderBoardRosters,
  currentLoggedInUser,
}) => {
  const classes = tableContentRowStyles();
  const smViewMediaQuery = useMediaQuery(theme.breakpoints.down('sm'));
  const [isCurrentUser, setIsCurrentUser] = useState<boolean>(false);
  const [notInTopFive, setNotInTopFive] = useState<boolean>(false);
  const [leaderboardUser, setLeaderBoardUser] = useState<IUserPrivate>();
  const [hideOneClick, setHideOneClick] = useState<boolean>(false);

  const creamBackground = isCurrentUser ? classes.creamBackground : undefined;
  const hideOnCurrentUser = isCurrentUser ? classes.visibilityNone : undefined;

  useEffect(() => {
    cognitoData.map((usr) => {
      if (usr[0]['Value'] === user.id) {
        setLeaderBoardUser(usr);
      }
    });
  }, [cognitoData]);

  useEffect(() => {
    if (user.id === currentLoggedInUser.sub) {
      setIsCurrentUser(true);
    }
  }, []);

  useEffect(() => {
    if (loggedInUserIndex > 5 && isCurrentUser) {
      setNotInTopFive(true);
    }
  }, [isCurrentUser]);

  useEffect(() => {
    const userHasRoster = leaderBoardRosters.filter(
      (r) => r.userId === user.id,
    );

    if (userHasRoster.length < 1) {
      setHideOneClick(true);
    }
  }, [user]);

  const sendRosterForOneClickChallenge = useCallback(() => {
    let roster = leaderBoardRosters.filter((r) => r.userId === user.id);

    createOneClickChallengeChallenge(roster[0]);
  }, []);

  return (
    <Grid
      container
      className={clsx([classes.contentRow, creamBackground])}
      alignItems="center"
      justifyContent="space-between"
      direction="row"
      wrap="nowrap"
      xs={12}
    >
      <Grid item xs={2} md={1}>
        {notInTopFive ? (
          <Typography variant="subtitle2" className={classes.smScreenFont}>
            {loggedInUserIndex}
          </Typography>
        ) : (
          <Typography variant="subtitle2" className={classes.smScreenFont}>
            {index + 1}
          </Typography>
        )}
      </Grid>
      <Grid
        container
        item
        xs={3}
        md={5}
        justifyContent="flex-start"
        alignItems="center"
        spacing={1}
      >
        {!smViewMediaQuery && leaderboardUser && (
          <Grid item>
            {leaderboardUser[6] ? (
              <Avatar
                src={'broken'}
                alt={`${leaderboardUser[6]['Value']} ${leaderboardUser[7]['Value']}`}
              />
            ) : (
              <Avatar src={'broken'} alt={`${leaderboardUser[3]['Value']} `} />
            )}
          </Grid>
        )}

        <Grid item>
          {leaderboardUser && (
            <Typography variant="subtitle2" className={classes.smScreenFont}>
              {leaderboardUser[6]
                ? leaderboardUser[6]['Value']
                : leaderboardUser[3]['Value']}{' '}
              {leaderboardUser[7]
                ? leaderboardUser[7]['Value'].slice(0, 1)
                : leaderboardUser[4]['Value'].slice(0, 1)}
            </Typography>
          )}
        </Grid>
      </Grid>
      <Grid
        container
        item
        xs={3}
        md={2}
        direction="row"
        alignItems="center"
        justifyContent="center"
      >
        {index === 0 && (
          <Grid item>
            <Avatar src={crownIcon} className={classes.prizeIcon}></Avatar>
          </Grid>
        )}
        {(index === 1 || index === 2) && (
          <Grid item>
            <Avatar src={trophyIcon} className={classes.prizeIcon}></Avatar>
          </Grid>
        )}

        <Grid item>
          <Typography variant="subtitle2" className={classes.prizeMoney}>
            {index === 0
              ? '$500'
              : index === 1
              ? '$250'
              : index == 2
              ? '$100'
              : undefined}
          </Typography>
        </Grid>
      </Grid>
      <Grid
        item
        container
        xs={3}
        md={3}
        className={classes.wltContainer}
        justifyContent="center"
        alignItems="center"
      >
        <Grid item>
          <Typography variant="subtitle2" className={classes.smScreenFont}>
            {user?.wins ? user?.wins : '0'}
          </Typography>
        </Grid>
        <Grid item>
          <Typography variant="subtitle2" className={classes.wltDash}>
            -
          </Typography>
        </Grid>
        <Grid item>
          <Typography variant="subtitle2" className={classes.smScreenFont}>
            {user?.losses ? user?.losses : '0'}
          </Typography>
        </Grid>
      </Grid>

      <Grid item container justifyContent="flex-end" xs={1} md={1}>
        {!hideOneClick ? (
          <Avatar
            src={challengeIcon}
            className={clsx([classes.challengeIcon, hideOnCurrentUser])}
            onClick={sendRosterForOneClickChallenge}
          />
        ) : (
          <Avatar
            src={greyChallengeIcon}
            className={clsx([
              classes.challengeIcon,
              classes.disabled,
              hideOnCurrentUser,
            ])}
          />
        )}
      </Grid>
    </Grid>
  );
};

const Leaderboard: React.FC<{
  leaderBoardChallengers: LeaderBoardUserData;
  createOneClickChallengeChallenge: Function;
  currentLoggedInUser: IUserPrivate;
}> = ({
  leaderBoardChallengers,
  createOneClickChallengeChallenge,
  currentLoggedInUser,
}) => {
  const classes = leaderboardStyles();
  const smViewMediaQuery = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <Grid container xs={12} className={classes.section}>
      <Grid
        container
        item
        className={classes.titleRow}
        direction="row"
        alignItems="center"
        xs={12}
      >
        <Grid item xs={12}>
          <Typography variant="h5">Leaderboard</Typography>
        </Grid>
        <Grid
          item
          container
          xs={12}
          alignItems="center"
          justifyContent="center"
          className={classes.blackContainer}
        >
          <Grid
            item
            xs={1}
            container
            justifyContent="center"
            className={classes.crownContainer}
          >
            <Avatar src={pinkCrown} className={classes.pinkCrownIcon} />
          </Grid>
          <Grid item xs={11} className={classes.copyContainer}>
            <Typography variant="caption" className={classes.whiteCopy}>
              Winners keep winning. Earn up to $500 in free Flex Credits.
              Awarded to the top 3 challenge champions of 2023.
            </Typography>
          </Grid>
        </Grid>
      </Grid>
      <Grid container xs={12}>
        <Grid
          container
          item
          className={classes.tableTitleContainer}
          alignItems="center"
          justifyContent="space-between"
        >
          <Grid item xs={2} md={2}>
            <Typography variant="caption" className={classes.labelsCopy}>
              POS
            </Typography>
          </Grid>
          <Grid item xs={4} md={4}>
            <Typography variant="caption" className={classes.labelsCopy}>
              Challenger
            </Typography>
          </Grid>
          <Grid item container justifyContent="center" xs={2} md={2}>
            <Typography variant="caption" className={classes.labelsCopy}>
              Prize
            </Typography>
          </Grid>
          <Grid item xs={3} md={3} container justifyContent="center">
            <Typography variant="caption" className={classes.labelsCopy}>
              W&nbsp;&nbsp; - &nbsp;&nbsp;L
            </Typography>
          </Grid>
          <Grid item xs={1} md={1}>
            &nbsp;
          </Grid>
        </Grid>
        <Grid item container xs={12}>
          {leaderBoardChallengers?.users
            ?.slice(0, 6)
            .map((challenger, index) => (
              <TableContentRow
                user={challenger}
                index={index}
                leaderBoardRosters={leaderBoardChallengers.allRosters}
                loggedInUserIndex={leaderBoardChallengers.loggedInUserIndex}
                cognitoData={leaderBoardChallengers.topFiveChallengersCognito}
                createOneClickChallengeChallenge={
                  createOneClickChallengeChallenge
                }
                currentLoggedInUser={currentLoggedInUser}
              />
            ))}
        </Grid>
      </Grid>
    </Grid>
  );
};

export default Leaderboard;
