import { Drawer, Grid, makeStyles, Typography } from '@material-ui/core';
import { NotificationsOff } from '@material-ui/icons';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import React, { useEffect, useState } from 'react';
import { NotifyItem } from '../../models/notifications';
import { getRandomQuote } from '../../utils/footballQuotes';
import { NotifyCardContainer } from './notifyCardContainer';

const useNavDrawerStyles = makeStyles((theme) => ({
  paper: {
    background: 'white',
    width: '375px',
    height: '100vh',
    overflowY: 'scroll',
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
  },
  topContainer: {
    height: '68px',
  },
  labelContainer: {
    padding: theme.spacing(2, 2.5),
    height: '64px',
  },
  notifyCardContainer: {
    height: 'auto',
    width: '100%',
    overFlowY: 'scroll',
  },
  divider: {
    width: '100%',
    height: '2px',
    backgroundImage: `linear-gradient(
      79deg,
      #892886 4%,
      #e94057 43%,
      #f27123 80%,
      #912d7f 109%,
      #892886 109%,
      #892886 109%
    )`,
  },
  notifyCopy: {
    lineHeight: 1,
  },
  arrowIcon: {
    width: '16px',
    height: '32px',
    verticalAlign: 'middle',
    '&:hover': {
      cursor: 'pointer',
    },
  },
  noNotificationsCont: {
    padding: theme.spacing(0, 3),
    height: '90%',
    width: '100%',
    overFlowY: 'scroll',
  },
  bell: {
    color: theme.palette.primary.light,
    width: '32px',
    height: '32px',
  },
  greyFont: {
    color: '#666666',
  },
  dividerContainer: {
    width: '100%',
  },
  innerContainer: {
    maxHeight: '50%',
  },
  dividerNoNotify: {
    width: '100%',
    height: '2px',
    backgroundColor: theme.palette.divider,
  },
}));

export interface IFootballQuote {
  quote: string;
  author: string;
}

export const NotifyDrawer: React.FC<{
  notifyOpen: boolean;
  onToggleNotify(): void;
  notificationList: NotifyItem[];
}> = ({ notifyOpen, onToggleNotify, notificationList }) => {
  const classes = useNavDrawerStyles();
  const [quote, setQuote] = useState<IFootballQuote>();

  useEffect(() => {
    setQuote(getRandomQuote());
  }, []);

  return (
    <div>
      <Drawer
        anchor={'right'}
        open={notifyOpen}
        onClose={onToggleNotify}
        classes={{ paper: classes.paper }}
      >
        <Grid container className={classes.topContainer}>
          <Grid
            container
            justifyContent="space-between"
            alignItems="flex-start"
            item
            direction="row"
            className={classes.labelContainer}
          >
            <Grid item xs={11}>
              <Typography className={classes.notifyCopy} variant="h4">
                Notifications
              </Typography>
            </Grid>
            <Grid item xs={1}>
              <ArrowForwardIosIcon
                onClick={onToggleNotify}
                className={classes.arrowIcon}
              />
            </Grid>
          </Grid>
          <Grid
            item
            container
            alignItems="flex-start"
            className={classes.divider}
          >
            {/* <div className={classes.divider}></div> */}
          </Grid>
        </Grid>
        {notificationList.length >= 1 && (
          <Grid container item className={classes.notifyCardContainer}>
            <NotifyCardContainer
              notificationList={notificationList}
              onToggle={onToggleNotify}
              dashboard={false}
            />
          </Grid>
        )}
        {notificationList.length < 1 && (
          <Grid
            container
            item
            alignItems="center"
            justifyContent="center"
            className={classes.noNotificationsCont}
          >
            <Grid
              item
              container
              direction="column"
              className={classes.innerContainer}
              spacing={4}
            >
              <Grid
                item
                container
                justifyContent="center"
                alignItems="flex-start"
              >
                <NotificationsOff className={classes.bell} />
              </Grid>
              <Grid item container justifyContent="center">
                <Typography variant="subtitle2" className={classes.greyFont}>
                  No Notifications Yet
                </Typography>
              </Grid>
              <Grid item className={classes.dividerContainer}>
                <div className={classes.dividerNoNotify}></div>
              </Grid>
              <Grid item container justifyContent="center">
                <Typography variant="subtitle2" className={classes.greyFont}>
                  {quote?.quote}
                </Typography>
              </Grid>
              <Grid item container justifyContent="center">
                <Typography variant="caption" className={classes.greyFont}>
                  ~{quote?.author}~
                </Typography>
              </Grid>
            </Grid>
          </Grid>
        )}
      </Drawer>
    </div>
  );
};
