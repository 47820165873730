import { IError } from '@propsfantasy/retrieval';
import { ActionType, createAsyncAction } from 'typesafe-actions';
import { ILeague } from '../../all-models';

export const getAllLeagues = createAsyncAction(
  'GetAllLeaguesRequest',
  'GetAllLeaguesSuccess',
  'GetAllLeaguesError',
)<any, ILeague, IError>();

export const getLeague = createAsyncAction(
  'GetLeagueRequest',
  'GetLeagueSuccess',
  'GetLeagueError',
)<string, ILeague, IError>();

export const getAllRostersForLeague = createAsyncAction(
  'UserCurrentGetAllRosterForLeagueRequest',
  'UserCurrentGetAllRosterForLeagueSuccess',
  'UserCurrentGetAllRosterForLeagueError',
  'UserCurrentGetAllRosterForLeagueCancel',
)<any, any, any, any>();

export const getAllRostersForUser = createAsyncAction(
  'UserCurrentGetAllRosterForLeagueRequest',
  'UserCurrentGetAllRosterForLeagueSuccess',
  'UserCurrentGetAllRosterForLeagueError',
  'UserCurrentGetAllRosterForLeagueCancel',
)<any, any, any, any>();

const actions = {
  getAllLeagues,
  getAllRostersForLeague,
  getLeague,
};

export type LeaguesActions = ActionType<typeof actions>;
