import { Grid, makeStyles } from '@material-ui/core';
import React from 'react';

const progressBarStyles = makeStyles({
  container: {
    height: 10,
    width: '100%',
    backgroundColor: '#e0e0de',
    borderRadius: 50,
  },
  purpleContainer: {
    height: '100%',
    width: '50%',
    background: 'rgba(137, 40, 134, 0.3)',
    borderRadius: 'inherit',
    borderTopRightRadius: 0,
    borderBottomRightRadius: 0,
  },
  purpleFill: {
    height: '100%',
    width: (props) => `${props.purpleWidth}%`,
    backgroundColor: (props) => '#892886',
    borderRadius: 'inherit',
    zIndex: 1,
  },
  redContainer: {
    height: '100%',
    width: '50%',
    background: 'rgba(233, 64, 87, 0.3)',
    borderRadius: 'inherit',
    borderTopLeftRadius: 0,
    borderBottomLeftRadius: 0,
  },
  redFill: {
    height: '100%',
    width: (props) => `${props.redWidth}%`,
    backgroundColor: (props) => '#e94057',
    borderRadius: 'inherit',
    zIndex: 1,
  },
});

const ProgressBar: React.FC = (props) => {
  const classes = progressBarStyles(props);

  return (
    <Grid container item className={classes.container}>
      <Grid
        container
        item
        className={classes.purpleContainer}
        justifyContent="flex-end"
      >
        <Grid container item className={classes.purpleFill}></Grid>
      </Grid>
      <Grid
        container
        item
        className={classes.redContainer}
        // justifyContent="flex-end"
      >
        <Grid container item className={classes.redFill}></Grid>
      </Grid>
    </Grid>
  );
};

export default ProgressBar;
