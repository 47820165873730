import { Auth } from 'aws-amplify';
import { IdentityValidationRequest, IdentityVerification } from '../../API';
import {
  IOneClickChallengeDefault,
  IUserPrivate,
  UserFormData,
} from '../../all-models';
import { IStoredUser } from '../../models/user';
import { PropsFantasyApi } from './base';

const getUserPayload = (body: UserFormData) => ({
  username: body.email,
  password: body.password || '',
  attributes: {
    given_name: body.firstName,
    family_name: body.lastName,
    email: body.email,
    name: `${body.firstName} ${body.lastName}`,
  },
});

export class PropsFantasyAuthApi extends PropsFantasyApi {
  currentUserInfo: any = undefined;

  public async getCurrentUser(): Promise<IUserPrivate> {
    if (this.currentUserInfo && Object.keys(this.currentUserInfo).length > 0) {
      return Promise.resolve(this.currentUserInfo);
    } else {
      this.currentUserInfo = await Auth.currentUserInfo().then((r) => ({
        id: r.id,
        sub: r.attributes.sub,
        username: r.username,
        firstName: r.attributes?.given_name,
        lastName: r.attributes?.family_name,
        displayName: r.attributes?.name,
        email: r.attributes?.email,
        isEmailVerified: r.attributes?.email_verified,
      }));
      return this.currentUserInfo;
    }
  }

  public async login(username: string, password: string): Promise<any> {
    return Auth.signIn(username, password).catch(this.handleAPIError);
  }

  public async logout(): Promise<any> {
    return Auth.signOut({ global: true }).catch(this.handleAPIError);
  }

  public async getStored(): Promise<IStoredUser> {
    const axios = (await this.getAxios()) || this.axiosInstance;
    const current = await this.getCurrentUser();
    const userId: string = current.sub;

    const result: { getUser: IStoredUser } = await axios.get(
      `/users/${userId}`,
    );
    return result?.getUser || { balance: 0 };
  }

  public async verifyIdentity(request: IdentityValidationRequest) {
    const axios = (await this.getAxios()) || this.axiosInstance;
    const result: {
      validateIdentity: Required<IdentityVerification>;
    } = await axios.post('/users/validateIdentity', {
      validation: request,
    });

    return result?.validateIdentity;
  }

  public async verifyEmail(
    username: string,
    confirmationCode: string,
  ): Promise<any> {
    return Auth.confirmSignUp(username, confirmationCode).catch(
      this.handleAPIError,
    );
  }

  public async resetPassword(
    username: string,
    code: string,
    password: string,
  ): Promise<any> {
    return Auth.forgotPasswordSubmit(username, code, password).catch(
      this.handleAPIError,
    );
  }

  public async forgotPassword(username: string): Promise<any> {
    return Auth.forgotPassword(username).catch(this.handleAPIError);
  }

  public async register(body: UserFormData): Promise<any> {
    const axios = (await this.getAxios()) || this.axiosInstance;

    return Auth.signUp(getUserPayload(body))
      .then(async (user) => {
        await axios.post('/users/create', {
          email: body.email,
          id: user.userSub,
        });
        return user;
      })
      .catch(this.handleAPIError);
  }

  public async updateUser(body: UserFormData): Promise<any> {
    const payload = getUserPayload(body);

    return Auth.currentAuthenticatedUser()
      .then(async (user) => {
        let value = {};
        const status = await Auth.updateUserAttributes(user, {
          ...payload.attributes,
        });

        if (status === 'SUCCESS') {
          value = {
            firstName: body.firstName,
            lastName: body.lastName,
            displayName: `${body.firstName} ${body.lastName}`,
            email: body.email,
          };
        }

        return { status, value };
      })
      .catch(this.handleAPIError);
  }

  public async setReferrer(referrer: string): Promise<void> {
    const axios = (await this.getAxios()) || this.axiosInstance;
    await axios.post('/users/setReferrer', { referrer });
  }

  //**CREATE OR UPDATE ONE CLICK CHALLENGE DEFAULTS FOR USER */
  public async createOrUpdateOneClickChallengeDefaults(
    body: IOneClickChallengeDefault,
  ) {
    const userInfo = await this.getUser();
    const axios = (await this.getAxios()) || this.axiosInstance;

    if (body.id) {
      const updateDefaults: {
        updateOneClickChallenge: { id: string };
      } = await axios.put(`/one-click/${body.id}`, {
        userId: userInfo.attributes.sub,
        type: body.type,
        entryFee: body.entryFee,
        private: body.private,
        rosterId: body.rosterID,
        leagueId: body.leagueId,
      });

      return updateDefaults?.updateOneClickChallenge;
    } else {
      const newDefaults: {
        createOneClickChallengeDefaults: IOneClickChallengeDefault;
      } = await axios.post('/one-click', {
        userId: userInfo.attributes.sub,
        type: body.type,
        entryFee: body.entryFee,
        private: body.private,
        rosterId: body.rosterID,
        leagueId: body.leagueId,
      });

      return newDefaults?.createOneClickChallengeDefaults;
    }
  }

  //**GET ONE CLICK CHALLENGE DEFAULTS FOR USER */
  public async getOneClickChallengeDefaultsRequest() {
    const axios = (await this.getAxios()) || this.axiosInstance;
    const userInfo = await this.getUser();

    const defaults: IOneClickChallengeDefault = await axios.get(
      `/one-click/${userInfo.attributes.sub}`,
    );

    return defaults;
  }
}
