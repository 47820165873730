import {
  Divider,
  Grid,
  makeStyles,
  Snackbar,
  Typography,
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import MuiAlert from '@material-ui/lab/Alert';
import React, { useEffect } from 'react';
import { Button } from '../base/button';
import { FlexIcon } from '../base/flexIcon';

export function Alert(props: any) {
  return <MuiAlert icon={false} elevation={6} {...props} />;
}

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    height: 'auto',
    position: 'relative',
  },
  snackBar: {
    borderRadius: theme.spacing(2),
    width: '21%',
    backgroundColor: '#fffff',
    [theme.breakpoints.down('md')]: {
      width: '30%',
    },
    [theme.breakpoints.down('sm')]: {
      width: '40%',
    },
    [theme.breakpoints.down('xs')]: {
      width: 'auto',
    },
  },
  alert: {
    display: 'flex',
    justifyContent: 'center',
    paddingBottom: 0,
    borderRadius: theme.spacing(2),
    backgroundColor: '#fffff',
    height: 'auto',
    width: '100%',
  },
  container: {
    paddingTop: theme.spacing(2),
    width: '100%',
  },
  iconContainer: {
    position: 'absolute',
    top: '-15%',
    left: '50%',
    transform: 'translate(-50%, 15%)',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '40px',
    width: '40px',
    objectFit: 'contain',
  },
  exit: {
    position: 'absolute',
    top: '2%',
    right: '2%',
    transform: 'translate(-2%, -2%)',
    cursor: 'pointer',
  },
  title: {
    color: '#000000',
  },
  dividerContainer: {
    width: '30%',
  },
  divider: {
    height: '2px',
    borderRadius: theme.spacing(2),
    background: `linear-gradient(
            79deg,
            #892886 4%,
            #e94057 43%,
            #f27123 80%,
            #912d7f 109%,
            #892886 109%,
            #892886 109%
          );`,
  },
  detailsContainer: {
    width: '100%',
    textAlign: 'center',
  },
  buttonContainer: {
    height: 'auto',
  },
  button: {
    width: '100%',
    margin: 0,
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
  },
  directionsContainer: {
    marginBottom: theme.spacing(1),
  },
  numberBullets: {
    color: theme.palette.secondary.main,
    textAlign: 'center',
    padding: theme.spacing(0, 1),
    border: '2px solid',
    borderRadius: '50%',
  },
  icon: {
    width: '48px',
    height: '48px',
    padding: '5px',
    borderRadius: '50%',
    border: `solid 2px ${theme.palette.secondary.main}`,
    backgroundColor: 'white',
  },
}));

export const PWABannerPopup: React.FC = () => {
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  const [installed, setInstalled] = React.useState(false);
  const [prompt, setPrompt] = React.useState<any>();
  const [isAppleDevice, setIsAppleDevice] = React.useState<boolean>(false);

  const handleClose = (reason: any) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpen(!open);
    const expiresDate = new Date();
    const expires = expiresDate.setHours(expiresDate.getHours() + 336);
    localStorage.setItem('pwaBannerPopup', expires.toString());
  };

  const handleButtonClick = async (event: any) => {
    event.preventDefault();
    if (prompt) {
      prompt!.prompt();
      const result = await prompt!.userChoice;
      if (result && result.outcome === 'accepted') {
        setPrompt(null);
        localStorage.setItem('pwaBannerPopup', 'accepted');
        setOpen(false);
      }
    } else {
      setOpen(false);
    }
  };

  const previouslySeenAt = localStorage.getItem('pwaBannerPopup');

  // Effect for handling event listeners and install status
  useEffect(() => {
    const ready = (e: any) => {
      e.preventDefault();
      setPrompt(e);
      if ('serviceWorker' in navigator) {
        setOpen(true);
      }
    };

    const appInstalled = (e: any) => {
      e.preventDefault();
      setInstalled(e);
    };

    const isInstalled = (e: any) => {
      if (
        (navigator as any).standalone ||
        window.matchMedia('(display-mode: standalone)').matches ||
        window.matchMedia('(display-mode: fullscreen)').matches ||
        window.matchMedia('(display-mode: minimal-ui)').matches
      ) {
        setInstalled(true);
      }
    };

    //check for when app gets installed by user
    window.addEventListener('appinstalled', appInstalled as any);

    //hijack prompt to throw later on button click
    window.addEventListener('beforeinstallprompt', ready as any);

    //check when the page is loaded if it matches one of the PWA display modes
    window.addEventListener('DOMContentLoaded', isInstalled as any);

    return () => {
      window.removeEventListener('beforeinstallprompt', ready as any);
      window.removeEventListener('DOMContentLoaded', isInstalled as any);
    };
  }, []);

  useEffect(() => {
    setIsAppleDevice(
      (navigator as any).userAgent.match(/iPhone|iPad|iPod/i) ||
        (window as any).safari !== undefined,
    );
  }, []);

  useEffect(() => {
    if (isAppleDevice) {
      setOpen(true);
    }
  }, [isAppleDevice]);

  return (
    <div className={classes.root}>
      {(!previouslySeenAt || previouslySeenAt < Date.now().toString()) &&
        !installed &&
        open && (
          <Snackbar
            className={classes.snackBar}
            open={open}
            onClose={handleClose}
            anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
          >
            <Alert className={classes.alert}>
              <Grid
                container
                justifyContent="center"
                alignItems="center"
                className={classes.container}
              >
                <Grid
                  item
                  container
                  direction="column"
                  justifyContent="center"
                  alignItems="center"
                  spacing={1}
                >
                  <Grid item className={classes.iconContainer}>
                    <FlexIcon
                      isColor={true}
                      iconSize={48}
                      className={classes.icon}
                    />
                  </Grid>
                  <Grid item className={classes.exit}>
                    <CloseIcon fontSize="small" onClick={handleClose} />
                  </Grid>
                  <Grid item>
                    <Typography variant="h5" className={classes.title}>
                      Install Flex
                    </Typography>
                  </Grid>
                  <Grid item className={classes.dividerContainer}>
                    <Divider className={classes.divider} />
                  </Grid>
                  <Grid item className={classes.detailsContainer}>
                    <Typography variant="caption" paragraph={true}>
                      Install the application on your home screen for quick and
                      easy access when you're on the go.
                    </Typography>
                  </Grid>
                  <Grid item container className={classes.buttonContainer}>
                    {isAppleDevice ? (
                      <Typography variant="caption" paragraph={true}>
                        Click the share button and then "add to home screen"
                      </Typography>
                    ) : (
                      <Button
                        variant="contained"
                        className={classes.button}
                        onClick={handleButtonClick}
                      >
                        Get App
                      </Button>
                    )}
                  </Grid>
                </Grid>
              </Grid>
            </Alert>
          </Snackbar>
        )}
    </div>
  );
};
