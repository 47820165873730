import { IGraphQLDynamoRecord } from './dynamo';

export enum ProviderType {
  Sleeper = 'sleeper',
  Yahoo = 'yahoo',
  ESPN = 'espn',
}

export const providerNames = {
  [ProviderType.Sleeper]: 'Sleeper',
  [ProviderType.ESPN]: 'ESPN Fantasy',
  [ProviderType.Yahoo]: 'Yahoo Sports',
} as const;

export const providerDemands = {
  [ProviderType.Sleeper]: {
    username: true,
    password: false,
  },
  [ProviderType.ESPN]: {
    username: true,
    password: true,
  },
  [ProviderType.Yahoo]: {
    username: false,
    password: false,
  },
};

export interface IYahooProviderData {
  error?: string;
  access_token: string;
  refresh_token: string;
}

export interface ISleeperProviderData {
  username: string;
  user_id: string;
  avatar: string | undefined;
}

export interface IProviderRecord extends IGraphQLDynamoRecord {
  id: string;
  yahoo?: IYahooProviderData;
  sleeper?: ISleeperProviderData;
}
