import {
  error,
  idleRetrieval,
  Retrieval,
  success,
  workingRetrival,
} from '@propsfantasy/retrieval';
import produce from 'immer';
import { getType } from 'typesafe-actions';
import { IRoster } from '../../models/roster';
import { RootActions } from '../rootActions';
import { updateRoster } from './rosterActions';

export interface IUserRoster {
  id: string;
  roster: IRoster;
}

export interface IRosterState {
  retrived: Retrieval<IRoster>;
  updating: Retrieval<IRoster>;
  creating: Retrieval<IRoster>;
  roster: Retrieval<{ [id: string]: IUserRoster }>;
}

const initialState = {
  creating: idleRetrieval,
  updating: idleRetrieval,
  roster: idleRetrieval,
};

export const rosterReducer = produce((state: any, action: RootActions) => {
  switch (action.type) {
    case getType(updateRoster.request):
      state.roster = workingRetrival;
      return;
    case getType(updateRoster.success):
      state.updating = success(action.payload);
      state.roster = workingRetrival;

      return;
    case getType(updateRoster.failure):
      state.updating = error(action.payload);
      return;
  }
}, initialState);
