import { Button as MuiButton, withStyles } from '@material-ui/core';

export const Button = withStyles((theme) => ({
  root: {
    boxShadow: 'none !important',
    borderRadius: theme.spacing(4),
    paddingRight: theme.spacing(3),
    paddingLeft: theme.spacing(3),
    fontWeight: 'bold',
    whiteSpace: 'nowrap',
  },
  contained: {
    backgroundColor: theme.palette.secondary.light,
    color: theme.palette.common.white,
    '&$disabled': {
      backgroundColor: theme.palette.grey[400],
      color: theme.palette.common.white,
    },
  },
  disabled: {},
  outlined: {
    backgroundColor: 'transparent',
    borderColor: theme.palette.secondary.light,
    color: theme.palette.secondary.light,
  },
}))(MuiButton);
