import { IError } from '@propsfantasy/retrieval';
import { ActionType, createAsyncAction } from 'typesafe-actions';
import { srNflWeek } from '../../models/sportradar';

export const requestFullSchedule = createAsyncAction(
  'ScheduleGetRequest',
  'ScheduleGetSuccess',
  'ScheduleGetError',
  'ScheduleGetCancel',
)<undefined, srNflWeek[], IError, undefined>();

export const requestCurrentWeekSchedule = createAsyncAction(
  'CurrentWeekSchedGetRequest',
  'CurrentWeekSchedSuccess',
  'CurrentWeekSchedError',
  'CurrentWeekSchedCancel',
)<undefined, srNflWeek[], IError, undefined>();

const actions = {
  requestFullSchedule,
  requestCurrentWeekSchedule,
};

export type ScheduleActions = ActionType<typeof actions>;
