import { SvgIcon } from '@material-ui/core';
import React from 'react';

import { ReactComponent as FlexFanColorIcon } from '../../assets/Flex_LM_CLR.svg';
import { ReactComponent as FlexFanWhiteIcon } from '../../assets/Flex_LM_WTH.svg';

type SvgProps = React.ComponentProps<typeof SvgIcon>;

interface FlexIconProps extends SvgProps {
  isColor?: boolean;
  iconSize?: number;
}

export const FlexIcon: React.FC<FlexIconProps> = ({ ...props }) => {
  const viewSize = props.iconSize! > 50 ? props.iconSize! / 2 : props.iconSize;
  const { isColor, iconSize } = props;
  delete props.isColor;
  delete props.iconSize;
  return (
    <SvgIcon
      {...props}
      viewBox={`0 0 ${viewSize} ${viewSize}`}
      style={{ width: `${iconSize}px`, height: `${iconSize}px` }}
    >
      {isColor ? <FlexFanColorIcon /> : <FlexFanWhiteIcon />}
    </SvgIcon>
  );
};
