import { srNflWeek } from '../../models/sportradar';
import { PropsFantasyApi } from './base';

export const convertStringToDate = (stringDate: any) => {
  const date = new Date(stringDate);
  return date;
};

export class PropsFantasyScheduleApi extends PropsFantasyApi {
  //API CALL TO GET FULL SCHEDULE IN SORTED ORDER BY WEEK
  public async getSchedule(): Promise<srNflWeek[]> {
    const axios = this.getAxios() || this.axiosInstance;

    const schedule = (await axios)
      .get('/footballSchedule')
      .then((sched) => {
        return sched;
      })
      .catch((err) => {
        console.log(err, 'Error getting full schedule');
        return err;
      });

    return schedule;
  }

  //API CALL TO GET SCHEDULE OF ACTIVE WEEK (LIVE)
  public async getCurrentWeekSchedule(): Promise<srNflWeek[]> {
    const axios = (await this.getAxios()) || this.axiosInstance;

    const schedule = await axios
      .get('/currentWeek')
      .then((sched) => {
        return sched;
      })
      .catch((err) => {
        console.log(err, 'Error getting current week schedule');
        return err;
      });

    return schedule;
  }
}
