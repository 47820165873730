import { Grid, makeStyles, Typography } from '@material-ui/core';
import React from 'react';
import { IChallenge, IStoredUser } from '../../all-models';

const gamesHistoryStyles = makeStyles((theme) => ({
  section: {
    backgroundColor: 'white',
    borderRadius: theme.spacing(2),
    padding: theme.spacing(3),
    paddingBottom: theme.spacing(2),
    [theme.breakpoints.down('sm')]: {
      paddingBottom: theme.spacing(1.25),
    },
  },
  chart: {
    backgroundColor: '#9c9c9c',
    marginBottom: theme.spacing(4),
    [theme.breakpoints.down('sm')]: {
      marginBottom: theme.spacing(2.25),
    },
  },
  progressBarContainer: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(3),
  },
  summaryTileContainer: {
    [theme.breakpoints.down('sm')]: {
      marginBottom: theme.spacing(1.5),
    },
  },
  numbers: {
    fontSize: '32px',
    marginBottom: theme.spacing(-1.25),
    [theme.breakpoints.down('sm')]: {
      fontSize: '24px',
      marginBottom: theme.spacing(-0.5),
    },
  },
  text: {
    fontSize: '16px',
    color: '#666666',
    [theme.breakpoints.down('sm')]: {
      fontSize: '12px',
    },
  },
  dividerBlack: {
    width: '2px',
    height: '50px',
    backgroundColor: '#000000',
    marginRight: theme.spacing(2),
    [theme.breakpoints.down('sm')]: {
      height: '42px',
    },
  },
  dividerRed: {
    width: '2px',
    height: '50px',
    backgroundColor: '#fc8282',
    marginRight: theme.spacing(2),
    [theme.breakpoints.down('sm')]: {
      height: '42px',
    },
  },
  dividerBlue: {
    width: '2px',
    height: '50px',
    backgroundColor: '#9bc8ff',
    marginRight: theme.spacing(2),
    [theme.breakpoints.down('sm')]: {
      height: '42px',
    },
  },
  dividerGrey: {
    width: '2px',
    height: '50px',
    backgroundColor: '#9c9c9c',
    marginRight: theme.spacing(2),
    [theme.breakpoints.down('sm')]: {
      height: '42px',
    },
  },
}));

const progressBarStyles = makeStyles({
  container: {
    height: 20,
    width: (props) => ` ${props.winsWidth}% + ${props.lossesWidth}% `,
    backgroundColor: '#e0e0de',
    borderRadius: 50,
  },
  wins: {
    height: '100%',
    width: (props) => ` ${props.winsWidth}%`,
    backgroundColor: (props) => props.winsBg,
    borderRadius: 'inherit',
    borderTopRightRadius: 0,
    borderBottomRightRadius: 0,
  },
  losses: {
    height: '100%',
    width: (props) => `${props.lossesWidth}%`,
    backgroundColor: (props) => props.lossesBg,
    borderRadius: 'inherit',
    borderTopLeftRadius: 0,
    borderBottomLeftRadius: 0,
  },
});

export const ProgressBar: React.FC = (props) => {
  const classes = progressBarStyles(props);

  return (
    <Grid container item className={classes.container}>
      <Grid container item className={classes.wins}></Grid>
      <Grid container item className={classes.losses}></Grid>
    </Grid>
  );
};

const GamesHistory: React.FC<{
  challenges: IChallenge[];
  userData: IStoredUser | undefined;
}> = ({ challenges, userData }) => {
  const classes = gamesHistoryStyles();

  const dividerColor = (color: string) => {
    return color === '#000000'
      ? classes.dividerBlack
      : color === '#fc8282'
      ? classes.dividerRed
      : color === '#9bc8ff'
      ? classes.dividerBlue
      : classes.dividerGrey;
  };

  const historyData = [
    {
      copy: 'Games Played',
      amount: (userData?.wins || 0) + (userData?.losses || 0),
      color: '#000000',
    },
    {
      copy: 'Wins',
      amount: userData?.wins || '0',
      color: '#fc8282',
    },
    {
      copy: 'Loss',
      amount: userData?.losses || '0',
      color: '#9bc8ff',
    },
  ];

  let totalPoints = userData?.wins + userData?.losses;

  return (
    <Grid container item className={classes.section} xs={12}>
      <Grid container item xs={12} className={classes.progressBarContainer}>
        <ProgressBar
          winsBg="#fc8282"
          winsWidth={(userData?.wins / totalPoints) * 100 || '0'}
          lossesBg="#9bc8ff"
          lossesWidth={(userData?.losses / totalPoints) * 100 || '0'}
        />
      </Grid>
      <Grid container item>
        {historyData.map((data) => (
          <Grid container item xs={4} md={4} justifyContent="space-between">
            <Grid
              container
              xs={12}
              alignItems="center"
              justifyContent="space-between"
              className={classes.summaryTileContainer}
            >
              <Grid item xs={1}>
                <Typography className={dividerColor(data.color)}></Typography>
              </Grid>
              <Grid container item direction="column" xs={11}>
                <Grid item xs={2}>
                  <Typography className={classes.numbers}>
                    {data.amount}
                  </Typography>
                </Grid>
                <Grid item xs={10}>
                  <Typography className={classes.text}>{data.copy}</Typography>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        ))}
      </Grid>
    </Grid>
  );
};

export default GamesHistory;
