import {
  Avatar,
  CircularProgress,
  Grid,
  Snackbar,
  Typography,
  makeStyles,
} from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import { RetrievalState } from '@propsfantasy/retrieval';
import {
  AnimatePresence,
  PanInfo,
  motion,
  useMotionValue,
  useTransform,
} from 'framer-motion/dist/framer-motion';

import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import 'react-virtualized/styles.css';
import { IChallenge, IOpenChallenge, IRoster } from '../../all-models';
import closeIcon from '../../assets/Close.png';
import { DEFAULT_ALERT_AUTO_HIDE_DURATION } from '../../utils/constants';

import {
  createOpenChallenge,
  enterChallenge,
  requestChallenges,
  requestOpenChallenges,
} from '../../stores/challenge/challengeActions';

import {
  getChallengesContinuation,
  getOpenChallengesContinuation,
  getOpenChallengesList,
  getRetrievedChallenges,
} from '../../stores/challenge/challengeReducer';
import {
  getCurrentUser,
  getCurrentUserLeagues,
  getCurrentUserRosters,
} from '../../stores/user/userReducer';

import challengeIconWhite from '../../assets/challenge-icon-white.svg';
import { challengeLocksDate } from '../../utils/enterChallengeCutoff';
import { dateFormat } from '../../utils/time';
import { Button } from '../base/button';
import { BetChallengeLive } from '../live/betChallengeLive';

const swipeContainerStyles = makeStyles((theme) => ({
  section: {
    backgroundColor: 'white',
    width: '100%',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    overflowY: 'scroll',
  },
  headerContainer: {
    height: '54px',
    borderBottom: '1px solid #DCDCDC',
    position: 'relative',
  },

  headerText: {
    color: theme.palette.text.primary,
    fontWeight: 700,
  },
  closeContainer: {
    position: 'absolute',
    right: '10px',
    top: '50%',
    transform: 'translateY(-50%)',
  },
  close: {
    width: '24px',
    height: '24px',
    textAlign: 'right',
  },
  greyText: {
    color: theme.palette.text.primary,
  },
  challengeContainer: {
    position: 'relative',
    height: '100%',
  },
  overLay: {
    position: 'absolute',
    top: '-30px',
    height: '630px',
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    cursor: 'grab',
    zIndex: 1,
    borderRadius: '15px',
    textAlign: 'center',
  },
  overlayText: {
    color: '#fff',
  },
  animationContainer: {
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'flex-start',
    width: '100%',
    height: '100vh',
    padding: theme.spacing(10, 0, 0),
  },
  animationStyles: {
    position: 'absolute',
    minHeight: '630px',
    height: 'auto',
    width: '78%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    cursor: 'grab',
  },
  animationStylesTwo: {
    position: 'absolute',
    minHeight: '630px',
    height: 'auto',
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
  endOfChallenges: {
    marginTop: '40%',
  },
  button: {
    paddingTop: theme.spacing(8),
  },
  btnStyle: {
    width: '280px',
    height: '40px',
    fontSize: '16px',
    letterSpacing: '0.67px',
  },
  challengeIcon: {
    width: '145px',
    height: '145px',
    margin: theme.spacing(0, 0, 2),
  },
  referralContainer: {
    marginTop: theme.spacing(4),
    padding: theme.spacing(3, 4),
    width: '90%',
    maxWidth: '423px',
    backgroundColor: theme.palette.secondary.main,
    borderRadius: '20px',
    color: '#fff',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    justifyContent: 'space-between',
    gap: '30px',
    '& h6': {
      fontWeight: 'bold',
      fontSize: '18px',
      alignSelf: 'center',
    },
    '& span': {
      fontWeight: 'bold',
      color: '#fff',
    },
  },
  referralBtn: {
    color: '#fff',
    fonSize: '12px',
    fontWeight: 'bold',
    textAlign: 'center',
    width: '100%',
    alignSelf: 'center',
    borderColor: '#fff',
  },
}));

interface IAlert {
  message: string;
  severity: Color;
}

/**
 * Deep-Codes
 * @reference https://github.com/Deep-Codes/framer-tinder-cards
 */

export const SwipeChallenges: React.FC<{}> = ({}) => {
  const classes = swipeContainerStyles();
  const dispatch = useDispatch();
  const history = useHistory();
  const currentUser = useSelector(getCurrentUser);
  const [alert, setAlert] = useState<IAlert | null>(null);

  const allChallenges = useSelector(getRetrievedChallenges);
  const continuation = useSelector(getChallengesContinuation);

  const openChallenges = useSelector(getOpenChallengesList);
  const continuationOC = useSelector(getOpenChallengesContinuation);

  const [cards, setCards] = useState<IChallenge[]>();
  const [loading, setLoading] = useState<boolean>(true);
  const [skippedChallenges, setSkippedChallenges] = useState<string[]>([]);
  const leagueData = useSelector(getCurrentUserLeagues);
  const rosterData = useSelector(getCurrentUserRosters);

  const activeIndex = cards?.length - 1;

  //Helper function to filter out open challenges user has already submitted to
  const hasThisUserChallenged = (challengeId: string) => {
    let hasUserEntered = openChallenges?.openChallenges?.value?.some(
      (openChallenge: IOpenChallenge) =>
        openChallenge.opposingUser === currentUser?.value?.username &&
        openChallenge.challengeId === challengeId,
    );

    return hasUserEntered;
  };

  useEffect(() => {
    dispatch(requestOpenChallenges.request({ continuation: undefined }));
  }, [dispatch]);

  useEffect(() => {
    if (continuation.state === RetrievalState.Idle) {
      dispatch(
        requestChallenges.request({
          continuation: undefined,
        }),
      );
    }
  }, [continuation.state, dispatch]);

  useEffect(() => {
    if (continuation?.value !== undefined) {
      dispatch(
        requestChallenges.request({
          continuation: continuation?.value,
        }),
      );
    }
  }, [continuation?.value, dispatch]);

  useEffect(() => {
    if (
      currentUser.state === RetrievalState.Succeeded &&
      openChallenges.openChallenges.state === RetrievalState.Succeeded &&
      allChallenges
    ) {
      const filteredChallenges: IChallenge[] = [];
      const finalFilterChallenges: IChallenge[] = [];

      const filterFreeChallengesAndNotOpen = allChallenges
        .filter((challenge: IChallenge) => challenge.entryFee === 0)
        .filter((c: IChallenge) => {
          return (
            dateFormat(challengeLocksDate(c.endDate)?.toString()!) >=
            dateFormat(new Date().toString())
          );
        });

      const filterNoParticipation = filterFreeChallengesAndNotOpen.map(
        (challenge: IChallenge) => {
          const isparticipant = challenge.participants.items.find(
            (participant) => participant.userId === currentUser.value.sub,
          );

          if (!isparticipant) {
            filteredChallenges.push(challenge);
          }
        },
      );

      const filterSkipped = filteredChallenges.filter(
        (challenge) => !skippedChallenges.includes(challenge.id),
      );

      //Check to see if user has already submitted to the open challenge or open challenge is created by that user
      const filterOpenCUserHasSubmitted = filterSkipped.filter((challenge) => {
        if (!challenge.openChallenge) {
          finalFilterChallenges.push(challenge);
        } else {
          if (
            !hasThisUserChallenged(challenge.id) &&
            challenge.userId !== currentUser.value.username
          ) {
            finalFilterChallenges.push(challenge);
          }
        }
      });

      setCards(finalFilterChallenges);
    }
  }, [currentUser, allChallenges, openChallenges.openChallenges]);

  const handleExit = useCallback(() => {
    history.push({
      pathname: `/`,
    });
  }, [history]);

  const handleCreateChallenge = useCallback(() => {
    history.push({
      pathname: `/challenge/create`,
    });
  }, [history]);

  const handleSkip = useCallback((oldCard: IChallenge) => {
    setCards((current) =>
      current.filter((card) => {
        return card.id !== oldCard.id;
      }),
    );
    setSkippedChallenges([...skippedChallenges, oldCard.id]);
  }, []);

  const removeCard = (oldCard: IChallenge) => {
    setCards((current) =>
      current.filter((card) => {
        return card.id !== oldCard.id;
      }),
    );
  };

  const handleAlertClose = useCallback(() => {
    setAlert(null);
  }, [setAlert]);

  const copyLink = () => {
    let sharedUrl = `${window.location.href}?rid=${currentUser.value.sub}`;
    navigator.clipboard.writeText(sharedUrl);
  };

  useEffect(() => {
    if (
      allChallenges?.length > 0 &&
      openChallenges.openChallenges.state === RetrievalState.Succeeded
    )
      setLoading(false);
  }, [allChallenges, openChallenges.openChallenges.state]);

  if (loading) {
    return <CircularProgress />;
  }

  return (
    <Grid container className={classes.section}>
      <Grid container item>
        <Grid
          container
          item
          className={classes.headerContainer}
          justifyContent="center"
          alignItems="center"
        >
          <Grid item>
            <Typography variant="h6" className={classes.headerText}>
              Swipe to Enter
            </Typography>
          </Grid>
          <Grid item className={classes.closeContainer}>
            <Avatar
              src={closeIcon}
              className={classes.close}
              onClick={handleExit}
            />
          </Grid>
        </Grid>

        {cards && cards?.length > 0 && (
          <Grid
            container
            item
            className={classes.challengeContainer}
            justifyContent="center"
            direction="column"
            alignItems="center"
          >
            <Grid item>
              <Typography variant="caption" className={classes.greyText}>
                Swipe towards the winner
              </Typography>
            </Grid>
            <Grid container item className={classes.animationContainer}>
              <AnimatePresence>
                {cards
                  .filter((c) => !skippedChallenges.includes(c.id))
                  .map((card, index) => (
                    <AnimatedCardsContainer
                      key={card.id}
                      active={index === activeIndex}
                      removeCard={removeCard}
                      card={card}
                      handleSkip={handleSkip}
                      rosterData={rosterData}
                      alert={alert}
                      setAlert={setAlert}
                    />
                  ))}
              </AnimatePresence>
            </Grid>
          </Grid>
        )}
        {(!cards || cards?.length === 0) && (
          <Grid
            container
            justifyContent="center"
            alignItems="flex-start"
            className={classes.endOfChallenges}
          >
            <Grid item>
              <Typography variant="h6" className={classes.greyText}>
                End of available challenges
              </Typography>
            </Grid>
            <Grid item className={classes.referralContainer}>
              <Typography variant="subtitle2">
                Unlock Rewards by referring friends
              </Typography>
              <Typography variant="caption">
                Score 3 Flex Bits when they join, plus $25 credit for both you
                and your friends on deposit. More you refer, the more you earn
              </Typography>
              <Button
                className={classes.referralBtn}
                variant="outlined"
                onClick={copyLink}
              >
                Copy Referral Link
              </Button>
            </Grid>

            <Grid item className={classes.button}>
              <Button
                className={classes.btnStyle}
                variant="contained"
                onClick={handleCreateChallenge}
              >
                Create challenge
              </Button>
            </Grid>
          </Grid>
        )}
      </Grid>
      {alert && (
        <Snackbar
          open
          autoHideDuration={DEFAULT_ALERT_AUTO_HIDE_DURATION}
          onClose={handleAlertClose}
        >
          <Alert onClose={handleAlertClose} severity={alert.severity}>
            {alert.message}
          </Alert>
        </Snackbar>
      )}
    </Grid>
  );
};

export const AnimatedCardsContainer: React.FC<{
  active: boolean;
  card: any;
  removeCard: Function;
  handleSkip: Function;
  rosterData: IRoster;
  alert: IAlert;
  setAlert: Function;
}> = ({
  active,
  handleSkip,
  removeCard,
  card,
  rosterData,
  alert,
  setAlert,
}) => {
  const classes = swipeContainerStyles();
  const [swipingRight, setSwipingRight] = useState(false);
  const [leaveX, setLeaveX] = useState(0);
  const [leaveY, setLeaveY] = useState(0);
  const [challengerName, setChallengerName] = useState<string>('');

  const dispatch = useDispatch();

  // Motion styles
  const x = useMotionValue(0);
  const xInput = [-50, 0, 50];
  const opacityOutput = [0.7, 1, 0.7];
  const zIndexOutput = [10, 0, 10];
  const opacity = useTransform(x, xInput, opacityOutput);
  const zIndex = useTransform(x, xInput, zIndexOutput);
  const rotate = useTransform(x, [0, 60], [0, 5], { clamp: false });
  const backgroundColor = useTransform(x, xInput, [
    '#892886',
    '#00000000',
    '#e94057',
  ]);

  const handleDrag = (event: any, info: PanInfo) => {
    if (info.offset.x < -100) {
      setChallengerName(card.challengers[0].name);
      setSwipingRight(false);
    } else if (info.offset.x > 100 && card.openChallenge) {
      setChallengerName(card.challengers[0].name);
      setSwipingRight(true);
    } else if (info.offset.x > 100 && !card.openChallenge) {
      setChallengerName(card.challengers[1].name);
    }
  };

  const onDragEnd = (_e: any, info: PanInfo) => {
    if (info.offset.y < -150) {
      setChallengerName('');
      return;
    }
    if (info.offset.x > 175) {
      if (card.openChallenge) {
        if (rosterData.value.length < 1) {
          setAlert({
            message: 'Sync your leagues to join challenges!',
            severity: 'error',
          });
        } else {
          //JOIN OPEN CHALLENGE
          dispatch(
            createOpenChallenge.request({
              challengeId: card.id,
              rosterID: rosterData.value[0].id,
            }),
          );
        }
      } else {
        //SWIPED RIGHT
        dispatch(
          enterChallenge.request({
            challengeId: card.id,
            challengerId: card.challengers[1].id,
            serviceFee: false,
            creditFee: 0,
          }),
        );
      }

      setLeaveX(1000);
      removeCard(card);
      setChallengerName('');
    }
    if (info.offset.x < -175) {
      if (card.openChallenge) {
        //DONT JOIN OPEN CHALLENGE
      } else {
        //SWIPED LEFT
        dispatch(
          enterChallenge.request({
            challengeId: card.id,
            challengerId: card.challengers[0].id,
            serviceFee: false,
            creditFee: 0,
          }),
        );
      }

      setLeaveX(-1000);
      removeCard(card);
      setChallengerName('');
    }
    setChallengerName('');
  };

  return (
    <>
      {active && rosterData ? (
        <motion.div
          drag={true}
          dragConstraints={{ left: 0, right: 0, top: 0, bottom: 0 }}
          dragElastic={0.8}
          onDragEnd={onDragEnd}
          onDrag={handleDrag}
          initial={{
            scale: 1,
          }}
          animate={{
            scale: 1.1,
          }}
          exit={{
            x: leaveX,
            y: leaveY,
            opacity: 0,
            scale: 0.5,
            transition: { duration: 0.2 },
          }}
          style={{
            x,
            rotate,
          }}
          className={classes.animationStyles}
        >
          <motion.div
            className={classes.overLay}
            center
            style={{
              opacity,
              backgroundColor,
              zIndex,
            }}
          >
            {challengerName !== '' && (
              <>
                {swipingRight && (
                  <Avatar
                    src={challengeIconWhite}
                    className={classes.challengeIcon}
                  />
                )}
                <Typography variant="h5" className={classes.overlayText}>
                  {swipingRight ? 'CHALLENGE' : 'Enter and Back'}
                  <br />
                  {challengerName}
                </Typography>
              </>
            )}
          </motion.div>
          <div className={classes.animationStylesTwo}>
            <BetChallengeLive
              challenge={card}
              isLive={true}
              isSwipe={true}
              handleSkip={handleSkip}
              swipeStyles={true}
            />
          </div>
        </motion.div>
      ) : (
        <div className={classes.animationStyles}>
          <BetChallengeLive
            challenge={card}
            isLive={true}
            isSwipe={true}
            handleSkip={handleSkip}
            swipeStyles={true}
          />
        </div>
      )}
    </>
  );
};
