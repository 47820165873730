import { IError } from '@propsfantasy/retrieval';
import { ActionType, createAsyncAction } from 'typesafe-actions';

import { IRoster, IRosterUpdateBody } from '../../models/roster';

export const updateRoster = createAsyncAction(
  'UpdateRosterRequest',
  'UpdateRosterSuccess',
  'UpdateRosterError',
)<IRosterUpdateBody, IRoster, IError>();

const actions = {
  updateRoster,
};

export type RosterActions = ActionType<typeof actions>;
