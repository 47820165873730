import React, { useEffect } from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import { LoginContainer } from './loginContainer';
import { Link, makeStyles } from '@material-ui/core';
import { useHistory } from 'react-router';
import { useCallback } from 'react';

const useStyles = makeStyles((theme) => ({
  title: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  link: {
    cursor: 'pointer',
  },
}));

interface LoginProps {
  isOpen: boolean;
  onClose: () => void;
}

export const LoginDialog: React.FC<LoginProps> = ({ isOpen, onClose }) => {
  const classes = useStyles();
  const history = useHistory();
  const [open, setOpen] = React.useState(isOpen);

  useEffect(() => {
    setOpen(isOpen);
  }, [isOpen]);

  const handleClose = useCallback(() => {
    setOpen(false);
    onClose();
  }, [setOpen, onClose]);

  const handleRegisterLink = useCallback(() => {
    history.push('/register');
  }, [history]);

  return (
    <div>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="login-dialog-title"
      >
        <DialogContent>
          <div className={classes.title}>
            <h2>Sign In</h2>
            <Link className={classes.link} onClick={handleRegisterLink}>
              Register
            </Link>
          </div>
          <LoginContainer onSuccess={handleClose} />
        </DialogContent>
      </Dialog>
    </div>
  );
};
