export const emailSenderAddress = 'no-reply@flex.fan';

export const isTestingEnvironment = () => {
  const hostName = window.location.hostname;
  const isTesting = /dev|testing|localhost/.test(hostName);

  return isTesting;
};

export const isDev = () => window.location.hostname.includes('dev');

export const isTesting = () => window.location.hostname.includes('testing');

export const isLocal = () => window.location.hostname === 'localhost';

export const getWebHost = (envName: string) => {
  let fullLink = '';
  switch (envName) {
    case 'test':
      fullLink = 'optimum-testing.d2t4c5i7lajhbz.amplifyapp.com';
      break;
    case 'main':
      fullLink = 'www.flexfantasy.app';
      break;
    default:
      fullLink = 'dev.flexfantasy.app';
      break;
  }
  return fullLink;
};

export const gets3BucketName = (envName: string) => {
  if (envName === 'test') {
    return 'props-fantasy-assets122011-test';
  } else if (envName === 'next') {
    return 'props-fantasy-assets154652-next';
  } else if (envName === 'beta') {
    return 'props-fantasy-assets115446-beta';
  } else {
    return 'props-fantasy-assets222004-main';
  }
};
