import { Grid, makeStyles, useMediaQuery, useTheme } from '@material-ui/core';
// import HowToBackground from '../../assets/FPO_VIdeo-Image@3x.png'
import React, { useCallback, useContext, useEffect, useState } from 'react';
// import crownIcon from '../../assets/icon-crown@3x.png';
import { RetrievalState } from '@propsfantasy/retrieval';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import {
  IChallenge,
  IOneClickChallengeDefault,
  IStoredUser,
  ITransaction,
  IUserPrivate,
  IUserTransactions,
  TeamsPlayedUserData,
} from '../../all-models';
import {
  requestAllUsers,
  requestAllUsersPlayed,
  requestUserCompletedChallenges,
  requestUserLiveChallenges,
} from '../../stores/challenge/challengeActions';
import {
  getCurrentUserCompletedChallenges,
  getCurrentUserLiveChallenges,
  getLeaderboardChallengers,
  getTeamsPlayedUserInfo,
} from '../../stores/challenge/challengeReducer';
import { getWallet } from '../../stores/money/moneyReducer';
import {
  getAllTransactions as getAllTransactionsAction,
  getOneClickChallengeDefaults,
  getStoredUser as getStoredUserAction,
} from '../../stores/user/userActions';
import {
  RosterWithOwner,
  getCurrentUser,
  getCurrentUserOneClickChallengeDefaults,
  getCurrentUserTransactions,
  getStoredUser,
} from '../../stores/user/userReducer';
import { calculateTransactionsByChallenger } from '../../utils/calculateTransactions';
import EndOfSeasonBanner from '../base/endOfSeasonBanner';

import swipeToEnterCover from '../../assets/swipe-to-enter-cover.png';
import { getKeyFromValueForEndDate } from '../../utils/getWeekFromEndDate';
import { getWeekFromTodaysDate } from '../../utils/getWeekFromTodaysDate';
import { PropsPage } from '../base/page';
import BalanceSummary from './balanceSummary';
import ChallengerHomeComponent from './challengerHome';
import GamesHistory from './gamesHistory';
import Leaderboard from './leaderboard';
import NotificationsWidget from './notificationsWidget';
import { OneClickChallengeModal } from './oneClickModal';
import TeamsYouPlayed from './teamsYouPlayed';
import WeeklyChallenges from './weeklyChallenges';

import { NotificationContext } from '../../App';
import tocBtnDesktop from '../../assets/desktop-undrop-toc.svg';
import tocBtnMobile from '../../assets/mobile-undrop-toc.svg';

const pageStyles = makeStyles((theme) => ({
  page: {
    marginTop: theme.spacing(14),
    [theme.breakpoints.down('sm')]: {
      marginTop: theme.spacing(8),
    },
  },
  bannerContainer: {
    marginTop: theme.spacing(10),
  },
  swipe2Enter: {
    marginTop: theme.spacing(2.5),
  },
  buttonSvg: {
    margin: '15px auto 0',
    display: 'flex',
    '&:hover': {
      cursor: 'pointer',
    },
    [theme.breakpoints.up('sm')]: {
      maxWidth: '95%',
    },
  },
  bannerContainer: {
    margin: theme.spacing(10, 'auto', -5, 'auto'),
    [theme.breakpoints.up('md')]: {
      margin: theme.spacing(10, 'auto', -11, 'auto'),
    },
  },
}));

export const DashboardPage: React.FC = () => {
  const theme = useTheme();
  const classes = pageStyles();
  const dispatch = useDispatch();
  const history = useHistory();

  const smViewMediaQuery = useMediaQuery(theme.breakpoints.down('sm'));
  const currentUser = useSelector(getCurrentUser);
  const storedUser = useSelector(getStoredUser);
  const wallet = useSelector(getWallet);
  const transactionData = useSelector(getCurrentUserTransactions);
  const userOneClickChallengeDefaults = useSelector(
    getCurrentUserOneClickChallengeDefaults,
  );
  const [userData, setUserData] = useState<IUserPrivate>();
  const [storedUserData, setStoredUserData] = useState<IStoredUser>();
  const challenges = useSelector(getCurrentUserLiveChallenges);
  const completedChallenges = useSelector(getCurrentUserCompletedChallenges);
  const leaderBoardChallengers = useSelector(getLeaderboardChallengers);
  const allTeamsPlayedData = useSelector(getTeamsPlayedUserInfo);
  const { notificationList } = useContext(NotificationContext);
  const [allTransactions, setAllTransactions] = useState<ITransaction[]>([]);
  const [calculatedTransactions, setCalculatedTransactions] = useState<
    IUserTransactions[]
  >([]);
  const [allTeamsPlayed, setAllTeamsPlayed] = useState<TeamsPlayedUserData>();
  const [showOneClickChallengeModal, setShowOneClickChallengeModal] =
    useState<boolean>(false);
  const [oneClickChallengeDefaults, setOneClickChallengeDefaults] =
    useState<IOneClickChallengeDefault>();
  const [filteredChallenges, setFilteredChallenges] = useState<IChallenge[]>();
  const [priorWeekChallenges, setPriorWeekChallenges] =
    useState<IChallenge[]>();
  const [week, setWeek] = useState<number>();
  const lastWeek = Number(week) - 1;
  const isMobile = !useMediaQuery(theme.breakpoints.up('sm'));

  const isTournamentLive = false;

  useEffect(() => {
    let getWeek = getWeekFromTodaysDate();

    getWeek < 0 ? setWeek(1) : setWeek(getWeek);
  }, []);

  useEffect(() => {
    if (challenges.state === RetrievalState.Idle) {
      dispatch(requestUserLiveChallenges.request());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch]);

  useEffect(() => {
    if (completedChallenges.state === RetrievalState.Idle) {
      dispatch(requestUserCompletedChallenges.request());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch]);

  useEffect(() => {
    if (leaderBoardChallengers.state === RetrievalState.Idle) {
      dispatch(requestAllUsers.request());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch]);

  useEffect(() => {
    if (allTeamsPlayedData.state === RetrievalState.Idle) {
      dispatch(requestAllUsersPlayed.request());
    } else if (allTeamsPlayedData.state === RetrievalState.Succeeded) {
      setAllTeamsPlayed(allTeamsPlayedData.value);
    }
  }, [dispatch, allTeamsPlayedData]);

  useEffect(() => {
    if (currentUser.state === RetrievalState.Succeeded)
      setUserData(currentUser.value);
  }, [currentUser]);

  useEffect(() => {
    if (storedUser.state === RetrievalState.Idle && !storedUserData) {
      dispatch(getStoredUserAction.request());
    } else if (storedUser.state === RetrievalState.Succeeded) {
      setStoredUserData(storedUser.value);
    }
  }, [storedUser, dispatch, storedUserData]);

  useEffect(() => {
    if (
      transactionData.state === RetrievalState.Idle ||
      transactionData.state === RetrievalState.Errored
    ) {
      dispatch(getAllTransactionsAction.request());
    } else if (transactionData.state === RetrievalState.Succeeded) {
      setAllTransactions(transactionData.value?.transactions);
    }
  }, [dispatch, transactionData]);

  useEffect(() => {
    if (challenges.state === RetrievalState.Succeeded) {
      if (challenges.value.length > 0) {
        let challengeData = challenges?.value
          ?.filter(
            (challenge: IChallenge) =>
              getKeyFromValueForEndDate(challenge.endDate) === week,
          )
          .sort((a: any, b: any) => b.pot - a.pot)
          .slice(0, 3);

        setFilteredChallenges([...challengeData]);
      }
    }
  }, [challenges, week]);

  useEffect(() => {
    if (completedChallenges?.state === RetrievalState.Succeeded) {
      if (completedChallenges.value.length > 0) {
        const challengeData = completedChallenges?.value
          ?.filter(
            (challenge: IChallenge) =>
              getKeyFromValueForEndDate(challenge.endDate) === lastWeek,
          )
          .sort((a: any, b: any) => b.pot - a.pot)
          .slice(0, 3);

        setPriorWeekChallenges([...challengeData]);
      }
    }
  }, [completedChallenges, lastWeek]);

  useEffect(() => {
    (async () => {
      if (allTeamsPlayed && allTransactions) {
        let calculateTransactions = await calculateTransactionsByChallenger(
          allTeamsPlayed?.challengersPlayedAgainst,
          allTeamsPlayed?.currentSeasonChallenges,
          allTransactions,
          currentUser,
        );

        setCalculatedTransactions(calculateTransactions);
      }
    })();
  }, [allTransactions, allTeamsPlayed?.challengersPlayedAgainst]);

  useEffect(() => {
    if (userOneClickChallengeDefaults.state === RetrievalState.Idle) {
      dispatch(getOneClickChallengeDefaults.request());
    }
  }, [dispatch, userOneClickChallengeDefaults.state]);

  useEffect(() => {
    if (userOneClickChallengeDefaults.state === RetrievalState.Succeeded) {
      setOneClickChallengeDefaults(userOneClickChallengeDefaults?.value);
    }
  }, [dispatch, userOneClickChallengeDefaults.state]);

  const createOneClickChallengeChallenge = useCallback(
    (roster: RosterWithOwner) => {
      if (userOneClickChallengeDefaults.value?.id) {
        history.push({
          pathname: `/challenge/create`,
          state: {
            roster: roster?.[0] || roster,
            defaults: { ...userOneClickChallengeDefaults?.value },
          },
        });
      } else {
        setShowOneClickChallengeModal(true);
      }
    },
    [history, userOneClickChallengeDefaults],
  );

  const closeOneClickChallengeModal = useCallback(() => {
    setShowOneClickChallengeModal(false);
  }, [setShowOneClickChallengeModal]);

  const clickSwipeToEnter = useCallback(() => {
    history.push({
      pathname: `/swipe-to-enter`,
    });
  }, [history]);

  const handleTournamentButtonClick = useCallback(() => {
    history.push('/tournament-of-champions');
  }, [history]);

  return (
    <PropsPage>
      <Grid container item className={classes.bannerContainer}>
        <EndOfSeasonBanner />
      </Grid>
      {showOneClickChallengeModal ? (
        <OneClickChallengeModal
          setShowOneClickChallengeModal={setShowOneClickChallengeModal}
          closeOneClickChallengeModal={closeOneClickChallengeModal}
        />
      ) : null}
      <Grid container item direction="column" xs={12}>
        <Grid item container xs={12} className={classes.page}>
          <Grid
            container
            item
            xs={12}
            alignItems="center"
            justifyContent="space-between"
            spacing={smViewMediaQuery ? 0 : 2}
          >
            <Grid item xs={12} md={3}>
              <ChallengerHomeComponent currentUser={userData} />
            </Grid>
            <Grid
              container
              item
              direction="column"
              xs={12}
              md={9}
              justifyContent="space-between"
              spacing={smViewMediaQuery ? 0 : 2}
            >
              <Grid container item>
                {wallet.state === RetrievalState.Succeeded && (
                  <BalanceSummary wallet={wallet.value} userData={userData} />
                )}
              </Grid>
              <Grid container item>
                {completedChallenges.state === RetrievalState.Succeeded && (
                  <GamesHistory
                    challenges={completedChallenges.value}
                    userData={storedUserData}
                  />
                )}
              </Grid>
            </Grid>
          </Grid>
          {/* <Grid item container xs={12}>
          <HowToSection />
        </Grid> */}
          {isTournamentLive && (
            <Grid
              item
              container
              xs={12}
              justifyContent="center"
              alignItems="center"
            >
              <img
                src={isMobile ? tocBtnMobile : tocBtnDesktop}
                alt="Click to Join the Tournament of CHampions"
                className={classes.buttonSvg}
                onClick={handleTournamentButtonClick}
              />
            </Grid>
          )}

          {smViewMediaQuery && (
            <Grid
              container
              item
              xs={12}
              className={classes.swipe2Enter}
              onClick={clickSwipeToEnter}
            >
              <img src={swipeToEnterCover} alt="Swipe to enter" width="100%" />
            </Grid>
          )}

          <Grid
            container
            item
            xs={12}
            alignItems="flex-start"
            justifyContent="space-between"
            spacing={smViewMediaQuery ? 0 : 2}
          >
            <Grid item xs={12} md={9}>
              <WeeklyChallenges
                filteredChallenges={filteredChallenges}
                priorWeekChallenges={priorWeekChallenges}
                week={week}
                currentUser={userData!}
              />
            </Grid>
            <Grid item xs={12} md={3}>
              <NotificationsWidget
                notificationList={notificationList}
                dashboard={true}
              />
            </Grid>
          </Grid>

          {/* <Top5 /> */}
          <Grid
            container
            item
            xs={12}
            spacing={smViewMediaQuery ? 0 : 2}
            justifyContent="space-between"
          >
            {leaderBoardChallengers.state === RetrievalState.Succeeded &&
              leaderBoardChallengers?.value &&
              currentUser.state === RetrievalState.Succeeded && (
                <Grid item xs={12} md={6}>
                  <Leaderboard
                    leaderBoardChallengers={leaderBoardChallengers.value}
                    createOneClickChallengeChallenge={
                      createOneClickChallengeChallenge
                    }
                    currentLoggedInUser={currentUser.value}
                  />
                </Grid>
              )}
            <Grid item xs={12} md={6}>
              {calculatedTransactions.length > 0 &&
                allTeamsPlayed?.challengersPlayedAgainst.length > 0 && (
                  <TeamsYouPlayed
                    transactions={calculatedTransactions}
                    challengersPlayedAgainst={
                      allTeamsPlayed?.challengersPlayedAgainst
                    }
                    createOneClickChallengeChallenge={
                      createOneClickChallengeChallenge
                    }
                  />
                )}
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </PropsPage>
  );
};
