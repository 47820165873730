import { Typography } from '@material-ui/core';
import AppBar from '@material-ui/core/AppBar';
import Avatar from '@material-ui/core/Avatar';
import Badge from '@material-ui/core/Badge';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import Toolbar from '@material-ui/core/Toolbar';
import { Notifications } from '@material-ui/icons';
import MenuIcon from '@material-ui/icons/Menu';
import { RetrievalState } from '@propsfantasy/retrieval';
import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import { NotificationContext } from '../../App';
import { getWallet as getWalletAction } from '../../stores/money/moneyActions';
import { getWallet } from '../../stores/money/moneyReducer';
import { getCurrentUser } from '../../stores/user/userReducer';
import theme from '../../theme';
import { LoginDialog } from '../auth/loginDialog';
import { BackButton } from '../base/backButton';
import { FlexIcon } from '../base/flexIcon';
import { useWindowDimensions } from '../base/useWindowDimensions';
import { formatCurrency } from '../currency/moneyFormatter';
import { NotificationContainer } from '../notifications/notificationContainer';
import { NavDrawer } from './navDrawer';

const iconSize = 50;

const useNavStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flexGrow: 1,
    },
    appBar: {
      background: 'white',
      borderBottomLeftRadius: theme.spacing(3),
      borderBottomRightRadius: theme.spacing(3),
      color: theme.palette.secondary.main,
      minHeight: '64px',
      marginTop: '-1px',
      zIndex: 2,
    },
    menu: {
      flex: 1,
    },
    menuButton: {
      marginRight: theme.spacing(2),
    },
    flexIcon: {
      height: `${iconSize}px`,
      width: `${iconSize}px`,
      position: 'absolute',
      left: `calc(50% - ${iconSize / 2}px)`,
      top: '8px',
      [theme.breakpoints.up('sm')]: {
        left: '80px',
      },
      '&:hover': {
        cursor: 'pointer',
      },
    },
    avatar: {
      width: '30px',
      height: '30px',
      color: theme.palette.secondary.main,
      backgroundColor: 'transparent',
      '&:hover': {
        cursor: 'pointer',
      },
    },
    user: {
      flex: 1,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'flex-end',
      color: theme.palette.secondary.main,
    },
    balance: {
      lineHeight: 1,
      paddingLeft: theme.spacing(1),
      color: theme.palette.secondary.main,
      borderLeft: `solid 1px ${theme.palette.background.default}`,
      [theme.breakpoints.up('md')]: {
        paddingLeft: theme.spacing(1.5),
        marginLeft: theme.spacing(0.5),
      },
    },
    balanceText: {
      textDecoration: 'initial',
      color: theme.palette.secondary.main,
      '&:hover': {
        color: theme.palette.secondary.main,
      },
    },
    notifyContainer: {
      padding: theme.spacing(0, 1),
    },
    notifyBell: {
      width: '24px',
      height: '24px',
      '&:hover': {
        cursor: 'pointer',
      },
    },
    customBadge: {
      backgroundColor: theme.palette.success.light,
      fontSize: '5px',
    },
    hideBadge: {
      visibility: 'hidden',
      display: 'none',
    },
  }),
);

export const Nav: React.FC = () => {
  const dispatch = useDispatch();
  const wallet = useSelector(getWallet);
  const currentUser = useSelector(getCurrentUser);
  const classes = useNavStyles();
  const history = useHistory();
  const location = useLocation();
  const [isOpen, setIsOpen] = useState(false);
  const [notifyOpen, setNotifyOpen] = useState(false);
  const [showLogin, setShowLogin] = useState(false);
  const [showLoginForm, setShowLoginForm] = useState(false);
  const [showBackButton, setShowBackButton] = useState(false);
  const { badgeVisibility } = useContext(NotificationContext);
  const { width } = useWindowDimensions();

  const localStorageActivity = useMemo(() => {
    return {
      last: 'last_visited_page',
      current: 'current_page',
    };
  }, []);

  const showBackBtnPages = useMemo(() => {
    return {
      profile: '/profile',
      challenges: '/challenges/',
    };
  }, []);

  const lastVisitedPages = useMemo(() => {
    return ['/', '/tournament-of-champions'];
  }, []);

  // This function is to update the value of the current page and last visited page in local storage.
  const updatePages = () => {
    try {
      const currentPageFirstPath = location.pathname.split('/')[1];
      const lastPage =
        localStorage.getItem(localStorageActivity.current) || '/';
      const lastPageFirstPath = lastPage.split('/')[1];
      if (currentPageFirstPath !== lastPageFirstPath) {
        localStorage.setItem(localStorageActivity.last, lastPage);
        localStorage.setItem(localStorageActivity.current, location.pathname);
      } else {
        localStorage.setItem(localStorageActivity.current, location.pathname);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    try {
      // initialize lastPage and currentPage
      if (
        !localStorage.getItem(localStorageActivity.current) ||
        !localStorage.getItem(localStorageActivity.last)
      ) {
        localStorage.setItem(localStorageActivity.current, location.pathname);
        localStorage.setItem(localStorageActivity.last, location.pathname);
      }
      // Update lastPage and currentPage
      if (
        localStorage.getItem(localStorageActivity.current) !== location.pathname
      ) {
        updatePages();
      }
      return history.listen(() => {
        if (history.action === 'PUSH') {
          if (
            localStorage.getItem(localStorageActivity.last) !== 'menu' &&
            !localStorage
              .getItem(localStorageActivity.current)
              ?.includes(showBackBtnPages.profile)
          ) {
            updatePages();
          }
        }
      });
    } catch (error) {
      console.log(error);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [history, location.pathname]);

  useEffect(() => {
    try {
      // Do not show back button in large screen.
      if (width && width < theme.breakpoints.values.md) {
        const currentPage =
          localStorage.getItem(localStorageActivity.current) || '';
        const lastPage = localStorage.getItem(localStorageActivity.last) || '';
        // Show back button if last page was Arena page and the current page url contain "challenges" or "profile"
        if (lastVisitedPages.includes(lastPage)) {
          let showButton = false;
          Object.keys(showBackBtnPages).forEach((page) => {
            if (currentPage.includes(page)) {
              showButton = true;
            }
          });
          setShowBackButton(showButton);
        } else {
          setShowBackButton(false);
        }
      }
    } catch (error) {
      console.log(error);
    }
  }, [
    width,
    location.pathname,
    setShowBackButton,
    localStorageActivity,
    showBackBtnPages,
    lastVisitedPages,
  ]);

  const toggleDrawer = useCallback(() => {
    setIsOpen(!isOpen);
  }, [setIsOpen, isOpen]);

  const toggleNotify = useCallback(() => {
    setNotifyOpen(false);
  }, [setNotifyOpen]);

  const notifyDrawerOpen = useCallback(() => {
    setNotifyOpen(true);
  }, [setNotifyOpen]);

  const login = useCallback(() => {
    setShowLogin(true);
  }, [setShowLogin]);

  const goHome = useCallback(() => {
    history.push('/');
  }, [history]);

  const goToProfile = useCallback(() => {
    history.push('/profile');
  }, [history]);

  useEffect(() => {
    if (wallet.state === RetrievalState.Idle) {
      dispatch(getWalletAction.request());
    }
  }, [wallet, dispatch]);

  useEffect(() => {
    setTimeout(() => {
      if (showLoginForm) {
        setShowLogin(true);
      }
    }, 500);
  }, [showLoginForm]);

  useEffect(() => {
    setShowLoginForm(
      location?.search?.includes('?login=true') ||
        location.pathname.includes('/?login=true'),
    );
  }, [location]);

  const getLastPage = useCallback(() => {
    return localStorage.getItem(localStorageActivity.last) || '/';
  }, [localStorageActivity.last]);

  return (
    <>
      <div className={classes.root}>
        <AppBar position="fixed" className={classes.appBar} elevation={3}>
          <Toolbar>
            <div className={classes.menu}>
              {showBackButton ? (
                <BackButton path={getLastPage()} />
              ) : (
                <IconButton
                  edge="start"
                  onClick={toggleDrawer}
                  className={classes.menuButton}
                  color="inherit"
                  aria-label="menu"
                >
                  <MenuIcon />
                </IconButton>
              )}
            </div>
            <div className={classes.flexIcon}>
              <FlexIcon isColor={true} iconSize={iconSize} onClick={goHome} />
            </div>
            {/* <FlexIcon className={classes.propsIcon} onClick={goHome} /> */}
            <div className={classes.user}>
              {currentUser.state === RetrievalState.Succeeded ? (
                <>
                  <div className={classes.notifyContainer}>
                    <Box
                      sx={{ color: 'action.active' }}
                      onClick={notifyDrawerOpen}
                    >
                      <Badge
                        classes={{
                          badge: classes.customBadge,
                        }}
                        invisible={!badgeVisibility}
                        overlap="circular"
                        variant="dot"
                        badgeContent=""
                        aria-label=""
                      >
                        <Notifications
                          aria-label="notification bell"
                          className={classes.notifyBell}
                        />
                      </Badge>
                    </Box>
                  </div>
                  <Avatar className={classes.avatar} onClick={goToProfile} />
                  <Typography variant="subtitle2" className={classes.balance}>
                    <a href="/profile/wallet" className={classes.balanceText}>
                      {wallet.state === RetrievalState.Succeeded
                        ? formatCurrency(wallet.value.funds)
                        : 'Loading...'}
                    </a>
                  </Typography>
                </>
              ) : (
                <Button color="inherit" onClick={login}>
                  Register / Login
                </Button>
              )}
            </div>
          </Toolbar>
        </AppBar>
      </div>
      <LoginDialog isOpen={showLogin} onClose={() => setShowLogin(false)} />
      <NavDrawer isOpen={isOpen} onToggle={toggleDrawer} />
      <NotificationContainer
        notifyOpen={notifyOpen}
        toggleNotify={toggleNotify}
      />
    </>
  );
};
