import React, { useContext, useEffect } from 'react';
import { NotificationContext } from '../../App';
import { NotifyItem } from '../../models/notifications';
import { updateNotificationAsRead } from '../../stores/api/notifications3';
import { NotifyDrawer } from './notifyDrawer';

export const NotificationContainer: React.FC<{
  notifyOpen: boolean;
  toggleNotify(): void;
}> = ({ notifyOpen, toggleNotify }) => {
  const { notificationList, setNotificationList, setBadgeVisibility } =
    useContext(NotificationContext);

  useEffect(() => {
    if (notifyOpen) {
      notificationList.forEach((notif) => {
        if (
          !notif.data?.MessageAttributes?.ctaButtonText?.StringValue &&
          notif.data.notificationRead === 'false'
        ) {
          updateNotificationAsRead(notif);

          setNotificationList?.((prev: NotifyItem[]) =>
            prev?.filter((prevNotif) => {
              if (notif.key === prevNotif.key) {
                prevNotif.data.notificationRead = 'true';
              }

              return prevNotif;
            }),
          );

          setTimeout(() => {
            const allRead = notificationList.every(
              (notif) => notif.data.notificationRead !== 'false',
            );

            if (allRead) {
              setBadgeVisibility?.(false);
            } else {
              setBadgeVisibility?.(true);
            }
          }, 1500);
        }
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [notifyOpen]);

  return (
    <>
      <NotifyDrawer
        notifyOpen={notifyOpen}
        onToggleNotify={toggleNotify}
        notificationList={notificationList}
      />
    </>
  );
};
