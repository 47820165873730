import { enableMapSet } from 'immer';
import { applyMiddleware, compose, createStore } from 'redux';
import { createEpicMiddleware } from 'redux-observable';
import {
  PropsFantasyAuthApi,
  PropsFantasyBitApi,
  PropsFantasyChallengeApi,
  PropsFantasyLeagueApi,
  PropsFantasyMoneyApi,
  PropsFantasyProviderApi,
  PropsFantasyRosterApi,
  xpointApi,
} from './api';
import { PropsFantasyScheduleApi } from './api/schedule';
import { RootActions } from './rootActions';
import { IServices, rootEpics } from './rootEpics';
import { IRootState, rootReducer } from './rootReducer';

declare global {
  interface Window {
    __REDUX_DEVTOOLS_EXTENSION_COMPOSE__?: typeof compose;
  }
}

const mw = createEpicMiddleware<
  RootActions,
  RootActions,
  IRootState,
  IServices
>({
  dependencies: {
    challengeApi: new PropsFantasyChallengeApi(),
    providerApi: new PropsFantasyProviderApi(),
    authApi: new PropsFantasyAuthApi(),
    moneyApi: new PropsFantasyMoneyApi(),
    leagueApi: new PropsFantasyLeagueApi(),
    rosterApi: new PropsFantasyRosterApi(),
    bitApi: new PropsFantasyBitApi(),
    scheduleApi: new PropsFantasyScheduleApi(),
    xpoint: xpointApi,
  },
});

enableMapSet();

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
export const store = createStore(
  rootReducer,
  composeEnhancers(applyMiddleware(mw)),
);

mw.run(rootEpics);
