import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { KeyboardArrowLeft } from '@material-ui/icons';
import React, { ReactElement, useCallback } from 'react';
import { useHistory } from 'react-router-dom';

interface ShareProps {
  headerComponent?: ReactElement;
  path: string;
}

const useNavStyles = makeStyles((theme: Theme) =>
  createStyles({
    icon: {
      cursor: 'pointer',
      width: '24px',
      height: '24px',
      marginTop: '10px',
    },
  }),
);

// PWA Back Button component
export const BackButton: React.FC<ShareProps> = ({ path }) => {
  const history = useHistory();
  const classes = useNavStyles();

  const backToArena = useCallback(() => {
    history.push(path);
  }, [history, path]);

  return <KeyboardArrowLeft onClick={backToArena} className={classes.icon} />;
};
