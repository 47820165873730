import {
  Avatar,
  Grid,
  makeStyles,
  Typography,
  useMediaQuery,
  useTheme,
} from '@material-ui/core';
import React, { useCallback } from 'react';
import { useHistory } from 'react-router';
import { IUserPrivate } from '../../all-models';
import rewardsIcon from '../../assets/arena.svg';
import verifiedIcon from '../../assets/icon-verified.png';
import challengeIcon from '../../assets/ui-icon-challenge-003-copy@3x.png';
import inviteIcon from '../../assets/ui-icon-invite@3x.png';

const challengerNameStyles = makeStyles((theme) => ({
  section: {
    backgroundColor: 'white',
    padding: theme.spacing(2, 3),
    borderRadius: theme.spacing(2),
    [theme.breakpoints.down('sm')]: {
      paddingBottom: theme.spacing(1),
      marginBottom: theme.spacing(2),
    },
  },
  titleRow: {
    marginBottom: theme.spacing(1),
  },

  title: {
    fontSize: '20px',
    fontWeight: 'bold',
    lineHeight: 1.2,
    paddingLeft: '12px',
  },

  greyDividerArrowUpContainer: {
    [theme.breakpoints.up('md')]: {
      display: 'none',
    },
  },

  greyDivider: {
    width: '1px',
    height: '45px',
    borderRadius: '17.5px',
    backgroundColor: '#ddd',
    marginRight: theme.spacing(1),
  },
  arrowUp: {
    width: '24px',
    height: '24px',
  },
  buttonContainer: {
    flexDirection: 'column',
    marginTop: theme.spacing(2),
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'row',
      justifyContent: 'space-between',
    },
  },
  avatarContainer: {
    position: 'relative',
    maxWidth: '64px',
    width: '100%',
    [theme.breakpoints.down('sm')]: {
      maxWidth: '48px',
    },
  },
  avatar: {
    width: '64px',
    height: '64px',
    [theme.breakpoints.down('sm')]: {
      width: '48px',
      height: '48px',
    },
  },
  verifiedIcon: {
    position: 'absolute',
    zIndex: 2,
    bottom: 0,
    right: 0,
    width: '20px',
    height: '20px',

    [theme.breakpoints.down('sm')]: {
      width: '15px',
      height: '15px',
    },
    color: theme.palette.success.light,
  },
  button: {
    backgroundColor: '#f6f7f9',
    marginBottom: theme.spacing(1),
    padding: theme.spacing(2),
    borderRadius: '4px',
    flexDirection: 'row',
    alignItems: 'center',
    cursor: 'pointer',
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
      justifyContent: 'space-between',
      padding: theme.spacing(1),
    },
  },

  avatarButton: {
    width: '24px',
    height: '24px',
    marginRight: theme.spacing(1),
    [theme.breakpoints.down('sm')]: {
      justifyContent: 'center',
      marginBottom: theme.spacing(1),
    },
  },
  buttonTextContainer: {
    padding: theme.spacing(-2),
  },
  buttonText: {
    fontSize: '14px',
    lineHeight: '25px',
    [theme.breakpoints.down('sm')]: {
      textAlign: 'center',
      lineHeight: '17px',
    },
  },
}));

const ChallengerHomeComponent: React.FC<{
  currentUser: IUserPrivate | undefined;
}> = ({ currentUser }) => {
  const classes = challengerNameStyles();
  const theme = useTheme();
  const history = useHistory();
  const smViewMediaQuery = useMediaQuery(theme.breakpoints.down('sm'));

  const onClickJoin = useCallback(
    (redirectParam) => {
      history.push(redirectParam);
    },
    [history],
  );

  const buttonData = [
    {
      buttonCopy: 'Create a Challenge',
      icon: challengeIcon,
      redirect: '/challenge/create',
    },
    {
      buttonCopy: 'View Arena',
      icon: rewardsIcon,
      redirect: '/arena',
    },
    {
      buttonCopy: 'Invite Your League',
      icon: inviteIcon,
      redirect: '/profile/profile',
    },
  ];

  return (
    <Grid container xs={12} className={classes.section} direction="column">
      <Grid
        container
        item
        xs={12}
        alignItems="center"
        alignContent="center"
        justifyContent="space-between"
        className={classes.titleRow}
      >
        <Grid container item xs={11} alignItems="center">
          <Grid
            item
            container
            sm={2}
            xs={2}
            md={4}
            className={classes.avatarContainer}
          >
            <Avatar
              className={classes.avatar}
              src={'broken'}
              alt={`${currentUser?.firstName} ${currentUser?.lastName}`}
            />
            <Avatar src={verifiedIcon} className={classes.verifiedIcon} />
          </Grid>
          <Grid item xs={9} sm={9} md={7}>
            <Typography className={classes.title}>
              {currentUser?.displayName}
            </Typography>
          </Grid>
        </Grid>
      </Grid>
      <Grid container item className={classes.buttonContainer} xs={12}>
        {buttonData.map((data) => {
          return (
            <Grid
              container
              item
              xs={4}
              md={12}
              spacing={smViewMediaQuery ? 2 : 0}
              className={classes.button}
              onClick={() => onClickJoin(data.redirect)}
            >
              <Grid container xs={12}>
                <Grid
                  container
                  item
                  xs={12}
                  md={2}
                  justifyContent={smViewMediaQuery ? 'center' : 'flex-start'}
                >
                  <Avatar
                    src={data.icon}
                    className={classes.avatarButton}
                  ></Avatar>
                </Grid>
                <Grid
                  container
                  item
                  xs={12}
                  md={9}
                  className={classes.buttonTextContainer}
                  justifyContent={smViewMediaQuery ? 'center' : 'flex-start'}
                >
                  <Typography className={classes.buttonText}>
                    {data.buttonCopy}
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
          );
        })}
      </Grid>
    </Grid>
  );
};

export default ChallengerHomeComponent;
