import { Snackbar } from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import React, { useCallback, useEffect, useState } from 'react';

export const UserGeolocation: React.FC<{
  onNext(data: {}): void | undefined;
}> = ({ onNext }) => {
  const [hasError, setHasError] = useState<boolean>(false);

  const closeAlert = useCallback(() => setHasError(!hasError), [hasError]);

  useEffect(() => {
    navigator.geolocation.getCurrentPosition(
      function (position) {
        if (onNext) {
          onNext({});
        } else {
          return;
        }
      },
      function (error) {
        if (error.code === error.PERMISSION_DENIED) {
          setHasError(true);
        }
      },
    );
  }, []);

  return (
    <Snackbar open={hasError}>
      <Alert onClose={closeAlert} severity="error">
        We need your location. Without it you will not be able to particiapte in
        paid challenges.
      </Alert>
    </Snackbar>
  );
};
