import { combineEpics } from 'redux-observable';
import { exhaustMap, filter } from 'rxjs/operators';
import { isActionOf } from 'typesafe-actions';
import { Epic } from '../rootEpics';
import {
  requestCurrentWeekSchedule,
  requestFullSchedule,
} from './scheduleActions';

const requestFullScheduleEpic: Epic = (action$, _, { scheduleApi: api }) =>
  action$.pipe(
    filter(isActionOf(requestFullSchedule.request)),
    exhaustMap(
      async () =>
        await api
          .getSchedule()
          .then((r) => requestFullSchedule.success(r))
          .catch((err) => requestFullSchedule.failure(err)),
    ),
  );

const requestCurrentWeekScheduleEpic: Epic = (
  action$,
  _,
  { scheduleApi: api },
) =>
  action$.pipe(
    filter(isActionOf(requestCurrentWeekSchedule.request)),
    exhaustMap(
      async () =>
        await api
          .getCurrentWeekSchedule()
          .then((r) => requestCurrentWeekSchedule.success(r))
          .catch((err) => requestCurrentWeekSchedule.failure(err)),
    ),
  );

export const scheduleEpics = combineEpics(
  requestFullScheduleEpic,
  requestCurrentWeekScheduleEpic,
);
