import { ENDDATE_WEEK_TO_DATE } from '../stores/schedule/scheduleReducer';

//GETS WEEK VALUE FROM ANY END DATE
export const getKeyFromValueForEndDate = (val: string) => {
  for (let [key, value] of ENDDATE_WEEK_TO_DATE.entries()) {
    if (val === value) {
      return key;
    }
  }
};
