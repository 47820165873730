import { Grid, makeStyles, Typography } from '@material-ui/core';
// import HowToBackground from '../../assets/FPO_VIdeo-Image@3x.png'
import React, { useEffect, useState } from 'react';
// import crownIcon from '../../assets/icon-crown@3x.png';
import { NotificationsOff } from '@material-ui/icons';
import { NotifyItem } from '../../models/notifications';
import { getRandomQuote } from '../../utils/footballQuotes';
import { NotifyCardContainer } from '../notifications/notifyCardContainer';
import { IFootballQuote } from '../notifications/notifyDrawer';

const notificationStyles = makeStyles((theme) => ({
  section: {
    backgroundColor: 'white',
    padding: theme.spacing(3),
    borderRadius: theme.spacing(2),
    marginTop: theme.spacing(2.5),
    height: '357px',
    overflowY: 'hidden',
  },

  sectionTitle: {
    borderBottom: '1px solid lightgray',
    width: '100%',
    padding: theme.spacing(0, 0, 2.5),
  },
  arrowUpIcon: {
    marginTop: theme.spacing(1.5),
    marginBottom: theme.spacing(-1.5),
    width: '24px',
    height: '24px',
    [theme.breakpoints.up('md')]: {
      display: 'none',
    },
  },
  noNotificationsCont: {
    width: '100%',
    textAlign: 'center',
  },
  bell: {
    color: theme.palette.primary.light,
    width: '32px',
    height: '32px',
  },

  dividerNoNotify: {
    width: '100%',
    height: '2px',
    backgroundColor: theme.palette.divider,
  },
  greyFont: {
    color: '#666666',
  },
  dividerContainer: {
    width: '100%',
  },
  container: {
    maxHeight: '352px',
    overflowY: 'scroll',
  },

  notifyCardContainer: {
    maxHeight: '352px',
    overflowY: 'scroll',
    width: '100%',
    paddingBottom: '90px',
  },
}));
const notificationRowStyles = makeStyles((theme) => ({
  notificationRow: {
    paddingBottom: theme.spacing(1.75),
    paddingTop: theme.spacing(1.75),
    borderBottom: '1px solid lightgray',
  },
  avatar: {
    width: '24px',
    height: '24px',
    marginTop: theme.spacing(0.25),
  },
  notification: {
    marginLeft: theme.spacing(1.5),
    fontSize: '16px',
  },
}));

const NotificationsWidget: React.FC<{
  notificationList: NotifyItem[];
  dashboard: boolean;
}> = ({ notificationList, dashboard }) => {
  const classes = notificationStyles();
  const [quote, setQuote] = useState<IFootballQuote>();

  useEffect(() => {
    setQuote(getRandomQuote());
  }, []);

  return (
    <Grid container className={classes.section} xs={12} alignItems="flex-start">
      <Grid item xs={12} className={classes.sectionTitle}>
        <Typography variant="h5">Game On</Typography>
      </Grid>
      <Grid container xs={12} className={classes.container}>
        {notificationList.length >= 1 && (
          <Grid container item className={classes.notifyCardContainer}>
            <NotifyCardContainer
              notificationList={notificationList}
              dashboard={dashboard}
            />
          </Grid>
        )}
        {notificationList.length < 1 && (
          <Grid
            container
            item
            alignItems="center"
            justifyContent="center"
            direction="column"
            xs={12}
            className={classes.noNotificationsCont}
          >
            <Grid item>
              <NotificationsOff className={classes.bell} />
            </Grid>
            <Grid item>
              <Typography variant="caption" className={classes.greyFont}>
                No Notifications Yet
              </Typography>
            </Grid>
            <Grid item>
              <Typography variant="caption" className={classes.greyFont}>
                {quote?.quote}
              </Typography>
            </Grid>
            <Grid item>
              <Typography variant="caption" className={classes.greyFont}>
                ~{quote?.author}~
              </Typography>
            </Grid>
          </Grid>
        )}
      </Grid>
    </Grid>
  );
};

export default NotificationsWidget;
