import { combineEpics } from 'redux-observable';
import { exhaustMap, filter } from 'rxjs/operators';
import { isActionOf } from 'typesafe-actions';
import { Epic } from '../rootEpics';
import { updateRoster } from './rosterActions';

const updateRosterEpic: Epic = (action$, _, { rosterApi: api }) =>
  action$.pipe(
    filter(isActionOf(updateRoster.request)),
    exhaustMap(
      async (action) =>
        await api
          .updateRoster(action.payload)
          .then((r) => updateRoster.success(r))
          .catch((err) => updateRoster.failure(err)),
    ),
  );

export const rosterEpics = combineEpics(updateRosterEpic);
