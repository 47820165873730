export const formatDate = (date: Date): string => {
  return new Intl.DateTimeFormat('en', {
    month: '2-digit',
    year: 'numeric',
    day: '2-digit',
  }).format(date);
};

export const parseDate = (dateString: string): Date => {
  return new Date(dateString);
};

export const dateFormat = (date: string, includeTime: boolean = true) => {
  const dateFormatter = includeTime
    ? Intl.DateTimeFormat('en-US', {
        year: '2-digit',
        month: '2-digit',
        day: '2-digit',
        hour: 'numeric',
        minute: 'numeric',
      })
    : Intl.DateTimeFormat('en-US', {
        year: '2-digit',
        month: '2-digit',
        day: '2-digit',
      });

  let returnDate = '';

  try {
    returnDate = dateFormatter.format(new Date(date)).replace(',', '');
  } catch (err) {
    console.error('OOPS', err);
  }

  return returnDate;
};
