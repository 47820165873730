/* eslint-disable array-callback-return */
import { Participants } from '../../API';
import {
  IParticipants,
  IRoster,
  IUserPrivate,
  LeaderBoardUserData,
  TeamsPlayedUserData,
} from '../../all-models';
import {
  ChallengeStatus,
  IChallenge,
  IChallengeCreateBody,
  IChallengeScoringBreakdown,
  IOpenChallenge,
  IParticipant,
} from '../../models/challenge';
import { IChallenger, IChallengerPlayerPoints } from '../../models/challenger';
import { getCurrentSeason, getFirstEndDateOfSeason } from '../../utils/season';
import { ENDDATE_WEEK_TO_DATE } from '../schedule/scheduleReducer';
import { PropsFantasyApi } from './base';

export class PropsFantasyChallengeApi extends PropsFantasyApi {
  public async createChallenge(body: IChallengeCreateBody) {
    //Create the challenge and get the id of that challenge
    const axios = (await this.getAxios()) || this.axiosInstance;

    const endWeekToDate = ENDDATE_WEEK_TO_DATE.get(body?.endWeek);
    const userInfo = await this.getUser();

    const newChallenge: {
      createChallenges: { id: string };
    } = await axios.post('/challenges', {
      openChallenge: body.openChallenge,
      type: body.type,
      pot: 0,
      private: body.isPrivate,
      status: ChallengeStatus.Live,
      entryFee: body.entryFee,
      endDate: endWeekToDate,
      userId: userInfo.attributes.sub,
    });

    const challengeId = newChallenge.createChallenges.id;

    //Create challengers that will constain the new challengeId
    const challengers = await Promise.all(
      body.challengers!.map((challenger) =>
        axios.post('/challengers', {
          challengerId: challenger.id,
          name: challenger.name,
          challengeId: challengeId,
          rosterId: challenger.rosterID,
          leagueId: challenger.leagueID,
        }),
      ),
    );

    const filteredChallenger = challengers?.filter(
      (challenger) =>
        challenger.createChallenger.rosterId === body.selectedChallengerId,
    );

    try {
      await axios.post('/join-challenge', {
        challengerId:
          filteredChallenger[0]?.createChallenger?.id ||
          challengers[0]?.createChallenger?.id,
        challengeId: challengeId,
        serviceFee: body.serviceFee,
        creditFee: body.creditFee,
      });
    } catch (err) {
      console.log('Enter challenge error', err);
    }

    //Query the new challenge by the id which will now show the 2 challengers associated
    const getChallengeById: { getChallenges: IChallenge } = await axios.get(
      `/challenges/${challengeId}`,
    );

    return getChallengeById.getChallenges;
  }

  public async getChallenges(
    continuation: string | undefined,
  ): Promise<{ challenges: IChallenge[]; continuation: string | undefined }> {
    try {
      const axios = (await this.getAxios()) || this.axiosInstance;
      const dateNow = new Date(new Date().getDate() + 2);

      const filteredChallengesLive: {
        challengesByPot: {
          items: any[];
          nextToken: null | string;
        };
      } = await axios.get(`/challenges/live`);

      return {
        challenges: filteredChallengesLive.challengesByPot.items.map(
          (item) => ({
            ...item,
            challengers: item.challengers.items,
          }),
        ),
        continuation: undefined,
      };
    } catch (err) {
      console.log({ 'get challenges error': err });
      return {
        challenges: [] as any[],
        continuation: undefined,
      };
    }
  }

  public async getCompletedChallenges(
    continuation: string | undefined,
  ): Promise<{ challenges: IChallenge[]; continuation: string | undefined }> {
    try {
      const axios = (await this.getAxios()) || this.axiosInstance;
      const userInfo = await this.getUser();

      const filteredChallengesCompleted: {
        challengesByStatus: {
          items: any[];
          nextToken: null | string;
          limit: number;
        };
      } = await axios.get(
        `/challenges/completed?nextToken=${continuation}&limit=${50}`,
      );

      return {
        challenges: filteredChallengesCompleted.challengesByStatus.items.map(
          (item) => ({
            ...item,
            challengers: item.challengers.items,
          }),
        ),
        continuation:
          filteredChallengesCompleted.challengesByStatus.nextToken || undefined,
      };
    } catch (err) {
      console.log({ 'get challenges error': err });
      return {
        challenges: [] as any[],
        continuation: undefined,
      };
    }
  }

  public async getUserChallenges(status: 'completed' | 'live'): Promise<any[]> {
    const userInfo = await this.getUser();
    const firstEndDate = await getFirstEndDateOfSeason();

    const getUserChallenges: any = async (
      data: any[],
      continuation?: string,
    ) => {
      try {
        const axios = (await this.getAxios()) || this.axiosInstance;
        const challengeData = [...data];

        const listChallengesByParticipation: {
          participationByUser: IParticipants;
        } = await axios.get(
          `/challenges/participants/${userInfo.attributes.sub}?nextToken=${continuation}&endDate=${firstEndDate}`,
        );

        const challengesByPart: IChallenge[] = [];

        await Promise.all(
          listChallengesByParticipation?.participationByUser?.items?.map(
            async (participantData: Participants) => {
              const getChallengeById: {
                getChallenges: IChallenge;
              } = await axios.get(`/challenges/${participantData.challengeId}`);

              if (getChallengeById?.getChallenges?.status === status) {
                challengesByPart.push(getChallengeById.getChallenges);
              }
            },
          ),
        );

        const { nextToken } =
          listChallengesByParticipation?.participationByUser;

        let allChallenges = challengeData.concat(challengesByPart);

        if (nextToken !== null) {
          return new Promise((resolve) =>
            resolve(getUserChallenges(allChallenges, nextToken)),
          );
        } else {
          return Promise.resolve(allChallenges);
        }
      } catch (err) {
        console.log(err, 'Error getting Challenges by status');
        return {
          challenges: [] as any[],
          continuation: undefined,
        };
      }
    };

    return getUserChallenges([]).then((data: any) => {
      return data;
    });
  }

  public async refreshChallengePoints(challengeId: string) {
    try {
      const axios = (await this.getAxios()) || this.axiosInstance;

      const score: { recalculateChallengeScore: string } = await axios.post(
        '/recalculate-score',
        {
          challengeId,
        },
      );

      const parsed: { [challengerId: string]: IChallengerPlayerPoints[] } =
        JSON.parse(score.recalculateChallengeScore);

      return { points: parsed, matchedRoster: score.matchedRoster };
    } catch (e) {
      console.error(e);
      throw e;
    }
  }

  public async requestChallengeScoringBreakdown(
    challengeId: string,
  ): Promise<IChallengeScoringBreakdown> {
    const axios = (await this.getAxios()) || this.axiosInstance;
    const result: {
      scoringBreakdown: IChallengeScoringBreakdown;
    } = await axios.post('/scoring-breakdown', { challengeId });
    return result.scoringBreakdown;
  }

  public async getChallengeById(challengeId: string): Promise<IChallenge> {
    const axios = (await this.getAxios()) || this.axiosInstance;
    const singleChallenge: { getChallenges: IChallenge } = await axios.get(
      `/challenges/${challengeId}`,
    );

    return singleChallenge.getChallenges;
  }

  public async enterChallenge(body: {
    challengeId: string;
    challengerId: string;
    serviceFee?: boolean;
    creditFee?: string;
  }) {
    const axios = (await this.getAxios()) || this.axiosInstance;
    try {
      await axios.post('/join-challenge', {
        challengerId: body.challengerId,
        challengeId: body.challengeId,
        serviceFee: body.serviceFee,
        creditFee: body.creditFee,
      });
    } catch (err) {
      console.log('Enter challenge error', err);
    }

    const getChallengeById: {
      getChallenges: IChallenge;
    } = await axios.get(`/challenges/${body.challengeId}`);

    return getChallengeById.getChallenges;
  }

  public async leaveChallenge(body: { id: string; challengeId: string }) {
    const axios = (await this.getAxios()) || this.axiosInstance;
    try {
      await axios.post('/leave-challenge', {
        challengeId: body.challengeId,
      });
    } catch (err) {
      console.log('Leave challenge error', err);
    }

    const getChallengeById: {
      getChallenges: IChallenge;
    } = await axios.get(`/challenges/${body.challengeId}`);

    return getChallengeById.getChallenges;
  }

  // ** CREATE OPEN CHALLENGE ** //
  public async createOpenChallenge(body: {
    challengeId: string;
    rosterID: string;
  }): Promise<IOpenChallenge> {
    const axios = (await this.getAxios()) || this.axiosInstance;
    const userInfo = await this.getUser();

    const newOpenChallenge: {
      createTypeOpenChallenge: IOpenChallenge;
    } = await axios.post('/open-challenge', {
      challengeId: body.challengeId,
      opposingUser: userInfo.attributes.sub,
      rosterId: body.rosterID,
      isCompleted: false,
    });

    await axios.post('/join-open-challenge', {
      openChallengeId: newOpenChallenge?.createTypeOpenChallenge?.id,
    });

    return newOpenChallenge.createTypeOpenChallenge;
  }

  // ** LIST OPEN CHALLENGES ** //
  public async getOpenChallenges(continuation: string | undefined): Promise<{
    openChallenges: IOpenChallenge[];
    continuation: string | undefined;
  }> {
    let openChallenges: IOpenChallenge[] = [];

    try {
      const axios = (await this.getAxios()) || this.axiosInstance;
      //GET ALL USERS
      const getAllOpenChallengesData = async (
        data: any = [],
        continuation?: string,
      ) => {
        try {
          const challengeData = [...data];

          const challenges: {
            listTypeOpenChallenges: {
              items: IOpenChallenge[];
              nextToken: null | string;
              limit: number;
            };
          } = await axios.get(
            `/open-challenge?nextToken=${continuation}&limit=${500}`,
          );

          const { nextToken } = challenges.listTypeOpenChallenges;
          let allChallenges = challengeData.concat(
            challenges.listTypeOpenChallenges.items,
          );

          if (nextToken !== null) {
            return new Promise((resolve) =>
              resolve(getAllOpenChallengesData(allChallenges, nextToken)),
            );
          } else {
            return Promise.resolve(allChallenges);
          }
        } catch (err) {
          return console.error(err);
        }
      };

      await getAllOpenChallengesData().then((data: IOpenChallenge[]) => {
        openChallenges = [...data];
      });

      return {
        openChallenges: [...openChallenges],
        continuation: undefined,
      };
    } catch (err) {
      console.log({ 'Get open challenges': err });
      return {
        openChallenges: [] as any[],
        continuation: undefined,
      };
    }
  }

  // ** OPEN CHALLENGE OPPONENT SELECTED ** //
  public async updateChallenge(body: IChallengeCreateBody) {
    //Create the challenge and get the id of that challenge
    const axios = (await this.getAxios()) || this.axiosInstance;
    const updatingChallenge: {
      updateChallenges: { id: string };
    } = await axios.patch(`/challenges/${body.id}`, {
      openChallenge: body.openChallenge,
    });

    const challengeId = updatingChallenge.updateChallenges.id;

    //Create challengers that will contain the new challengeId
    await Promise.all(
      body.challengers!.map((challenger) =>
        axios.post('/challengers', {
          challengerId: challenger.id,
          name: challenger.name,
          challengeId: challengeId,
          rosterId: challenger.rosterID,
          leagueId: challenger.leagueID,
        }),
      ),
    );

    //List open challenges by challenge id
    const listOpenChallenges: {
      openChallengesByChallengeId: IOpenChallenge[];
    } = await axios.get(`/open-challenge/${challengeId}`);

    //Update selected open challenge with challenger id completed and trigger lambda
    await Promise.all(
      listOpenChallenges?.openChallengesByChallengeId?.map(
        (openChallenge: IOpenChallenge) => {
          return body.challengers?.map(async (singleChallenger) => {
            if (singleChallenger.id === openChallenge.opposingUser) {
              await axios.post('/join-open-challenge', {
                openChallengeId: openChallenge.id,
              });
              return axios.patch(`/open-challenge/${openChallenge.id}`, {
                isCompleted: true,
              });
            }
          });
        },
      ),
    );

    //Query the new challenge by the id which will now show the 2 challengers associated
    const getChallengeById: { getChallenges: IChallenge } = await axios.get(
      `/challenges/${challengeId}`,
    );

    getChallengeById.getChallenges?.challengers?.forEach((c) => {
      if (c.finalRoster) {
        c.finalRoster.players = JSON.parse(c.finalRoster?.players);
        c.finalRoster.bench = JSON.parse(c.finalRoster?.bench);
        c.finalRoster.starting = JSON.parse(c.finalRoster?.starting);
      }
    });

    return getChallengeById.getChallenges;
  }

  /**Get all Users and User attributes for Leaderboard Challenge */
  public async getUserAttributeData(): Promise<LeaderBoardUserData> {
    //CURRENT LOGGED IN USER
    const userInfo = await this.getUser();
    const currentSeason = getCurrentSeason();

    let loggedInUserIndex;
    let topFiveChallengersCognito;
    let users: IUserPrivate[] = [];
    let allRosters: IRoster[] = [];
    let qualifiedUser: IUserPrivate[] = [];
    let usersRosters: IRoster[] = [];

    //GET ALL USERS
    const getUsers = async (data: any = [], continuation?: string) => {
      try {
        const axios = (await this.getAxios()) || this.axiosInstance;
        const userData = [...data];

        const users: any = await axios.get(
          `/users?nextToken=${continuation}&limit=${500}`,
        );

        const { nextToken } = users.listUsers;
        let allUsers = userData.concat(users.listUsers.items);

        if (nextToken !== null) {
          return new Promise((resolve) =>
            resolve(getUsers(allUsers, nextToken)),
          );
        } else {
          return Promise.resolve(allUsers);
        }
      } catch (err) {
        return console.error(err);
      }
    };

    await getUsers().then((data: IUserPrivate[]) => {
      users = [...data];
    });

    const rankEloConst = 10;

    users.map((user) => {
      user.winPercent =
        ((user.wins || 0) + rankEloConst) /
        ((user.wins || 0) + ((user.losses || 0) + rankEloConst) + rankEloConst);
      user.gameCount = (user.wins || 0) + (user.losses || 0);
    });

    users.sort((a, b) => b.gameCount - a.gameCount);

    //WE WANT TO MAKE SURE TO DISPLAY USES WITH RECORDS
    const sortByWinsThenLosses = users.sort(
      (userA: IUserPrivate, userB: IUserPrivate) => {
        const rankEloConst = (users[0].gameCount || 100) / 3;

        if (userA.wins + userA.losses <= 1) {
          return 1;
        }
        if (userB.wins + userB.losses <= 1) {
          return -1;
        }

        // validate based on percentage of wins data
        return (
          (userB.wins + rankEloConst) /
            (userB.wins + (userB.losses + rankEloConst) + rankEloConst) -
          (userA.wins + rankEloConst) /
            (userA.wins + (userA.losses + rankEloConst) + rankEloConst)
        );
      },
    );

    //SLICE TOP 10
    let topPlayers = sortByWinsThenLosses.slice(0, 10);

    const excludedMembers = ['baf9f9f7-f27e-4e37-bb65-56bee094d5e3'];

    topPlayers = topPlayers.filter(
      (user) => !excludedMembers.includes(user.id),
    );

    //SORT BY DESCENDING BY WINS AND ASCENDING BY LOSSES
    // topPlayers.sort((a, b) => (a.wins === b.wins ? 0 : a.wins > b.wins ? -1 : 1));

    //GET ALL ROSTERS FOR USERS AND VERIFY SEASON AND IF ASSOCIATED WITH USER
    const axios = (await this.getAxios()) || this.axiosInstance;

    await Promise.all(
      topPlayers.map((usr) => axios.get(`/rosters/users/${usr.id}`)),
    )
      .then((result) => {
        result?.map((rost) => {
          if (rost.rosterByUserId.items) {
            rost.rosterByUserId.items.map((r: IRoster) => {
              if (r.league.season === currentSeason.toString()) {
                usersRosters.push(r);
              }
            });
          }
        });
      })
      .catch((err) => {
        console.log(err, 'error gett rosters');
      });

    topPlayers.map((u) => {
      let match = usersRosters.find((d: IRoster) => d.userId === u.id);

      if (match) {
        allRosters.push(match);
      }
    });

    //SLICE TOP 5 USERS
    const topFive = topPlayers.slice(0, 5);

    //CHECK TO SEE IF CURRENT USER IS IN TOP 5
    const isUserInTop5 = topPlayers.some(
      (e) => e.id === userInfo.attributes.sub,
    );

    //SORT OUT CURRENT USER DATA IF NOT IN TOP 5
    const currentUserData = sortByWinsThenLosses.filter(
      (user: IUserPrivate) => user.id === userInfo.attributes.sub,
    );

    //GET POSITION/RANK OF CURRENT USER
    const indexOfCurrentUser =
      sortByWinsThenLosses.findIndex(
        (user) => user.id === userInfo.attributes.sub,
      ) + 1;

    loggedInUserIndex = indexOfCurrentUser;

    const userNotInTop5 = [...topFive, ...currentUserData];

    const topChallengers = isUserInTop5 ? topPlayers : userNotInTop5;

    //MAKE CALL TO COGNITO TO GET DATA
    users = topChallengers;

    await axios
      .post('/users', { users })
      .then((result) => {
        topFiveChallengersCognito = result;
      })
      .catch((err) => {
        console.log(err, 'Error getting leaderboard');
      });

    return {
      topFiveChallengersCognito,
      loggedInUserIndex,
      users,
      allRosters,
    };
  }

  /**Get all Users and User attributes for Teams Played */
  public async getUsersForTeamsPlayed(): Promise<TeamsPlayedUserData> {
    let challengersPlayedAgainst: IChallenger[] = [];
    let users: IUserPrivate[] = [];

    //CURRENT LOGGED IN USER
    const userInfo = await this.getUser();

    //GET ALL USERS TO GET W-L records
    const getUsers = async (data: any = [], continuation?: string) => {
      try {
        const axios = (await this.getAxios()) || this.axiosInstance;
        const userData = [...data];

        const users: any = await axios.get(
          `/users?nextToken=${continuation}&limit=${500}`,
        );

        const { nextToken } = users.listUsers;
        let allUsers = userData.concat(users.listUsers.items);

        if (nextToken !== null) {
          return new Promise((resolve) =>
            resolve(getUsers(allUsers, nextToken)),
          );
        } else {
          return Promise.resolve(allUsers);
        }
      } catch (err) {
        return console.error(err);
      }
    };

    await getUsers().then((data: IUserPrivate[]) => {
      users = [...data];
    });

    //Creating a hardcoded fallback for now - will circle back create a backup utility
    const firstEndDate =
      (await getFirstEndDateOfSeason()) || '2023-09-12T17:00:00.000Z';

    //GET ALL CURRENT USER COMPLETED CHALLENGES
    const userChallenges = await this.getUserChallenges('completed');

    const currentSeasonChallenges = userChallenges?.filter(
      (uc: IChallenge) => uc.endDate >= firstEndDate,
    );

    //FILTER OUT CHALLENGERS THAT USER HAS PLAYED AGAINST NOT THE CHALLENGER USER BACKED
    const filteredChallengersPlayedAgainst = currentSeasonChallenges?.map(
      (challenge: IChallenge) => {
        challenge.participants?.items.map((participant: IParticipant) => {
          if (userInfo.attributes.sub === participant.userId) {
            challenge?.challengers?.filter((challenger: IChallenger) => {
              if (
                challenger.id !== participant.challengerId &&
                challengersPlayedAgainst.indexOf(challenger) === -1
              ) {
                challengersPlayedAgainst.push(challenger);
              }
            });
          }
        });
      },
    );

    return {
      users,
      challengersPlayedAgainst,
      currentSeasonChallenges,
    };
  }
}
