import { IError } from '@propsfantasy/retrieval';
import { from, fromEvent, interval, merge, Observable, throwError } from 'rxjs';
import {
  catchError,
  delay,
  filter,
  ignoreElements,
  map,
  mapTo,
  mergeMap,
  take,
  tap,
} from 'rxjs/operators';
import { ProviderType } from '../../models/provider';
import { assertNever } from '../../utils/assertNever';
import { CreateProviderRequest } from '../user/userActions';
import { PropsFantasyApi } from './base';

export class PropsFantasyProviderApi extends PropsFantasyApi {
  public createProvider(
    body: CreateProviderRequest,
  ): Observable<{ type: ProviderType }> {
    switch (body.type) {
      case ProviderType.ESPN:
        throw new Error('not implemented');
      case ProviderType.Yahoo:
        return this.createProviderViaOAuth(body.type);
      case ProviderType.Sleeper:
        return from(this.createSleeper(body.username));
      default:
        throw assertNever(body);
    }
  }

  private createProviderViaOAuth(
    providerType: ProviderType,
    endpoint = 'yahoo',
  ) {
    // This must be opened synchronously to avoid popup blockers
    const opened = window.open(
      'about:blank',
      undefined,
      'scrollbars,resizable,width=400, height=600',
    );

    if (!opened) {
      throw oauthLinkError(
        'Could not open a login window. Disable your popup blocker and try again.',
      );
    }

    const axios = this.axiosInstance;

    return merge(
      // 1. Listen for the oauth callback
      fromEvent<MessageEvent>(window, 'message').pipe(
        map((evt): { type?: string; query?: string } => {
          try {
            return JSON.parse(evt.data);
          } catch {
            return {};
          }
        }),
        filter((msg) => msg.type === 'oauthCallback'),
      ),

      // 2. Get the API endpoint and update the window location
      from(
        axios.post('/providers/link-yahoo', { fieldName: 'yahooRedirect' }),
      ).pipe(
        tap(({ yahooRedirect }) => {
          opened.location.href = yahooRedirect.uri;
        }),
        ignoreElements(),
        catchError(async (err) => {
          opened.close();
          throw err;
        }),
      ),

      // 3. Meanwhile, see if the user closed the window, and abort if so
      interval(500).pipe(
        filter(() => opened.closed),
        delay(1000), // give any in-flight postMessage ample time to resolve
        mergeMap(() => throwError(oauthLinkError('Login cancelled.'))),
      ),
    ).pipe(
      take(1),
      mergeMap((msg) =>
        axios.post('/providers/link-yahoo', {
          callback: msg.query,
          fieldName: 'completeLinkYahoo',
        }),
      ),
      mapTo({ type: providerType }),
    );
  }

  private async createSleeper(username: string) {
    const axios = (await this.getAxios()) || this.axiosInstance;
    await axios.post('/providers/link-sleeper', {
      username,
    });

    return { type: ProviderType.Sleeper };
  }

  public async getCurrentUserProviders(): Promise<any> {
    const axios = (await this.getAxios()) || this.axiosInstance;
    const userInfo = await this.getUser();
    return axios.get(`/users/provider/${userInfo.attributes.sub}`);
  }
}

const oauthLinkError = (errorMessage: string): IError => ({
  statusCode: 400,
  serviceError: {
    errorCode: 0,
    errorMessage,
  },
});
