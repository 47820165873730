import { error, Retrieval, success } from '@propsfantasy/retrieval';
import { fromEvent } from 'rxjs';
import { map } from 'rxjs/operators';
import { once } from '../../utils/function';

export enum XpointStatus {
  Allowed = 'ALLOWED',
  Denied = 'DENIED',
  Waiting = 'WAITING',
}

export interface IXpointCheckResponse {
  errors?: { code: number; description: string }[];
  status?: XpointStatus;
}

export interface IXpointSDK {
  logout(): Promise<void>;
  liteCheck(): Promise<IXpointCheckResponse>;
  setUserId(
    userId: string,
    clientId?: string,
    customData?: Record<string, string>,
  ): Promise<string>;
}

export interface IXpointExports {
  WebSdk: { new (): IXpointSDK };
  WebSdkLite: { new (): IXpointSDK };
}

export const onXpointSDKMessage = () =>
  fromEvent(window, 'xpoint-sdk-message').pipe(
    map((evt) => (evt as any).detail as IXpointCheckResponse),
  );

declare const xpoint: IXpointExports;

const isLocal = window.location.hostname === 'localhost';
const isDev = window.location.hostname.includes('dev');
const clientId = isDev ? 'propsfantasy' : 'propsfantasy8736prod';

const mapCheckResponse = (
  response: IXpointCheckResponse,
): Retrieval<boolean> => {
  if (!response.status && response.errors?.length) {
    return error({
      statusCode: 500,
      serviceError: {
        errorCode: response.errors[0].code,
        errorMessage: response.errors[0].description,
      },
    });
  }

  return success(response.status === XpointStatus.Allowed);
};

export class XPoint {
  private userId?: string;
  /**
   * Sets (or unsets) the user ID.
   */
  public async setUserId(userId: string | undefined) {
    this.userId = userId;

    if (!this.loadOnce.value) {
      return;
    }

    const loaded = await this.loadOnce.value;
    if (userId) {
      await loaded.setUserId(userId, clientId);
    } else {
      await loaded.logout();
    }
  }

  /**
   * Gets whether the user is allowed to access props. Can throw if
   * a service error occurs.
   */
  public async isAllowed(): Promise<Retrieval<boolean>> {
    if (isLocal) {
      return success(true);
    }

    try {
      const sdk = await this.loadOnce.invoke();
      return mapCheckResponse(await sdk.liteCheck());
    } catch (e) {
      return error({
        statusCode: 500,
        serviceError: {
          errorCode: 0,
          errorMessage: e.stack,
        },
      });
    }
  }

  private readonly loadOnce = once(async () => {
    const script = await fetch('https://cdn.xpoint.tech/2.0.0/xpoint.min.js');
    if (!script.ok) {
      throw new Error(`${script.status} error loading xpoint: ${script.text}`);
    }

    try {
      // eslint-disable-next-line no-new-func
      new Function(await script.text()).call(globalThis);
    } catch (e) {
      console.error('Error evaluating xpoint:', e);
      throw e;
    }

    const sdk = new xpoint.WebSdkLite();
    if (this.userId) {
      sdk.setUserId(this.userId, clientId);
    }

    return sdk;
  });
}

export const xpointApi = new XPoint();
