import { combineReducers } from 'redux';
import { bitReducer, IBitState } from './bit/bitReducers';
import {
  challengeReducer,
  IChallengeState,
} from './challenge/challengeReducer';
import { DropdownReducer, FilterState } from './dropdown/dropdownReducer';
import { ILeagueStatus, leagueReducer } from './league/leagueReducers';
import { IMoneyState, moneyReducer } from './money/moneyReducer';
import { RootActions } from './rootActions';
import { IRosterState, rosterReducer } from './roster/rosterReducers';
import { IScheduleState, scheduleReducer } from './schedule/scheduleReducer';
import { IUserState, userReducer } from './user/userReducer';

export interface IRootState {
  roster: IRosterState;
  leagues: ILeagueStatus;
  challenges: IChallengeState;
  user: IUserState;
  dropdown: FilterState;
  money: IMoneyState;
  bit: IBitState;
  schedule: IScheduleState;
}

export const rootReducer = combineReducers<IRootState, RootActions>({
  roster: rosterReducer,
  leagues: leagueReducer,
  challenges: challengeReducer,
  user: userReducer,
  dropdown: DropdownReducer,
  money: moneyReducer,
  bit: bitReducer,
  schedule: scheduleReducer,
});
