import { IError } from '@propsfantasy/retrieval';
import { ActionType, createAsyncAction } from 'typesafe-actions';

import { IBit } from '../../models/bit';

export const createBit = createAsyncAction(
  'CreateBitRequest',
  'CreateBitSuccess',
  'CreateBitError',
)<any, IBit, IError>();

const actions = {
  createBit,
};

export type BitActions = ActionType<typeof actions>;
