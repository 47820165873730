import { combineEpics } from 'redux-observable';
import { from, of } from 'rxjs';
import {
  catchError,
  exhaustMap,
  filter,
  mergeMap,
  takeUntil,
} from 'rxjs/operators';
import { isActionOf } from 'typesafe-actions';
import { Epic } from '../rootEpics';
import {
  getAllLeagues,
  getAllRostersForLeague,
  getLeague,
} from './leaguesActions';

const getAllLeaguesEpics: Epic = (action$, _, { leagueApi: api }) =>
  action$.pipe(
    filter(isActionOf(getAllLeagues.request)),
    exhaustMap(
      async () =>
        await api
          .getAllLeagues()
          .then((r) => getAllLeagues.success(r))
          .catch((err) => getAllLeagues.failure(err)),
    ),
  );

const getLeagueEpic: Epic = (action$, _, { leagueApi: api }) =>
  action$.pipe(
    filter(isActionOf(getLeague.request)),
    mergeMap(
      async (action) =>
        await api
          .getLeagueById(action.payload)
          .then((r) => getLeague.success(r))
          .catch((error) => getLeague.failure({ id: action.payload, error })),
    ),
  );

const getAllRostersForLeagueEpic: Epic = (action$, _, { leagueApi: api }) =>
  action$.pipe(
    filter(isActionOf(getAllRostersForLeague.request)),
    mergeMap(({ payload: leagueId }) =>
      from(api.getAllRostersForLeague(leagueId)).pipe(
        mergeMap((rosters) =>
          of(getAllRostersForLeague.success({ leagueId, rosters })),
        ),
        catchError((error) =>
          of(getAllRostersForLeague.failure({ leagueId, error })),
        ),
        takeUntil(
          action$.pipe(filter(isActionOf(getAllRostersForLeague.cancel))),
        ),
      ),
    ),
  );

export const leaguesEpics = combineEpics(
  getAllLeaguesEpics,
  getAllRostersForLeagueEpic,
  getLeagueEpic,
);
