import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useInterval } from 'react-use';
import { refreshChallengePoints } from '../../stores/challenge/challengeActions';
import {
  challengePointRefreshInterval,
  getChallengeLivePoints,
  getShouldRefreshChallengePoints,
} from '../../stores/challenge/challengeReducer';

export const useLiveChallengePoints = (
  challengeId: string,
  disableRefresh?: boolean,
) => {
  const live = useSelector(getChallengeLivePoints(challengeId));
  const dispatch = useDispatch();
  const [ticks, setTicks] = useState(0);
  const [inactive, setInactive] = useState(false);
  const shouldRefresh = useSelector(
    getShouldRefreshChallengePoints(challengeId),
  );

  useInterval(
    () => setTicks(Date.now()),
    inactive ? challengePointRefreshInterval : null,
  );

  useEffect(() => {
    if (shouldRefresh && !disableRefresh) {
      dispatch(refreshChallengePoints.request(challengeId));
    }

    return function cleanup() {
      setInactive(true);
    };
  }, [ticks, shouldRefresh, challengeId, dispatch, inactive]);

  return live.state;
};
