import {
  Button,
  Drawer,
  List,
  ListItem,
  ListItemText,
  makeStyles,
} from '@material-ui/core';
import { Refresh } from '@material-ui/icons';
import CloseIcon from '@material-ui/icons/Close';
import React, { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { logout } from '../../stores/user/userActions';
import { isLoggedIn } from '../../stores/user/userReducer';
import { FlexIcon } from '../base/flexIcon';
import { InstagramIcon } from '../base/instagramIcon';
import { TwitterIcon } from '../base/twitterIcon';

const useNavDrawerStyles = makeStyles((theme) => ({
  paper: {
    background: `white`,
    color: theme.palette.secondary.main,
    width: '100%',
    height: '100%',
  },
  content: {
    padding: theme.spacing(2),
  },
  navList: {
    paddingTop: theme.spacing(6),
  },
  navListItem: {
    textAlign: 'center',
  },
  navItem: {
    textTransform: 'uppercase',
    fontSize: '1.5em',
    lineHeight: '1.5em',
    letterSpacing: '1.33px',
  },
  logo: {
    height: '96px',
    width: '72px',
    objectFit: 'contain',
    flexGrow: 1,
    display: 'flex',
    margin: '0 auto',
    '&:hover': {
      cursor: 'pointer',
    },
  },
  flexIcon: {
    display: 'block',
    margin: '0 auto',
    '&:hover': {
      cursor: 'pointer',
    },
  },
  socialList: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    textAlign: 'center',
  },
  socialListItem: {
    maxWidth: '48px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    textAlign: 'center',
    '&:hover': {
      cursor: 'pointer',
    },
  },
  logoutButton: {
    backgroundColor: theme.palette.secondary.main,
    position: 'absolute',
    top: theme.spacing(2),
    right: theme.spacing(2),
    zIndex: theme.zIndex.drawer + 1,
    width: 200,
    borderRadius: 24,
    fontWeight: 'bold',
    color: 'white',
    [theme.breakpoints.down('sm')]: {
      top: 'auto',
      right: 0,
      left: 0,
      bottom: theme.spacing(2),
      margin: '0 auto',
    },
  },
  refresh: {
    position: 'absolute',
    bottom: theme.spacing(4),
    right: theme.spacing(4),
    '&:hover': {
      cursor: 'pointer',
    },
  },
}));

interface NavDrawerProps {
  isOpen: boolean;
  onToggle(): void;
}

interface NavItem {
  name: string;
  link?: string;
  action?: any;
}

export const NavDrawer: React.FC<NavDrawerProps> = ({ isOpen, onToggle }) => {
  const classes = useNavDrawerStyles();
  const history = useHistory();
  const dispatch = useDispatch();
  const isUserloggedIn = useSelector(isLoggedIn);

  const goHome = useCallback(() => {
    history.push('/');
  }, [history]);

  const handleNavClick = (link?: string) => {
    try {
      link && localStorage.setItem('last_visited_page', 'menu');
      link && localStorage.setItem('current_page', link);
    } catch (error) {
      console.log(error);
    }
    link && history.push(link);
  };

  const handleLogout = useCallback(() => {
    dispatch(logout.request());
    // Redirect the user to homepage every time user logs out.
    history.push('/');
    window.location.reload();
  }, [dispatch, history]);

  const reload = useCallback(() => {
    window.location.reload();
  }, []);

  const navList: NavItem[] = [
    { name: 'My Account', link: '/profile' },
    { name: 'My Challenges', link: '/my-challenges' },
    { name: 'The Arena', link: '/arena' },
    // { name: 'Tournament of Champions', link: '/tournament-of-champions' },
    { name: 'Create Challenge', link: '/challenge/create' },
    { name: 'Help', link: '/help' },
  ];

  return (
    <div>
      <Drawer
        anchor={'top'}
        open={isOpen}
        onClose={onToggle}
        classes={{ paper: classes.paper }}
      >
        <div
          role="presentation"
          onClick={onToggle}
          onKeyDown={onToggle}
          className={classes.content}
        >
          <CloseIcon onClick={onToggle} />
          <FlexIcon
            onClick={goHome}
            iconSize={100}
            className={classes.flexIcon}
            isColor={true}
          />
          <List className={classes.navList}>
            {navList.map((item: NavItem) => (
              <ListItem
                button
                key={item.name}
                onClick={() => handleNavClick(item.link)}
                className={classes.navListItem}
              >
                <ListItemText
                  primary={<span className={classes.navItem}>{item.name}</span>}
                />
              </ListItem>
            ))}
          </List>
          <List className={classes.socialList}>
            <ListItem
              onClick={() =>
                window.location.replace(
                  'https://www.instagram.com/flex_fantasy?utm_medium=copy_link',
                )
              }
              className={classes.socialListItem}
            >
              <InstagramIcon />
            </ListItem>
            <ListItem
              onClick={() =>
                window.location.replace(
                  'https://twitter.com/Flex_FantasyApp?s=11',
                )
              }
              className={classes.socialListItem}
            >
              <TwitterIcon />
            </ListItem>
          </List>
          <div className={classes.refresh}>
            <Refresh onClick={reload} />
          </div>
          {isUserloggedIn && (
            <Button
              variant="contained"
              className={classes.logoutButton}
              onClick={handleLogout}
            >
              LOG OUT
            </Button>
          )}
        </div>
      </Drawer>
    </div>
  );
};
