import {
  Container,
  makeStyles,
  useMediaQuery,
  useTheme,
} from '@material-ui/core';
import React, { ReactElement, ReactNode } from 'react';
import { Nav } from '../nav/nav';
import { Refresh } from './pullToRefesh';
interface PageProps {
  headerComponent?: ReactElement;
  fluid?: Boolean;
  onRefreshAction?: Function;
}

interface ContentProps {
  children?: any;
  hasHeader: boolean;
}

const usePageStyles = makeStyles((theme) => ({
  container: {
    padding: theme.spacing(0, 0, 8, 0),
    overflow: 'hidden',
    position: 'relative',
    maxWidth: '100%',
    marginTop: '0px', //30px
  },
}));

/**
 * We will end up with different layout types.  This is our base.
 */
export const PropsPage: React.FC<PageProps> = ({
  children,
  fluid,
  onRefreshAction,
  ...props
}) => {
  const classes = usePageStyles();
  const theme = useTheme();
  const mdViewMediaQuery = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <PageContainer
      isMobile={mdViewMediaQuery}
      onRefreshAction={onRefreshAction}
    >
      <>
        <Container maxWidth="lg" className={classes.container}>
          <Nav />
          {props.headerComponent && props.headerComponent}
          {fluid && (
            <ContentFluid
              children={children}
              hasHeader={!props.headerComponent}
            />
          )}
          {!fluid && (
            <Content children={children} hasHeader={!props.headerComponent} />
          )}
        </Container>
      </>
    </PageContainer>
  );
};

const ContentFluid: React.FC<ContentProps> = ({ children, hasHeader }) => {
  return <div>{children}</div>;
};

const Content: React.FC<ContentProps> = ({ children, hasHeader }) => {
  return <Container>{children}</Container>;
};

const PageContainer: React.FC<{
  isMobile: boolean;
  onRefreshAction?: Function;
  children: ReactNode;
}> = ({ isMobile, onRefreshAction, children }) => {
  return isMobile ? (
    <Refresh onRefreshAction={onRefreshAction}>{children}</Refresh>
  ) : (
    { ...children }
  );
};
