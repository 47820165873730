import {
  error,
  idleRetrieval,
  Retrieval,
  success,
  workingRetrival,
} from '@propsfantasy/retrieval';
import produce from 'immer';
import { getType } from 'typesafe-actions';
import { createBit } from './bitActions';

import { IBit } from '../../models/bit';
import { RootActions } from '../rootActions';

export interface IBitState {
  creating: Retrieval<IBit>;
}

const initialState = {
  creating: idleRetrieval,
};

export const bitReducer = produce((state: any, action: RootActions) => {
  switch (action.type) {
    case getType(createBit.request):
      state.creating = workingRetrival;
      return;
    case getType(createBit.success):
      state.creating = success(action.payload);

      return;
    case getType(createBit.failure):
      state.creating = error(action.payload);
      return;
  }
}, initialState);
