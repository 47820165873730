import {
  Button,
  CircularProgress,
  Grid,
  makeStyles,
  TextField,
} from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import { RetrievalState } from '@propsfantasy/retrieval';
import React, { useEffect } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useHistory } from 'react-router-dom';
import { login } from '../../stores/user/userActions';
import { requests } from '../../stores/user/userReducer';

const useStyles = makeStyles((theme) => ({
  grid: {
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
  },
}));

export const LoginContainer: React.FC<{ onSuccess: Function }> = ({
  onSuccess,
}) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const history = useHistory();
  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm<{ username: string; password: string }>();
  const loginRequest = useSelector(requests).login;
  const loading = loginRequest?.state === RetrievalState.Retrieving;
  const error =
    loginRequest?.state === RetrievalState.Errored ? loginRequest.error : null;

  const onSubmit = async (data: any) => {
    const { username, password } = data;
    dispatch(login.request({ username, password }));
  };

  useEffect(() => {
    if (loginRequest?.state === RetrievalState.Succeeded) {
      dispatch(login.cancel());
      history.push('/');
      onSuccess();
    }
  }, [dispatch, history, loginRequest, onSuccess]);

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      {error && (
        <Alert variant="filled" severity="error">
          {`Error authenticating. ${error.serviceError?.errorMessage}`}
        </Alert>
      )}
      <Controller
        control={control}
        render={({ field }) => (
          <TextField
            {...field}
            autoFocus
            margin="dense"
            id="username"
            label="Email Address"
            type="email"
            fullWidth
          />
        )}
        name="username"
        rules={{ required: true }}
        defaultValue=""
      />
      {errors.username && `This is required.`}

      <Controller
        control={control}
        render={({ field }) => (
          <TextField
            {...field}
            margin="dense"
            id="password"
            label="Password"
            type="password"
            fullWidth
          />
        )}
        name="password"
        defaultValue=""
      />

      <Grid
        container
        direction="row"
        justifyContent="space-between"
        alignItems="flex-start"
        className={classes.grid}
      >
        <Grid item xs={6}>
          <Button
            variant="contained"
            color="primary"
            disabled={loading}
            type="submit"
          >
            {loading ? <CircularProgress /> : 'Log in'}
          </Button>
        </Grid>
        <Grid item xs={6} style={{ textAlign: 'right' }}>
          <Link className="login-form-forgot" to="/forgot-password">
            Forgot password
          </Link>
        </Grid>
      </Grid>
    </form>
  );
};
