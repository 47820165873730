import {
  SportsRadarConversionsTotals,
  SportsRadarDefenseTotals,
  SportsRadarFieldGoalsTotals,
  SportsRadarFumblesTotals,
  SportsRadarGameStatsSummary,
  SportsRadarIntReturnsTotals,
  SportsRadarKickoffsTotals,
  SportsRadarKickReturnsTotals,
  SportsRadarKicksTotals,
  SportsRadarMiscReturnsTotals,
  SportsRadarPassingTotals,
  SportsRadarPenaltiesTotals,
  SportsRadarPuntReturnsTotals,
  SportsRadarPuntsTotals,
  SportsRadarReceivingTotals,
  SportsRadarRushingTotals,
} from '../API';
import { IScoringSetting } from './league';

export interface IPlayByPlayStats {
  /** Passing first downs */
  pass1d?: number;
  /** Pases >= 40 yards */
  pass40?: number;
  /** Pases >= 40 yards with touchdown */
  passTd40?: number;
  /** Negative point value for throwing an interception that is converted by the defense into a touchdown */
  passIntTd?: number;
  /** 40+ Yard rushes */
  rush40?: number;
  /** 40+ Yard Rushing with touchdown */
  rushTd40?: number;
  /** Rushing 1st Downs */
  rush1d?: number;
  /** Receiving 1st down */
  recv1d?: number;
  /** Receiving by yards: */
  recv0_4?: number;
  recv5_9?: number;
  recv10_19?: number;
  recv20_29?: number;
  recv30_39?: number;
  recv40p?: number;
  /** 40+ yard receiving td */
  recv40pTd?: number;
  /** reception by a running back */
  recvRB?: number;
  /** reception by a running back */
  recvWR?: number;
  /** reception by a tight-end */
  recvTE?: number;
}

export interface IPlayerScoringStats {
  opponent?: { points: number } & SportsRadarGameStatsSummary;
  playByPlay?: IPlayByPlayStats;
  rushing?: SportsRadarRushingTotals;
  receiving?: SportsRadarReceivingTotals;
  punts?: SportsRadarPuntsTotals;
  puntReturns?: SportsRadarPuntReturnsTotals;
  penalities?: SportsRadarPenaltiesTotals;
  passing?: SportsRadarPassingTotals;
  miscReturns?: SportsRadarMiscReturnsTotals;
  kickoffs?: SportsRadarKickoffsTotals;
  kickReturns?: SportsRadarKickReturnsTotals;
  intReturns?: SportsRadarIntReturnsTotals;
  fumbles?: SportsRadarFumblesTotals;
  fieldGoals?: SportsRadarFieldGoalsTotals;
  kicks?: SportsRadarKicksTotals;
  conversions?: SportsRadarConversionsTotals;
  defense?: SportsRadarDefenseTotals;
  extraPointsKicks?: SportsRadarKicksTotals;
  extraPointsConversions?: SportsRadarConversionsTotals;
}

/** Stored in the "playerScoring" table in dynamodb. Not expose in graphql. */
export interface IPlayerScoringInfo {
  /** Row ID, formed by hashing the game ID and player ID */
  id: string;
  /** Time the game was started as an ISO timestamp (date) */
  gameDate: string;
  /** Season (as a string, e.g. "2019") */
  season: string;
  /** Sports radar player ID. Indexed. */
  playerId: string;
  /**
   * Stats for each category in the game from SportsRadar. Will be updated
   * until the game completes.
   */
  stats: IPlayerScoringStats;
}

/** Stored in the "playerProjections" table in dynamodb. Not expose in graphql. */
export interface IPlayerScoringProjection {
  /** Row ID, formed by hashing the game ID and player ID */
  id: string;
  /** Time the game was started as an ISO timestamp (date) */
  gameDate: string;
  /** Sports radar player ID. Indexed. */
  playerId: string;
  /**
   * Stats for each category in the game from SportsRadar. Will be updated
   * until the game completes.
   */
  stats: IPlayerScoringStats;
}

export interface Scorable {
  stats: IPlayerScoringStats;
}

type ScoringLogicMap = {
  [key: string]: (setting: IScoringSetting, info: Scorable[]) => number;
};

export const calculateScore = (
  info: Scorable[],
  logic: ScoringLogicMap,
  settings: IScoringSetting[] = defaultScoringSettings,
) => {
  const categories: { key: string; name: string; value: number }[] = [];
  for (const setting of settings) {
    const value = logic[setting.key]?.(setting, info) || 0;
    if (value !== 0) {
      categories.push({
        key: setting.key,
        name: scoringLabels[setting.key],
        value,
      });
    }
  }

  return categories;
};

type ScoringFn = (setting: IScoringSetting, info: Scorable[]) => number;

type RequiredStats = Required<IPlayerScoringStats>;

const sumStat =
  <K1 extends keyof RequiredStats, K2 extends keyof RequiredStats[K1]>(
    k1: K1,
    k2: K2,
  ): ScoringFn =>
  ({ value }, infos) => {
    let counts = 0;
    for (const info of infos) {
      const category = info.stats[k1] as unknown as { [key: string]: number };
      if (category) {
        counts += category[k2 as string] || 0;
      }
    }

    return counts * value;
  };

const reduceStats =
  (
    reduce: (a: number, b: number) => number,
    ...stats: ScoringFn[]
  ): ScoringFn =>
  (settings, infos) => {
    let x = stats[0](settings, infos);
    for (let i = 1; i < stats.length; i++) {
      x = reduce(x, stats[i](settings, infos));
    }

    return x;
  };

const sumBonuses =
  (
    extract: (info: Scorable) => number | undefined,
    rangeMin: number,
    rangeMax = Infinity,
  ): ScoringFn =>
  (setting, info) => {
    let count = 0;
    for (const i of info) {
      const total = extract(i) || 0;
      if (total >= rangeMin && total < rangeMax) {
        count++;
      }
    }
    return count * setting.value;
  };

const getOpponentPoints = (i: Scorable) => i.stats.opponent?.points;
const getOpponentYards = (i: Scorable) => i.stats.opponent?.totalYards;
const getRushingYards = (i: Scorable) => i.stats.rushing?.yards;
const getRushingAndReceivingYards = (i: Scorable) =>
  (i.stats.rushing?.yards || 0) + (i.stats.receiving?.yards || 0);

const giveTypeToScoringLogic = <T extends ScoringLogicMap>(
  v: T,
): T & ScoringLogicMap => v;

export const playerScoringLogic = giveTypeToScoringLogic({
  //#region yahoo, see https://gist.github.com/connor4312/189f2fbf8835ddf723342adf120db296 for reference
  '5': sumStat('passing', 'touchdowns'),
  '6': sumStat('passing', 'interceptions'),
  '1': sumStat('passing', 'attempts'),
  '2': sumStat('passing', 'completions'),
  '3': reduceStats(
    (a, b) => a - b,
    sumStat('passing', 'attempts'),
    sumStat('passing', 'completions'),
  ),
  '7': sumStat('passing', 'sacks'),
  '4': sumStat('passing', 'yards'),
  '58': sumStat('intReturns', 'touchdowns'),
  '59': sumStat('playByPlay', 'pass40'),
  '60': sumStat('playByPlay', 'passTd40'),
  '79': sumStat('playByPlay', 'pass1d'),
  '10': sumStat('rushing', 'touchdowns'),
  '8': sumStat('rushing', 'attempts'),
  '9': sumStat('rushing', 'yards'),
  '61': sumStat('playByPlay', 'rush40'),
  '62': sumStat('playByPlay', 'rushTd40'),
  '81': sumStat('playByPlay', 'rush1d'),
  '13': sumStat('receiving', 'touchdowns'),
  '11': sumStat('receiving', 'receptions'),
  '12': sumStat('receiving', 'yards'),
  '63': sumStat('playByPlay', 'recv40p'),
  '64': sumStat('playByPlay', 'recv40pTd'),
  '80': sumStat('playByPlay', 'recv1d'),
  '15': reduceStats(
    (a, b) => a + b,
    sumStat('kickReturns', 'touchdowns'),
    sumStat('puntReturns', 'touchdowns'),
  ),
  '14': reduceStats(
    (a, b) => a + b,
    sumStat('kickReturns', 'yards'),
    sumStat('puntReturns', 'yards'),
  ),
  '16': reduceStats(
    (a, b) => a + b,
    sumStat('conversions', 'rushSuccesses'),
    sumStat('conversions', 'passSuccesses'),
  ),
  '17': sumStat('fumbles', 'fumbles'),
  '18': sumStat('fumbles', 'lostFumbles'),
  '57': sumStat('fumbles', 'oppRecTds'),
  '19': sumStat('fieldGoals', 'attempts19'),
  '20': sumStat('fieldGoals', 'attempts29'),
  '21': sumStat('fieldGoals', 'attempts39'),
  '22': sumStat('fieldGoals', 'attempts49'),
  '23': sumStat('fieldGoals', 'attempts50'),
  '24': reduceStats(
    (a, b) => a - b,
    sumStat('fieldGoals', 'attempts19'),
    sumStat('fieldGoals', 'made19'),
  ),
  '25': reduceStats(
    (a, b) => a - b,
    sumStat('fieldGoals', 'attempts29'),
    sumStat('fieldGoals', 'made29'),
  ),
  '26': reduceStats(
    (a, b) => a - b,
    sumStat('fieldGoals', 'attempts39'),
    sumStat('fieldGoals', 'made39'),
  ),
  '27': reduceStats(
    (a, b) => a - b,
    sumStat('fieldGoals', 'attempts49'),
    sumStat('fieldGoals', 'made49'),
  ),
  '28': reduceStats(
    (a, b) => a - b,
    sumStat('fieldGoals', 'attempts50'),
    sumStat('fieldGoals', 'made50'),
  ),
  '84': sumStat('fieldGoals', 'yards'),
  '29': sumStat('extraPointsKicks', 'made'),
  '30': sumStat('extraPointsKicks', 'missed'),
  // '77': Three and Outs Forced, not available on SR API
  //#endregion yahoo

  //#region sleeper, see https://gist.github.com/connor4312/5ddbc532a9e03b694a3e715387bac0d6
  pass_yd: sumStat('passing', 'yards'),
  pass_td: sumStat('passing', 'touchdowns'),
  pass_fd: sumStat('playByPlay', 'pass1d'),
  pass_int: sumStat('passing', 'interceptions'),
  pass_int_td: sumStat('playByPlay', 'passIntTd'),
  pass_cmp: sumStat('passing', 'completions'),
  pass_inc: reduceStats(
    (a, b) => a + b,
    sumStat('passing', 'attempts'),
    sumStat('passing', 'completions'),
  ),
  pass_att: sumStat('passing', 'attempts'),
  pass_sack: sumStat('passing', 'sacks'),
  pass_cmp_40p: sumStat('playByPlay', 'pass40'),
  pass_td_40p: sumStat('playByPlay', 'passTd40'),
  rush_yd: sumStat('rushing', 'yards'),
  rush_td: sumStat('rushing', 'touchdowns'),
  rush_fd: sumStat('playByPlay', 'rush1d'),
  rush_2pt: sumStat('conversions', 'rushSuccesses'),
  rush_att: sumStat('rushing', 'attempts'),
  rush_40p: sumStat('playByPlay', 'rush40'),
  rush_td_40p: sumStat('playByPlay', 'rushTd40'),
  rec: sumStat('receiving', 'receptions'),
  rec_yd: sumStat('receiving', 'yards'),
  rec_td: sumStat('receiving', 'touchdowns'),
  rec_fd: sumStat('playByPlay', 'recv1d'),
  rec_2pt: sumStat('conversions', 'passSuccesses'),
  rec_0_4: sumStat('playByPlay', 'recv0_4'),
  rec_5_9: sumStat('playByPlay', 'recv5_9'),
  rec_10_19: sumStat('playByPlay', 'recv10_19'),
  rec_20_29: sumStat('playByPlay', 'recv20_29'),
  rec_30_39: sumStat('playByPlay', 'recv30_39'),
  rec_40p: sumStat('playByPlay', 'recv40p'),
  rec_td_40p: sumStat('playByPlay', 'recv40pTd'),
  bonus_rec_rb: sumStat('playByPlay', 'recvRB'),
  bonus_rec_wr: sumStat('playByPlay', 'recvWR'),
  bonus_rec_te: sumStat('playByPlay', 'recvTE'),
  fgm: sumStat('fieldGoals', 'made'),
  fgm_0_19: sumStat('fieldGoals', 'made19'),
  fgm_20_29: sumStat('fieldGoals', 'made29'),
  fgm_30_39: sumStat('fieldGoals', 'made39'),
  fgm_40_49: sumStat('fieldGoals', 'made49'),
  fgm_50p: sumStat('fieldGoals', 'made50'),
  fgm_yds: sumStat('fieldGoals', 'yards'),
  fgm_yds_over_30: reduceStats(
    (a, b) => a + b,
    sumStat('fieldGoals', 'made39'),
    sumStat('fieldGoals', 'made49'),
    sumStat('fieldGoals', 'made50'),
  ),
  xpm: sumStat('extraPointsKicks', 'made'),
  xpmiss: sumStat('extraPointsKicks', 'missed'),
  fgmiss: sumStat('fieldGoals', 'missed'),
  fgmiss_0_19: reduceStats(
    (a, b) => a + b,
    sumStat('fieldGoals', 'attempts19'),
    sumStat('fieldGoals', 'made19'),
  ),
  fgmiss_20_29: reduceStats(
    (a, b) => a + b,
    sumStat('fieldGoals', 'attempts29'),
    sumStat('fieldGoals', 'made29'),
  ),
  fgmiss_30_39: reduceStats(
    (a, b) => a + b,
    sumStat('fieldGoals', 'attempts39'),
    sumStat('fieldGoals', 'made39'),
  ),
  fgmiss_40_49: reduceStats(
    (a, b) => a + b,
    sumStat('fieldGoals', 'attempts49'),
    sumStat('fieldGoals', 'made49'),
  ),
  fgmiss_50p: reduceStats(
    (a, b) => a + b,
    sumStat('fieldGoals', 'attempts50'),
    sumStat('fieldGoals', 'made50'),
  ),
  st_td: reduceStats(
    (a, b) => a + b,
    sumStat('puntReturns', 'touchdowns'),
    sumStat('kickReturns', 'touchdowns'),
    sumStat('miscReturns', 'touchdowns'),
  ),
  st_ff: sumStat('fumbles', 'forcedFumbles'),
  st_fum_rec: sumStat('fumbles', 'oppRec'),
  st_tkl_solo: sumStat('defense', 'spTackles'),
  pr_yd: sumStat('puntReturns', 'yards'),
  kr_yd: sumStat('kickReturns', 'yards'),
  fum: sumStat('fumbles', 'fumbles'),
  fum_lost: sumStat('fumbles', 'lostFumbles'),
  fum_rec_td: sumStat('fumbles', 'ownRecTds'),
  bonus_rush_yd_100: sumBonuses(getRushingYards, 100, 200),
  bonus_rush_yd_200: sumBonuses(getRushingYards, 200, Infinity),
  bonus_rush_rec_yd_100: sumBonuses(getRushingAndReceivingYards, 100, 200),
  bonus_rush_rec_yd_200: sumBonuses(getRushingAndReceivingYards, 200, Infinity),
  bonus_pass_cmp_25: sumBonuses((s) => s.stats.passing?.completions, 25),
  bonus_rush_att_20: sumBonuses((s) => s.stats.rushing?.attempts, 20),
  idp_def_td: reduceStats(
    (a, b) => a + b,
    sumStat('intReturns', 'touchdowns'),
    sumStat('kickReturns', 'touchdowns'),
    sumStat('miscReturns', 'touchdowns'),
    sumStat('puntReturns', 'touchdowns'),
  ),
  idp_sack: sumStat('defense', 'sacks'),
  idp_qb_hit: sumStat('defense', 'qbHits'),
  idp_tkl: sumStat('defense', 'tackles'),
  idp_tkl_loss: sumStat('defense', 'tloss'),
  idp_blk_kick: sumStat('defense', 'spBlocks'),
  idp_int: sumStat('defense', 'interceptions'),
  idp_int_ret_yd: sumStat('intReturns', 'yards'),
  idp_fum_rec: sumStat('defense', 'fumbleRecoveries'),
  idp_fum_ret_yd: sumStat('fumbles', 'oppRecYards'),
  idp_ff: sumStat('defense', 'forcedFumbles'),
  idp_safe: sumStat('defense', 'safeties'),
  idp_tkl_ast: sumStat('defense', 'assists'),
  idp_tkl_solo: sumStat('defense', 'tackles'),
  idp_pass_def: sumStat('defense', 'passesDefended'),
  //#endregion sleeper
});

export const defScoringLogic = giveTypeToScoringLogic({
  //#region yahoo, see https://gist.github.com/connor4312/189f2fbf8835ddf723342adf120db296 for reference
  '50': sumBonuses(getOpponentPoints, 0, 1),
  '51': sumBonuses(getOpponentPoints, 1, 7),
  '52': sumBonuses(getOpponentPoints, 7, 14),
  '53': sumBonuses(getOpponentPoints, 14, 21),
  '54': sumBonuses(getOpponentPoints, 21, 28),
  '55': sumBonuses(getOpponentPoints, 28, 35),
  '56': sumBonuses(getOpponentPoints, 35),
  '32': sumStat('defense', 'sacks'),
  '33': sumStat('defense', 'interceptions'),
  '34': sumStat('fumbles', 'ownRec'),
  // '35': reduceStats(
  //   (a, b) => a + b,
  //   sumStat('receiving', 'touchdowns'),
  //   sumStat('rushing', 'touchdowns'),
  //   sumStat('passing', 'touchdowns'),
  //   sumStat('intReturns', 'touchdowns'),
  //   sumStat('kickReturns', 'touchdowns'),
  //   sumStat('miscReturns', 'touchdowns'),
  //   sumStat('puntReturns', 'touchdowns'),
  // ),
  '36': sumStat('defense', 'safeties'),
  '37': sumStat('punts', 'blocked'),
  '48': reduceStats(
    (a, b) => a + b,
    sumStat('intReturns', 'yards'),
    sumStat('miscReturns', 'yards'),
    sumStat('kickReturns', 'yards'),
  ),
  '49': reduceStats(
    (a, b) => a + b,
    sumStat('intReturns', 'touchdowns'),
    sumStat('miscReturns', 'touchdowns'),
    sumStat('kickReturns', 'touchdowns'),
  ),
  // '67': 4th Down Stops, not available on SR API
  '68': sumStat('defense', 'tloss'),
  '69': sumStat('defense', 'tlossYards'),
  '71': sumBonuses(getOpponentYards, 0, 100),
  '72': sumBonuses(getOpponentYards, 100, 200),
  '73': sumBonuses(getOpponentYards, 200, 300),
  '74': sumBonuses(getOpponentYards, 300, 400),
  '75': sumBonuses(getOpponentYards, 400, 500),
  '76': sumBonuses(getOpponentYards, 500),
  //#endregion yahoo

  //#region sleeper, see https://gist.github.com/connor4312/5ddbc532a9e03b694a3e715387bac0d6
  def_td: reduceStats(
    (a, b) => a + b,
    sumStat('intReturns', 'touchdowns'),
    sumStat('fumbles', 'oppRecTds'),
  ),
  pts_allow_0: sumBonuses(getOpponentPoints, 0, 1),
  pts_allow_1_6: sumBonuses(getOpponentPoints, 1, 7),
  pts_allow_7_13: sumBonuses(getOpponentPoints, 7, 14),
  pts_allow_14_20: sumBonuses(getOpponentPoints, 14, 21),
  pts_allow_21_27: sumBonuses(getOpponentPoints, 21, 28),
  pts_allow_28_34: sumBonuses(getOpponentPoints, 28, 35),
  pts_allow_35p: sumBonuses(getOpponentPoints, 35),
  pts_allow: sumStat('opponent', 'points'),
  yds_allow_0_100: sumBonuses(getOpponentYards, 0, 100),
  yds_allow_100_199: sumBonuses(getOpponentYards, 100, 200),
  yds_allow_200_299: sumBonuses(getOpponentYards, 200, 300),
  yds_allow_300_349: sumBonuses(getOpponentYards, 300, 350),
  yds_allow_350_399: sumBonuses(getOpponentYards, 350, 400),
  yds_allow_400_449: sumBonuses(getOpponentYards, 400, 450),
  yds_allow_450_499: sumBonuses(getOpponentYards, 450, 500),
  yds_allow_500_549: sumBonuses(getOpponentYards, 500, 550),
  yds_allow_550p: sumBonuses(getOpponentYards, 550),
  yds_allow: sumStat('opponent', 'totalYards'),
  qb_hit: sumStat('defense', 'qbHits'),
  sack: sumStat('defense', 'sacks'),
  sack_yd: sumStat('defense', 'sackYards'),
  int: sumStat('defense', 'interceptions'),
  int_ret_yd: sumStat('intReturns', 'yards'),
  fum_rec: sumStat('defense', 'fumbleRecoveries'),
  fum_ret_yd: sumStat('fumbles', 'ownRecYards'),
  tkl_loss: sumStat('defense', 'tloss'),
  tkl_ast: sumStat('defense', 'assists'),
  tkl_solo: sumStat('defense', 'tackles'),
  tkl: sumStat('defense', 'tackles'),
  safe: sumStat('defense', 'safeties'),
  ff: sumStat('defense', 'forcedFumbles'),
  blk_kick: sumStat('kicks', 'blocked'),
  def_pass_def: sumStat('defense', 'passesDefended'),
  def_2pt: sumStat('conversions', 'turnoverSuccesses'),
  fg_ret_yd: sumStat('miscReturns', 'yards'),
  blk_kick_ret_yd: sumStat('miscReturns', 'blkFgTouchdowns'),
  def_st_td: sumStat('defense', 'spBlocks'),
  def_st_ff: sumStat('defense', 'spForcedFumbles'),
  def_st_fum_rec: sumStat('defense', 'spFumbleRecoveries'),
  def_st_tkl_solo: sumStat('defense', 'spTackles'),
  def_pr_yd: sumStat('puntReturns', 'yards'),
  def_kr_yd: sumStat('kickReturns', 'yards'),
  //#endregion sleeper
});

export const scoringLabels: {
  [key in keyof (typeof defScoringLogic & typeof playerScoringLogic)]: string;
} = {
  //#region yahoo
  '5': 'Passing Touchdowns',
  '6': 'Interceptions',
  '1': 'Passing Attempts',
  '2': 'Completions',
  '3': 'Incomplete Passes',
  '7': 'Sacks',
  '4': 'Passing Yards',
  '58': 'Pick Sixes Thrown',
  '59': '40+ Yard Completions',
  '60': '40+ Yard Passing Touchdowns',
  '79': 'Passing 1st Downs',
  '10': 'Rushing Touchdowns',
  '8': 'Rushing Attempts',
  '9': 'Rushing Yards',
  '61': '40+ Yard Run',
  '62': '40+ Yard Rushing Touchdowns',
  '81': 'Rushing 1st Downs',
  '13': 'Receiving Touchdowns',
  '11': 'Receptions',
  '12': 'Receiving Yards',
  '63': '40+ Yard Receptions',
  '64': '40+ Yard Receiving Touchdowns',
  '80': 'Receiving 1st Downs',
  '15': 'Return Touchdowns',
  '14': 'Return Yards',
  '16': '2-Point Conversions',
  '17': 'Fumbles',
  '18': 'Fumbles Lost',
  '57': 'Offensive Fumble Return TD',
  '19': 'Field Goals 0-19 Yards',
  '20': 'Field Goals 20-29 Yards',
  '21': 'Field Goals 30-39 Yards',
  '22': 'Field Goals 40-49 Yards',
  '23': 'Field Goals 50+ Yards',
  '24': 'Field Goals Missed 0-19 Yards',
  '25': 'Field Goals Missed 20-29 Yards',
  '26': 'Field Goals Missed 30-39 Yards',
  '27': 'Field Goals Missed 40-49 Yards',
  '28': 'Field Goals Missed 50+ Yards',
  '84': 'Field Goals Total Yards',
  '29': 'Point After Attempt Made',
  '30': 'Point After Attempt Missed',
  '50': 'Points Allowed 0 points',
  '51': 'Points Allowed 1-6 points',
  '52': 'Points Allowed 7-13 points',
  '53': 'Points Allowed 14-20 points',
  '54': 'Points Allowed 21-27 points',
  '55': 'Points Allowed 28-34 points',
  '56': 'Points Allowed 35+ points',
  '32': 'Sack',
  '33': 'Interception',
  '34': 'Fumble Recovery',
  '35': 'Touchdown',
  '36': 'Safety',
  '37': 'Block Kick',
  '48': 'Return Yards',
  '49': 'Kickoff and Punt Return Touchdowns',
  '67': '4th Down Stops',
  '68': 'Tackles for Loss',
  '69': 'Tackles for Loss Yards',
  '70': 'Defensive Yards Allowed - Negative',
  '71': 'Defensive Yards Allowed 0-99',
  '72': 'Defensive Yards Allowed 100-199',
  '73': 'Defensive Yards Allowed 200-299',
  '74': 'Defensive Yards Allowed 300-399',
  '75': 'Defensive Yards Allowed 400-499',
  '76': 'Defensive Yards Allowed 500+',
  '77': 'Three and Outs Forced',
  '82': 'Extra Point Returned',
  //#endregion

  pass_yd: 'Passing Yards',
  pass_td: 'Passing TD',
  pass_fd: 'Passing First Down',
  pass_2pt: '2-Pt Conversion',
  pass_int: 'Pass Intercepted',
  pass_int_td: 'Pick 6 Thrown',
  pass_cmp: 'Pass Completed',
  pass_inc: 'Incomplete Pass',
  pass_att: 'Pass Attempts',
  pass_sack: 'QB Sacked',
  pass_cmp_40p: '40+ Yard Completion Bonus',
  pass_td_40p: '40+ Yard Pass TD Bonus',
  rush_yd: 'Rushing Yards',
  rush_td: 'Rushing TD',
  rush_fd: 'Rushing First Down',
  rush_2pt: '2-Pt Conversion',
  rush_att: 'Rush Attempts',
  rush_40p: '40+ Yard Rush Bonus',
  rush_td_40p: '40+ Yard Rush TD Bonus',
  rec: 'Reception',
  rec_yd: 'Receiving Yards',
  rec_td: 'Receiving TD',
  rec_fd: 'Receiving First Down',
  rec_2pt: '2-Pt Conversion',
  rec_0_4: '0-4 Yard Reception Bonus',
  rec_5_9: '5-9 Yard Reception Bonus',
  rec_10_19: '10-19 Yard Reception Bonus',
  rec_20_29: '20-29 Yard Reception Bonus',
  rec_30_39: '30-39 Yard Reception Bonus',
  rec_40p: '40+ Yard Reception Bonus',
  rec_td_40p: '40+ Yard Reception TD Bonus',
  bonus_rec_rb: 'Reception Bonus - RB',
  bonus_rec_wr: 'Reception Bonus - WR',
  bonus_rec_te: 'Reception Bonus - TE',
  fgm: 'FG Made',
  fgm_0_19: 'FG Made (0-19 yards)',
  fgm_20_29: 'FG Made (20-29 yards)',
  fgm_30_39: 'FG Made (30-39 yards)',
  fgm_40_49: 'FG Made (40-49 yards)',
  fgm_50p: 'FG Made (50+ yards)',
  fgm_yds: 'Points per FG yard',
  fgm_yds_over_30: 'Points per FG yard over 30',
  xpm: 'PAT Made',
  fgmiss: 'FG Missed',
  fgmiss_0_19: 'FG Missed (0-19 yards)',
  fgmiss_20_29: 'FG Missed (20-29 yards)',
  fgmiss_30_39: 'FG Missed (30-39 yards)',
  fgmiss_40_49: 'FG Missed (40-49 yards)',
  fgmiss_50p: 'FG Missed (50+ yards)',
  xpmiss: 'PAT Missed',
  def_td: 'Defense TD',
  pts_allow_0: 'Points Allowed 0',
  pts_allow_1_6: 'Points Allowed 1-6',
  pts_allow_7_13: 'Points Allowed 7-13',
  pts_allow_14_20: 'Points Allowed 14-20',
  pts_allow_21_27: 'Points Allowed 21-27',
  pts_allow_28_34: 'Points Allowed 28-34',
  pts_allow_35p: 'Points Allowed 35+',
  pts_allow: 'Points Per Point Allowed',
  yds_allow_0_100: 'Less Than 100 Total Yards Allowed',
  yds_allow_100_199: '100-199 Total Yards Allowed',
  yds_allow_200_299: '200-299 Total Yards Allowed',
  yds_allow_300_349: '300-349 Total Yards Allowed',
  yds_allow_350_399: '350-399 Total Yards Allowed',
  yds_allow_400_449: '400-449 Total Yards Allowed',
  yds_allow_450_499: '450-499 Total Yards Allowed',
  yds_allow_500_549: '500-549 Total Yards Allowed',
  yds_allow_550p: '550+ Total Yards Allowed',
  yds_allow: 'Points Per Yard Allowed',
  qb_hit: 'Hit on QB',
  sack: 'Sacks',
  sack_yd: 'Sack Yards',
  int: 'Interceptions',
  int_ret_yd: 'INT Return Yards',
  fum_rec: 'Fumble Recovery',
  fum_ret_yd: 'Fumble Return Yards',
  tkl_loss: 'Tackle For Loss',
  tkl_ast: 'Assisted Tackle',
  tkl_solo: 'Solo Tackle',
  tkl: 'Tackle',
  safe: 'Safety',
  ff: 'Forced Fumble',
  blk_kick: 'Blocked Kick',
  def_pass_def: 'Pass Defended',
  def_2pt: '2-Pt Conversion Returns',
  fg_ret_yd: 'Missed FG Return Yards',
  blk_kick_ret_yd: 'Blocked Kick Return Yards',
  def_st_td: 'Special Teams TD',
  def_st_ff: 'Special Teams Forced Fumble',
  def_st_fum_rec: 'Special Teams Fumble Recovery',
  def_st_tkl_solo: 'Special Teams Solo Tackle',
  def_pr_yd: 'Punt Return Yards',
  def_kr_yd: 'Kick Return Yards',
  st_td: 'Special Teams Player TD',
  st_ff: 'Special Teams Player Forced Fumble',
  st_fum_rec: 'Special Teams Player Fumble Recovery',
  st_tkl_solo: 'Special Teams Player Solo Tackle',
  pr_yd: 'Player Punt Return Yards',
  kr_yd: 'Player Kick Return Yards',
  fum: 'Fumble',
  fum_lost: 'Fumble Lost',
  fum_rec_td: 'Fumble Recovery TD',
  bonus_rush_yd_100: '100-199 Yard Rushing Game',
  bonus_rush_yd_200: '200+ Yard Rushing Game',
  bonus_rec_yd_100: '100-199 Yard Receiving Game',
  bonus_rec_yd_200: '200+ Yard Receiving Game',
  bonus_pass_yd_300: '300-399 Yard Passing Game',
  bonus_pass_yd_400: '400+ Yard Passing Game',
  bonus_rush_rec_yd_100: '100-199 Combined Rush + Rec Yards',
  bonus_rush_rec_yd_200: '200+ Combined Rush + Rec Yards',
  bonus_pass_cmp_25: '25+ pass completions',
  bonus_rush_att_20: '20+ carries',
  idp_def_td: 'IDP TD',
  idp_sack: 'Sack',
  idp_qb_hit: 'Hit on QB',
  idp_tkl: 'Tackle',
  idp_tkl_loss: 'Tackle For Loss',
  idp_blk_kick: 'Blocked Punt, PAT or FG',
  idp_int: 'Interception',
  idp_int_ret_yd: 'Int Return Yards',
  idp_fum_rec: 'Fumble Recovery',
  idp_fum_ret_yd: 'Fumble Return Yards',
  idp_ff: 'Forced Fumble',
  idp_safe: 'Safety',
  idp_tkl_ast: 'Assisted Tackle',
  idp_tkl_solo: 'Solo Tackle',
  idp_pass_def: 'Pass Defended',
};

const defaultScoringSettings: IScoringSetting[] = [
  { value: 2, key: 'pass_2pt' },
  { value: -1, key: 'pass_int' },
  { value: -1, key: 'fgmiss' },
  { value: 0.10000000149011612, key: 'rec_yd' },
  { value: -1, key: 'xpmiss' },
  { value: 3, key: 'fgm_30_39' },
  { value: 2, key: 'blk_kick' },
  { value: 4, key: 'pts_allow_7_13' },
  { value: 1, key: 'ff' },
  { value: 3, key: 'fgm_20_29' },
  { value: 4, key: 'fgm_40_49' },
  { value: 7, key: 'pts_allow_1_6' },
  { value: 1, key: 'st_fum_rec' },
  { value: 1, key: 'def_st_ff' },
  { value: 1, key: 'st_ff' },
  { value: -1, key: 'pts_allow_28_34' },
  { value: 5, key: 'fgm_50p' },
  { value: 2, key: 'fum_rec' },
  { value: 6, key: 'def_td' },
  { value: 3, key: 'fgm_0_19' },
  { value: 2, key: 'int' },
  { value: 10, key: 'pts_allow_0' },
  { value: 0, key: 'pts_allow_21_27' },
  { value: 2, key: 'rec_2pt' },
  { value: 0.5, key: 'rec' },
  { value: 1, key: 'xpm' },
  { value: 6, key: 'st_td' },
  { value: 1, key: 'def_st_fum_rec' },
  { value: 6, key: 'def_st_td' },
  { value: 1, key: 'sack' },
  { value: 6, key: 'fum_rec_td' },
  { value: 2, key: 'rush_2pt' },
  { value: 6, key: 'rec_td' },
  { value: -4, key: 'pts_allow_35p' },
  { value: 1, key: 'pts_allow_14_20' },
  { value: 0.10000000149011612, key: 'rush_yd' },
  { value: 0.03999999910593033, key: 'pass_yd' },
  { value: 4, key: 'pass_td' },
  { value: 6, key: 'rush_td' },
  { value: -2, key: 'fum_lost' },
  { value: -1, key: 'fum' },
  { value: 2, key: 'safe' },
];
