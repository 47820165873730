import produce from 'immer';
import { getType } from 'typesafe-actions';
import { RootActions } from '../rootActions';
import { IRootState } from '../rootReducer';
import { updateType } from './dropdownActions';

export interface FilterState {
  name: any;
}

const initialState: FilterState = {
  name: 'all',
};

export const DropdownReducer = produce(
  (state: FilterState, action: RootActions) => {
    switch (action.type) {
      case getType(updateType):
        state.name = action.payload.name;
        return;
    }
  },
  initialState,
);

const select = (state: IRootState) => state.dropdown;

export const getTypeName = (state: IRootState) => {
  const filterType = select(state).name;
  return filterType;
};
