import { combineEpics } from 'redux-observable';
import { exhaustMap, filter } from 'rxjs/operators';
import { isActionOf } from 'typesafe-actions';
import { Epic } from '../rootEpics';
import { createBit } from './bitActions';

const createBitEpic: Epic = (action$, _, { bitApi: api }) =>
  action$.pipe(
    filter(isActionOf(createBit.request)),
    exhaustMap(
      async (action) =>
        await api
          .createBit(action.payload)
          .then((r) => createBit.success(r))
          .catch((err) => createBit.failure(err)),
    ),
  );

export const bitEpics = combineEpics(createBitEpic);
