import {
  error,
  idleRetrieval,
  Retrieval,
  RetrievalState,
  success,
  workingRetrival,
} from '@propsfantasy/retrieval';
import produce from 'immer';

import { getType } from 'typesafe-actions';
import { RootActions } from '../rootActions';
import { IRootState } from '../rootReducer';
import {
  getAllLeagues as getAllLeaguesAction,
  getAllRostersForLeague,
  getLeague,
} from './leaguesActions';

export interface ILeagueStatus {
  leagues: Retrieval<any>;
  league: Retrieval<any>;
}

const initialState = {
  leagues: idleRetrieval,
  league: idleRetrieval,
};

const updateRosterLeague = (
  state: any,
  leagueId: string,
  fn: (l: any) => void,
) => {
  if (state.leagues.state === RetrievalState.Succeeded) {
    const league = state.leagues.value.listLeagues.items.find(
      (l) => l.id === leagueId,
    );

    if (league) {
      fn(league);
    }
  }
};

export const leagueReducer = produce((state: any, action: RootActions) => {
  switch (action.type) {
    case getType(getAllLeaguesAction.request):
      state.leagues = workingRetrival;
      return;
    case getType(getAllLeaguesAction.success):
      state.leagues = success(action.payload);
      return;
    case getType(getAllLeaguesAction.failure):
      state.leagues = error(action.payload);
      return;
    case getType(getLeague.request):
      state.league = workingRetrival;
      return;
    case getType(getLeague.success):
      state.league = success(action.payload);
      return;
    case getType(getLeague.failure):
      state.league = error(action.payload);
      return;
    case getType(getAllRostersForLeague.request):
      updateRosterLeague(
        state,
        action.payload,
        (l) => (l.allRosters = workingRetrival),
      );
      return;
    case getType(getAllRostersForLeague.success):
      updateRosterLeague(
        state,
        action.payload.leagueId,
        (l) => (l.allRosters = success(action.payload.rosters)),
      );
      return;
    case getType(getAllRostersForLeague.failure):
      updateRosterLeague(
        state,
        action.payload.leagueId,
        (l) => (l.allRosters = error(action.payload.error)),
      );
      return;
    case getType(getAllRostersForLeague.cancel):
      updateRosterLeague(
        state,
        action.payload,
        (l) => (l.allRosters = idleRetrieval),
      );
      return;
  }
}, initialState);

const select = (state: IRootState) => state.leagues;

export const getAllLeagues = (state: IRootState) => select(state).leagues;

export const getLeagueById =
  (leagueId: string) =>
  (state: IRootState): Retrieval<any | undefined> => {
    const l = select(state).leagues;
    const result =
      l.state === RetrievalState.Succeeded
        ? success(
            l.value.listLeagues.items.find((league) => league.id === leagueId),
          )
        : l;

    return result;
  };

export const getLeagueSelector =
  (leagueId: string) =>
  (state: IRootState): Retrieval<any | undefined> =>
    select(state).league;
