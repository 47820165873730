import React from 'react';
import { NotifyItem } from '../../models/notifications';
import { NotificationCard } from './notificationCard';

export const NotifyCardContainer: React.FC<{
  notificationList: NotifyItem[];
  onToggle?(): void;
  dashboard: boolean;
}> = ({ notificationList, onToggle, dashboard }) => {
  return (
    <>
      {notificationList &&
        notificationList
          .sort(
            (a, b) =>
              new Date(
                b.data.MessageAttributes.currentDate.StringValue,
              ).getTime() -
              new Date(
                a.data.MessageAttributes.currentDate.StringValue,
              ).getTime(),
          )
          .map((listItem: NotifyItem) => (
            <NotificationCard
              notifyDetails={listItem}
              onToggle={onToggle}
              dashboard={dashboard}
            />
          ))}
    </>
  );
};
