import { UPDATE_TYPE } from './models/types';
import { ActionType, createAction } from 'typesafe-actions';

export const updateType = createAction(UPDATE_TYPE)<{ name: string }>();

const actions = {
  updateType,
};

export type DropdownActions = ActionType<typeof actions>;
