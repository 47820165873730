const unset = Symbol('unset');

export const once = <T, A extends unknown[]>(fn: (...args: A) => T) => {
  let value: T | typeof unset = unset;

  return {
    invoke: (...args: A): T => {
      if (value === unset) {
        value = fn(...args);
      }

      return value;
    },

    get value(): T | undefined {
      return value === unset ? undefined : value;
    },

    forget() {
      value = unset;
    },
  };
};

export const truthy = <T>(
  v: undefined | null | void | false | 0 | '' | T,
): v is T => !!v;

export const memoize = <A extends unknown[], T>(
  fn: (...args: A) => T,
  map: (...args: A) => unknown = (...a) => a[0],
): ((...args: A) => T) => {
  const fns = new Map<unknown, T>();
  return (...args) => {
    const key = map(...args);
    if (fns.has(key)) {
      return fns.get(key) as T;
    }

    const value = fn(...args);
    fns.set(key, value);
    return value;
  };
};

export const round = (value: number, prescion: number = 2) => {
  return (
    (Math.round(Number((Math.abs(value) * 100).toPrecision(prescion))) / 100) *
    Math.sign(value)
  );
};

export const objectsEqual = (o1: any, o2: any): any =>
  typeof o1 === 'object' && Object.keys(o1).length > 0
    ? Object.keys(o1).length === Object.keys(o2).length &&
      Object.keys(o1).every((p: any) => objectsEqual(o1[p], o2[p]))
    : o1 === o2;

export const arraysEqual = (a1: any[], a2: any[]) =>
  a1.length === a2.length && a1.every((o, idx) => objectsEqual(o, a2[idx]));
