import { Storage } from 'aws-amplify';
import { NotifyItem } from '../../models/notifications';
import { isDev, isTestingEnvironment } from '../../utils/serverConfig';
import { PropsFantasyAuthApi } from './auth';

const isTestingEnv = isTestingEnvironment();
const s3BucketHost = !isTestingEnv
  ? 'props-fantasy-assets222004-main'
  : isDev()
  ? 'props-fantasy-assets115446-beta'
  : 'props-fantasy-assets115446-beta';

//Fetch call to s3 bucket by userid for Notifications. Return undefined if empty
export const fetchNotificationsByUserId = async (userId: string) => {
  Storage.configure({
    Storage: {
      AWSS3: {
        bucket: s3BucketHost,
        region: 'us-west-2',
      },
      customPrefix: {
        public: `notifications/${userId}/`,
      },
    },
  });

  const notificationList: NotifyItem[] = [];
  Storage.list('')
    .then((result) => {
      result.forEach((item: any) => {
        const then = new Date(item.lastModified);
        const now = new Date();
        const msBetweenDates = Math.abs(then.getTime() - now.getTime());
        const hoursBetweenDates = msBetweenDates / (60 * 60 * 1000);
        if (hoursBetweenDates < 24) {
          return getSpecificObjectByKey(item.key);
        }
      });
    })
    .catch((err) => {
      console.log(err, 'Error grabbing notifications');
    });

  //Use given key to grab specific objects in the folder
  const getSpecificObjectByKey = (key: string) => {
    Storage.get(key).then((response: any) => {
      fetch(response)
        .then((dataInfo) => dataInfo.json())
        .then((data) => {
          if (data?.MessageAttributes?.currentDate?.StringValue) {
            //Check if notification is older than 24 hours
            const then = new Date(
              data.MessageAttributes.currentDate.StringValue,
            );
            const now = new Date();
            const msBetweenDates = Math.abs(then.getTime() - now.getTime());
            const hoursBetweenDates = msBetweenDates / (60 * 60 * 1000);
            if (hoursBetweenDates < 24) {
              notificationList.push({
                data: data,
                key: key,
              });
            }
          }
        })
        .catch((err) => 'Error fetching notifications');
    });
  };

  return notificationList;
};

export const updateNotificationAsRead = async (notifyData: NotifyItem) => {
  const authApi = new PropsFantasyAuthApi();

  if (notifyData.data.notificationRead === 'false') {
    const userInfo = await authApi.getUser();

    Storage.configure({
      Storage: {
        AWSS3: {
          bucket: s3BucketHost,
          region: 'us-west-2',
        },
        customPrefix: {
          public: `notifications/${userInfo.attributes.sub}/`,
        },
      },
    });

    const dataToStore = {
      MessageAttributes: notifyData.data.MessageAttributes,
      messageBody: notifyData.data.messageBody,
      notificationRead: 'true',
    };

    Storage.put(notifyData.key, dataToStore).catch((err) =>
      console.log(err, err.stack),
    );
  }
};

//TO DO** MOVE TO API IN BACKEND
// export const sendGeolocationNotification = async () => {
//   const duplicationId = uuidv4();
//   const userInfo = await Auth.currentUserInfo();
//   const currentDate = new Date(Date.now()).toLocaleString();
//   const notificationParams = {
//     MessageAttributes: {
//       currentDate: {
//         DataType: 'String',
//         StringValue: currentDate,
//       },
//       notificationReceiver: {
//         DataType: 'String',
//         StringValue: userInfo.attributes.sub,
//       },
//       ctaButtonText: {
//         DataType: 'String',
//         StringValue: 'View Profile',
//       },
//       backPlateColor: {
//         DataType: 'String',
//         StringValue: '#000000',
//       },
//     },
//     MessageBody:
//       'We need your location. Without it you will not be able to particiapte in paid challenges',
//     MessageGroupId: 'GENERAL-1', //Group message by type and list number
//     MessageDeduplicationId: `${duplicationId}`,
//     QueueUrl: queueArnToUrl(
//       sqs,
//       'arn:aws:sqs:us-west-2:627314824204:notificationsQueue',
//     ),
//   };

//   await sqs
//     .sendMessage(notificationParams, function (err, data) {
//       if (err) {
//         console.log('Error sending geolocation notification', err);
//       } else {
//         console.log('Success sending geolocation notification', data.MessageId);
//       }
//     })
//     .promise();
// };
