import { Avatar, Grid, makeStyles, Typography } from '@material-ui/core';
import React, { useCallback } from 'react';
import { IChallenger, IUserTransactions } from '../../all-models';
import challengeIcon from '../../assets/ui-icon-challenge-003-copy@3x.png';
import { formatCurrency } from '../currency/moneyFormatter';

const teamsYouPlayedStyles = makeStyles((theme) => ({
  section: {
    backgroundColor: 'white',
    padding: theme.spacing(2.5),
    borderRadius: theme.spacing(2),
    marginTop: theme.spacing(2.5),
    minHeight: '685px',
    [theme.breakpoints.down('sm')]: {
      padding: theme.spacing(2, 1.5),
      minHeight: '100%',
    },
  },
  viewLeaderboardButton: {
    color: '#05c500',
    fontSize: '16px',
    fontWeight: 'bold',
    fontStretch: 'condensed',
    lineHeight: 1.25,
    textAlign: 'center',
    marginTop: theme.spacing(3.5),
    marginBottom: theme.spacing(0.5),
  },
  tableTitleContainer: {
    backgroundColor: '#e4e4e4',
    borderRadius: '6px',
    height: '30px',
    padding: theme.spacing(0, 2),
    marginTop: theme.spacing(5),
    [theme.breakpoints.down('sm')]: {
      padding: theme.spacing(0, 1),
      marginTop: theme.spacing(3.5),
    },
  },

  contentRow: {
    padding: theme.spacing(2.5),
    borderBottom: '1px solid lightgray',
    '&:last-of-type': {
      borderBottom: 'none',
    },
    [theme.breakpoints.down('sm')]: {
      padding: theme.spacing(2, 1),
    },
  },

  resultsContainer: {
    borderRight: '1px solid #EDEDED',
  },

  labelsCopy: {
    fontWeight: 'bold',
    color: '#7C7C7C',
  },
  smScreenFont: {
    '@media (max-width: 360px)': {
      fontSize: '14px',
    },
  },
  challengerNameIcon: {
    [theme.breakpoints.down('sm')]: {
      display: 'none',
    },
  },
  prizeContentContainer: {
    paddingLeft: theme.spacing(4),
  },
  prizeIcon: {
    width: '24px',
    height: '24px',
    marginRight: theme.spacing(1.2),
  },
  prizeMoney: {
    fontWeight: 'bold',
    lineHeight: 1.25,
  },
  wlt: {
    '@media (max-width: 360px)': {
      fontSize: '14px',
    },
  },
  wltDash: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    [theme.breakpoints.down('sm')]: {
      marginRight: theme.spacing(0.5),
      marginLeft: theme.spacing(0.5),
    },
  },
  contentGreyDivider: {
    width: '1px',
    height: '32px',
    backgroundColor: '#ededed',
    marginRight: theme.spacing(3),
    marginLeft: theme.spacing(3),
    [theme.breakpoints.down('sm')]: {
      marginRight: theme.spacing(4),
      marginLeft: theme.spacing(4),
    },
    [theme.breakpoints.down('xs')]: {
      marginRight: theme.spacing(3),
      marginLeft: theme.spacing(3),
    },
  },
  contentResultsWin: {
    color: '#0cae08',
    fontWeight: 'bold',
    '@media (max-width: 360px)': {
      fontSize: '14px',
    },
  },

  contentResultsLoss: {
    color: '#ed0000',
    fontWeight: 'bold',
    '@media (max-width: 360px)': {
      fontSize: '14px',
    },
  },
  contentResultsDollars: {
    marginLeft: theme.spacing(0.75),
    '@media (max-width: 360px)': {
      fontSize: '14px',
    },
  },
  challengeIcon: {
    width: '24px',
    height: '24px',
    cursor: 'pointer',

    [theme.breakpoints.down('sm')]: {
      width: '20px',
      height: '20px',
    },
  },
}));

const TeamsYouPlayed: React.FC<{
  transactions: IUserTransactions[];
  challengersPlayedAgainst: IChallenger[] | undefined;
  createOneClickChallengeChallenge: Function;
}> = ({
  transactions,
  createOneClickChallengeChallenge,
  challengersPlayedAgainst,
}) => {
  const classes = teamsYouPlayedStyles();
  const sendRosterForOneClickChallenge = useCallback((id: string) => {
    let roster = challengersPlayedAgainst?.filter(
      (r) => r.challengerId === id || r.id === id,
    );

    createOneClickChallengeChallenge(roster);
  }, []);

  return (
    <Grid
      container
      xs={12}
      className={classes.section}
      direction="column"
      justifyContent="flex-start"
    >
      <Grid container item direction="row" alignItems="center" xs={12}>
        <Grid item xs={12}>
          <Typography variant="h5">Teams You Played</Typography>
        </Grid>
        <Grid
          container
          item
          className={classes.tableTitleContainer}
          alignItems="center"
          justifyContent="space-between"
          xs={12}
        >
          <Grid item xs={2} md={2}>
            <Typography variant="caption" className={classes.labelsCopy}>
              POS
            </Typography>
          </Grid>
          <Grid item xs={4} md={4}>
            <Typography variant="caption" className={classes.labelsCopy}>
              Challenger
            </Typography>
          </Grid>

          <Grid item xs={2} md={3} container justifyContent="center">
            <Typography variant="caption" className={classes.labelsCopy}>
              W&nbsp;&nbsp; - &nbsp;&nbsp;L
            </Typography>
          </Grid>
          <Grid item container justifyContent="center" xs={3} md={2}>
            <Typography variant="caption" className={classes.labelsCopy}>
              Results
            </Typography>
          </Grid>
          <Grid item xs={1} md={1}>
            &nbsp;
          </Grid>
        </Grid>
      </Grid>

      <Grid container xs={12}>
        {transactions
          ?.sort((a, b) => a.wins + a.losses + (b.wins + b.losses))
          ?.slice(0, 6)
          .map((team: IUserTransactions, index: number) => (
            <Grid
              container
              className={classes.contentRow}
              alignItems="center"
              justifyContent="space-between"
              xs={12}
              wrap="nowrap"
            >
              <Grid item xs={2} md={1}>
                <Typography
                  variant="subtitle2"
                  className={classes.smScreenFont}
                >
                  {index + 1}
                </Typography>
              </Grid>
              <Grid
                container
                item
                xs={4}
                md={5}
                justifyContent="flex-start"
                alignItems="center"
                spacing={1}
              >
                <Grid item className={classes.challengerNameIcon}>
                  <Avatar src={'broken'} alt={`${team.name}`} />
                </Grid>
                <Grid item>
                  <Typography
                    variant="subtitle2"
                    className={classes.smScreenFont}
                  >
                    {team.name}
                  </Typography>
                </Grid>
              </Grid>

              <Grid
                item
                container
                xs={3}
                md={3}
                justifyContent="center"
                alignItems="center"
              >
                <Grid item>
                  <Typography
                    variant="subtitle2"
                    className={classes.smScreenFont}
                  >
                    {team.wins ? team.wins : '0'}
                  </Typography>
                </Grid>
                <Grid item>
                  <Typography variant="subtitle2" className={classes.wltDash}>
                    -
                  </Typography>
                </Grid>
                <Grid item>
                  <Typography
                    variant="subtitle2"
                    className={classes.smScreenFont}
                  >
                    {team.losses ? team.losses : '0'}
                  </Typography>
                </Grid>
              </Grid>

              <Grid
                container
                item
                xs={3}
                md={2}
                direction="row"
                alignItems="center"
                justifyContent="center"
                className={classes.resultsContainer}
              >
                <Grid item>
                  <Typography
                    variant="subtitle2"
                    className={
                      team.transactions > 0
                        ? classes.contentResultsWin
                        : classes.contentResultsLoss
                    }
                  >
                    {team.transactions > 0 ? '+' : '-'}
                  </Typography>
                </Grid>
                <Grid item>
                  <Typography
                    variant="subtitle2"
                    className={classes.contentResultsDollars}
                  >
                    {formatCurrency(team.transactions)}
                  </Typography>
                </Grid>
              </Grid>

              <Grid item container justifyContent="flex-end" xs={1} md={1}>
                <Avatar
                  src={challengeIcon}
                  className={classes.challengeIcon}
                  onClick={() =>
                    sendRosterForOneClickChallenge(team.challengerId)
                  }
                />
              </Grid>
            </Grid>
          ))}
      </Grid>
    </Grid>
  );
};

export default TeamsYouPlayed;
