import { idleRetrieval } from '@propsfantasy/retrieval';
import { ILeague } from '../../all-models';
import { SEASON_STATUS } from '../../utils/constants';
import { getCurrentSeason } from '../../utils/season';
import { IUserLeagues, RosterWithOwner } from '../user/userReducer';
import { PropsFantasyApi } from './base';

const currentSeason = getCurrentSeason();

export class PropsFantasyLeagueApi extends PropsFantasyApi {
  public async getCurrentUserLeagues() {
    const userInfo = await this.getUser();

    const getRosters = async (data: any = [], continuation?: string) => {
      try {
        const axios = (await this.getAxios()) || this.axiosInstance;
        const rosters: any = await axios.get(
          `/rosters/users/${userInfo.attributes.sub}/`,
        );

        return Promise.resolve(rosters.rosterByUserId.items);
      } catch (err) {
        return console.error(err);
      }
    };

    return getRosters().then((data) => {
      const leagues: { [key: string]: IUserLeagues } = {};

      for (const roster of data) {
        if (
          (roster.league.season === currentSeason.toString() ||
            roster.league.season === (currentSeason + 1).toString()) &&
          (roster.league.status === SEASON_STATUS ||
            roster.league.status === 'post_season' ||
            roster.league.status === 'pre_season')
        ) {
          const existing = leagues[roster.leagueId];

          if (existing) {
            existing.ownRosters.push(roster);
          } else {
            leagues[roster.leagueId] = {
              ownRosters: [roster],
              league: roster.league,
              allRosters: idleRetrieval,
            };
          }
        }
      }

      return leagues;
    });
  }

  public async getAllRostersForLeague(
    leagueId: string,
  ): Promise<RosterWithOwner[]> {
    const getRosters: any = async (data: any = [], continuation?: string) => {
      try {
        const axios = (await this.getAxios()) || this.axiosInstance;
        const rosters: any = await axios.get(`/rosters/leagues/${leagueId}/`);

        return Promise.resolve(rosters.rosterByLeagueId.items);
      } catch (err) {
        return console.error(err);
      }
    };

    return getRosters().then((data: any) => {
      return data;
    });
  }

  public async getAllLeagues(): Promise<any> {
    const getAllLeagues: any = async () => {
      try {
        const axios = (await this.getAxios()) || this.axiosInstance;

        const leagues: any = await axios.get(
          `/league/current-season?currentSeason=${currentSeason}`,
        );

        return Promise.resolve({
          listLeagues: { items: leagues.listLeagues.items },
        });
      } catch (err) {
        return console.error(err);
      }
    };

    return getAllLeagues().then((data: any) => {
      return data;
    });
  }

  public async getLeagueById(leagueId: string): Promise<any> {
    const axios = (await this.getAxios()) || this.axiosInstance;
    const leagueById: { getLeague: ILeague } = await axios.get(
      `/league/${leagueId}`,
    );

    return leagueById.getLeague;
  }
}
