import API, { GRAPHQL_AUTH_MODE, GraphQLResult } from '@aws-amplify/api';
import { IError } from '@propsfantasy/retrieval';
import { Auth, graphqlOperation } from 'aws-amplify';
import axios from 'axios';

export class PropsFantasyApi {
  apiName = 'api171a9e50';
  userInfo: any = undefined;

  public async getUser() {
    try {
      if (this.userInfo && Object.keys(this.userInfo).length > 0) {
        return Promise.resolve(this.userInfo);
      } else {
        this.userInfo = await Auth.currentUserInfo();
        return Promise.resolve(this.userInfo);
      }
    } catch (err) {
      console.error('auth check error', err);
    }
  }

  jsonInterceptor = [
    (response: any) => response?.data || response,
    (error: any) => Promise.reject(error),
  ];

  axiosInstance = axios.create({
    baseURL: !process.env.REACT_APP_ENV!
      ? 'http://localhost:4000'
      : process.env.REACT_APP_ENV! === 'staging'
      ? 'https://api-staging.flexfantasy.app'
      : 'https://api.flexfantasy.app',
  });

  async getAxios() {
    try {
      const user = await this.getUser();

      if (user) {
        const res = await Auth.currentSession();

        let accessToken = res?.getAccessToken();
        let jwt = accessToken?.getJwtToken();
        this.axiosInstance.defaults.headers.common[
          'Authorization'
        ] = `Bearer ${jwt}`;
      }

      this.axiosInstance.interceptors.response.use(...this.jsonInterceptor);
      this.axiosInstance.defaults.headers.common['Content-Type'] =
        'application/json';

      return this.axiosInstance;
    } catch (err) {
      console.log('Error fetching data', err);
      throw this.handleAPIError(err);
    }
  }

  protected async graphql<T = unknown>(
    ...args: Parameters<typeof graphqlOperation>
  ) {
    let result: GraphQLResult<T>;
    try {
      const user = await this.getUser();

      if (!user) {
        result = (await (API.graphql({
          ...graphqlOperation(...args),
          authMode: GRAPHQL_AUTH_MODE.API_KEY,
        }) as unknown)) as GraphQLResult<T>;
      } else {
        result = (await (API.graphql(
          graphqlOperation(...args),
        ) as unknown)) as GraphQLResult<T>;
      }
    } catch (err: any) {
      if (err.errors instanceof Array) {
        result = err;
      } else {
        throw this.handleAPIError(err);
      }
    }

    if (result.errors?.length) {
      throw gqlErrorToStandardError(result.errors[0]);
    }

    return result.data as T;
  }

  handleAPIError(err: any): never {
    const e: IError = {
      statusCode: 0,
      serviceError: {
        errorCode: err.code,
        errorMessage: err.message,
      },
    };
    throw e;
  }
}

export const gqlErrorToStandardError = (errors: any[]): IError => {
  if (typeof errors === 'object') {
    errors = [errors];
  }
  return {
    statusCode: 0,
    serviceError: {
      errorCode: errors[0] ? errors[0].errorType : -1,
      path: errors[0] ? errors[0].path : '',
      errorMessage: errors.map
        ? errors.map((e) => e.message).join('\n')
        : typeof errors,
    },
  };
};
