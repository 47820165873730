import { Avatar, Grid, Typography, makeStyles } from '@material-ui/core';
import React, { useCallback, useRef } from 'react';
import { useHistory } from 'react-router';
import challengeIcon from '../../assets/challenge-icon.svg';
import closeIcon from '../../assets/white-close.svg';

const modalStyles = makeStyles((theme) => ({
  overlay: {
    position: 'fixed',
    zIndex: 10,
    top: 0,
    left: 0,
    bottom: 0,
    right: 0,
    height: '100vh',
    width: '100vw',
    background: 'rgba(0, 0, 0, 0.8)',
  },
  closeContainer: {
    position: 'absolute',
    top: '5%',
    right: '5%',
    cursor: 'pointer',
    [theme.breakpoints.down('sm')]: {
      top: '3%',
    },
  },
  closeIcon: {
    width: '30px',
    height: '30px',
    [theme.breakpoints.down('sm')]: {
      width: '18px',
      height: '18px',
    },
  },
  container: {
    width: '90%',
    maxWidth: '1080px',
    height: 'auto',
    maxHeight: '700px',
    backgroundColor: 'white',
    borderRadius: '16px',
    padding: theme.spacing(8, 0, 4),
    textAlign: 'center',
    [theme.breakpoints.down('sm')]: {
      padding: theme.spacing(4, 0),
    },
  },
  oneClickChallenge: {
    fontSize: '80px',
    letterSpacing: '5px',
    fontFamily: 'Roboto',
    fontWeight: 100,
    padding: theme.spacing(1, 0),
    [theme.breakpoints.down('sm')]: {
      padding: theme.spacing(2, 0),
      fontSize: '36px',
      letterSpacing: '2.6px',
      lineHeight: '42px',
    },
  },
  copyContainer: {
    color: theme.palette.primary.dark,
    maxWidth: '75%',
    margin: '0 auto',
    '& > div:nth-of-type(3)': {
      '& h6': {
        padding: theme.spacing(3, 0, 10),
      },
    },
    [theme.breakpoints.down('sm')]: {
      maxWidth: '95%',
      '& > div': {
        '& h6': {
          fontSize: '16px',
        },
      },
    },
  },
  ctaContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '240px',
    height: '45px',
    backgroundColor: theme.palette.secondary.light,
    borderRadius: '24px',
    color: '#ffffff',
    fontWeight: 700,
    fontSize: '16px',
    letterSpacing: '.6667px',
    textTransform: 'uppercase',
    cursor: 'pointer',
  },
  challengeIcon: {
    width: '142px',
    height: '142px',
    [theme.breakpoints.down('sm')]: {
      width: '80px',
      height: '80px',
    },
  },
}));

export const OneClickChallengeModal: React.FC<{
  setShowOneClickChallengeModal: Function;
  closeOneClickChallengeModal: Function;
}> = ({ setShowOneClickChallengeModal, closeOneClickChallengeModal }) => {
  const modalRef = useRef();
  const classes = modalStyles();
  const history = useHistory();

  const setDefaults = useCallback(() => {
    history.push(`/profile/one-click`);
    setShowOneClickChallengeModal(false);
  }, [history]);

  const closeModal = (e) => {
    if (e.target === modalRef.current) {
      setShowOneClickChallengeModal(false);
    }
  };

  return (
    <Grid
      container
      className={classes.overlay}
      direction="column"
      alignItems="center"
      justifyContent="center"
    >
      <Grid item className={classes.closeContainer}>
        <Avatar
          src={closeIcon}
          className={classes.closeIcon}
          onClick={() => closeOneClickChallengeModal()}
        />
      </Grid>
      <Grid
        container
        item
        className={classes.container}
        direction="column"
        alignContent="center"
      >
        <Grid item container justifyContent="center">
          <Avatar src={challengeIcon} className={classes.challengeIcon} />
        </Grid>
        <Grid item className={classes.copyContainer}>
          <Grid item>
            <Typography className={classes.oneClickChallenge}>
              1-Click Challenge
            </Typography>
          </Grid>
          <Grid item>
            <Typography variant="h6">
              Create a challenge instantly against anyone with 1-click
              Challenges.
            </Typography>
          </Grid>
          <Grid item>
            <Typography variant="h6">
              Set default options that will be used every time you create a
              “1-click challenge”. You can customize these options at the
              confirmation screen or change the defauls in your profile
              settings.
            </Typography>
          </Grid>
        </Grid>
        <Grid item container justifyContent="center">
          <div className={classes.ctaContainer} onClick={setDefaults}>
            SET YOUR DEFAULTS
          </div>
        </Grid>
      </Grid>
    </Grid>
  );
};
