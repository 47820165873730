import { combineEpics, Epic as RawEpic } from 'redux-observable';
import {
  PropsFantasyAuthApi,
  PropsFantasyBitApi,
  PropsFantasyChallengeApi,
  PropsFantasyLeagueApi,
  PropsFantasyMoneyApi,
  PropsFantasyProviderApi,
  PropsFantasyRosterApi,
  XPoint,
} from './api';
import { PropsFantasyScheduleApi } from './api/schedule';
import { bitEpics } from './bit/bitEpics';
import { challengeEpics } from './challenge/challengeEpics';
import { leaguesEpics } from './league/leagueEpics';
import { moneyEpics } from './money/moneyEpics';
import { RootActions } from './rootActions';
import { IRootState } from './rootReducer';
import { rosterEpics } from './roster/rosterEpics';
import { scheduleEpics } from './schedule/scheduleEpics';
import { userEpics } from './user/userEpics';

export const rootEpics: Epic = combineEpics(
  challengeEpics,
  leaguesEpics,
  userEpics,
  moneyEpics,
  rosterEpics,
  bitEpics,
  scheduleEpics,
);

export interface IServices {
  challengeApi: PropsFantasyChallengeApi;
  providerApi: PropsFantasyProviderApi;
  authApi: PropsFantasyAuthApi;
  moneyApi: PropsFantasyMoneyApi;
  leagueApi: PropsFantasyLeagueApi;
  rosterApi: PropsFantasyRosterApi;
  scheduleApi: PropsFantasyScheduleApi;
  xpoint: XPoint;
  bitApi: PropsFantasyBitApi;
}

export type Epic = RawEpic<RootActions, RootActions, IRootState, IServices>;
