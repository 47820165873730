import { convertStringToDate } from '../stores/api/schedule';
import {
  BEGDATE_TO_ENDDATE,
  ENDDATE_TO_LOCKDATE,
} from '../stores/schedule/scheduleReducer';

export const enterChallengeCutoff = (date: string) => {
  const currentDate = new Date();

  for (let [key, value] of BEGDATE_TO_ENDDATE.entries()) {
    let endDateStringToDate = convertStringToDate(value);
    const begDateStringToDate = convertStringToDate(key);
    const dateStringToDate = convertStringToDate(date);

    if (
      dateStringToDate >= begDateStringToDate &&
      dateStringToDate <= endDateStringToDate
    ) {
      if (currentDate <= endDateStringToDate) {
        return 'ENTRY';
      } else {
        return 'NO-ENTRY';
      }
    }
  }
};

export const challengeLocksDate = (date: string) => {
  for (let [key, value] of ENDDATE_TO_LOCKDATE.entries()) {
    const weekLockDate = convertStringToDate(value);

    if (key === date) {
      return weekLockDate.toLocaleString();
    }
  }
};
