import { combineEpics } from 'redux-observable';
import { from, of } from 'rxjs';
import {
  catchError,
  exhaustMap,
  filter,
  mergeMap,
  switchMap,
  takeUntil,
} from 'rxjs/operators';
import { isActionOf } from 'typesafe-actions';
import { Epic } from '../rootEpics';
import {
  applyPromocode,
  createAddFundLink,
  createWithdrawFundLink,
  getWallet,
  updatePrimaryUpo,
} from './moneyActions';

const getWalletEpic: Epic = (action$, _, { moneyApi: api }) =>
  action$.pipe(
    filter(isActionOf(getWallet.request)),
    mergeMap(() =>
      from(api.getWallet()).pipe(
        mergeMap((r) => of(getWallet.success(r))),
        catchError((err) => of(getWallet.failure(err))),
        takeUntil(action$.pipe(filter(isActionOf(getWallet.cancel)))),
      ),
    ),
  );

const createAddFundLinkEpic: Epic = (action$, _, { moneyApi: api }) =>
  action$.pipe(
    filter(isActionOf(createAddFundLink.request)),
    switchMap((action) =>
      from(api.createAddFundLink(action.payload.addFunds)).pipe(
        mergeMap((link) => of(createAddFundLink.success(link))),
        catchError((err) => of(createAddFundLink.failure(err))),
        takeUntil(action$.pipe(filter(isActionOf(createAddFundLink.cancel)))),
      ),
    ),
  );

const createWithdrawFundLinkEpic: Epic = (action$, _, { moneyApi: api }) =>
  action$.pipe(
    filter(isActionOf(createWithdrawFundLink.request)),
    exhaustMap((action) =>
      from(api.createWithdrawFundLink(action.payload.withdrawFunds)).pipe(
        mergeMap((link) => of(createWithdrawFundLink.success(link))),
        catchError((err) => of(createWithdrawFundLink.failure(err))),
        takeUntil(
          action$.pipe(filter(isActionOf(createWithdrawFundLink.cancel))),
        ),
      ),
    ),
  );

const updatePrimaryUpoEpic: Epic = (action$, _, { moneyApi: api }) =>
  action$.pipe(
    filter(isActionOf(updatePrimaryUpo.request)),
    mergeMap((action) =>
      from(api.updateUpo(action.payload.queryString)).pipe(
        mergeMap((user) => of(updatePrimaryUpo.success(user))),
        catchError((err) => of(updatePrimaryUpo.failure(err))),
        takeUntil(action$.pipe(filter(isActionOf(updatePrimaryUpo.cancel)))),
      ),
    ),
  );

const applyPromocodeEpic: Epic = (action$, _, { moneyApi: api }) =>
  action$.pipe(
    filter(isActionOf(applyPromocode.request)),
    mergeMap((action) =>
      from(api.applyPromocode(action.payload.promocode)).pipe(
        mergeMap((user) => of(applyPromocode.success(user))),
        catchError((err) => of(applyPromocode.failure(err))),
        takeUntil(action$.pipe(filter(isActionOf(applyPromocode.cancel)))),
      ),
    ),
  );
export const moneyEpics = combineEpics(
  getWalletEpic,
  createAddFundLinkEpic,
  createWithdrawFundLinkEpic,
  updatePrimaryUpoEpic,
  applyPromocodeEpic,
);
