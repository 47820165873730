import { PlayerPosition } from '../models/player';

export enum ChallengeType {
  head2head = 'Head 2 Head',
  bestBall = 'Best Ball',
  leagueChampion = 'League Champion',
  leagueLoser = 'League Loser',
}

export const SEASON_STATUS = 'in_season';
export const AUTH_USER_TOKEN_KEY = 'PF_UUID';
export const SERVICE_FEE_PERCENTAGE = 0.1; // IN DECIMAL FORMAT
export const DEFAULT_ALERT_AUTO_HIDE_DURATION = 6000;
export const POSITION_COLOR_MAP: ReadonlyMap<PlayerPosition, string> = new Map([
  [PlayerPosition.QB, '#f6a634'],
  [PlayerPosition.WR, '#ad7bff'],
  [PlayerPosition.RB, '#75a3db'],
  [PlayerPosition.TE, '#4ecdb3'],
  [PlayerPosition.DEF, '#5c4fbb'],
  [PlayerPosition.LB, '#43839d'],
  [PlayerPosition.K, '#ff7b7b'],
  [PlayerPosition.DL, '#6bcf8d'],
  [PlayerPosition.DE, '#3089ba'],
  [PlayerPosition.DB, '#dda400'],
  [PlayerPosition.SS, '#dda400'],
  [PlayerPosition.CB, '#dda400'],
]);

export const REFERRER_QUERY_PARAM = 'rid';

export const FALLBACK_POSITION_COLOR = '#dda400';
export const SUPER_FLEX_COLOR = '#d35066';

export const orderedPositions: PlayerPosition[] = [
  PlayerPosition.QB,
  PlayerPosition.WR,
  PlayerPosition.RB,
  PlayerPosition.TE,
  PlayerPosition.DEF,
  PlayerPosition.LB,
  PlayerPosition.K,
  PlayerPosition.DL,
  PlayerPosition.DE,
  PlayerPosition.DB,
  PlayerPosition.SS,
  PlayerPosition.DT,
  PlayerPosition.CB,
];

let s = 0;
export const positionsSortOrder: { [K in PlayerPosition]: number } = {
  [PlayerPosition.QB]: s++,
  [PlayerPosition.WR]: s++,
  [PlayerPosition.RB]: s++,
  [PlayerPosition.TE]: s++,
  [PlayerPosition.DEF]: s++,
  [PlayerPosition.LB]: s++,
  [PlayerPosition.K]: s++,
  [PlayerPosition.DL]: s++,
  [PlayerPosition.DE]: s++,
  [PlayerPosition.DB]: s++,
  [PlayerPosition.SS]: s++,
  [PlayerPosition.DT]: s++,
  [PlayerPosition.CB]: s++,
  [PlayerPosition.LS]: s++,
  [PlayerPosition.OG]: s++,
  [PlayerPosition.OL]: s++,
  [PlayerPosition.OT]: s++,
  [PlayerPosition.P]: s++,
};

export const sortPositions = (arr: PlayerPosition[]) =>
  arr.sort((a, b) => positionsSortOrder[a] - positionsSortOrder[b]);

export const challengeDuration = new Map<string, string | string[]>([
  [ChallengeType.head2head, 'Weekly'],
]);
