import { SvgIcon } from '@material-ui/core';
import React from 'react';

type SvgProps = React.ComponentProps<typeof SvgIcon>;

export const TwitterIcon: React.FC<SvgProps> = ({ ...props }) => {
  return (
    <SvgIcon {...props} viewBox="0 0 32 32">
      <g fill="none" fill-rule="evenodd">
        <rect fill="#E94057" width="32" height="32" rx="16" />
        <path
          d="M23.423 12.984c.01.16.01.319.01.479C23.434 18.357 19.582 24 12.538 24v-.003a11.114 11.114 0 0 1-5.87-1.66 7.854 7.854 0 0 0 5.668-1.535c-1.639-.03-3.077-1.064-3.578-2.572a3.95 3.95 0 0 0 1.729-.064c-1.787-.349-3.072-1.867-3.073-3.63v-.047a3.9 3.9 0 0 0 1.739.464c-1.683-1.087-2.203-3.252-1.186-4.944 1.945 2.314 4.814 3.72 7.893 3.87a3.63 3.63 0 0 1 1.108-3.54c1.542-1.402 3.968-1.33 5.419.162a7.839 7.839 0 0 0 2.431-.9 3.741 3.741 0 0 1-1.683 2.05c.759-.088 1.5-.284 2.2-.584a7.654 7.654 0 0 1-1.911 1.917"
          fill="#FFF"
        />
      </g>
    </SvgIcon>
  );
};
