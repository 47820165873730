import { Grid, makeStyles } from '@material-ui/core';
import React from 'react';

const usePageStyles = makeStyles((theme) => ({
  banner: {
    background: theme.palette.primary.main,
    color: 'white',
    textAlign: 'center',
    borderRadius: theme.spacing(2),
    alignContent: 'center',
    flexDirection: 'column',

    padding: theme.spacing(2, 35, 2, 35),
    [theme.breakpoints.down('md')]: {
      padding: theme.spacing(2, 20, 2, 20),
    },
    [theme.breakpoints.down('sm')]: {
      padding: theme.spacing(2, 10, 2, 10),
    },
    [theme.breakpoints.down('xs')]: {
      padding: theme.spacing(2, 2, 2, 2),
    },
  },
  title: {
    marginBottom: 0,
  },
  text: {
    fontSize: '16px',
  },
}));

const EndOfSeasonBanner: React.FC = () => {
  const classes = usePageStyles();
  // const theme = useTheme();

  return (
    <Grid container xs={12} className={classes.banner}>
      <h2 className={classes.title}>Thank you for a great 2023 season!</h2>
      <p className={classes.text}>
        Your support made this all possible. We hope you had fun. Our team is
        huddling up to build new games, new integrations, and more amazing new
        experiences for 2024. Stay tuned for more.
      </p>
    </Grid>
  );
};

export default EndOfSeasonBanner;
