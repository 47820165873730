import {
  IChallenge,
  IChallenger,
  ITransaction,
  IUserPrivate,
} from '../all-models';

export const calculateTransactionsByChallenger = async (
  challengers: IChallenger[],
  challenges: IChallenge[],
  userTransactions: ITransaction[],
  currentUser: IUserPrivate,
) => {
  const transactions: any = [];
  const noTransactions: any = [];

  if (userTransactions.length > 0) {
    userTransactions.forEach((t) => {
      const challengeid = t?.description?.split(/[, ]+/).pop();

      const challenger = challengers?.find(
        (c) => c.challengeId === challengeid,
      );

      const challenge = challenges?.find((c) => c.id === challengeid);

      if (challenger && challenge) {
        const transactionType = t?.description
          ?.split(' ')
          .slice(0, 1)
          .join(' ');

        if (
          transactionType === 'Entry' ||
          transactionType === 'Winnings' ||
          transactionType === 'Return'
        ) {
          transactions.push({
            id: challenger?.id,
            rosterId: challenger.rosterId,
            name: challenger?.name,
            transactions: t.amount,
            challengeId: challenge.id,
            type: transactionType,
          });
        }
      }
    });
  } else {
    //For cases when user has no transaction data/free challenges only
    challengers.forEach((challenger) => {
      let winsFreeChallenges = 0;
      let lossesFreechallenges = 0;

      const getChallenge = challenges.find(
        (challenge) => challenge.id === challenger.challengeId,
      );

      if (getChallenge) {
        if (getChallenge.participants.items.length >= 2) {
          //Check to see if valid participation
          const challengerWinnerId = getChallenge.winner;

          const getCurrentUserParticipation =
            getChallenge.participants.items.find(
              (p) => p.userId === currentUser.value.sub,
            );

          //Check to make sure there are backers for both challengers
          const checkBackers = getChallenge.participants.items.find(
            (p) => p.challengerId !== getCurrentUserParticipation?.challengerId,
          );

          if (checkBackers) {
            getCurrentUserParticipation?.challengerId === challengerWinnerId
              ? (winsFreeChallenges = 1)
              : (lossesFreechallenges = 1);

            noTransactions.push({
              challengeId: challenger.challengeId,
              challengerId: challenger.id,
              losses: lossesFreechallenges,
              wins: winsFreeChallenges,
              transactions: 0,
              name: challenger.name,
            });
          } else {
            noTransactions.push({
              challengeId: challenger.challengeId,
              challengerId: challenger.id,
              losses: 0,
              wins: 0,
              transactions: 0,
              name: challenger.name,
            });
          }
        }
      }
    });

    return noTransactions;
  }

  //Combine transaction with same challengeId
  const mergeTransactionsByChallengeId = transactions.reduce((acc, curr) => {
    if (acc[curr.challengeId]) {
      acc[curr.challengeId].push(curr);
    } else {
      acc[curr.challengeId] = [curr];
    }
    return acc;
  }, {});

  //Calculate the wins loss per team played per challenge and accumulate total
  let transactionsWithWL: any = [];

  const calculateWinsLosses = Object.values(
    mergeTransactionsByChallengeId,
  ).forEach((trans) => {
    let sortByTransType = trans.sort((a, b) => a.type.localeCompare(b.type));
    let winsTotal = 0;
    let lossesTotal = 0;

    // A loss would only have one transaction per challenge 'Entry fee' .
    // Second transaction will either be a 'Return' or a 'Winnings'. Returns are not considered a win or a loss
    if (!sortByTransType[1]) {
      lossesTotal = lossesTotal + 1;
    } else if (sortByTransType[1].type === 'Winnings') {
      winsTotal = winsTotal + 1;
    }

    let newTransactionMap = trans.reduce(function (map: any, e: any) {
      map['challengeId'] = e.challengeId;
      map['transactions'] = +e.transactions + (map['transactions'] || 0);
      map['rosterId'] = e.rosterId;
      map['wins'] = winsTotal;
      map['losses'] = lossesTotal;
      map['name'] = e.name;
      map['challengerId'] = e.id;
      return map;
    }, {});

    transactionsWithWL.push(newTransactionMap);
  });

  //Combine teams played with same rosterID and total wins/losses/transactions
  let combinedTeamsPlayed = transactionsWithWL.reduce((b, a) => {
    let index = b.findIndex((arr) => arr.rosterId === a.rosterId);
    delete a.id;
    if (index > -1) {
      b[index].wins += a.wins;
      b[index].losses += a.losses;
      b[index].transactions += a.transactions;
    } else b.push(a);
    return b;
  }, []);

  //For free challenges with no transactions

  let addInFreeChallenges = challengers.forEach((challenger) => {
    //Check to see if challenger is already in the map by the challenge id
    const isChallengerInMap = transactionsWithWL.some(
      (t) => t.challengeId === challenger.challengeId,
    );

    if (!isChallengerInMap) {
      //we need to determine wins/losses for free challenges
      let winsFreeChallenges = 0;
      let lossesFreechallenges = 0;

      const getChallenge = challenges.find(
        (challenge) => challenge.id === challenger.challengeId,
      );

      if (getChallenge) {
        if (getChallenge.participants.items.length >= 2) {
          // Check for valid participants
          const challengerWinnerId = getChallenge.winner;

          const getCurrentUserParticipation =
            getChallenge.participants.items.find(
              (p) => p.userId === currentUser.value.sub,
            );

          //Check to make sure there are backers for both challengers
          const checkBackers = getChallenge.participants.items.find(
            (p) => p.challengerId !== getCurrentUserParticipation?.challengerId,
          );

          if (checkBackers) {
            getCurrentUserParticipation?.challengerId === challengerWinnerId
              ? (winsFreeChallenges = 1)
              : (lossesFreechallenges = 1);

            combinedTeamsPlayed.push({
              challengeId: challenger.challengeId,
              challengerId: challenger.id,
              rosterId: challenger.rosterId,
              losses: lossesFreechallenges,
              wins: winsFreeChallenges,
              transactions: 0,
              name: challenger.name,
            });
          } else {
            combinedTeamsPlayed.push({
              challengeId: challenger.challengeId,
              challengerId: challenger.id,
              rosterId: challenger.rosterId,
              losses: 0,
              wins: 0,
              transactions: 0,
              name: challenger.name,
            });
          }
        }
      }
    }
  });

  //One last final combination including the free challenges
  let finalCombinedByRosterID = combinedTeamsPlayed.reduce((b, a) => {
    let index = b.findIndex((arr) => arr.rosterId === a.rosterId);
    delete a.id;
    if (index > -1) {
      b[index].wins += a.wins;
      b[index].losses += a.losses;
      b[index].transactions += a.transactions;
    } else b.push(a);
    return b;
  }, []);

  //Sort out by transactions and then wins/loss
  let filterOutTransAndSort = finalCombinedByRosterID
    .sort((teamA, teamB) => {
      if (teamA.wins + teamA.losses === 0) {
        return 1;
      }
      if (teamB.wins + teamB.losses === 0) {
        return -1;
      }

      return (
        (teamB.wins + 1) / (teamB.wins + teamB.losses + 1) -
        (teamA.wins + 1) / (teamA.wins + teamA.losses + 1)
      );
    })
    .sort((a, b) => (a.wins === b.wins ? 0 : a.wins > b.wins ? -1 : 1));
  // .sort((a, b) => b.transactions - a.transactions);

  return filterOutTransAndSort;
};
