export const footballQuotes = [
  {
    quote: '“Winning isn’t everything, but wanting to win is.”',
    author: '- Vince Lombardi',
  },
  {
    quote: '“It’s hard to beat a person who never gives up.” ',
    author: '- Babe Ruth',
  },
  {
    quote: '“Champions keep playing until they get it right.”',
    author: '- Billie Jean King',
  },
  {
    quote: '“You miss 100% of the shots you dont take”',
    author: '- Wayne Gretzky',
  },
];

export const getRandomQuote = () => {
  return footballQuotes[Math.floor(Math.random() * footballQuotes.length)];
};
