import { AddFundsRequest, WithdrawFundsRequest } from '../../API';
import { ITransaction } from '../../models/transaction';
import { IWallet } from '../../models/wallet';
import { PropsFantasyApi } from './base';

export class PropsFantasyMoneyApi extends PropsFantasyApi {
  public async getWallet(): Promise<IWallet> {
    const axios = (await this.getAxios()) || this.axiosInstance;
    const current = await this.getUser();
    const res: {
      getUser: {
        bits: number;
        balance: number;
        identityVerification: { succeeded: boolean };
        creditBalance: number;
      };
    } = await axios.get(`/users/${current.attributes.sub}`);

    return {
      bits: res.getUser?.bits || 0,
      funds: res.getUser?.balance || 0,
      verified: !!res.getUser?.identityVerification?.succeeded,
      creditBalance: res.getUser?.creditBalance || 0,
    };
  }

  public async createAddFundLink(addFunds: AddFundsRequest): Promise<string> {
    const axios = (await this.getAxios()) || this.axiosInstance;
    const res: { addFundsStart: string } = await axios.post('/user/add-funds', {
      addFunds,
    });

    return res.addFundsStart;
  }
  public async updateUpo(queryString: String): Promise<string> {
    const axios = (await this.getAxios()) || this.axiosInstance;
    const res: { updateUpo: string } = await axios.post('/update-upo', {
      queryString,
    });

    return res.updateUpo;
  }
  public async applyPromocode(promocode: String): Promise<String> {
    const axios = (await this.getAxios()) || this.axiosInstance;
    const res: { applyPromocode: string } = await axios.post(
      '/apply-promocode',
      {
        promocode,
      },
    );

    return res.applyPromocode;
  }
  public async createWithdrawFundLink(
    withdrawFunds: WithdrawFundsRequest,
  ): Promise<string> {
    const axios = (await this.getAxios()) || this.axiosInstance;

    const res: { withdrawFundsStart: string } = await axios.post(
      '/withdrawlFund',
      {
        withdrawFunds,
      },
    );

    return res.withdrawFundsStart;
  }

  public async getAllTransactions(): Promise<ITransaction[]> {
    const userInfo = await this.getUser();

    const getTransactions = async (data: any = [], continuation?: string) => {
      try {
        const axios = (await this.getAxios()) || this.axiosInstance;
        const transactionData = [...data];
        const rosters: any = await axios.get(
          `/transactions/${
            userInfo.attributes.sub
          }?nextToken=${continuation}&limit=${500}`,
        );

        const { nextToken } = rosters.transactionsByUserId;
        let allTransactions = transactionData.concat(
          rosters.transactionsByUserId.items,
        );

        if (nextToken !== null) {
          return new Promise((resolve) =>
            resolve(getTransactions(allTransactions, nextToken)),
          );
        } else {
          return Promise.resolve(allTransactions);
        }
      } catch (err) {
        return console.error(err);
      }
    };

    return getTransactions().then((data) => {
      return data;
    });
  }
}
