import { CreateBitInput } from '../../API';
import { PropsFantasyApi } from './base';

export class PropsFantasyBitApi extends PropsFantasyApi {
  public async createBit(body: CreateBitInput) {
    const axios = (await this.getAxios()) || this.axiosInstance;

    const newBit: { createBit: { id: string } } = await axios.post('/bits', {
      userId: body.userId,
      quantity: body.quantity,
      type: body.type,
      referenceId: body.referenceId,
      description: body.description,
    });

    return newBit.createBit.id;
  }
}
