/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-west-2",
    "aws_cloud_logic_custom": [
        {
            "name": "footballScheduleAPI",
            "endpoint": "https://e9ty82xcea.execute-api.us-west-2.amazonaws.com/main",
            "region": "us-west-2"
        },
        {
            "name": "fundsCallback",
            "endpoint": "https://77b0jfze67.execute-api.us-west-2.amazonaws.com/main",
            "region": "us-west-2"
        },
        {
            "name": "users",
            "endpoint": "https://t14rss7ro7.execute-api.us-west-2.amazonaws.com/main",
            "region": "us-west-2"
        },
        {
            "name": "withdrawlCallback",
            "endpoint": "https://irjsm4m739.execute-api.us-west-2.amazonaws.com/main",
            "region": "us-west-2"
        }
    ],
    "aws_appsync_graphqlEndpoint": "https://uq5d6oxkizalbeisz3vf24brua.appsync-api.us-west-2.amazonaws.com/graphql",
    "aws_appsync_region": "us-west-2",
    "aws_appsync_authenticationType": "AMAZON_COGNITO_USER_POOLS",
    "aws_appsync_apiKey": "da2-zkjektysmrg6zgvhgzdzmchxaa",
    "aws_cognito_identity_pool_id": "us-west-2:98ebee1f-49ba-470a-a897-4c13b8ee7498",
    "aws_cognito_region": "us-west-2",
    "aws_user_pools_id": "us-west-2_V6mQY2lWr",
    "aws_user_pools_web_client_id": "7h5po20ou1bhukkcbvorv37qic",
    "oauth": {},
    "aws_cognito_username_attributes": [
        "EMAIL",
        "PHONE_NUMBER"
    ],
    "aws_cognito_social_providers": [],
    "aws_cognito_signup_attributes": [
        "EMAIL"
    ],
    "aws_cognito_mfa_configuration": "OFF",
    "aws_cognito_mfa_types": [
        "SMS"
    ],
    "aws_cognito_password_protection_settings": {
        "passwordPolicyMinLength": 8,
        "passwordPolicyCharacters": []
    },
    "aws_cognito_verification_mechanisms": [
        "EMAIL"
    ],
    "aws_user_files_s3_bucket": "props-fantasy-assets222004-main",
    "aws_user_files_s3_bucket_region": "us-west-2",
    "aws_dynamodb_all_tables_region": "us-west-2",
    "aws_dynamodb_table_schemas": [
        {
            "tableName": "footballSchedule-main",
            "region": "us-west-2"
        },
        {
            "tableName": "playerProjections-main",
            "region": "us-west-2"
        },
        {
            "tableName": "playerScoring-main",
            "region": "us-west-2"
        },
        {
            "tableName": "sportradarEventSchedules-main",
            "region": "us-west-2"
        },
        {
            "tableName": "transactions-main",
            "region": "us-west-2"
        }
    ]
};


export default awsmobile;
