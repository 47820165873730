import { green, red } from '@material-ui/core/colors';
import { createTheme } from '@material-ui/core/styles';

// A custom theme for this app
const theme = createTheme({
  palette: {
    text: {
      primary: '#252525', // dark grey
      secondary: '#919191', // light grey
    },
    primary: {
      main: '#892886', // purple color
      light: '#919191', // light grey
      dark: '#585858', // latest dark grey color
    },
    secondary: {
      main: '#e94057', // pink color
      light: '#F27123', // our tertiary orange (mui doesn't think we need more than two colors...:$)
      dark: ' #F30000', //New dark red color
    },
    error: {
      main: red.A400, // deep red color
    },
    success: {
      main: green[400], // lighter green color
      light: '#05c500', // bright lime green color
    },
    background: {
      default: '#f2f2f2', // light grey red
    },
    divider: '#e4e4e4', //lightest grey
  },
  typography: {
    fontWeightRegular: 400,
    fontFamily: 'Roboto Condensed, Arial',
    h2: {
      fontFamily: 'Roboto Condensed, Arial',
      fontWeight: 'bold',
      letterSpacing: '2.67px',
    },
    h3: {
      fontSize: '42px',
      fontFamily: 'Roboto Condensed, Arial',
      fontWeight: 'bold',
      letterSpacing: '1.75px',
    },
    h4: {
      fontSize: '32px',
      fontFamily: 'Roboto Condensed, Arial',
      fontWeight: 'bold',
      letterSpacing: '1.33px',
    },
    h5: {
      fontFamily: 'Roboto Condensed, Arial',
      fontWeight: 'bold',
      fontSize: '24px',
      letterSpacing: '1px',
    },
    h6: {
      fontFamily: 'Roboto Condensed, Arial',
      letterSpacing: '0.83px',
      fontSize: '20px',
    },
    subtitle1: {
      fontFamily: 'Roboto Condensed, Arial',
      fontWeight: 'bold',
      letterSpacing: '0.75px',
      fontSize: '18px',
    },
    subtitle2: {
      fontFamily: 'Roboto Condensed, Arial',
      letterSpacing: 'normal',
      fontSize: '16px',
    },
    caption: {
      fontFamily: 'Roboto Condensed, Arial',
      color: '#919191',
      fontSize: '14px',
    },
  },
  overrides: {
    MuiInputBase: {
      input: {
        fontFamily: 'Roboto Condensed, Arial',
        fontSize: 16,
        letterSpacing: 1,
      },
    },
    MuiFormHelperText: {
      root: {
        fontSize: '12px',
        fontFamily: 'Roboto Condensed, Arial',
        fontWeight: 'bold',
        letterSpacing: '0.58px',
        color: '#919191',
      },
    },
  },
  props: {
    MuiCard: {
      elevation: 0,
    },
    MuiButton: {
      disableElevation: true,
    },
  },
});

export default theme;
