import { ITimestampedGraphQLDynamoRecord } from './dynamo';

export enum PlayerPosition {
  DB = 'DB',
  DEF = 'DEF',
  DL = 'DL',
  K = 'K',
  LB = 'LB',
  LS = 'LS',
  OG = 'OG',
  OL = 'OL',
  OT = 'OT',
  P = 'P',
  QB = 'QB',
  RB = 'RB',
  TE = 'TE',
  WR = 'WR',
  SS = 'SS',
  CB = 'CB',
  DT = 'DT',
  DE = 'DE',
}

export const allPositions = Object.values(PlayerPosition);

/** Map of positions to bits that can be used in a bitmap. */
export const { positionBits, allPositionBits } = (() => {
  const positionBits: { [key in PlayerPosition]?: bigint } = {};
  let allPositionBits = 0n;
  for (let i = 0; i < allPositions.length; i++) {
    const p = 1n << BigInt(i);
    positionBits[allPositions[i]] = p;
    allPositionBits |= p;
  }

  return {
    positionBits: positionBits as { [key in PlayerPosition]: bigint },
    allPositionBits,
  };
})();

/** Converts the positions to a bitmap */
export const positionsToBits = (positions: readonly PlayerPosition[]) => {
  let asBits = 0n;
  for (const position of positions) {
    if (!positionBits[position]) break;
    asBits |= positionBits[position]!;
  }
  return asBits;
};

/** Converts a bitmap from `positionsToBits` back to a list of positions */
export const bitsToPositions = (bits: bigint) => {
  const positions: PlayerPosition[] = [];
  let n = 1n;
  for (let i = 0; i < allPositions.length; i++) {
    if (bits & n) {
      positions.push(allPositions[i]);
    }
    n <<= 1n;
  }

  return positions;
};

export interface IStoredPlayer extends ITimestampedGraphQLDynamoRecord {
  id: string;
  active: boolean;
  espnId?: number;
  fantasyDataId?: number;
  gsisId?: string;
  rotowireId?: number;
  rotoworldId?: number;
  yahooId?: number;
  primaryPosition?: PlayerPosition;
  positions: PlayerPosition[];
  firstName: string;
  lastName: string;
  playerNumber?: number;
  teamName?: string;
  sport: string;
  sportRadarId?: string;
}
