import {
  error,
  idleRetrieval,
  Retrieval,
  success,
  workingRetrival,
} from '@propsfantasy/retrieval';
import produce from 'immer';
import { getType } from 'typesafe-actions';
import { IWallet } from '../../all-models';
import { RootActions } from '../rootActions';
import { IRootState } from '../rootReducer';
import {
  applyPromocode,
  createAddFundLink,
  createWithdrawFundLink,
  getWallet as getWalletAction,
  updatePrimaryUpo,
} from './moneyActions';

export interface IMoneyState {
  wallet: Retrieval<IWallet>;
  addFundLink: Retrieval<string>;
  withdrawFundLink: Retrieval<string>;
  primaryUpo: Retrieval<string>;
  applyPromcode: Retrieval<string>;
}

const initialState: IMoneyState = {
  wallet: idleRetrieval,
  addFundLink: idleRetrieval,
  withdrawFundLink: idleRetrieval,
  primaryUpo: idleRetrieval,
  applyPromcode: idleRetrieval,
};

export const moneyReducer = produce(
  (state: IMoneyState, action: RootActions) => {
    switch (action.type) {
      case getType(getWalletAction.request):
        state.wallet = workingRetrival;
        return;
      case getType(getWalletAction.success):
        state.wallet = success(action.payload);
        return;
      case getType(getWalletAction.failure):
        state.wallet = error(action.payload);
        return;
      case getType(getWalletAction.cancel):
        state.wallet = idleRetrieval;
        return;

      case getType(createAddFundLink.request):
        state.addFundLink = workingRetrival;
        return;
      case getType(createAddFundLink.success):
        state.addFundLink = success(action.payload);
        return;
      case getType(createAddFundLink.failure):
        state.addFundLink = error(action.payload);
        return;
      case getType(createAddFundLink.cancel):
        state.addFundLink = idleRetrieval;
        return;

      case getType(createWithdrawFundLink.request):
        state.withdrawFundLink = workingRetrival;
        return;
      case getType(createWithdrawFundLink.success):
        state.withdrawFundLink = success(action.payload);
        return;
      case getType(createWithdrawFundLink.failure):
        state.withdrawFundLink = error(action.payload);
        return;
      case getType(createWithdrawFundLink.cancel):
        state.withdrawFundLink = idleRetrieval;
        return;

      case getType(updatePrimaryUpo.request):
        state.primaryUpo = workingRetrival;
        return;
      case getType(updatePrimaryUpo.success):
        state.primaryUpo = success(action.payload);
        return;
      case getType(updatePrimaryUpo.failure):
        state.primaryUpo = error(action.payload);
        return;
      case getType(updatePrimaryUpo.cancel):
        state.primaryUpo = idleRetrieval;
        return;

      case getType(applyPromocode.request):
        state.applyPromcode = workingRetrival;
        return;
      case getType(applyPromocode.success):
        state.applyPromcode = success(action.payload);
        return;
      case getType(applyPromocode.failure):
        state.applyPromcode = error(action.payload);
        return;
      case getType(applyPromocode.cancel):
        state.applyPromcode = idleRetrieval;
        return;
    }
  },
  initialState,
);

const select = (state: IRootState) => state.money;

export const getWallet = (state: IRootState) => select(state).wallet;
export const getAddFundLink = (state: IRootState) => select(state).addFundLink;
export const getWithdrawFundsLink = (state: IRootState) =>
  select(state).withdrawFundLink;
export const getPrimaryUpo = (state: IRootState) => select(state).primaryUpo;
export const getPromocode = (state: IRootState) => select(state).applyPromcode;
