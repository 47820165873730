import {
  error,
  idleRetrieval,
  Retrieval,
  RetrievalState,
  success,
  workingRetrival,
} from '@propsfantasy/retrieval';
import produce from 'immer';
import { createSelector } from 'reselect';
import { getType } from 'typesafe-actions';
import {
  ILeague,
  IOneClickChallengeDefault,
  IRoster,
  IUserPrivate,
  ProviderType,
} from '../../all-models';
import { IStoredUser } from '../../models/user';
import { AUTH_USER_TOKEN_KEY } from '../../utils/constants';
import { RootActions } from '../rootActions';
import { IRootState } from '../rootReducer';
import {
  createOneClickChallengeDefaults,
  createProvider,
  forgotPassword,
  getAllRostersForLeague,
  getAllTransactions as getAllTransactionsAction,
  getCurrentUser as getCurrentUserAction,
  getCurrentUserLeagues as getCurrentUserLeaguesAction,
  getCurrentUserProviders as getCurrentUserProvidersAction,
  getOneClickChallengeDefaults,
  getStoredUser as getStoredUserAction,
  login,
  logout,
  register,
  resetPassword,
  setReferrer,
  submitIdentityVerification,
  updateUser,
  verifyEmail,
} from './userActions';

export type RosterWithOwner = IRoster & { user: { name: string } };

export interface IUserLeagues {
  league: ILeague;
  ownRosters: IRoster[];
  allRosters: Retrieval<readonly RosterWithOwner[]>;
}

interface Requests {
  login: Retrieval<any>;
  verifyEmail: Retrieval<any>;
  forgotPassword: Retrieval<any>;
  resetPassword: Retrieval<any>;
  register: Retrieval<any>;
  updateUser: Retrieval<any>;
}

export interface IUserState {
  isUserLoggedIn: boolean;
  referrer?: string;
  currentUser: Retrieval<IUserPrivate>;
  updateUser: Retrieval<IUserPrivate>;
  storedUser: Retrieval<IStoredUser>;
  identityVerification: Retrieval<void>;
  createProvider: {
    data: Map<ProviderType, Retrieval<void>>;
  };
  requests: Requests;
  leagues: Retrieval<{ [id: string]: IUserLeagues }>;
  providers: Retrieval<any>;
  transactions: Retrieval<any>;
  oneClickChallengeDefaults: Retrieval<IOneClickChallengeDefault>;
  workingOneClickChallengeDefaults: {
    creating: Retrieval<IOneClickChallengeDefault>;
  };
}

const initialState: IUserState = {
  isUserLoggedIn: false,
  currentUser: idleRetrieval,
  updateUser: idleRetrieval,
  storedUser: idleRetrieval,
  identityVerification: idleRetrieval,
  createProvider: { data: new Map() },
  requests: {
    login: idleRetrieval,
    verifyEmail: idleRetrieval,
    forgotPassword: idleRetrieval,
    resetPassword: idleRetrieval,
    register: idleRetrieval,
    updateUser: idleRetrieval,
  },
  leagues: idleRetrieval,
  providers: idleRetrieval,
  transactions: idleRetrieval,
  oneClickChallengeDefaults: idleRetrieval,
  workingOneClickChallengeDefaults: {
    creating: idleRetrieval,
  },
};

const updateUserLeague = (
  state: IUserState,
  leagueId: string,
  fn: (l: IUserLeagues) => void,
) => {
  if (state.leagues.state === RetrievalState.Succeeded) {
    const league = state.leagues.value[leagueId];
    if (league) {
      fn(league);
    }
  }
};

export const userReducer = produce((state: IUserState, action: RootActions) => {
  switch (action.type) {
    case getType(getCurrentUserAction.request):
      state.currentUser = workingRetrival;
      return;
    case getType(getCurrentUserAction.success):
      state.currentUser = success(action.payload);
      state.isUserLoggedIn = true;
      return;
    case getType(getCurrentUserAction.failure):
      state.currentUser = error(action.payload);
      return;
    case getType(getCurrentUserProvidersAction.request):
      state.providers = workingRetrival;
      return;
    case getType(getCurrentUserProvidersAction.success):
      state.providers = success(action.payload);
      return;
    case getType(getCurrentUserLeaguesAction.request):
      state.leagues = workingRetrival;
      return;
    case getType(getCurrentUserLeaguesAction.success):
      state.leagues = success(action.payload);
      return;
    case getType(getAllTransactionsAction.request):
      state.transactions = workingRetrival;
      return;
    case getType(getAllTransactionsAction.success):
      state.transactions = success(action.payload);
      return;
    case getType(createProvider.request):
      state.createProvider.data.set(action.payload.type, workingRetrival);
      return;
    case getType(createProvider.success):
      state.createProvider.data.set(action.payload.type, success(undefined));
      return;
    case getType(createProvider.failure):
      state.createProvider.data.set(
        action.payload.type,
        error(action.payload.err),
      );
      return;

    case getType(login.request):
      state.requests.login = workingRetrival;
      return;
    case getType(login.success):
      localStorage.setItem(
        AUTH_USER_TOKEN_KEY,
        action.payload.signInUserSession.accessToken.jwtToken,
      );
      state.isUserLoggedIn = true;
      state.requests.login = success(action.payload);
      return;
    case getType(login.failure):
      state.requests.login = error(action.payload);
      return;
    case getType(login.cancel):
      state.requests.login = idleRetrieval;
      return;

    case getType(verifyEmail.request):
      state.requests.verifyEmail = workingRetrival;
      return;
    case getType(verifyEmail.success):
      state.requests.verifyEmail = success(action.payload);
      return;
    case getType(verifyEmail.failure):
      state.requests.verifyEmail = error(action.payload);
      return;
    case getType(verifyEmail.cancel):
      state.requests.verifyEmail = idleRetrieval;
      return;

    case getType(getStoredUserAction.request):
      state.storedUser = workingRetrival;
      return;
    case getType(getStoredUserAction.success):
      state.storedUser = success(action.payload);
      state.identityVerification = action.payload.identityVerification
        ? success(undefined)
        : idleRetrieval;
      return;
    case getType(getStoredUserAction.failure):
      state.storedUser = error(action.payload);
      return;
    case getType(getStoredUserAction.cancel):
      state.storedUser = idleRetrieval;
      return;

    case getType(forgotPassword.request):
      state.requests.forgotPassword = workingRetrival;
      return;
    case getType(forgotPassword.success):
      state.requests.forgotPassword = success(action.payload);
      return;
    case getType(forgotPassword.failure):
      state.requests.forgotPassword = error(action.payload);
      return;
    case getType(forgotPassword.cancel):
      state.requests.forgotPassword = idleRetrieval;
      return;

    case getType(register.request):
      state.requests.register = workingRetrival;
      return;
    case getType(register.success):
      state.requests.register = success(action.payload);
      return;
    case getType(register.failure):
      state.requests.register = error(action.payload);
      return;
    case getType(register.cancel):
      state.requests.register = idleRetrieval;
      return;

    case getType(updateUser.request):
      state.requests.updateUser = workingRetrival;
      return;
    case getType(updateUser.success):
      state.requests.updateUser = success(action.payload.status);
      state.updateUser = action.payload.value;
      return;
    case getType(updateUser.failure):
      state.requests.updateUser = error(action.payload);
      return;
    case getType(updateUser.cancel):
      state.requests.updateUser = idleRetrieval;
      return;

    case getType(resetPassword.request):
      state.requests.resetPassword = workingRetrival;
      return;
    case getType(resetPassword.success):
      state.requests.resetPassword = success(action.payload);
      return;
    case getType(resetPassword.failure):
      state.requests.resetPassword = error(action.payload);
      return;
    case getType(resetPassword.cancel):
      state.requests.resetPassword = idleRetrieval;
      return;

    case getType(submitIdentityVerification.request):
      state.identityVerification = workingRetrival;
      return;
    case getType(submitIdentityVerification.success):
      if (state.storedUser.state === RetrievalState.Succeeded) {
        state.storedUser.value.identityVerification = action.payload;
      }
      state.identityVerification = success(undefined);
      return;
    case getType(submitIdentityVerification.failure):
      state.identityVerification = error(action.payload);
      return;
    case getType(submitIdentityVerification.cancel):
      state.identityVerification = idleRetrieval;
      return;

    case getType(getAllRostersForLeague.request):
      updateUserLeague(
        state,
        action.payload,
        (l) => (l.allRosters = workingRetrival),
      );
      return;
    case getType(getAllRostersForLeague.success):
      updateUserLeague(
        state,
        action.payload.leagueId,
        (l) => (l.allRosters = success(action.payload.rosters)),
      );
      return;
    case getType(getAllRostersForLeague.failure):
      updateUserLeague(
        state,
        action.payload.leagueId,
        (l) => (l.allRosters = error(action.payload.error)),
      );
      return;
    case getType(getAllRostersForLeague.cancel):
      updateUserLeague(
        state,
        action.payload,
        (l) => (l.allRosters = idleRetrieval),
      );
      return;

    case getType(logout.success):
      localStorage.removeItem(AUTH_USER_TOKEN_KEY);
      state.currentUser = idleRetrieval;
      state.isUserLoggedIn = false;
      return;

    case getType(setReferrer.request):
      state.referrer = action.payload;
      return;
    case getType(createOneClickChallengeDefaults.request):
      state.workingOneClickChallengeDefaults.creating = workingRetrival;
      return;
    case getType(createOneClickChallengeDefaults.success):
      state.workingOneClickChallengeDefaults.creating = success(action.payload);
      return;
    case getType(createOneClickChallengeDefaults.failure):
      state.workingOneClickChallengeDefaults.creating = error(action.payload);
      return;
    case getType(createOneClickChallengeDefaults.cancel):
      state.workingOneClickChallengeDefaults.creating = idleRetrieval;
      return;
    case getType(getOneClickChallengeDefaults.request):
      state.oneClickChallengeDefaults = workingRetrival;
      return;
    case getType(getOneClickChallengeDefaults.success):
      state.oneClickChallengeDefaults = success(action.payload);
      return;
    case getType(getOneClickChallengeDefaults.failure):
      state.oneClickChallengeDefaults = error(action.payload);
      return;
    case getType(getOneClickChallengeDefaults.cancel):
      state.oneClickChallengeDefaults = idleRetrieval;
      return;
  }
}, initialState);

const select = (state: IRootState) => state.user;

export const requests = (state: IRootState): Requests => select(state).requests;

export const getCurrentReferrer = (state: IRootState) => select(state).referrer;

export const getCurrentUser = (state: IRootState) => select(state).currentUser;

export const getUpdatedUser = (state: IRootState) => select(state).updateUser;

export const getStoredUser = (state: IRootState) => select(state).storedUser;

export const oneClickChallengeDefaults = (state: IRootState) =>
  select(state).workingOneClickChallengeDefaults;

export const getCurrentUserTransactions = (state: IRootState) =>
  select(state).transactions;

export const getCurrentUserProviders = (state: IRootState) => {
  const currentUserProviders = select(state).providers;
  return currentUserProviders?.state === RetrievalState.Succeeded
    ? currentUserProviders.value
    : null;
};

export const getCurrentUserLeagues = (state: IRootState) =>
  select(state).leagues;

export const getCurrentUserLeagueById =
  (leagueId: string) =>
  (state: IRootState): Retrieval<IUserLeagues | undefined> => {
    const l = select(state).leagues;

    return l.state === RetrievalState.Succeeded
      ? success(l.value[leagueId])
      : l;
  };

export type RosterWithLeague = Readonly<IRoster & { league: ILeague }>;

export const getCurrentUserRosters = createSelector(
  getCurrentUserLeagues,
  (leagues): Retrieval<readonly RosterWithLeague[]> =>
    leagues.state === RetrievalState.Succeeded
      ? success(
          Object.values(leagues.value).flatMap((l) =>
            l.ownRosters.map((r) => ({ ...r, league: l.league })),
          ),
        )
      : leagues,
);

export const getProvider =
  (providerType: ProviderType) => (state: IRootState) => {
    const providers = select(state).createProvider;
    return providers.data.get(providerType) ?? idleRetrieval;
  };

export const getIdentityVerification = (state: IRootState) =>
  select(state).identityVerification;

export const isLoggedIn = (state: IRootState) => {
  return select(state).isUserLoggedIn;
};

export const hasAccessToken = (state: IRootState) => {
  return localStorage.getItem(AUTH_USER_TOKEN_KEY) !== null;
};

export const getAccessToken = (state: IRootState) => {
  return localStorage.getItem(AUTH_USER_TOKEN_KEY);
};

export const isAnyProviderWorking = (state: IRootState): boolean => {
  const providers = select(state).createProvider;
  return (
    Array.from(providers.data).find(
      ([type, value]) => value.state === RetrievalState.Retrieving,
    ) !== undefined
  );
};

export const getCurrentUserOneClickChallengeDefaults = (state: IRootState) =>
  select(state).oneClickChallengeDefaults;
