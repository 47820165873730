import { Box, Grid, makeStyles, Typography } from '@material-ui/core';
import { Notifications } from '@material-ui/icons';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import React, { useCallback, useContext } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { NotificationContext } from '../../App';
import { NotifyItem } from '../../models/notifications';
import { updateNotificationAsRead } from '../../stores/api/notifications3';
import { getAccessToken } from '../../stores/user/userReducer';

const useCardStyles = makeStyles((theme) => ({
  cardContainer: {
    width: '100%',
    maxHeight: '110px',
    borderBottom: '1px solid rgba(0, 0,0, 0.15)',
    padding: (dashboard) =>
      dashboard ? theme.spacing(2.5, 1) : theme.spacing(2.5, 1.5, 2, 2),
  },
  lightGreyCard: {
    width: '100%',
    maxHeight: '110px',
    borderBottom: '1px solid rgba(0, 0,0, 0.15)',
    padding: (dashboard) =>
      dashboard ? theme.spacing(2.5, 1) : theme.spacing(2.5, 1.5, 2, 2),
    backgroundColor: (dashboard) => (dashboard ? '#ffffff' : '#f2f2f2'),
  },
  detailsContainer: {
    height: '100%',
  },
  messageContainer: {
    paddingLeft: (dashboard) =>
      dashboard ? theme.spacing(2) : theme.spacing(1),
    height: '100%',
  },
  success: {
    color: theme.palette.success.light,
  },
  warning: {
    color: theme.palette.error.main,
  },
  general: {
    color: '#000000',
  },
  messageCopy: {
    lineHeight: '20px',
  },
  bottomContainer: {
    padding: theme.spacing(1.5, 0, 0),
  },
  buttonGrey: {
    color: '  #919191',
    fontSize: '14px',
    fontWeight: 'bold',
    textTransform: 'uppercase',
    '&:hover': {
      cursor: 'pointer',
    },
  },
  buttonGreen: {
    color: theme.palette.success.light,
    fontSize: '14px',
    fontWeight: 'bold',
    textTransform: 'uppercase',
    '&:hover': {
      cursor: 'pointer',
    },
  },
  arrowIconGrey: {
    color: '  #919191',
    fontSize: '24px',
    fontWeight: 'bold',
    verticalAlign: 'middle',
    transform: 'rotate(-90deg)',
    '&:hover': {
      cursor: 'pointer',
    },
  },
  arrowIconGreen: {
    color: theme.palette.success.light,
    fontSize: '24px',
    fontWeight: 'bold',
    verticalAlign: 'middle',
    transform: 'rotate(-90deg)',
    '&:hover': {
      cursor: 'pointer',
    },
  },
}));

export const NotificationCard: React.FC<{
  notifyDetails: NotifyItem;
  onToggle?(): void;
  dashboard: boolean;
}> = ({ notifyDetails, onToggle, dashboard }) => {
  const classes = useCardStyles({ dashboard });
  const { setNotificationList, notificationList, setBadgeVisibility } =
    useContext(NotificationContext);
  const history = useHistory();
  const cardBackground =
    notifyDetails?.data.notificationRead === 'true'
      ? classes.lightGreyCard
      : classes.cardContainer;
  const buttonColor =
    notifyDetails?.data.notificationRead === 'true'
      ? classes.buttonGrey
      : classes.buttonGreen;
  const arrowColor =
    notifyDetails?.data.notificationRead === 'true'
      ? classes.arrowIconGrey
      : classes.arrowIconGreen;
  const bellStyling = notifyDetails?.data.MessageAttributes?.backPlateColor
    ? notifyDetails?.data.MessageAttributes?.backPlateColor.StringValue ===
      '#05c500'
      ? classes.success
      : notifyDetails.data.MessageAttributes.backPlateColor.StringValue ===
        ' #FF1744'
      ? classes.warning
      : classes.general
    : classes.general;
  const accessToken = useSelector(getAccessToken);

  const copyLink = () => {
    let sharedUrl = `${window.location.href}/?token=${accessToken}`;
    navigator.clipboard.writeText(sharedUrl);
  };

  const onButtonClicked = useCallback(() => {
    updateNotificationAsRead(notifyDetails);
    setNotificationList?.((prev: NotifyItem[]) =>
      prev?.filter((notif) => {
        if (notif.key === notifyDetails.key) {
          notifyDetails.data.notificationRead = 'true';
        }
        return notif;
      }),
    );

    setTimeout(() => {
      const allRead = notificationList.every(
        (notif) => notif.data.notificationRead !== 'false',
      );

      if (allRead) {
        setBadgeVisibility?.(false);
      } else {
        setBadgeVisibility?.(true);
      }
    }, 1500);

    switch (notifyDetails.data.MessageAttributes.ctaButtonText.StringValue) {
      case 'View Challenge':
        history.push({
          pathname: `/challenges/${notifyDetails?.data?.MessageAttributes?.challengeId?.StringValue}`,
        });
        if (onToggle) {
          onToggle();
        }
        break;
      case 'Share Challenge':
        copyLink();
        break;
      case 'Check Your Overall Record':
        history.push({
          pathname: `/`,
        });
        break;
    }
  }, [history, notifyDetails, onToggle]);

  return (
    <Grid container className={cardBackground} alignItems="center" xs={12}>
      <Grid
        item
        container
        xs={12}
        alignItems="flex-start"
        justifyContent="space-between"
        className={classes.detailsContainer}
      >
        <Grid item xs={1}>
          <Box>
            <Notifications
              className={bellStyling}
              aria-label="notification bell"
            />
          </Box>
        </Grid>
        <Grid container item xs={11} className={classes.messageContainer}>
          <Grid item xs={11}>
            <Typography variant="subtitle2" className={classes.messageCopy}>
              {notifyDetails.data.messageBody}
            </Typography>
          </Grid>
          {!dashboard && (
            <Grid
              container
              item
              direction="row"
              justifyContent="space-between"
              alignItems="center"
              className={classes.bottomContainer}
              xs={11}
            >
              <Grid item xs={3}>
                <Typography variant="caption">
                  {notifyDetails.data.MessageAttributes.currentDate.StringValue.slice(
                    0,
                    10,
                  )}
                </Typography>
              </Grid>
              {notifyDetails.data.MessageAttributes.ctaButtonText && (
                <Grid
                  item
                  xs={6}
                  container
                  alignItems="center"
                  alignContent="center"
                  justifyContent="flex-end"
                >
                  <Grid item>
                    <Typography
                      className={buttonColor}
                      onClick={onButtonClicked}
                    >
                      {
                        notifyDetails.data.MessageAttributes.ctaButtonText
                          .StringValue
                      }
                    </Typography>
                  </Grid>
                  <Grid item>
                    <ExpandMoreIcon className={arrowColor} />
                  </Grid>
                </Grid>
              )}
            </Grid>
          )}
        </Grid>
      </Grid>
    </Grid>
  );
};
