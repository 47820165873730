import { Participants } from '../API';
import { RosterWithOwner } from '../stores/user/userReducer';
import {
  ChallengeType,
  orderedPositions,
  positionsSortOrder,
  sortPositions,
} from '../utils/constants';
import { truthy } from '../utils/function';
import { IChallenger, IChallengerPlayerPoints } from './challenger';
import { createLineup } from './createLineup';
import { ITimestampedGraphQLDynamoRecord } from './dynamo';
import { ILeague } from './league';
import { PlayerPosition } from './player';
import { IRoster, IRosterPlayer } from './roster';

export interface IChallengerInfo extends IChallenger {
  roster: IRoster;
  league: ILeague;
}

export enum ParticipantPayoutState {
  Unnecessary,
  Pending,
  Complete,
}

export interface IParticipant extends ITimestampedGraphQLDynamoRecord {
  id?: string;
  userId?: string;
  challengeId?: string;
  challengerID?: string;
  entryFee?: number;
  processPayoutAfter?: string;
  payoutState: ParticipantPayoutState;
  serviceFee?: number;
  creditFee?: number;
}

export interface IChallengeScoringBreakdown {
  challengerId: string;
  localId: string;
  scoring: { key: string; name: string; value: number }[];
}

export interface IOpenChallenge {
  id: string;
  rosterID?: string;
  roster?: IRoster;
  challengeId: string;
  opposingUser?: string;
  isCompleted?: boolean;
}

const flexAwareSort = (a: IRosterPlayer, b: IRosterPlayer) =>
  a.startsAs!.length - b.startsAs!.length ||
  orderedPositions.indexOf(a.startsAs![0]) -
    orderedPositions.indexOf(b.startsAs![0]);

export interface IMatchedPosition {
  /** Players that mached up here, with order matching the order in which rosters were given */
  players: readonly IRosterPlayer[];
  /** Sorted player position this player is starting as. */
  position: readonly PlayerPosition[];
}

export type MatchedRosters = IMatchedPosition[];

//QB,RB,WR,TE, super flex, FLEX(All) K, DST, LB, DL, DB, IDP flex
const positionSorting = [
  PlayerPosition.QB,
  PlayerPosition.RB,
  PlayerPosition.WR,
  PlayerPosition.TE,
  // SUPER FLEX
  'SUPER_FLEX',
  // [PlayerPosition.QB, PlayerPosition.WR, PlayerPosition.RB, PlayerPosition.TE],
  // FLEX
  'FLEX',
  // [PlayerPosition.WR, PlayerPosition.TE, PlayerPosition.RB],
  PlayerPosition.K,
  PlayerPosition.DEF,
  PlayerPosition.DT,
  PlayerPosition.LB,
  PlayerPosition.DL,
  PlayerPosition.DB,
  'IDP_FLEX',
  // [
  //   PlayerPosition.DE,
  //   PlayerPosition.DT,
  //   PlayerPosition.LB,
  //   PlayerPosition.CB,
  //   PlayerPosition.SS,
  // ],
];

const lineupSorting = (lineup: any) =>
  lineup?.position!.length === 1
    ? lineup?.position![0]
    : lineup?.position!.length === 3
    ? 'FLEX'
    : lineup?.position.length === 4
    ? 'SUPER_FLEX'
    : 'IDP_FLEX';

/**
 * Gets the 'overlap' between the two rosters, filling positions that exist
 * only in both rosters with priority given to players who have the highest
 * scores.
 *
 * This is _close to_ `getChallengerRosters`, with the difference that players
 * can fill any position they can play as (not only their startsAs position)
 * and they do so in order of score rather than the order they're defined in.
 */
export const getBestBallRosters = (
  a: IRoster,
  aScoring: IChallengerPlayerPoints[],
  b: IRoster,
  bScoring: IChallengerPlayerPoints[],
  useActualScore: boolean,
): MatchedRosters => {
  const aPlayers = getPlayersSortedByPoints(a, aScoring, useActualScore);
  const bPlayers = getPlayersSortedByPoints(b, bScoring, useActualScore);
  return merge('positions', [a, aPlayers], [b, bPlayers]);
};

const checkStarting = (a: IRoster, b: IRoster): IRoster[] => {
  const rosterA = JSON.parse(JSON.stringify(a));
  const rosterB = JSON.parse(JSON.stringify(b));
  rosterA.starting?.forEach((s: any, index: any) => {
    const positionCopy: PlayerPosition[] = [...s.position];
    const sorted = JSON.stringify(
      positionCopy.sort((a, b) => a.localeCompare(b)),
    );

    const exist = rosterB.starting?.findIndex((item: any) => {
      const positionCopy: PlayerPosition[] = [...item.position];
      const sortedB = JSON.stringify(
        positionCopy.sort((a, b) => a.localeCompare(b)),
      );

      return sortedB === sorted;
    });

    if (exist !== -1) {
      if (s.count > rosterB.starting[exist]?.count) {
        s.count = rosterB.starting[exist]?.count;
      } else if (s.count < rosterB.starting[exist]?.count) {
        rosterB.starting[exist].count = s.count;
      }
    } else {
      rosterA?.starting?.splice(index, 1);
    }
  });

  rosterB.starting?.forEach((s: any, index: any) => {
    const positionCopy: PlayerPosition[] = [...s.position];
    const sorted = JSON.stringify(
      positionCopy.sort((a, b) => a.localeCompare(b)),
    );

    const exist = rosterA.starting?.findIndex((item: any) => {
      const positionCopy: PlayerPosition[] = [...item.position];
      const sortedB = JSON.stringify(
        positionCopy.sort((a, b) => a.localeCompare(b)),
      );

      return sortedB === sorted;
    });

    if (exist !== -1) {
      if (s.count > rosterA.starting[exist]?.count) {
        s.count = rosterA.starting[exist]?.count;
      } else if (s.count < rosterA.starting[exist]?.count) {
        rosterA.starting[exist].count = s.count;
      }
    } else {
      rosterB?.starting?.splice(index, 1);
    }
  });

  return [rosterA, rosterB];
};

const merge = (
  positionKey: 'startsAs' | 'positions',
  ...rosters: [IRoster, readonly IRosterPlayer[]][]
): MatchedRosters => {
  const [rosterA, rosterB] = checkStarting(rosters[0][0], rosters[1][0]);
  const updatedRosters: [IRoster, readonly IRosterPlayer[]][] = [
    [rosterA, rosters[0][1]],
    [rosterB, rosters[1][1]],
  ];
  const lineup = createLineup(
    ...updatedRosters.map(
      ([roster, players]) =>
        // use the 'starting' list if we have one, otherwise the legacy startsAs for back compat
        roster.starting?.flatMap((p) => new Array(p.count).fill(p.position)) ??
        players.map((p) => p.startsAs).filter(truthy),
    ),
  );

  const remaining = rosters.map(([, players]) => players.slice());
  const bench = rosters.map(([roster, players]) => roster?.bench?.slice());

  // rudimentary sort by positions of lineup to give priority to single position (single/flex/super/idp) for filling
  // doesn't so much matter for head to head;, but required for best ball
  const priorityLineup = lineup.sort((a, b) => a.length - b.length);

  return priorityLineup
    .map((position) => {
      let players: IRosterPlayer[] = [];
      let rostersIndex = 0;
      for (const r of remaining) {
        const index =
          positionKey === 'startsAs'
            ? r.findIndex(
                (p) =>
                  !bench[rostersIndex]?.includes(p?.localId) &&
                  p.positions?.some((p2) => position.includes(p2)),
              )
            : r.findIndex((p) => position.includes(p[positionKey]![0]));
        if (index === -1) {
          return undefined;
        }

        players.push(r.splice(index, 1)[0]);
      }
      rostersIndex++;
      return { position, players };
    })
    .sort(
      (a, b) =>
        positionSorting.indexOf(lineupSorting(a)) -
        positionSorting.indexOf(lineupSorting(b)),
    )
    .filter(truthy);
};

const getPlayersSortedByPoints = (
  r: IRoster,
  scoring: readonly IChallengerPlayerPoints[],
  useActualScore: boolean,
) =>
  r.players
    .map((player) => ({
      player,
      points:
        scoring.find((s) => s.playerId === player.localId)?.[
          useActualScore ? 'actual' : 'projected'
        ] ?? 0,
    }))
    .sort(
      (a, b) =>
        b.points - a.points ||
        (b.player.startsAs ? 1 : 0) - (a.player.startsAs ? 1 : 0),
    )
    .map((p) => p.player);

/**
 * Gets the 'overlap' between the two rosters, filling start positions that
 * exist only in both rosters and their players in order.
 */
export const getHeadToHeadRosters = (a: IRoster, b: IRoster) =>
  merge('startsAs', [a, a.players], [b, b.players]);

export const getChallengeTopPlayers = (info: IChallengerInfo) => {
  return info.roster.players
    .slice()
    .map((player) => ({
      player,
      score: info.playerPoints?.find(
        (p) =>
          p.playerId === (player.playerId || player.localId) && p.isStarter,
      ) || {
        actual: 0,
        playerId: player.playerId || player.localId,
        projected: 0,
      },
    }))
    .sort(
      (a, b) =>
        (b.score.actual || b.score.projected) -
        (a.score.actual || b.score.projected),
    );
};

export interface IChallengeType {
  title: ChallengeType;
  avatar: string;
}

export enum ChallengeStatus {
  Live = 'live',
  Completed = 'completed',
}

export interface IParticipants {
  items: Participants[];
  nextToken?: string;
  startedAt?: string;
}

export interface IChallenge {
  id: string;
  type: ChallengeType;
  challengers: IChallengerInfo[];
  openChallenge?: boolean;
  participants: IParticipants;
  status: ChallengeStatus;
  pot: number;
  endDate: string;
  joined?: number;
  entryFee: number;
  pointsUpdatedAt?: string;
  projectedPointsUpdatedAt?: string;
  verified?: boolean;
  private?: boolean;
  userId?: string;
  winner?: string;
}

export interface IChallengeCreateBody {
  id?: string;
  type?: ChallengeType;
  challengers?: {
    id: string;
    name: string;
    rosterID: string;
    leagueID: string;
  }[];
  openChallenge?: boolean;
  pot?: number;
  entryFee?: number;
  endWeek?: number;
  isPrivate?: boolean;
  selectedChallengerId?: string;
  serviceFee?: boolean;
  creditFee?: number;
}

export interface IOneClickChallengeDefault {
  id?: string;
  userId: string;
  type: ChallengeType;
  entryFee: number;
  private: boolean;
  rosterID: string;
  roster?: RosterWithOwner;
  leagueId: string;
  league?: ILeague;
}

export interface ILeagueChallenge extends IChallenge {
  leagueName?: string;
  leagueAvatar?: string;
}

interface IChartRow {
  /** Positions this row represents */
  readonly positions: readonly PlayerPosition[];
  /** Players in this row. Will have players from multiple rosters */
  readonly players: Set<PlayerInChart>;
  /**
   * Starting positions in each roster that can be used to assign this
   * position. Each top level index corresponds to the `rosterIndex`. When
   * any roster no longer has positions available for a certain row, the
   * row is deleted.
   */
  starting: StartingPosition[][];
}

class StartingPosition {
  public readonly positions: PlayerPosition[];

  constructor(public readonly rosterIndex: number, position: PlayerPosition[]) {
    this.positions = sortPositions(position.slice());
  }

  public equals(other: StartingPosition): boolean {
    if (other === this) {
      return true;
    }

    return (
      this.rosterIndex === other.rosterIndex &&
      this.positions.length === other.positions.length &&
      this.positions.every((p, i) => p === other.positions[i])
    );
  }

  public *keys() {
    // this is really just counting in binary...
    let n = 0;
    for (let i = 0; i < this.positions.length; i++) {
      n <<= 1;
      n |= 1;
    }

    while (n > 0) {
      let key: PlayerPosition[] = [];
      for (let j = n, i = 0; j > 0; j >>>= 1, i++) {
        if (j & 1) {
          key.push(this.positions[i]);
        }
      }

      yield key;
      n--;
    }
  }
}

/**
 * Picture two rosters, A and B. Using each combination of each player's
 * positions, we can create a 'chart' of player positions. For instance, if
 * player1 starts as a [QB, DE] and player2 was a [QB, RB], and player3 and
 * player4 were only a [RB], then we would create:
 *
 *  Position | A Players | B players
 *  ---------|-----------|-----------------
 *   QB      | player1   | player2
 *   RB      | player3   | player2, player4
 *   DE      | player1   |
 *   RB,QB   |           | player2
 *   RB,DE   | player1   | player2
 *
 *
 * Then, to create the starting lineup, we can do the following:
 * 1. Eliminate any sets of positions that a player can't fill on both teams.
 *    In this case, there'd be no [DE] or [RB, QB] posisiton.
 *
 * 2. Pull positions out the chart in ascending order based off the min number
 *    of positions the players in that row can fill. So if a player A can fill
 *    two positions but player B can only fill one, make sure player A doesn't
 *    take B's only available spot.
 *
 *    In this case, we take the [RB] position first, since player3 and player4
 *    can fill those and those only. We then take player3 and prefer player4
 *    over player2, since player4 again has fewer spots it can fill.
 *
 * 3. As rows are pulled, decrement the number of available positons a player
 *    has, and remove them from all other rows.  So, as we take out the [RB]
 *    row, we decrement player2's remaining positions.
 *
 * 4. Keep repeating 2 and 3 until the map is empty.
 *
 * Also, this conveniently works for any number of rosters! Three-way
 * challenges in the future, maybe? ;)
 */
class HeadToHeadChart {
  private readonly rosterIds: string[] = [];

  /** The key is the sorted, common-joined list of positions. */
  private readonly map = new Map<string, IChartRow>();

  /**
   * Adds a new roster for consideration in the chart.
   *
   * If `seedOnlyStarters`, then all players will get seeded into the chart --
   * though only starting positions will end up getting filled. This is used
   * for best ball where all players *can* be starters, and are then picked
   * in order of points.
   */
  public add(
    id: string,
    players: readonly IRosterPlayer[],
    seedOnlyStarters = true,
  ) {
    const rosterIndex = this.rosterIds.length;
    this.rosterIds.push(id);

    for (const player of players) {
      if (player.startsAs?.length) {
        const chartPlayer = new PlayerInChart(id, rosterIndex, player);
        const startingPositions = new StartingPosition(
          rosterIndex,
          player.startsAs,
        );

        for (const key of startingPositions?.keys()) {
          const r = this.addStartingPosition(startingPositions, key);
          if (seedOnlyStarters) {
            chartPlayer.inPositions++;
            r.players.add(chartPlayer);
          }
        }
      }
    }

    if (!seedOnlyStarters) {
      for (const player of players) {
        const chartPlayer = new PlayerInChart(id, rosterIndex, player);
        for (const key of new StartingPosition(
          rosterIndex,
          player.positions,
        ).keys()) {
          chartPlayer.inPositions++;
          this.map.get(key.join())?.players.add(chartPlayer);
        }
      }
    }

    return this;
  }

  /** Createse a starting lineup of players, consuming the chart in the process. */
  public consume() {
    const lineup: MatchedRosters = [];

    // First off, go through and get rid of rows that never got players from all rosters.
    for (const [key, row] of this.map) {
      for (let i = 0; i < this.rosterIds.length; i++) {
        const s = row.starting[i];
        if (!s?.length) {
          this.deleteRow(key, row);
          break;
        }
        s.sort((a, b) => b.positions.length - a.positions.length);
      }
    }

    while (this.map.size) {
      let minPositions = Infinity;
      let min: { key: string; row: IChartRow };

      // Scan the map, and pick the most flexible position with the fewest
      // candidate players. The length check avoids picking [RB, CB] when a
      // full flex slot of [RB, CB, K] would have worked.
      for (const [key, row] of this.map) {
        for (const player of row.players) {
          if (player.wasPlaced) {
            continue;
          }

          if (
            player.inPositions < minPositions ||
            (player.inPositions === minPositions &&
              row.positions.length > min!.row.positions.length)
          ) {
            minPositions = player.inPositions;
            min = { key, row };
          }
        }
      }

      const alreadyFilledRosters = new Set<String>();
      // once again, try to pick out the players with the fewest other positions
      const players = [...min!.row.players].sort(
        (a, b) => a.inPositions - b.inPositions,
      );

      // Now we found it, take a player from each roster, from *all* rows
      // they're in.
      const filled: IRosterPlayer[] = new Array(this.rosterIds.length);
      for (const player of players) {
        if (!alreadyFilledRosters.has(player.rosterId) && !player.wasPlaced) {
          filled[this.rosterIds.indexOf(player.rosterId)] = player.player;
          alreadyFilledRosters.add(player.rosterId);
          player.wasPlaced = true;
        }
      }

      // Take the last (most specific) position for each roster, and remove it.
      // We don't necessarily remove the whole row, since multiple positions
      // might be available here.
      for (const positions of min!.row.starting) {
        this.filledPosition(positions[positions.length - 1]);
      }

      lineup.push({ players: filled, position: min!.row.positions });
    }

    return lineup.sort(
      (a, b) =>
        a.position.length - b.position.length ||
        positionsSortOrder[a.position[0]] - positionsSortOrder[b.position[0]],
    );
  }

  /**
   * Removes a player from the row. If the row no longer has a player from each
   * roster, the row will be deleted, too.
   */
  private filledPosition(position: StartingPosition) {
    for (const key of position.keys()) {
      const keyStr = key.join();
      const row = this.map.get(keyStr);
      if (!row) {
        continue;
      }

      const s = row.starting[position.rosterIndex];
      for (let i = 0; i < s.length; i++) {
        if (s[i].equals(position)) {
          if (s.length === 1) {
            // no more positions in this row for us
            this.deleteRow(keyStr, row);
          } else {
            s.splice(i, 1);
          }
        }
      }
    }
  }

  private deleteRow(key: string, row: IChartRow) {
    for (const other of row.players) {
      other.inPositions--;
    }

    this.map.delete(key);
  }

  private addStartingPosition(
    position: StartingPosition,
    positions: readonly PlayerPosition[],
  ) {
    const key = positions.join();
    let r = this.map.get(key);
    if (!r) {
      r = { players: new Set([]), positions, starting: [] };
      this.map.set(key, r);
    }

    while (r.starting.length <= position.rosterIndex) {
      r.starting.push([]);
    }

    r.starting[position.rosterIndex].push(position);
    return r;
  }
}

export class PlayerInChart {
  /** Number of positions this player still appears in */
  public inPositions = 0;

  /** Whether this player has been placed as a starter already. */
  public wasPlaced = false;

  public readonly startingPositions =
    this.player.startsAs &&
    new StartingPosition(this.rosterIndex, this.player.startsAs);

  constructor(
    public readonly rosterId: string,
    public readonly rosterIndex: number,
    public readonly player: IRosterPlayer,
  ) {}
}
