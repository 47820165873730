import { IError } from '@propsfantasy/retrieval';
import { ActionType, createAsyncAction } from 'typesafe-actions';
import { AddFundsRequest, WithdrawFundsRequest } from '../../API';
import { IWallet } from '../../all-models';

export const updatePrimaryUpo = createAsyncAction(
  'UserUpdatePrimaryRequest',
  'UserUpdatePrimarySuccess',
  'UserUpdatePrimaryError',
  'UserUpdatePrimaryCancel',
)<{ queryString: string }, any, IError, undefined>();

export const applyPromocode = createAsyncAction(
  'ApplyPromoCodeGetRequest',
  'ApplyPromoCodeGetSuccess',
  'ApplyPromoCodeGetError',
  'ApplyPromoCodeGetCancel',
)<{ promocode: string }, string, IError, undefined>();

export const getWallet = createAsyncAction(
  'WalletGetRequest',
  'WalletGetSuccess',
  'WalletGetError',
  'WalletGetCancel',
)<undefined, IWallet, IError, undefined>();

export const createAddFundLink = createAsyncAction(
  'CreateAddFundLinkRequest',
  'CreateAddFundLinkSuccess',
  'CreateAddFundLinkError',
  'CreateAddFundLinkCancel',
)<{ addFunds: AddFundsRequest }, string, IError, undefined>();

export const createWithdrawFundLink = createAsyncAction(
  'createWithdrawFundLinkRequest',
  'createWithdrawFundLinkSuccess',
  'createWithdrawFundLinkError',
  'createWithdrawFundLinkCancel',
)<{ withdrawFunds: WithdrawFundsRequest }, string, IError, undefined>();

const actions = {
  getWallet,
  createAddFundLink,
  createWithdrawFundLink,
  updatePrimaryUpo,
  applyPromocode,
};

export type MoneyActions = ActionType<typeof actions>;
