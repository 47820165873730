import React from 'react';
import { PullToRefresh } from 'react-js-pull-to-refresh';
import '../../styles/pull-down-refresh.css';
interface ShareProps {
  onRefreshAction?: Function;
}

export const Refresh: React.FC<ShareProps> = ({
  children,
  onRefreshAction,
}) => {
  const onRefresh = () => {
    if (window?.navigator.vibrate) window.navigator?.vibrate(200);
    return new Promise((resolve) => {
      setTimeout(() => {
        if (typeof onRefreshAction === 'function') onRefreshAction();
        resolve(true);
      }, 1000);
    });
  };

  return (
    <PullToRefresh
      backgroundColor="transparent"
      onRefresh={onRefresh}
      pullDownContent={<div className="pull-down-wrapper"></div>}
      pullDownThreshold={82}
      refreshContent={<PullDownContentCustom />}
      releaseContent={<PullDownContentCustom />}
      startInvisible={true}
      triggerHeight={'auto'}
    >
      {children}
    </PullToRefresh>
  );
};

const PullDownContentCustom = () => (
  <div className="container-spinner">
    <div className="spinner">
      {new Array(12).fill(0).map((_, index) => (
        <div className="spinner-blade" key={index}></div>
      ))}
    </div>
  </div>
);
