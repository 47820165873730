import { IError } from '@propsfantasy/retrieval';
import { ActionType, createAsyncAction } from 'typesafe-actions';
import {
  IChallenge,
  IChallengeCreateBody,
  IChallengerPlayerPoints,
  IOpenChallenge,
  LeaderBoardUserData,
  TeamsPlayedUserData,
} from '../../all-models';
import {
  IChallengeScoringBreakdown,
  MatchedRosters,
} from '../../models/challenge';

/** The group call that triggers downstream detail actoins */
export const requestChallenges = createAsyncAction(
  'ChallengesGetRequest',
  'ChallengesGetSuccess',
  'ChallengesGetError',
  'ChallengesGetCancel',
)<{ continuation: string | undefined }, {}, IError, undefined>();

export const requestCompletedChallenges = createAsyncAction(
  'ChallengesGetRequest',
  'ChallengesGetSuccess',
  'ChallengesGetError',
  'ChallengesGetCancel',
)<{ continuation: string | undefined }, {}, IError, undefined>();

/** Gets the top level challege data */
export const requestChallengeList = createAsyncAction(
  'ChallengeListGetRequest',
  'ChallengeListGetSuccess',
  'ChallengeListGetError',
  'ChallengeListGetCancel',
)<
  { continuation: string | undefined },
  { challenges: IChallenge[]; continuation: string | undefined },
  IError,
  undefined
>();

/** Gets the top level completed challege data */
export const requestChallengeCompletedList = createAsyncAction(
  'ChallengeCompletedListGetRequest',
  'ChallengeCompletedListGetSuccess',
  'ChallengeCompletedListGetError',
  'ChallengeCompletedListGetCancel',
)<
  { continuation: string | undefined },
  { challenges: IChallenge[]; continuation: string | undefined },
  IError,
  undefined
>();

export const requestOpenChallenges = createAsyncAction(
  'OpenChallengesGetRequest',
  'OpenChallengesGetSuccess',
  'OpenChallengesGetError',
  'OpenChallengesGetCancel',
)<
  { continuation: string | undefined },
  { openChallenges: IOpenChallenge[]; continuation: string | undefined },
  IError,
  undefined
>();

/** Gets all the rosters for the current challengers in list */
export const requestRosters = createAsyncAction(
  'RostersGetRequest',
  'RostersGetSuccess',
  'RostersGetError',
  'RostersGetCancel',
)<{ rosterIds: string[] }, { rosters: any }, IError, undefined>();

/** Gets all the Leagues for the current challengers in list */
export const requestLeagues = createAsyncAction(
  'LeaguesGetRequest',
  'LeaguesGetSuccess',
  'LeaguesGetError',
  'LeaguesGetCancel',
)<{ leagueIds: string[] }, { leagues: any }, IError, undefined>();

/** Request a specific challenger */
export const requestChallenger = createAsyncAction(
  'ChallengerGetRequest',
  'ChallengerGetSuccess',
  'ChallengerGetError',
  'ChallengesGetCancel',
)<
  { challengeId: string; challengerId: string },
  { challenger: any; challengeId: string },
  IError,
  undefined
>();

/** Request a specific roster */
export const requestRoster = createAsyncAction(
  'RosterGetRequest',
  'RosterGetSuccess',
  'RosterGetError',
  'RosterGetCancel',
)<
  { rosterId: string; challengerId: string; challengeId: string },
  { roster: any; rosterId: string; challengerId: string; challengeId: string },
  IError,
  undefined
>();

/** Request a specific League */
export const requestLeague = createAsyncAction(
  'LeagueGetRequest',
  'LeagueGetSuccess',
  'LeagueGetError',
  'LeagueGetCancel',
)<
  { leagueId: string; challengerId: string; challengeId: string },
  { league: any; leagueId: string; challengerId: string; challengeId: string },
  IError,
  undefined
>();

export const requestChallengeScoringBreakdown = createAsyncAction(
  'requestChallengeScoringBreakdownRequest',
  'requestChallengeScoringBreakdownSuccess',
  'requestChallengeScoringBreakdownError',
)<
  string,
  { id: string; value: IChallengeScoringBreakdown },
  { id: string; error: IError }
>();

export const createChallenge = createAsyncAction(
  'ChallengeCreateRequest',
  'ChallengeCreateSuccess',
  'ChallengeCreateError',
  'ChallengeCreateCancel',
)<IChallengeCreateBody, IChallenge, IError, void>();

export const getChallenge = createAsyncAction(
  'ChallengeGetRequest',
  'ChallengeGetSuccess',
  'ChallengeGetError',
)<string, IChallenge, { id: string; error: IError }>();

export const enterChallenge = createAsyncAction(
  'ChallengeEnterRequest',
  'ChallengeEnterSuccess',
  'ChallengeEnterError',
  'ChallengeEnterCancel',
)<
  {
    challengeId: string;
    challengerId: string;
    serviceFee: boolean;
    creditFee: number;
  },
  IChallenge,
  IError,
  undefined
>();

export const leaveChallenge = createAsyncAction(
  'ChallengeLeaveRequest',
  'ChallengeLeaveSuccess',
  'ChallengeLeaveError',
  'ChallengeLeaveCancel',
)<{ id: string; challengeId: string }, IChallenge, IError, undefined>();

export const createOpenChallenge = createAsyncAction(
  'OpenChallengeCreateRequest',
  'OpenChallengeCreateSuccess',
  'OpenChallengeCreateError',
  'OpenChallengeCreateCancel',
)<
  { challengeId: string; rosterID: string },
  IOpenChallenge,
  IError,
  undefined
>();

export const updateChallenge = createAsyncAction(
  'ChallengeUpdateRequest',
  'ChallengeUpdateSuccess',
  'ChallengeUpdateError',
  'ChallengeUpdateCancel',
)<IChallengeCreateBody, IChallenge, IError, void>();

export const refreshChallengePoints = createAsyncAction(
  'RefreshChallengePointsRequest',
  'RefreshChallengePointsSuccess',
  'RefreshChallengePointsError',
)<
  /* challengeID= */ string,
  {
    challengeId: string;
    points: { [challengerId: string]: IChallengerPlayerPoints[] };
    matchedRoster: MatchedRosters;
  },
  { challengeId: string; error: IError }
>();

export const requestUserLiveChallenges = createAsyncAction(
  'RequestUserLiveChallengesRequest',
  'RequestUserLiveChallengesSuccess',
  'RequestUserLiveChallengesError',
)<undefined, any, IError>();

export const requestUserCompletedChallenges = createAsyncAction(
  'RequestUserCompletedChallengesRequest',
  'RequestUserCompletedChallengesSuccess',
  'RequestUserCompletedChallengesError',
)<undefined, any, IError>();

/** Request all users for top challengers */
export const requestAllUsers = createAsyncAction(
  'UsersGetRequest',
  'UsersGetSuccess',
  'UsersGetError',
  'UsersGetCancel',
)<undefined, LeaderBoardUserData, IError, undefined>();

/** Request all users for teams played */
export const requestAllUsersPlayed = createAsyncAction(
  'UsersPlayedGetRequest',
  'UsersPlayedGetSuccess',
  'UsersPlayedGetError',
  'UsersPlayedGetCancel',
)<undefined, TeamsPlayedUserData, IError, undefined>();

const actions = {
  createChallenge,
  enterChallenge,
  requestChallenges,
  requestChallengeList,
  requestOpenChallenges,
  createOpenChallenge,
  updateChallenge,
  requestRosters,
  requestLeagues,
  requestChallenger,
  requestRoster,
  requestLeague,
  requestAllUsers,
  requestAllUsersPlayed,
  getChallenge,
  leaveChallenge,
  refreshChallengePoints,
  requestChallengeCompletedList,
  requestCompletedChallenges,
  requestChallengeScoringBreakdown,
  requestUserLiveChallenges,
  requestUserCompletedChallenges,
};

export type ChallengeActions = ActionType<typeof actions>;
