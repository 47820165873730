import { RetrievalState } from '@propsfantasy/retrieval';
import { Auth } from 'aws-amplify';
import { combineEpics } from 'redux-observable';
import { EMPTY, exhaustMap, from, of } from 'rxjs';
import {
  catchError,
  filter,
  ignoreElements,
  map,
  mergeMap,
  switchMap,
  takeUntil,
  tap,
} from 'rxjs/operators';
import { isActionOf } from 'typesafe-actions';
import { getWallet } from '../money/moneyActions';
import { Epic } from '../rootEpics';
import {
  createOneClickChallengeDefaults,
  createProvider,
  forgotPassword,
  getAllRostersForLeague,
  getAllTransactions,
  getCurrentUser,
  getCurrentUserLeagues,
  getCurrentUserProviders,
  getOneClickChallengeDefaults,
  getStoredUser,
  login,
  logout,
  register,
  resetPassword,
  setReferrer,
  submitIdentityVerification,
  updateUser,
  verifyEmail,
} from './userActions';
import * as reducer from './userReducer';

const createProviderEpic: Epic = (action$, _, { providerApi: api }) =>
  action$.pipe(
    filter(isActionOf(createProvider.request)),
    mergeMap((action) =>
      api.createProvider(action.payload).pipe(
        map((r) => createProvider.success(r)),
        catchError((err) =>
          of(createProvider.failure({ type: action.payload.type, err })),
        ),
      ),
    ),
  );

/**
 * Dispatches all data we want to initialize for user
 */
const getCurrentUserEpic: Epic = (action$, _, { authApi: api }) =>
  action$.pipe(
    filter(isActionOf(getCurrentUser.request)),
    mergeMap(() =>
      from(api.getCurrentUser()).pipe(
        mergeMap((user) =>
          of(
            getCurrentUser.success(user),
            getWallet.request(),
            getCurrentUserProviders.request(),
            getCurrentUserLeagues.request(),
            getStoredUser.request(),
          ),
        ),
        catchError((err) => of(getCurrentUser.failure(err))),
        takeUntil(action$.pipe(filter(isActionOf(getCurrentUser.cancel)))),
      ),
    ),
  );

const getCurrentUserProvidersEpic: Epic = (action$, _, { providerApi: api }) =>
  action$.pipe(
    filter(isActionOf(getCurrentUserProviders.request)),
    mergeMap(() =>
      from(api.getCurrentUserProviders()).pipe(
        mergeMap((providers) => of(getCurrentUserProviders.success(providers))),
        catchError((err) => of(getCurrentUserProviders.failure(err))),
        takeUntil(
          action$.pipe(filter(isActionOf(getCurrentUserProviders.cancel))),
        ),
      ),
    ),
  );

const getCurrentUserLeaguesEpic: Epic = (action$, _, { leagueApi: api }) =>
  action$.pipe(
    filter(isActionOf(getCurrentUserLeagues.request)),
    mergeMap(() =>
      from(api.getCurrentUserLeagues()).pipe(
        mergeMap((leagues) => of(getCurrentUserLeagues.success(leagues))),
        catchError((err) => of(getCurrentUserLeagues.failure(err))),
        takeUntil(
          action$.pipe(filter(isActionOf(getCurrentUserLeagues.cancel))),
        ),
      ),
    ),
  );

const getAllRostersForLeagueEpic: Epic = (action$, _, { leagueApi: api }) =>
  action$.pipe(
    filter(isActionOf(getAllRostersForLeague.request)),
    mergeMap(({ payload: leagueId }) =>
      from(api.getAllRostersForLeague(leagueId)).pipe(
        mergeMap((rosters) =>
          of(getAllRostersForLeague.success({ leagueId, rosters })),
        ),
        catchError((error) =>
          of(getAllRostersForLeague.failure({ leagueId, error })),
        ),
        takeUntil(
          action$.pipe(filter(isActionOf(getAllRostersForLeague.cancel))),
        ),
      ),
    ),
  );

const getAllTransactionsEpic: Epic = (action$, _, { moneyApi: api }) =>
  action$.pipe(
    filter(isActionOf(getAllTransactions.request)),
    mergeMap(() =>
      from(api.getAllTransactions()).pipe(
        mergeMap((transactions) =>
          of(getAllTransactions.success({ transactions })),
        ),
        catchError((error) => of(getAllTransactions.failure({ error }))),
        takeUntil(action$.pipe(filter(isActionOf(getAllTransactions.cancel)))),
      ),
    ),
  );

const verifyEmailEpic: Epic = (action$, _, { authApi: api }) =>
  action$.pipe(
    filter(isActionOf(verifyEmail.request)),
    mergeMap((action) =>
      from(
        api.verifyEmail(
          action.payload.username,
          action.payload.confirmationCode,
        ),
      ).pipe(
        mergeMap((user) => of(verifyEmail.success(user))),
        catchError((err) => of(verifyEmail.failure(err))),
        takeUntil(action$.pipe(filter(isActionOf(verifyEmail.cancel)))),
      ),
    ),
  );

const resetPasswordEpic: Epic = (action$, _, { authApi: api }) =>
  action$.pipe(
    filter(isActionOf(resetPassword.request)),
    mergeMap((action) =>
      from(
        api.resetPassword(
          action.payload.username,
          action.payload.code,
          action.payload.password,
        ),
      ).pipe(
        mergeMap((user) => of(resetPassword.success(user))),
        catchError((err) => of(resetPassword.failure(err))),
        takeUntil(action$.pipe(filter(isActionOf(resetPassword.cancel)))),
      ),
    ),
  );

const forgotPasswordEpic: Epic = (action$, _, { authApi: api }) =>
  action$.pipe(
    filter(isActionOf(forgotPassword.request)),
    mergeMap((action) =>
      from(api.forgotPassword(action.payload.username)).pipe(
        mergeMap((user) => of(forgotPassword.success(user))),
        catchError((err) => of(forgotPassword.failure(err))),
        takeUntil(action$.pipe(filter(isActionOf(forgotPassword.cancel)))),
      ),
    ),
  );

const loginEpic: Epic = (action$, _, { authApi: api }) =>
  action$.pipe(
    filter(isActionOf(login.request)),
    mergeMap((action) =>
      from(api.login(action.payload.username, action.payload.password)).pipe(
        mergeMap((user) => of(login.success(user), getCurrentUser.request())),
        catchError((err) => of(login.failure(err))),
        takeUntil(action$.pipe(filter(isActionOf(login.cancel)))),
      ),
    ),
  );

const getStoredUserEpic: Epic = (action$, _, { authApi: api }) =>
  action$.pipe(
    filter(isActionOf(getStoredUser.request)),
    switchMap(() =>
      from(api.getStored()).pipe(
        map(getStoredUser.success),
        catchError((err) => of(getStoredUser.failure(err))),
      ),
    ),
  );

const requestVerificationEpic: Epic = (action$, _, { authApi: api }) =>
  action$.pipe(
    filter(isActionOf(submitIdentityVerification.request)),
    switchMap((action) =>
      from(api.verifyIdentity(action.payload)).pipe(
        map((r) =>
          r.error
            ? submitIdentityVerification.failure({
                statusCode: 400,
                serviceError: { errorMessage: r.error, errorCode: 0 },
              })
            : submitIdentityVerification.success(r),
        ),
        catchError((err) => of(submitIdentityVerification.failure(err))),
      ),
    ),
  );

const setXpointUserEpic: Epic = (action$, _, { xpoint }) =>
  action$.pipe(
    filter(isActionOf([login.success, getCurrentUser.success, logout.success])),
    switchMap(() => Auth.currentUserInfo()),
    tap((user) => xpoint.setUserId(user?.attributes.sub)),
    ignoreElements(),
  );

const registerEpic: Epic = (action$, _, { authApi: api }) =>
  action$.pipe(
    filter(isActionOf(register.request)),
    mergeMap((action) =>
      from(api.register(action.payload)).pipe(
        mergeMap((user) => of(register.success(user))),
        catchError((err) => of(register.failure(err))),
        takeUntil(action$.pipe(filter(isActionOf(register.cancel)))),
      ),
    ),
  );

const updateUserEpic: Epic = (action$, _, { authApi: api }) =>
  action$.pipe(
    filter(isActionOf(updateUser.request)),
    mergeMap((action) =>
      from(api.updateUser(action.payload)).pipe(
        mergeMap((user) => of(updateUser.success(user))),
        catchError((err) => of(updateUser.failure(err))),
        takeUntil(action$.pipe(filter(isActionOf(updateUser.cancel)))),
      ),
    ),
  );

const logoutEpic: Epic = (action$, _, { authApi: api }) =>
  action$.pipe(
    filter(isActionOf(logout.request)),
    mergeMap(() =>
      from(api.logout()).pipe(
        mergeMap((user) => of(logout.success(user))),
        catchError((err) => of(logout.failure(err))),
        takeUntil(action$.pipe(filter(isActionOf(logout.cancel)))),
      ),
    ),
  );

const setReferrerEpic: Epic = (action$, state, { authApi: api }) =>
  action$.pipe(
    filter(isActionOf([getCurrentUser.success, setReferrer.request])),
    filter(
      () =>
        reducer.getCurrentUser(state.value).state === RetrievalState.Succeeded,
    ),
    mergeMap(() => {
      const referrer = reducer.getCurrentReferrer(state.value);
      return referrer ? api.setReferrer(referrer) : EMPTY;
    }),
    ignoreElements(),
  );

const createOneClickChallengeDefaultsEpic: Epic = (
  action$,
  _,
  { authApi: api },
) =>
  action$.pipe(
    filter(isActionOf(createOneClickChallengeDefaults.request)),
    exhaustMap(
      async (action) =>
        await api
          .createOrUpdateOneClickChallengeDefaults(action.payload)
          .then((r) => createOneClickChallengeDefaults.success(r))
          .catch((err) => createOneClickChallengeDefaults.failure(err)),
    ),
  );

const getOneClickChallengeDefaultsEpic: Epic = (action$, _, { authApi: api }) =>
  action$.pipe(
    filter(isActionOf(getOneClickChallengeDefaults.request)),
    mergeMap(
      async (action) =>
        await api
          .getOneClickChallengeDefaultsRequest()
          .then((r) => getOneClickChallengeDefaults.success(r))
          .catch((err) => getOneClickChallengeDefaults.failure(err)),
    ),
  );

export const userEpics = combineEpics(
  createProviderEpic,
  getCurrentUserEpic,
  getCurrentUserLeaguesEpic,
  getCurrentUserProvidersEpic,
  getAllRostersForLeagueEpic,
  getAllTransactionsEpic,
  verifyEmailEpic,
  resetPasswordEpic,
  forgotPasswordEpic,
  loginEpic,
  logoutEpic,
  registerEpic,
  requestVerificationEpic,
  updateUserEpic,
  setXpointUserEpic,
  getStoredUserEpic,
  setReferrerEpic,
  createOneClickChallengeDefaultsEpic,
  getOneClickChallengeDefaultsEpic,
);
