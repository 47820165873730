import produce, { Draft, Immutable } from 'immer';

export const produceItem =
  <T>(initial: Immutable<T>) =>
  (
    map: { [key: string]: Immutable<T> },
    key: string,
    producer: (item: Draft<Immutable<T>>) => void,
  ) => {
    map[key] = produce((item) => {
      producer(item);
    }, initial)(map[key]);
  };
